import React,{useState,useEffect} from 'react'
import {Digital} from "react-activity"
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import "react-activity/dist/library.css"
import { ToastContainer, toast } from 'react-toastify';
import * as xlsx from 'xlsx';
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style';
import "react-toastify/dist/ReactToastify.css";
import './AddStudent.css'
const AddStudent = () =>{
    const [dob, setDob] = useState(null)
    const [enrolledDob, setEnrolledDob] = useState(null)
    const [selectedRole, setSelectedRole] = useState('');
    const [loader, setLoader] = useState(false)
    const [buttonLoader, setButtonLoader] = useState(false)
    const [selectedClass, setSelectedClass] = useState(null)
    const [classList, setClassList] = useState([])
    const [focusStudent, setFocusStudent] = useState({
      _id:'',
      studentname:'',
      studentId:'',
      classId:'',
      student_id:null
    })

    const userCreds = JSON.parse(localStorage.getItem('userCreds'))
    const schoolID = userCreds.user.schoolId

    const excelData = [
      {'IDNumber':''},
      {'Name':''},
      {'GENDER':''},
      {'DOB':''},
      {'ENROLLED':''}
    ]

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const fileName = 'enrollStudentsTemplate'

    const exportToExcel = async() =>{
      const ws = XLSX.utils.json_to_sheet(excelData)
      const wb = {Sheets: {'data':ws},SheetNames:['data']}
      const excelBuffer = XLSX.write(wb, {bookType:'xlsx', type:'array'});
      const data = new Blob([excelBuffer],{type:fileType})
      FileSaver.saveAs(data, fileName + fileExtension);
    }

    const fetchClasses = async(schoolID) =>{
        setLoader(true)
          try {
              await fetch(`${global._URL}/classes/schoolclasses?schoolId=${schoolID}`)
                .then((res)=>{
                  return res.json()
                })
                .then((result)=>{
                  setLoader(false)
                 // console.log(result)
                  if(!result.message){
                    setClassList(result)
                    
                  }
                  //if(result.length === 1){
                    setSelectedClass(result[0]._id)
                    setFocusStudent((prevItem)=>({
                      ...prevItem,
                      ['classId']:result[0]._id
                    }))
                 // }
                  
                })
                .catch((err)=>{
                  setLoader(false)
                  console.log('Failed to fetch school classes',err)
                  const notify = () => {
                    toast.warn("Disconnected, check Internet", {
                      position: "top-center",
                      autoClose:1500,
                      hideProgressBar: true,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light"
                     })
                   };
          
                   notify()
                })
          } catch (error) {
            setLoader(false)
              console.log('Failed to get classes',error)
          }
      } 

      useEffect(()=>{
        const userCreds = JSON.parse(localStorage.getItem('userCreds'))
        const schoolID = userCreds.user.schoolId
        //console.log(schoolID)
          fetchClasses(schoolID)
    },[])

    const saveStudent = async() =>{
        setButtonLoader(true)
        console.log({
          studentname:focusStudent.studentname,
          classId:selectedClass,//focusStudent.classId,
          schoolId:schoolID,
          gender:selectedRole,
          DOB:dob,
          IDNumber:focusStudent.student_id,
          enrolledYear:enrolledDob
        })
        const options = {
          method:'POST',
          body:JSON.stringify({
            studentname:focusStudent.studentname,
            classId:selectedClass,
            gender:selectedRole,
            schoolId:schoolID,
            DOB:dob,
            IDNumber:focusStudent.student_id,
            enrolledYear:enrolledDob
          }),
          headers:{
            'Content-type':'application/json'
          }
        }
       try {
          await fetch(`${global._URL}/students/newstudent`,options)
            .then((res)=>{
              return res.json()
            })
            .then((result)=>{
              setButtonLoader(false)
              console.log({result})
              
  
              if(result.successMessage){
                setFocusStudent({
                  _id:'',
                studentname:'',
                studentId:'',
                classId:'',
                student_id:''
              })
                //Notification
               const notify = () => {
                toast.success("New student added successful", {
                  position: "top-center",
                  autoClose:3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
               notify()
              }else{
                const notify = () => {
                  toast.error(`Error, ${result.errorMessage}`, {
                    position: "top-center",
                    autoClose:3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
      
                 notify()
              }
              
            }).catch((err)=>{
              setButtonLoader(false)
              console.log('Server eroor',err)
  
              const notify = () => {
                toast.error("Server Error, try again", {
                  position: "top-center",
                  autoClose:3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
      
               notify()
            })
        } catch (error) {
          setButtonLoader(false)
          console.log('Failed to save class')
  
          const notify = () => {
            toast.warn("Disconnected, check Internet", {
              position: "top-center",
              autoClose:3000,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light"
             })
           };
  
           notify()
        }
      }

      const [studentsArray, setStudentsArray] = useState(null)

      const saveStudentsArray = async() =>{
        const indexOfEmptyEntry = studentsArray.findIndex(obj => Object.values(obj)[0] === "");

        // If an empty entry is found, remove all subsequent objects
        if (indexOfEmptyEntry !== -1) {
          studentsArray.splice(indexOfEmptyEntry);
        }

        setButtonLoader(true)
        console.log({
          schoolID,
          selectedClass,
          studentsArray
        })

        const options = {
          method:'POST',
          body:JSON.stringify({studentsArray
          }),
          headers:{
            'Content-type':'application/json'
          }
        }
       try {
          await fetch(`${global._URL}/students/newstudentarray?schoolId=${schoolID}&classId=${selectedClass}`,options)
            .then((res)=>{
              return res.json()
            })
            .then((result)=>{
              setButtonLoader(false)
              console.log({result})
              
  
              if(!result.errorMessage){
               
                //Notification
               const notify = () => {
                toast.success("New student added successful", {
                  position: "top-center",
                  autoClose:3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
               notify()
              }else{
                const notify = () => {
                  toast.error(`Error, ${result.errorMessage}`, {
                    position: "top-center",
                    autoClose:3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
      
                 notify()
              }
              
            }).catch((err)=>{
              setButtonLoader(false)
              console.log('Server eroor',err)
  
              const notify = () => {
                toast.error("Server Error, try again", {
                  position: "top-center",
                  autoClose:3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
      
               notify()
            })
        } catch (error) {
          setButtonLoader(false)
          console.log('Failed to save class')
  
          const notify = () => {
            toast.warn("Disconnected, check Internet", {
              position: "top-center",
              autoClose:3000,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light"
             })
           };
  
           notify()
        }
      }
  
      const handleInputChange = (e) =>{
        const {name,value} = e.target
        //console.log(name,value)
        setFocusStudent((prevItem)=>({
          ...prevItem,
          [name]:value
        }))
      }
  
      const handleClassChange = (focus) => {
        const selectedClassItem = focus.target.value
        setSelectedClass(selectedClassItem)
        //console.log(selectedClassItem)
      }
  
      const handleRoleChange = (e) => {
        //console.log(e.target.value)
        setSelectedRole(e.target.value);
      };
  
      const handleDateChange = (date) =>{
        console.log(date)
        setDob(date)
      }
  
      const handleEnrollDateChange = (date) =>{
        console.log(date)
        const fullYear = date.getFullYear()
        console.log(fullYear)
        setEnrolledDob(date)
      }


      const readUploadFile = (e) => {
        e.preventDefault();
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = xlsx.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = xlsx.utils.sheet_to_json(worksheet,{raw:false,dateNF:'yyyy-mm-dd'});
                console.log(json);
                setStudentsArray(json)
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
    }

    return(
        <div className='addStudent_container'>
        
            <div className='addStudentBody_wrapper'>
              <div className='offlineOptions_container'>
                <div className='downloadTemplate'> 
                  <button onClick={(e)=>exportToExcel()}>Download Template</button>
                </div>
                <div className='saveTemplate'>
                <form>
                  <input
                      type="file"
                      name="upload"
                      id="upload"
                      onChange={readUploadFile}
                  />
                </form>
                <button onClick={saveStudentsArray}>Save Template</button>
                </div>
              </div>
            <div className='addStudent_header'>
              <span>Fill Details</span>
            </div>  
            
            <div className='addStudent_details'>
                <select 
                  value={selectedClass} 
                  onChange={handleClassChange}
                  >
                  {classList.map((classItem)=>(
                    <option key={classItem._id} 
                       value={classItem._id}
                       >
                      {classItem?.classname}
                    </option>
                  ))}
                </select>
                <div className='nameInput'>
                <input type="text" 
                  placeholder="student name"
                  name="studentname"
                  value={focusStudent.studentname}
                 //onChange={(e)=>handleStudentChange(e)}
                  onChange={handleInputChange}
                />
                </div>
                
                <div className='radioContainer'>
               <label> 
                  <input
                    type="radio"
                    name="role"
                    value="Male"
                    checked={selectedRole === "Male"}
                    onChange={handleRoleChange}
                  />
                 Male
               </label>
               <label> 
                 <input
                   type="radio"
                   name="role"
                   value="Female"
                   checked={selectedRole === "Female"}
                   onChange={handleRoleChange}
                 />
                Female
               </label>
               </div>
               <DatePicker
                selected={dob}
                onChange={handleDateChange}
                dateFormat="MM/dd/yyyy"
                placeholderText={"Date of birth"}
                />

               <DatePicker
                selected={enrolledDob}
                onChange={handleEnrollDateChange}
                dateFormat="yyyy"
                showYearPicker
                placeholderText={"Enrolled year"}
              />
              <div className='idInput'>
              <input type="number" 
               placeholder="Reg Number"
               name="student_id"
               value={focusStudent.student_id}
               //onChange={(e)=>handleStudentChange(e)}
               onChange={handleInputChange}
               />
             </div>
             {/*<div className='idInput'>
              <input type="text" 
               placeholder="Exam No(Candidate)"
               //name="student_id"
              // value={focusStudent.student_id}
               //onChange={(e)=>handleStudentChange(e)}
              // onChange={handleInputChange}
               />
             </div>*/}
            </div>
             <div className='addStudent_buttons'>
                <button onClick={saveStudent}>
                    Enroll
                </button>
                {buttonLoader && (<Digital/>)}
             </div>
             </div>

             <ToastContainer
             position="top-center"
             autoClose={1500}
             hideProgressBar
             newestOnTop
             closeOnClick
             rtl={false}
             pauseOnFocusLoss
             draggable
             pauseOnHover
             theme="light"
            />
        </div>
    )
}

export default AddStudent

