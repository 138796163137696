import React,{useState,useEffect} from "react"
import { Link, useNavigate,useNavigation,Navigate } from "react-router-dom";
import './TeacherPage.css'

//import logo from '../../assets/fake_logo.jpeg'
import logo from '../assets/fake_logo.png';
import ClassList from "../subComponents/ClassList/ClassList";
import ManageClasses from "../subComponents/ManageClasses/ManageClasses";
import StudentList from "../StudentList/StudentList";
import ManageStudents from "../subComponents/ManageStudents/ManageStudents";
import ManageSubjects from "../subComponents/ManageSubjects/ManageSubjects";
import TeacherProfile from "../subComponents/TeacherProfile/TeacherProfile";
import ClassExercises from "../subComponents/ClassExercises/ClassExercises";
import Analytics from "../subComponents/Analytics/Analytics";

const TeacherPage = () =>{
    const [userDetails, setUserDetails] = useState({
        user_name:'',
        user_id:'',
        schoolId:'',
        role:''
      })
    const [activeFunctionality, setActiveFunctionality] = useState(null);

    const navigate = useNavigate()

  useEffect(()=>{
     //Validate If User Logged In
    const isLoggedIn = localStorage.getItem('userCreds')
    if(!isLoggedIn){
       return <Navigate to= "/login"/>
    }else{
      const userData = JSON.parse(isLoggedIn)
      console.log(userData)
        setUserDetails({
          user_name:userData.user.username,
          user_id:userData.user.userId,
          school_id:userData.user.schoolId,
          school_name:userData.user.schoolname,
          role:userData.role,
          accountStatus:userData.user.roleStatus
        })
    }
  },[])  

  //Date
  const today = new Date().toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  let functionalities;
  if(userDetails.accountStatus !== 'Frozen'){
    functionalities = [
      {
        id:1,
        title: 'Grade Tracker',
        routeName:'grade-tracker',
        description: 'Access reports, record marks & competencies',
        component: <ClassList />
      },
      {
        id:2,
        title: 'Performance Insights',
        routeName:'performance-insights',
        description: 'View student performance analytics',
        component: <Analytics />
      },
      {
        id:3,
        title: 'Teacher Profile',
        routeName:'teacher-profile', 
        description: 'Teacher School Profile.',
        component: <TeacherProfile />
      },
      {
        id:4,
        title: 'Competence',
        description: 'Students Competence Analysis',
        routeName:'class-quiz',
        component: <ClassExercises/>
      },
      // Add more functionality objects as needed
    ];
  }else{
    functionalities = [
      {
        id:1,
        title: 'Teacher Profile',
        routeName:'teacher-profile',
        description: 'Teacher School Profile.',
        component: <TeacherProfile />
      },
      // Add more functionality objects as needed
    ];
  }
    

    const handleFunctionalityClick = (functionalityId) => {
      /*const selectedFunctionality = functionalities.find(
        (functionality) => functionality.id === functionalityId
      );
      //console.log(selectedFunctionality)
      setActiveFunctionality(selectedFunctionality);*/
      navigate(`/tools/${functionalityId}`);
    };
  
  
    const handleGoBack = () => {
      setActiveFunctionality(null);
    };

    const handleLogOut = () =>{
      localStorage.clear('userCreds')
      navigate('/login')
    }

    return (
      <div className="main-page">
       <div className="header-part">
        <div className="logo-container">
          <img src={logo} alt="Company Logo" />
        </div>
        <div className="logout-button">
          <button onClick={handleLogOut}>Log Out</button>
        </div>
       </div> 
        
        <div className="topHeader_container">
        <div className="date-container">
          <p>
            {userDetails.school_name}
          </p>
        </div>
        <div className="greetings-container">
            <p>{today}</p>
        </div>
        </div>
        {!activeFunctionality && (
        <div className="functionalities-container">
          <h2 className="functionalities-heading">Functionalities</h2>
          <div className="functionalities">
            {functionalities.map((functionality) => (
              <div
                className="functionality-card"
                key={functionality.id}
                onClick={() => handleFunctionalityClick(functionality.routeName)}
              >
                <p>{functionality.title}</p>
                <p>{functionality.description}</p>
              </div>
            ))}
          </div>
        </div>
            )}
       {activeFunctionality && (
        <div>
          <button className="back-button" onClick={handleGoBack}>
            Back Home
          </button>
          {activeFunctionality.component}
        </div>
      )}
        </div>
    )
}

export default TeacherPage
