import React, { useEffect, useState, useReducer } from "react";
import './StudentProfile.css'
import { Link,useParams,useNavigation,useLocation } from "react-router-dom";
import {createRoot} from "react-dom/client";
import {ReactPDF,PDFViewer} from "@react-pdf/renderer"
import {Dots,Levels} from "react-activity"
import "react-activity/dist/library.css"
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import {Page,Text,View,Document,StyleSheet,PDFDownloadLink} from "@react-pdf/renderer"
import logo from '../assets/fake_logo.png';
import personAvatar from '../assets/person2.png'

const StudentProfile = () =>{
  const [classSubjectsList, setClassSubjectsList] = useState([])
  const [loader, setLoader] = useState(false)
  const [documentReady, setDocumentReady] = useState(false)
  const [subjectsData, setSubjectsData] = useState({
    subject:undefined,
    marks:undefined
  });
  const [reportData, setReportData] = useState([])
  const [previousMessages,setPreviousMessages] = useState([])
  const [recentReportData, setRecentReportData] = useState([])
  const [studentInfo, setStudentInfo] = useState(null)
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const subjectsList = JSON.parse(decodeURIComponent(urlParams.get('subjectsList')))
  const [subjectMarks, setSubjectMarks] = useState('');
  const [inputValue, setInputValue] = useState({})
  const [comments, setComments] = useState({});
  const [OverallPerformance,setOverallPerformance] = useState([])
  const [bestPerformingSubject, setBestPerformingSubject] = useState(null)
  const [areaToImprove, setAreaToImprove] = useState([])
  const [OverallSubjectPerformance, setOverallSubjectPerformance] = useState(null)
  const [studentInfoDetails,setStudentInfoDetails] = useState(null)
  const [PDFData, setPDFData] = useState(null)
    const studentId = useParams()

    const classSubjectsData = JSON.parse(localStorage.getItem('classSubjects'))
    const adminCreds = JSON.parse(localStorage.getItem('userCreds'))

    const studentID = useParams()
    //console.log(studentID)
    //console.log(classSubjectsData)

    //Load Todays Subject Marks if exists and previous comments

    const retrieveRecentReports = async() =>{
      console.log(studentID)
       try {
         await fetch(`${global._URL}/reports/recentreports/${studentID.studentId}`)
           .then((res)=> {
            return res.json()
          })
          .then((result)=>{
            console.log(result)
            if(result.successMessage){
              setRecentReportData(result.result)
            }
          })
          .catch((err)=>{
            console.log('Server error failed to retrieve reports',err)
          })
       } catch (error) {
        console.log('Failed to retrieve recent reports',error)
       }
    }

    const previousComments = async () =>{
      setLoader(true)
       try {
        await fetch(`${global._URL}/comments/studentcomments?studentId=${studentID.studentId}`)
          .then((res)=>{
            return res.json()
          })
          .then((result)=>{
            setLoader(false)
            //console.log('Previous commments',result)
            if(!result.message){
              setPreviousMessages(result)
            }
            
          })
          .catch((err)=>{
            setLoader(false)
            console.log('Failed to retrieve comments',err)
          })
       } catch (error) {
        setLoader(false)
        console.log('Failed to load Previous student comments',error)
       }
    }

    const fetchStudentDetails = async (reportdata) =>{
      console.log({
        studentId:reportdata
       })

      try {
        await fetch(`${global._URL}/students/studentdetails/${reportdata}`)
           .then((res)=>{
            return res.json()
           })
           .then((result)=>{
            console.log(result)
            if(result.successMessage){
              setStudentInfoDetails(result.result)
             // setStudentInfo(result.result)
              //setDocumentReady(true)
              //Render Documents inside PDF
             // setReportData(reportdata)
            }
           /* if(!result.message){
              setStudentInfo(result)
              setDocumentReady(true)
              //Render Documents inside PDF
              setReportData(reportdata)
            }*/
           })
           .catch((err)=>{
            console.log('Failed to fetch student data',err)
           })
      } catch (error) {
        console.log('Failed to fetch students data',error)
      }
    } 

    useEffect(()=>{
      console.log(studentID.studentId)
      previousComments()
      retrieveRecentReports()
      fetchStudentDetails(studentID.studentId)
    },[])
  
    const [commentMessage,setCommentMessage] = useState('')
    const handleCommentChange = (e) => {
      setCommentMessage(e.target.value)
    };
  
  
    const handleSubmitComment = async() => {
      console.log({
        studentId:studentID.studentId,
        comment:commentMessage,
        signedBy:adminCreds.user.userId
      })
      // Clear the input field for the comment

      const options = {
        method:'POST',
        body:JSON.stringify({
        studentId:studentID.studentId,
        comment:commentMessage,
        signedBy:adminCreds.user.userId
      }),
        headers:{
           'Content-type':'application/json'
        }
      }
      try {
        await fetch(`${global._URL}/comments/newcomment`,options)
         .then((res)=>{
          return res.json()
         })
         .then((result)=>{
          console.log('CommentSubmissionResult',result)
          setCommentMessage("")
          if(!result.message){
            setPreviousMessages([...previousMessages, result])
            //Notification

          const notify = () => {
            toast.success("Comment sent successfully.", {
              position: "top-center",
              autoClose:1500,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light"
            })
          };

         notify()
          }
         })
         .catch((err)=>{
          console.log('Failed to save comment',err)
          const notify = () => {
            toast.error("Server error, try again.", {
              position: "top-center",
              autoClose:1500,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light"
            })
          };

         notify()
         })
      } catch (error) {
        console.log('Failed to submit comments',error)
        const notify = () => {
          toast.error("Failed to send, check internet connection.", {
            position: "top-center",
            autoClose:1500,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light"
          })
        };

       notify()
      }
    };

    const handleMarksChange = (event, subjectId) => {
      console.log(event.target.value)
      console.log(subjectId)
      setInputValue((data)=>({
        ...data,
        [event.target.id]: event.target.value,
      }))
      //console.log(inputValue)

      const updatedSubjectsData = classSubjectsData.filter((subject)=>{
        return subject._id === subjectId
      })
  
     
      setSubjectsData({
        subject:updatedSubjectsData[0],
        marks:event.target.value//inputValue[updatedSubjectsData[0].subjectname]//event.target.value
      });
    }

    const [focusQuality, setFocusQuality] = useState([])

    const handlePerformanceMarkChange = (event,subjectId,criteriaId) =>{
     /* console.log({
        'data':event.target.value, 
        'subjectId':subjectId,
        'criteriaId':criteriaId
      })*/

      const input_data = event.target.value

      //Focus subject
      const focusSubjects = classSubjectsData.filter((subject)=>{
        return subject._id === subjectId
      })
      //console.log({focusSubjects:focusSubjects[0].qualities})

      //Focus dev_criteria
      const focusCriteria = focusSubjects[0].qualities?.filter((criteria)=>{
        return criteria._id === criteriaId
      })
     // console.log({focusCriteria})

      //Save Inputs that comes from diff qualities
      const index = focusQuality.findIndex(obj => obj.id === focusCriteria[0]._id)

      if(index !== -1){
        setFocusQuality(prevData=>{
          const newData = [...prevData]
          newData[index] = {...newData[index], criterion:focusCriteria[0].name, score:input_data}
          return newData
        })
      }else{
         setFocusQuality(prevData=> [...prevData, {id:focusCriteria[0]._id, criterion:focusCriteria[0].name,score:input_data}])
      }
    }

    const saveMarks = async(subjectId,subjectName) => {
      console.log({subjectID:subjectId,subject:subjectName})

      //Filter qualities
      //console.log({classData:classSubjectsData})
      //console.log(focusQuality)

     //Filter focusSubject
     const subject_inFocus = classSubjectsData.filter((subject)=>{
      return subject._id === subjectId
     })

     //console.log(subject_inFocus[0].qualities)
    
     //Filter subject performance criteria
     //const subjectPerformance_list = subject_inFocus.qualities.filter((item)=>{})
     const resultPerformanceList = focusQuality.filter(item=>{
      //console.log({id:item.id,criteria:item.criterion})
      return subject_inFocus[0].qualities?.some(item2=>item2._id === item.id)
     })

     //console.log({resultPerformanceList})

      console.log({
        studentId:studentId.studentId,
        subjectname: subjectName,//subjectsData.subject.subjectname,
        subjectId: subjectId,//subjectsData.subject._id,
        subject_score: subjectsData.marks,//marks
        performance:resultPerformanceList,//focusQuality,
        signedBy:adminCreds.user.username,
      })
      try {
        // Send the marks to the API using fetch or any other HTTP client library
        await fetch(`${global._URL}/reports/observationreport`, {
          method: 'POST',
          body: JSON.stringify({
          studentId:studentId.studentId,
          subjectname: subjectName,//subjectsData.subject.subjectname,
          subjectId: subjectId,
          subject_score: subjectsData.marks,//marks
          performance:resultPerformanceList,
          signedBy:adminCreds.user.username,
        }),
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(response => response.json())
          .then(data => {
            console.log(data)
            const subjectfield = document.getElementById(subjectsData.subject.subjectname) 
            //const performancefield = document.getElementById(subjectsData.item.subjectname) 
            subjectfield.value = ''
            if(data.successMessage){
              //alert("Report saved succesful")
              //Notification
              const notify = () => {
                toast.success("Report saved successfully !!", {
                  position: "top-center",
                  autoClose:1500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                })
             };

            notify()
            }
          // Update the state with the response data if needed
        })
        .catch(error => {
          console.error('Error saving report marks:', error);
          const notify = () => {
            toast.error("Server Error, try again", {
              position: "top-center",
              autoClose:1500,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light"
            })
         };

        notify()
        });
      } catch (error) {
        console.log('Failed to save observation report', error)
        const notify = () => {
          toast.warn("Disconnected, check Internet", {
            position: "top-center",
            autoClose:1500,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light"
          })
       };

      notify()
      }
    };

    const computeStudentData = (monthlyReports) =>{
      const subjectPerformances = {};
      const subjectCounts = {};
      
      monthlyReports.forEach((report) => {
        //const { subjectname, subject_score } = report;
        const subjectname = report.subjectname
        const subject_score = parseFloat(report.subject_score)
        if (subjectPerformances[subjectname]) {
          subjectPerformances[subjectname] += subject_score;
          subjectCounts[subjectname]++;
        } else {
          subjectPerformances[subjectname] = subject_score;
          subjectCounts[subjectname] = 1;
        }
      });
      
      
      Object.keys(subjectPerformances).forEach((subjectname) => {
        subjectPerformances[subjectname] /= subjectCounts[subjectname];
        subjectPerformances[subjectname] = Math.floor(subjectPerformances[subjectname])
      });
      
      
      // Calculate overall growth in all measured criteria
      const criterionGrowth = {};
      const criterionCounts = {};
      monthlyReports.forEach((report) => {
        report.performance.forEach((performance) => {
          //const { criterion, score } = performance;
          const criterion = performance.criterion
          const score = parseFloat(performance.score)
          if (criterionGrowth[criterion]) {
            criterionGrowth[criterion] += score;
            criterionCounts[criterion]++;
          } else {
            criterionGrowth[criterion] = score;
            criterionCounts[criterion] = 1;
          }
        });
      });
      
      
      const overallGrowth = Object.entries(criterionGrowth).map(([criterion, score]) => {
        const averageScore = score / criterionCounts[criterion];
        let status;
    
        if (averageScore >= 80) {
          status = "Master";
        } else if (averageScore >= 70) {
          status = "Very Good";
        } else if (averageScore >= 60) {
          status = "Good";
        } else if (averageScore >= 50) {
          status = "Average";
        } else {
          status = "Bad";
        }
    
        return {
          criterion,
          score: Math.floor(averageScore),
          status,
        };
      });
      
      // Find the best performing subjects
      const bestPerformingSubjects = Object.entries(subjectPerformances)
        .sort((a, b) => b[1] - a[1])
        .map(([subject, score]) => ({ subject, score }));
    
        const areasOfImprovement = Object.entries(criterionGrowth)
         .filter(([criterion, score]) => {
           const averageScore = score / criterionCounts[criterion];
           return averageScore < 60; // Adjust the threshold as needed
         })
         .map(([criterion]) => {
           const matchingCriteria = monthlyReports[0].performance.find(
             (performance) => performance.criterion === criterion
           );
           return matchingCriteria ? matchingCriteria.criterion : null;
         })
        .filter((criterion) => criterion !== null);
      
      
      console.log("Average Performance in Each Subject:", subjectPerformances);
      console.log("Overall Growth in Criteria:", overallGrowth);
      console.log("Best Performing Subjects:", bestPerformingSubjects[0]);
      console.log("Areas of Improvement:", areasOfImprovement);
    
      if(overallGrowth.length !== 0){
        setOverallPerformance(overallGrowth)
      }
    
      if(subjectPerformances){
        setOverallSubjectPerformance(subjectPerformances)
      }
    
      if(bestPerformingSubjects.length !== 0){
        setBestPerformingSubject(bestPerformingSubjects[0])
      }
    
      if(areasOfImprovement.length !== 0){
        setAreaToImprove(areasOfImprovement)
      }

      if(subjectPerformances && overallGrowth && bestPerformingSubjects[0] && areasOfImprovement){
        setPDFData({
        subjectPerformances,
        overallGrowth,
        bestPerformingSubjects:bestPerformingSubjects[0],
        areasOfImprovement
      })
      }
      
    
    }

    const reportDuration = [
      {id:1, duration:1, period:'Monthly'},
      {id:2, duration:3, period:'Quarter'},
      {id:3, duration:6, period:'Semi-Annually'},
      {id:4, duration:12, period:'Annual'}
    ]

    const[reportPeriod, setReportPeriod] = useState('')
    const retrieveReport = async(timeperiod) =>{
      console.log({
        studentId:studentId.studentId,
        timeperiod:timeperiod
      })

       try {
        await fetch(`${global._URL}/reports/studentreports?studentId=${studentId.studentId}&timeperiod=${timeperiod}`)
          .then((res)=>{
            return res.json()
          })
          .then((result)=>{
            console.log(result.result[0])
            if(result.successMessage){
              //setTodayReportList(result)
              //setStudentReports(result.result)
              setReportPeriod(timeperiod)
              computeStudentData(result.result)
              if(result.result.length !== 0){
                fetchStudentData(result.result[0].studentId)
              }
            }

          })
          .catch((err)=>{
            console.log('Failed to get reports',err)
          })
       } catch (error) {
        console.log('Failed to retrieve reports',error)
       }
    }

    const fetchStudentData = async (reportdata) =>{
      console.log({
        studentId:reportdata
       })

      try {
        await fetch(`${global._URL}/students/studentdetails/${reportdata}`)
           .then((res)=>{
            return res.json()
           })
           .then((result)=>{
            console.log(result)
            if(result.successMessage){
              setStudentInfo(result.result)
              setDocumentReady(true)
              const notify = () => {
                toast.info("Student report is ready", {
                    position: "top-center",
                    autoClose:1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                })
             };
    
            notify()
              //Render Documents inside PDF
             // setReportData(reportdata)
            }
           /* if(!result.message){
              setStudentInfo(result)
              setDocumentReady(true)
              //Render Documents inside PDF
              setReportData(reportdata)
            }*/
           })
           .catch((err)=>{
            console.log('Failed to fetch student data',err)
            const notify = () => {
              toast.error("Server Error, try again", {
                  position: "top-center",
                  autoClose:1500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
              })
           };
  
          notify()
           })
      } catch (error) {
        console.log('Failed to fetch students data',error)
        const notify = () => {
          toast.warn("Disconnected check Internet", {
              position: "top-center",
              autoClose:1500,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light"
          })
       };

      notify()
      }
    }  

    const formattedDate = (dateString) =>{
      const date = new Date(dateString)
      return date.toLocaleString()
    }

    const reportTime = (duration)=>{
      let durationTime
      if(duration === 1){
        return durationTime = 'Monthly'
      }else if(duration === 3){
        return durationTime = 'Quartery'
      }else if(duration === 6){
        return durationTime = 'Semi-Annual'
      }else if(duration === 12){
        return durationTime = 'Annual'
      }else{
        return durationTime = ''
      }   
    }

    const styles = StyleSheet.create({
      page: {
        //fontFamily: 'Arial',
        backgroundColor:'#F2F2F2',
        fontSize: 12,
        padding: 40,
      },
      school_title: {
        marginBottom: 20,
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 'bold',
      },
      report_title: {
        marginBottom: 30,
        textAlign: 'center',
        fontSize: 16,
        fontWeight: 'bold',
        textDecoration: 'underline',
      },
      studentInfo: {
        marginBottom: 20,
        backgroundColor: '#E4E4E4',
        padding: 10,
      },
      studentInfo_text: {
        fontWeight: 'bold',
      },
      subjectPerformances: {
        marginBottom: 20,
        backgroundColor: '#E4E4E4',
        padding: 10,
      },
      subjectPerformances_title: {
        marginBottom: 10,
        fontWeight: 'bold',
        textDecoration: 'underline',
      },
      subjectPerformances_list: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      subjectPerformances_listCard:{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems:'center',
        gap:12
      },
      subjectPerformances_item: {
        width: '50%',
      },
      bestPerformingSubject: {
        marginBottom: 20,
        backgroundColor: '#E4E4E4',
        padding: 10,
      },
      bestPerformingSubject_title: {
        marginBottom: 10,
        fontWeight: 'bold',
        textDecoration: 'underline',
      },
      bestPerformingSubject_list: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        gap:12,
        alignItems:'center'
      },
      holisticDevelopment: {
        marginBottom: 20,
        backgroundColor: '#E4E4E4',
        padding: 10,
      },
      holisticDevelopment_title: {
        marginBottom: 10,
        fontWeight: 'bold',
        textDecoration: 'underline',
      },
      holisticDevelopment_list: {
        marginBottom: 10,
      },
      holisticDevelopment_listCard:{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems:'center',
        gap:12,
        paddingBottom:10
      },
      holisticDevelopment_item: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      areasOfImprovement: {
        marginBottom: 20,
        backgroundColor: '#E4E4E4',
        padding: 10,
      },
      areasOfImprovement_title: {
        marginBottom: 10,
        fontWeight: 'bold',
        textDecoration: 'underline',
      },
      areasOfImprovement_list: {
        marginLeft: 20,
      },
    })

    const DocPDF = () =>{
      //console.log(PDFData,studentInfo)
     /* setPDFData({
        subjectPerformances,
        overallGrowth,
        bestPerformingSubjects:bestPerformingSubjects[0],
        areasOfImprovement
      })*/
      const reportTimePeriod = reportDuration.filter((time)=>{
           return time.duration === reportDuration 
      })

      //console.log(reportTimePeriod)

      return(
        <Document>
            <Page style={styles.page}>
             <View style={styles.school_title}>
                <Text>
                  {studentInfo.school}
                </Text>
              </View>
              <View style={styles.report_title}>
                <Text>
                  STUDENT PROGRESS REPORT
                </Text>
              </View>
              <View style={styles.studentInfo}>
                <Text>Student: {studentInfo.studentname}</Text>
                <Text>Class: {studentInfo.class}</Text>
              </View>
              <View style={styles.subjectPerformances}>
                    <View style={styles.subjectPerformances_title}>
                       <Text>Overall Class Subjects Performance</Text>
                    </View>
                    <View style={styles.subjectPerformances_list}>
                      {Object.entries(PDFData.subjectPerformances)?.map(([subject,score])=>(
                      <View style={styles.subjectPerformances_listCard}>
                        <Text>{subject}</Text>
                        <Text>{score}</Text>
                      </View>
                      ))}
                    </View>
              </View>
              <View style={styles.bestPerformingSubject}>
                    <View style={styles.bestPerformingSubject_title}>
                      <Text>Best Performing Subject</Text>
                    </View>
                    <View style={styles.bestPerformingSubject_list}>
                      <Text>{PDFData.bestPerformingSubjects?.subject}</Text>
                      <Text>{PDFData.bestPerformingSubjects?.score}</Text>
                    </View>
              </View>
              <View style={styles.holisticDevelopment}>
                    <View style={styles.holisticDevelopment_title}>
                       <Text>Overall Holistic Performance</Text>
                    </View>
                    <View style={styles.holisticDevelopment_list}>
                      {PDFData.overallGrowth.map((data)=>(
                      <View style={styles.holisticDevelopment_listCard}>
                       <Text>{data.criterion}</Text>
                       <Text>{data.score}</Text>
                       <Text>{data.status}</Text>
                      </View>
                    ))}
                    </View>
              </View>
               
                {PDFData.areasOfImprovement.length !== 0 && (
                  <View style={styles.areasOfImprovement}>
                     <View style={styles.areasOfImprovement_title}>
                      <Text>Areas to Improvement</Text>
                     </View>
                     {PDFData.areasOfImprovement.map((criteria)=>(
                       <View style={styles.areasOfImprovement_list}>
                         <Text>{criteria}</Text>
                       </View>
                  ))}
                  </View>
                )}   
            </Page>
          </Document>
      )
    }
    
    return (
      <div className="teacher-form_container">
        <div className = "form-header">
          <p>Student Progress Report Form</p>
        </div>

        {/**------Student ID--------- */}
        <div className="student-info_container">
          <div className="student-info_avatar">
            <img src={[personAvatar]} alt="Avatar" className="avatar" />
          </div>
          <div className="student-info_details">
            <p>Student Name: {studentInfoDetails?.studentname}</p>
            <p>Class: {studentInfoDetails?.class}</p>
          </div>      
        </div>

        {/**------Past Reports--------- */}
        <div className="form-report"> 
          <div className="form-report_title"> 
           <p>Retrieve past {studentInfoDetails?.studentname} reports</p>
          </div>  
          <div className="reports-cards">
            {reportDuration.map((time)=>(
            <button onClick={()=>retrieveReport(time.duration)}>
              {time.period}
            </button>
          ))}
          </div>
          <div>
          {documentReady && (
            <PDFDownloadLink document={<DocPDF/>} fileName="docPDF.pdf">
            {({blob,url,loading,error})=>(loading ? 'Loading document...':'Download Report')}
           </PDFDownloadLink>
          )}  
          </div>
        </div>
       
       {/**------Recent Reports--------- */}
        <div className="form-report"> 
          <div className="form-report_title"> 
           <p>Recent {studentInfoDetails?.studentname} reports</p>
          </div>
          <div className="form-report_recent-cards">
            {recentReportData.map((subject)=>(
              <div className="form-report_recent-card">
                <div className="form-report_recent-cards_main-subject">
                 <p>{subject.subjectname}</p>
                 <p>{subject.subject_score}</p>
                </div>
                {subject.performance.map((criteria)=>(
                   <div className="form-report_recent-cards_subject-criterias">
                     <p>{criteria.criterion}</p>
                     <p>{criteria.score}</p>
                  </div>
                ))}
                
              </div>
            ))}
          </div>  
        </div>

        {/**------New Report--------- */}
        {classSubjectsData.length !== 0 ? (
          <div className="subjects-list">
           <div className="form-report_title"> 
            <p>New report</p>
           </div>
           <div className="form-report_score-demonstration"> 
            <p>Score 80+ Master</p>
            <p>Score 70+ Very Good</p>
            <p>Score 60+ Good</p>
            <p>Score -50 Average</p>
           </div>
            {classSubjectsData?.map((subject)=>(
           <div className="report-form_card" key={subject._id}>
             <div className="report-form_card-subject"> 
               <label>{subject.subjectname}</label>
               <input
                type="number"
                id={subject.subjectname}
                //value={subjectMarks}
                onChange={event => handleMarksChange(event, subject._id)}
               />
             </div>
           <div className="report-form_card-quality_list">
            {subject.qualities?.map((item)=>(
              <div className="report-form_card-quality_criteria">
                <label>{item.name}</label>
                <input
                  type="number"
                  id={item.name}
                  //value={subjectMarks}
                  onChange={event => handlePerformanceMarkChange(event, subject._id, item._id)}
                />
              </div>
            ))}
           </div>

          {subjectsData.subject?.subjectname === subject.subjectname &&
            <div className="report-form_card-quality_list-button">
               <button 
                 onClick={() => saveMarks(subject._id,subject.subjectname)}
               >
                Save
              </button>
            </div>
          } 
         </div>
         
        ))}
       
       </div>
        ):(
          <>
           <p>No Subjects Data yet</p>
          </>
        )}
        
         
         {/**------Comment Section--------- */}
          <div className="comments-container">
           <div className="comments-inputs">
            <textarea
              className="comment-input"
              placeholder="Teacher comment if necessary"
              value={commentMessage}
              onChange={(e)=>handleCommentChange(e)}
            />
            <div className="comment-input_saveButton">
            <button
              type="button"
              className="send-button"
              onClick={() => handleSubmitComment('comment')}
            >
              Send
            </button>

            </div>
            
           </div>

          {loader ? (
            <div className="loader">
            <Levels/>
            </div>
          ):(
            <div className="comments-cards">
          {previousMessages.length !== 0 ? (
            <>
              {previousMessages?.slice(-5).reverse().map((comment)=>(
            <div className="previous-comments-card" key={comment._id}>
              <div>
                <p>{comment.comment}</p>
              </div>
              <div className="comment-status">
                 <div>
                  {comment.seen ?(
                   <p>Seen</p>
                  ):(
                    <p>Unopened</p>
                  )}
                </div>
                <div>
                  <p>{formattedDate(comment.createdAt)}</p>
                </div>
              </div>
          </div>
          ))}
            </>
          ):(
            <>
              <p>No Comments yet</p>
            </>
          )}
          </div> 
          )}
          
         </div>
         <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        </div>
      
    );
}

export default StudentProfile