import React,{useState} from 'react'

import './UpdateClassExercise.css'
const UpdateClassExercise = () =>{
    return(
        <div className='UpdateExer_Report_wrapper'>
          <div className='UpdateExer_Report_container'>
           <div className='UpdateExer_Report_header'>
            <span>Update Exercise Report</span>
           </div>
           <div className='UpdateExer_ReportDetails'>
             <div className='UpdateExer_ReportDetails_classChoice'>
              <span>Class Exercise</span>
              <span>Options</span>
             </div>
             <div className='UpdateExer_ReportDetails_reportname'>
              <span>Report Name</span>
              <span>input</span>
             </div>
             <div className='UpdateExer_ReportDetails_subjectChoice'>
              <span>Report Subject</span>
              <span>Options</span>
             </div>
             <div className='UpdateExer_ReportDetails_topicsChoice'>
              <span>Tag Topics</span>
              <span>Options</span>
             </div>
             <div className='UpdateExer_ReportDetails_conceptsChoice'>
              <span>Tag Concepts</span>
              <span>Options</span>
             </div>
             <div className='UpdateExer_ButtonsOptions'>
              <button>Save</button>
              <button>Cancel</button>
             </div>
           </div>
         </div>
        </div>
    )
}

export default UpdateClassExercise