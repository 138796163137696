import React,{useState,useEffect} from "react"
import "./AddCAReport.css"
import {Digital} from "react-activity"
import "react-activity/dist/library.css"
import { ToastContainer, toast } from 'react-toastify';
import {useLocation, Link,useNavigate } from "react-router-dom";

const AddCAReport = () =>{
    const [reportList, setReportList] = useState([])
    const [firstTermSelections, setFirstTermSelections] = useState([]);
    const [secondTermSelections, setSecondTermSelections] = useState([]);
    const [buttonLoader,setButtonLoader] = useState(false)

      const location = useLocation()
      const newCAData = location.state

     // console.log({newCAData}reportList = [] || reportList

      // Change handler for first term selection
      const handleFirstTermChange = (e,index) => {
        const selectedReportId = e.target.value//parseInt(e.target.value);
        console.log(selectedReportId)
        const selectedReport = reportList.find(report => report._id === selectedReportId);
        console.log(selectedReport)
        
        // Check if the selected report already exists in the selections
        const existingIndexFirst = firstTermSelections.findIndex(selection => selection && selection.examId === selectedReport._id);
        const existingIndexSecond = secondTermSelections.findIndex(selection => selection && selection.examId === selectedReport._id);
        //const existingIndex = firstTermSelections.findIndex(selection => selection && selection.examId === selectedReport.id);
      
        if (existingIndexFirst === -1 && existingIndexSecond === -1 && firstTermSelections.length < 3) {
          setFirstTermSelections(prevSelections => [...prevSelections, { examId: selectedReport._id }]);
        } else {
          console.log("First term full or exam already selected")
          // Handle error: Exam already selected or limit reached
        }
      };

      const handleRemoveFirstTermSelection = (indexToRemove) => {
        setFirstTermSelections(prevSelections => {
          return prevSelections.filter((_, index) => index !== indexToRemove);
        });
      };
      
    
      // Change handler for second term selection

      const handleSecondTermChange = (e,index) => {
        const selectedReportId = e.target.value//parseInt(e.target.value);
        const selectedReport = reportList.find(report => report._id === selectedReportId);
      
        // Check if the selected report already exists in the selections
        //const existingIndex = secondTermSelections.findIndex(selection => selection && selection.examId === selectedReport.id);
        const existingIndexFirst = firstTermSelections.findIndex(selection => selection && selection.examId === selectedReport._id);
        const existingIndexSecond = secondTermSelections.findIndex(selection => selection && selection.examId === selectedReport._id);
        // If the selected report already exists, update it; otherwise, add a new selection

        if (existingIndexFirst === -1 && existingIndexSecond === -1 && secondTermSelections.length < 3) {
          setSecondTermSelections(prevSelections => [...prevSelections, { examId: selectedReport._id }]);
        } else {
          console.log("Second term full or exam already selected")
          // Handle error: Exam already selected or limit reached
        }
      };

      const handleRemoveSecondTermSelection = (indexToRemove) => {
        setSecondTermSelections(prevSelections => {
          return prevSelections.filter((_, index) => index !== indexToRemove);
        });
      };

      const handleSave = () => {
            console.log('First Term Selections:', firstTermSelections);
            console.log('Second Term Selections:', secondTermSelections);
            // Add your save logic here...
      };    

      const fetchReports = async(classItem) =>{
        const currentDate = new Date()
        const listedYear =  currentDate.getFullYear() 
          console.log({classId:classItem})
          try {
              await fetch(`${global._URL}/schoolreportform/classreportformlist?classId=${classItem}&year=${listedYear}`)
                .then((res)=>{
                  return res.json()
                })
                .then((result)=>{
                  console.log(result.result)
                  
                  if(result.result){
                    setReportList(result.result)
                  }
                })
                .catch((err)=>{
                  console.log('Failed to fetch class reports',err)
                })
          } catch (error) {
              console.log('Failed to get class reports',error)
              const notify = () => {
                toast.warn("Disconnected, check Internet", {
                  position: "top-center",
                  autoClose:3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
      
               notify()
          }
      }
     
      useEffect(()=>{
        fetchReports(newCAData.classId)
      },[newCAData.classId])
    
        

        const saveCAReport = async() =>{
           console.log({
             classId:newCAData.classId,
             term1Exams:firstTermSelections,
             term2Exams:secondTermSelections
             })

             if(firstTermSelections?.length === 0 && secondTermSelections?.length === 0){
              const notify = () => {
                toast.warn("Choose Exams", {
                  position: "top-center",
                  autoClose:3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
               notify()
               return
             }
    
             const option = {
              method:'POST',
              body:JSON.stringify({
                classId:newCAData.classId,
                term1Exams:firstTermSelections,
                term2Exams:secondTermSelections
              }),
              headers:{
                'Content-type':'application/json'
              }
            }
          
             try {
                const response = await fetch(`${global._URL}/CA/newCA`,option)
                const data = await response.json()
                console.log(data)
                      //alert("Subject saved successful")
                       //Notification
                   const notify = () => {
                        toast.success("New progressive report added", {
                          position: "top-center",
                          autoClose:3000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light"
                         })
                       };
            
                       notify()
                      //fetchSubjects(result.classId)
              } catch (error) {
                setButtonLoader(false)
                console.log('Failed to save report')
          
                const notify = () => {
                  toast.warn("Internal server error", {
                    position: "top-center",
                    autoClose:3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
          
                 notify()
              }
      }    

    return(
    <div className="m-4 grid gap-2 w-[40rem]">
      <div className="min-h-[2rem] rounded-lg  shadow-sm">
        <h1 className="px-4 py-5 text-left text-2xl font-bold sm:text-2xl tracking-tight text-indigo-600">Class Progressive Report</h1>
      </div>
      <div className="min-h-[80px] rounded-lg">
        <input type="text" disabled className="my-4 block h-10 w-[25rem] rounded-md border-2 p-2 py-1.5 text-blue-900 shadow-sm" placeholder={`${newCAData?.className || `CLASS`} PROGRESSIVE REPORT 2024`}/>
      </div>
      <div className="border-2 border-blue-50 rounded-lg">
      <h2 className="text-lg py-2 mx-4">First Term Exams</h2>
      <div className="grid min-h-[100px] rounded-lg shadow-sm sm:grid-cols-12">
      {Array.from({ length: 3 })?.map((_, index) => (
          <div className="m-4 min-h-[30px] rounded-lg border-2 border-blue-50 shadow-sm sm:col-span-4" key={index}>
            <div className="w-full px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold my-2" htmlFor={`first-term-test-${index + 1}`}>
                TEST {index + 1}
              </label>
              <div className="relative">
                <select
                  className="block appearance-none w-full bg-white-200 border border-indigo-200 text-black-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id={`first-term-test-${index + 1}`}
                  value={firstTermSelections[index] ? firstTermSelections[index].examId : ''}
                  onChange={/*handleFirstTermChange*/(e) => handleFirstTermChange(e, index)}
                >
                  <option value="">Select Exam</option>
                  {reportList?.map(report => (
                    <option key={report._id} value={report._id}>{report.reportname}</option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                </div>
                {firstTermSelections[index] && (
                <div className="flex items-center mt-2">
                  <span className="text-gray-700">{reportList?.find(report => report._id === firstTermSelections[index].examId).reportname}</span>
                  <button className="ml-2 text-red-600" onClick={() => handleRemoveFirstTermSelection(index)}>Remove</button>
                </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      </div>
      <div className="border-2 border-blue-50 rounded-lg">
        <h2 className="text-lg py-2 mx-4">Second Term Exams</h2>
      <div className="grid min-h-[100px] rounded-lg  shadow-sm sm:grid-cols-12">
      {Array.from({ length: 3 })?.map((_, index) => (
          <div className="m-4 min-h-[30px] rounded-lg border-2 border-blue-50 shadow-sm sm:col-span-4 my-2" key={index}>
            <div className="w-full px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold my-2" htmlFor={`second-term-test-${index + 1}`}>
                TEST {index + 1}
              </label>
              <div className="relative w-full">
                <select
                  className="block  appearance-none w-full border border-indigo-200 text-black-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"
                  id={`second-term-test-${index + 1}`}
                  value={secondTermSelections[index] ? secondTermSelections[index].examId : ''}
                  onChange={(e)=>handleSecondTermChange(e,index)}
                >
                  <option value="">Select Exam</option>
                  {reportList?.map(report => (
                    <option key={report._id} value={report._id}>{report.reportname}</option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                </div>
                {secondTermSelections[index] && (
                <div className="flex items-center mt-2">
                  <span className="text-gray-700">{reportList?.find(report => report._id === secondTermSelections[index].examId).reportname}</span>
                  <button className="ml-2 text-red-600" onClick={() => handleRemoveSecondTermSelection(index)}>Remove</button>
                </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      </div>
      <div className="min-h-[80px] rounded-lg shadow-sm flex">
        <button 
           onClick={saveCAReport}
           type="submit" 
           className="flex w-[20rem] sm:w-full justify-center rounded-md bg-indigo-600 py-4 my-3 text-lg font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Save
        </button>
      </div>
      <ToastContainer
             position="top-center"
             autoClose={1500}
             hideProgressBar
             newestOnTop
             closeOnClick
             rtl={false}
             pauseOnFocusLoss
             draggable
             pauseOnHover
             theme="light"
            />
    </div>
  
       
    )
}

export default AddCAReport