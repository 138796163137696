import React, { useEffect,useState } from 'react'
import {useLocation} from 'react-router-dom'
import "react-activity/dist/library.css"
import { ToastContainer, toast } from 'react-toastify';
import {Page,Text,View,Document,StyleSheet,PDFDownloadLink} from "@react-pdf/renderer"
import { Digital } from 'react-activity';
//import { CAData } from '../../../utils/CAData'

const StudentCA = () =>{
  const [CADetails, setCADetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [documentReady, setDocumentReady] = useState(true)
  const [error, setError] = useState(null);
  const location = useLocation();
  const studentData = location.state || {};
  const reportYear = studentData.caYear;

  //let CADetails = CAData

  const fetchStudentCAPerformance = async () => {
    setLoading(true)
    try {
      const response = await fetch(`${global._URL}/CA/studentCA?studentId=${studentData.studentId}&year=${reportYear}`);
      if (!response.ok) {
        const notify = () => {
          toast.error("Failed to fetch report data", {
            position: "top-center",
            autoClose:2000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light"
           })
         };

        notify()
        throw new Error('Failed to fetch report data');
      }
        
      
      const data = await response.json();
      setCADetails(data);
      console.log(data)
      setLoading(false);
      setDocumentReady(true)
    } catch (error) {
      const notify = () => {
        toast.error("Low Internet Connection", {
          position: "top-center",
          autoClose:2000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          theme: "light"
         })
       };

      notify()
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStudentCAPerformance();
  }, []);

  if (loading) {
    return <div className='loaderContainer'><Digital/></div>;
  }

  let subjects = [];
  let examNames = [];
  let examNamesTerm1 = []
  let examNamesTerm2 = []

  if (CADetails !== null) {
    const term1Exams = CADetails.term1.exams;
    const term2Exams = CADetails.term2.exams;
    console.log(term1Exams, term2Exams)

    subjects = Array.from(new Set([...term1Exams.flatMap(exam => Object.keys(exam.marksObtained)), ...term2Exams.flatMap(exam => Object.keys(exam.marksObtained))]));
   
    // Extracting exam names from both terms
   examNamesTerm1 = term1Exams.map(exam => exam.examName);
   
   if(term2Exams.length !== 0){
    examNamesTerm2 = term2Exams.map(exam => exam.examName);
   }else{
    examNamesTerm2 = ['-','-','-']
   }
   console.log(examNamesTerm1,examNamesTerm2)
  
    examNames = [
      ...term1Exams.map(exam => exam.examName),
      ...term2Exams.map(exam => exam.examName)
    ];
    examNames = Array.from(new Set(examNames));
  }

  //---------------------------------------------------------------

  const styles = StyleSheet.create({
    page: {
      width:'100%',
      //padding: 10,
      //backgroundColor:'#F2F2F2'
    },
    mainHeader:{
      /*borderWidth:1,
      borderColor:'#333',*/
      //padding: 10,
      display:'flex',
      flexDirection:'row',
      justifyContent:'space-between',
      alignItems:'center',
      marginBottom:'20px',
      paddingVertical:'5px',
      backgroundColor:'#142664',
      height:100
     },
     mainHeader_Title:{
     /* border:1,
      borderColor:'#f2f2f2',*/
      paddingVertical:'5px',
      paddingHorizontal:'10px',
      color:'#fff'
     },
     mainHeader_Info:{
     /* border:1,
      borderColor:'#f2f2f2',*/
      paddingVertical:'5px',
      paddingHorizontal:'10px',
      color:'#fff',
      display:'flex',
      flexDirection:'column',
      alignItems:'flex-end',
      justifyContent:'flex-end',
      marginTop:'14px'
     },
     schoolName:{
      marginBottom:'5px',
      fontSize:20,
      fontWeight:'bold',
      fontStyle:'Courier',
      color:'#D9C001'
     },
     moreInfoTitle:{
      fontSize:13
     },
     moreInfoContacts:{
      fontSize:9
     },

     studentDetails:{
      paddingHorizontal:'10px',
      marginBottom:'10px',
      /*borderColor:'#142664',
      borderWidth:1,*/
     },
     StudentName:{
      fontSize:16,
      paddingBottom:'5px'
     },
     ClassLevel:{
      fontSize:11,
      fontWeight:'light'
     },
     mainTableContainer:{
      marginTop:'10px'
     },
     TableContainer:{
      //paddingHorizontal:'20px',
      marginBottom:'10px',
      borderColor:'#F2F2F2',
      borderWidth:1,
      backgroundColor:'#F2F2F2'
     },
     TableHeader:{
      fontSize:15,
      paddingHorizontal:'10px',
      paddingVertical:'5px'
     },

     RemarksContainer:{
      //paddingHorizontal:'20px',
      marginBottom:'10px',
      backgroundColor:'#F2F2F2',
      borderColor:'#F2F2F2',
      borderWidth:1,
      marginTop:'20px'
     },
     TermMainContainer:{
      display:'flex',
      flexDirection:'row',
      justifyContent:'space-between',
      /*borderColor:'#142664',
      borderWidth:1,*/
      marginBottom:'10px',
      marginTop:'10px'
     },
     TermContainer:{
       flex:2,
       border:1,
       borderColor:'#cae3f5',
       backgroundColor:'#cae3f5',//'#f7edcd',//'#cae3f5',
       borderWidth:1,
       fontSize:14,
       padding:'5px',
       margin:'5px'
     },
     RemarksHeader:{
      fontSize:15,
      paddingHorizontal:'10px',
      paddingVertical:'4px'
     },
     RemarksSmallHeader:{
      fontSize:14,
      marginBottom:'5px'
     },
     RemarksAttribute:{
      paddingVertical:'3px',
      fontSize:11
     },
     AnnualContainer:{
       borderColor:'#f7edcd',
       borderWidth:1,
       fontSize:14,
       padding:'5px',
       margin:'5px',
       width:300,
       backgroundColor:'#f7edcd'
     },

     TermTableRow:{
       justifyContent:'space-between',
       flexDirection:'row',
       paddingVertical:'5px',
       paddingHorizontal:'10px',
       borderBottomWidth:1,
       borderBottomColor:'#333'
     },
     TermTableRowHeader:{
      fontSize:13,
      width:'40%',
     },
     TermTableDataRow:{
      justifyContent:'space-between',
      flexDirection:'row',
      paddingVertical:'5px',
      paddingHorizontal:'10px'//'#cae3f5',
     },
     TermTableDataRowCell:{
      width:'25%',
      paddingHorizontal:'5px',
      fontSize:10,
     }
  })

  const DocPDF = () =>{

    //console.log(reportTimePeriod)

    return(
      <Document>
          <Page size={'A4'} style={styles.page}>
            <View style={styles.mainHeader}>
              <View style={styles.mainHeader_Title}>
                <Text style={styles.schoolName}>Oasis Primary School</Text>
                <Text style={styles.reportTitle}>Progress Report</Text>
              </View>
              <View style={styles.mainHeader_Info}>
                <Text style={styles.moreInfoTitle}>For More Information</Text>
                <Text style={styles.moreInfoContacts}>0712 841 715 | 0714 344 438</Text>
              </View>
            </View>

            <View style={styles.studentDetails}>
              <Text style={styles.StudentName}>{CADetails?.studentName}'s Report</Text>
              <Text style={styles.ClassLevel}>{CADetails?.className} - {CADetails?.year}</Text>
            </View>
            
            <View style={styles.mainTableContainer}>
            <View style={styles.TableContainer}>
              <Text style={styles.TableHeader}>{CADetails.term1.termName}</Text>

              <View style={styles.TermTableRow}>
                <Text style={styles.TermTableRowHeader}>Subject</Text>
                {CADetails.term1.exams.map((exam,index) => (
                    <Text  style={styles.TermTableRowHeader}key={exam.examId}>Test {/*exam.examName*/index + 1}</Text>
                  ))}
                <Text style={styles.TermTableRowHeader}>Term Average</Text>
              </View>
              {Object.entries(CADetails.term1AvgScore).map(([subject, avgScore]) => (
                <View style={styles.TermTableDataRow} key={subject} className="text-center">
                    <Text style={styles.TermTableDataRowCell}>{subject}</Text>
                    {CADetails.term1.exams.map(exam => (
                      <Text  style={styles.TermTableDataRowCell}key={exam.examId}>{exam.marksObtained[subject]}</Text>
                    ))}
                    <Text style={styles.TermTableDataRowCell}>{avgScore}</Text>
                </View>
                ))}
            </View>

            <View style={styles.TableContainer}>
              <Text style={styles.TableHeader}>{CADetails.term2.termName}</Text>

              <View style={styles.TermTableRow}>
                <Text style={styles.TermTableRowHeader}>Subject</Text>
                {CADetails.term1.exams.map((exam,index) => (
                    <Text  style={styles.TermTableRowHeader}key={exam.examId}>Test {/*exam.examName*/index + 1}</Text>
                  ))}
                <Text style={styles.TermTableRowHeader}>Term Average</Text>
              </View>
              {Object.entries(CADetails.term2AvgScore).map(([subject, avgScore]) => (
                <View style={styles.TermTableDataRow} key={subject} className="text-center">
                    <Text style={styles.TermTableDataRowCell}>{subject}</Text>
                    {CADetails.term1.exams.map(exam => (
                      <Text  style={styles.TermTableDataRowCell}key={exam.examId}>{exam.marksObtained[subject]}</Text>
                    ))}
                    <Text style={styles.TermTableDataRowCell}>{avgScore}</Text>
                </View>
                ))}
            </View>

            <View style={styles.TableContainer}>
              <Text style={styles.TableHeader}>Annual Summary</Text>
              <View style={styles.TermTableRow}>
                <Text style={styles.TermTableRowHeader}>Subject</Text>
                {CADetails.term1.exams.map((exam,index) => (
                    <Text  style={styles.TermTableRowHeader}key={exam.examId}>Test {/*exam.examName*/index + 1}</Text>
                  ))}
                <Text style={styles.TermTableRowHeader}>Annual Average</Text>
              </View>
              {Object.entries(CADetails.annualAvgScore).map(([subject, avgScore]) => (
                <View style={styles.TermTableDataRow} key={subject} className="text-center">
                    <Text style={styles.TermTableDataRowCell}>{subject}</Text>
                    {CADetails.term1.exams.map(exam => (
                      <Text  style={styles.TermTableDataRowCell}key={exam.examId}>{exam.marksObtained[subject]}</Text>
                    ))}
                    <Text style={styles.TermTableDataRowCell}>{avgScore}</Text>
                </View>
                ))}
            </View>
            </View>

            <View style={styles.RemarksContainer}>
              <Text style={styles.RemarksHeader}>Remarks</Text>
              <View style={styles.TermMainContainer}>
                <View style={styles.TermContainer}>
                   <Text style={styles.RemarksSmallHeader}>{CADetails.term1.termName}</Text>
                   <Text style={styles.RemarksAttribute}>Average: {CADetails.totalAvgScore.term1}</Text>
                   <Text style={styles.RemarksAttribute}>Grade: {CADetails.OverallAverageGrades.term1}</Text>
                   <Text style={styles.RemarksAttribute}>{CADetails.teacherRemarks.term1}</Text>
                </View>
                <View style={styles.TermContainer}>
                   <Text style={styles.RemarksSmallHeader}>{CADetails.term2.termName}</Text>
                   <Text style={styles.RemarksAttribute}>Average: {CADetails.totalAvgScore.term2}</Text>
                   <Text style={styles.RemarksAttribute}>Grade: {CADetails.OverallAverageGrades.term2}</Text>
                   <Text style={styles.RemarksAttribute}>{CADetails.teacherRemarks.term2}</Text>
                </View>
              </View>
              <View style={styles.AnnualContainer}>
                   <Text style={styles.RemarksSmallHeader}>Annual Remarks</Text>
                   <Text style={styles.RemarksAttribute}>Average Score: {CADetails.totalAvgScore.annually}</Text>
                   <Text style={styles.RemarksAttribute}>Grade:{CADetails.OverallAverageGrades.annually}</Text>
                   <Text style={styles.RemarksAttribute}>{CADetails.teacherRemarks.annually}</Text>
               </View>    
            </View>
            
          </Page>
        </Document>
    )
  }

  //----------------------------------------------------------------
  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-2xl mx-auto bg-white rounded-lg shadow-md overflow-hidden">
      <div className='text-right rounded-md p-3'>
      {documentReady && (
                   <PDFDownloadLink document={<DocPDF/>} fileName={`Student Progressive Report.pdf`}>
                   {({blob,url,loading,error})=>(loading ? 'Loading document...':'Download Report')}
                  </PDFDownloadLink>
      )}
      </div>
        <div className='m-2 text-center'>
         <h1 className="text-2xl font-bold mb-2">Student Progressive Report</h1>
        </div>
        <div className="px-6 py-4">
          <h2 className="text-xl font-semibold mb-2">{CADetails?.studentName}'s Report</h2>
          <p className="text-gray-600 mb-4">{CADetails?.className} - {CADetails?.year}</p>
          
          {/* Term 1 Table */}
          <div className="mb-8">
            <h3 className="text-lg font-semibold mb-2">{CADetails?.term1.termName}</h3>
            <table className="w-full mb-4 border border-gray-500">
              <thead>
                <tr>
                  <th>Subject</th>
                  {CADetails?.term1.exams?.map((exam,index) => (
                    <th key={exam.examId}>Test {/*exam.examName*/index + 1}</th>
                  ))}
                  <th>Term Average</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(CADetails.term1AvgScore).map(([subject, avgScore]) => (
                  <tr key={subject} className="text-center">
                    <td>{subject}</td>
                    {CADetails.term1.exams.map(exam => (
                      <td key={exam.examId}>{exam.marksObtained[subject]}</td>
                    ))}
                    <td>{avgScore}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Term 2 Table */}
          <div className="mb-8">
            <h3 className="text-lg font-semibold mb-2">{CADetails.term2.termName}</h3>
            <table className="w-full mb-4 border border-gray-500">
              <thead>
                <tr>
                  <th>Subject</th>
                  {CADetails.term1.exams.map((exam,index) => (
                    <th key={exam.examId}>Test {/*exam.examName*/index + 1}</th>
                  ))}
                  <th>Term Average</th>
                </tr>
              </thead>
              {CADetails.term2.exams.length > 0 && (
              <tbody>
                {Object.entries(CADetails.term1AvgScore).map(([subject, avgScore]) => (
                  <tr key={subject} className="text-center">
                    <td>{subject}</td>
                    {CADetails.term1.exams.map(exam => (
                      <td key={exam.examId}>{exam.marksObtained[subject]}</td>
                    ))}
                    <td>{avgScore}</td>
                  </tr>
                ))}
              </tbody>
              )}
            </table>
          </div>

          {/* Annual Summary Table */}
          {CADetails.term2.exams.length > 0 && (
          <div>
            <h3 className="text-lg font-semibold mb-2">Annual Summary</h3>
            <table className="w-full border border-gray-500">
              <thead>
                <tr className="text-center">
                  <th>Subject</th>
                  <th>Term 1</th>
                  <th>Term 2</th>
                  <th>Average</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(CADetails.annualAvgScore).map(subject => (
                  <tr key={subject} className="text-center">
                    <td>{subject}</td>
                    <td>{CADetails.term1AvgScore[subject]}</td>
                    <td>{CADetails.term2AvgScore[subject]}</td>
                    <td>{CADetails.annualAvgScore[subject]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          )}

           {/* Remarks Section */}
           <div className='mt-4'>
            <h3 className="text-lg font-semibold mb-2">Remarks</h3>
            
            <div className='grid grid-cols-2'>
            {/* Term 1 Remarks */}
            <div className="mb-4">
              <h4 className="text-lg font-semibold mb-2">{CADetails.term1.termName} Remarks</h4>
              <p>Term Average Score: {CADetails.totalAvgScore.term1}</p>
              <p>Term Grade: {CADetails.OverallAverageGrades.term1}</p>
              <p>Teacher Comments: {CADetails.teacherRemarks.term1}</p>
            </div>

            {/* Term 2 Remarks */}
            {CADetails.term2.exams.length > 0 && (
            <div className="mb-4">
              <h4 className="text-lg font-semibold mb-2">{CADetails.term2.termName} Remarks</h4>
              <p>Term Average Score: {CADetails.totalAvgScore.term2}</p>
              <p>Term Grade: {CADetails.OverallAverageGrades.term2}</p>
              <p>Teacher Comments: {CADetails.teacherRemarks.term2}</p>
            </div>
            )}
            </div>

            {/* Annual Remarks */}
            {CADetails.term2.exams.length > 0 && (
            <div>
              <h4 className="text-lg font-semibold mb-2">Annual Remarks</h4>
              <p>Term Average Score: {CADetails.totalAvgScore.annually}</p>
              <p>Term Grade: {CADetails.OverallAverageGrades.annually}</p>
              <p>Teacher Comments: {CADetails.teacherRemarks.annually}</p>
            </div>
            )}
          </div>

        </div>
      </div>
      
    </div>
  );
}
export default StudentCA