import React,{useState,useEffect,useRef} from 'react'
import { Link,useParams,useLocation, useNavigate } from "react-router-dom";
import DatePicker from 'react-datepicker'
import "react-activity/dist/library.css"
import { ToastContainer, toast } from 'react-toastify';
import {
  Chart,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,ArcElement,
  LineElement
    } from 'chart.js'
import { Bar,Pie,Line } from 'react-chartjs-2';
import { Radio, Select,Table,Space } from 'antd';
import { subjectList,sampleTableData } from '../../utils/fakeData';
import './StudentAnalytic.css'

Chart.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  ArcElement,
  LineElement
)

const StudentAnalytics = () =>{
  const [studentOverallData,setStudentOverallData] = useState(null)
  const [loader, setLoader] = useState(false)
  const [studentReportData,setStudentReportData] = useState(null)
  const [reportsYear, setReportsYear] = useState(null)
  const [studentReportFormList,setStudentReportFormList] = useState(null)
  const [selectedReport, setSelectedReport] = useState(null)
  const [studentRecentReportData, setStudentRecentReportData] = useState(null)
  const [topicoverallAveragePerfomance, setTopcOverallAveragePerformance] = useState(null)
  const [selectedSubject, setSelectedSubject] = useState(null)
  const [studentOverallAverage, setStudentOverallAverage] = useState(null)
  const [selectedReportDetails, setSelectedReportDetails] = useState(null)
  const [selectedCAReport, setSelectedCAReport] = useState(null)
  const [CAReportList, setCAReportList] = useState(null)
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const className = urlParams.get('className')
  const navigate = useNavigate()
 
  const studentData = JSON.parse(decodeURIComponent(urlParams.get('studentData')))

  //const randomColor = `rgba(${Math.random() * 255},${Math.random() * 255},${Math.random() * 255},0.6)`;

  console.log(studentData)

  const classId = studentData.classId || studentData.ReportDetails.classId
  console.log({classId})
  //|| studentData.ReportDetails.report.classId

  const userCreds = JSON.parse(localStorage.getItem('userCreds'))
  const schoolId = userCreds.user.schoolId

  const /*reportId*/studentId = useParams().id
  console.log(studentId)

    //Fetch Student Analytics based on subject
    const [studentInfo, setStudentInfo] = useState(null)
    const fetchStudentDetails = async() =>{
      setLoader(true)
        try {
            await fetch(`${global._URL}/students/studentdetails/${studentId}`)
               .then((res)=>{
                return res.json()
               })
               .then((result)=>{
                console.log(result)
                setLoader(false)
                if(!result.errorMessage){
                    //Other functions
                    setStudentInfo(result.result)
                }else{
                  console.log('Error Message',result.errorMessage)
    
                   const notify = () => {
                        toast.error(`${result.errorMessage}`, {
                          position: "top-center",
                          autoClose:2000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light"
                         })
                       };
            
                notify()
                }
                
               }).catch((err)=>{
                setLoader(false)
                console.log('Server Error, failed to get class exercise report', err)
                const notify = () => {
                        toast.error("Internal server Error", {
                          position: "top-center",
                          autoClose:2000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light"
                         })
                       };
            
                notify()
               })
        } catch (error) {
            console.log('Server Error, no connection')
            setLoader(false)
            const notify = () => {
                        toast.warn("IServer Error, no connection", {
                          position: "top-center",
                          autoClose:2000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light"
                         })
                       };
            
                notify()
        }
    } 
    
    useEffect(()=>{
      fetchStudentDetails()
    },[])


  const fetchOverallPerformance = async() =>{
    const currentDate = new Date()
    const choosenYear = new Date(reportsYear).getFullYear()
    const listedYear = choosenYear === 1970 ? currentDate.getFullYear() : choosenYear
    try {
      await fetch(`${global._URL}/reports/studentoverallperformance?studentId=${studentId}&year=${listedYear}`)
          .then((res)=>{
            return res.json()
          })
          .then((result)=>{
            console.log(result)
            if(!result.errorMessage){
              setStudentOverallData(result?.overallPerformance)
              setStudentOverallAverage(result?.studentAverage)
            }else{
              const notify = () => {
                toast.error("Student Records not found", {
                  position: "top-center",
                  autoClose:1500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
      
               //notify()

            }
          }) 
          .catch((err)=>{
            console.log('Failed to fetch report data',err)
          })
    } catch (error) {
      console.log('Failed to fetch studet overall performance',error)
    }
  }

  const fetchReportPerformance = async(reportId) =>{
    console.log({studentId,reportId,schoolId})
    try {
      await fetch(`${global._URL}/reports/studentperformance?studentId=${studentId}&reportId=${reportId}&schoolId=${schoolId}`)
          .then((res)=>{
            return res.json()
          })
          .then((result)=>{
            console.log(result)
            if(!result.errorMessage){
              setStudentReportData(result)
            }
          }) 
          .catch((err)=>{
            console.log('Failed to fetch report data',err)
          })
    } catch (error) {
      console.log('Failed to fetch studet overall performance',error)
    }
  }

  const fetchStudentReportList = async() =>{
    const currentDate = new Date()
    const choosenYear = new Date(reportsYear).getFullYear()
    const listedYear = choosenYear === 1970 ? currentDate.getFullYear() : choosenYear
    try {
        await fetch(`${global._URL}/schoolreportform/studentallreports?studentId=${studentId}&year=${listedYear}`)
           .then((res)=>{
            return res.json()
           })
           .then((result)=>{
            console.log(result)
            if(!result.errorMessage){
              setStudentReportFormList(result.result)
                //Other functions
            }else{
              console.log('Error Message',result.errorMessage)

               const notify = () => {
                    toast.error(`No Reports found for this year`, {
                      position: "top-center",
                      autoClose:2000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light"
                     })
                   };
        
            notify()
            }
            
           }).catch((err)=>{
            console.log('Server Error', err)
            const notify = () => {
                    toast.error("Internal server Error", {
                      position: "top-center",
                      autoClose:2000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light"
                     })
                   };
        
            notify()
           })
    } catch (error) {
        console.log('Server Error, no connection')

        const notify = () => {
                    toast.warn("Server Error, no connection", {
                      position: "top-center",
                      autoClose:2000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light"
                     })
                   };
        
            notify()
    }
}

//CA Reports
const fetchCAReports = async() =>{
  const currentDate = new Date()
  const listedYear =  currentDate.getFullYear() 
    console.log({classId})
    try {
        await fetch(`${global._URL}/CA/studentCAList?studentId=${studentId}`)
          .then((res)=>{
            return res.json()
          })
          .then((result)=>{
            console.log(result)
            
            if(result){
              setCAReportList(result)

              //Put output on a table for visibility of details
            }
          })
          .catch((err)=>{
            console.log('Failed to fetch class reports',err)
          })
    } catch (error) {
        console.log('Failed to get class reports',error)
        const notify = () => {
          toast.warn("Disconnected, check Internet", {
            position: "top-center",
            autoClose:3000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light"
           })
         };

         notify()
    }
}


useEffect(()=>{
  fetchCAReports()
},[])

const handleReportsYearChange = (date) =>{
  console.log(date)
  setReportsYear(date)
}

const fetchStudentRecentProgress = async() =>{
  const currentDate = new Date()
  const listedYear = currentDate.getFullYear()
  try {
      await fetch(`${global._URL}/reports/studentRecentReports?studentId=${studentId}&year=${listedYear}`)
         .then((res)=>{
          return res.json()
         })
         .then((result)=>{
          console.log(result)
          if(!result.errorMessage){
              //Other functions
              setStudentRecentReportData(result)
          }else{
            console.log('Error Message',result.errorMessage)

             const notify = () => {
                  toast.error(`${result.errorMessage}`, {
                    position: "top-center",
                    autoClose:2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
      
          notify()
          }
          
         }).catch((err)=>{
          console.log('Server Error', err)
          const notify = () => {
                  toast.error("Internal server Error", {
                    position: "top-center",
                    autoClose:2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
      
          notify()
         })
  } catch (error) {
      console.log('Server Error, no connection')

      const notify = () => {
                  toast.warn("IServer Error, no connection", {
                    position: "top-center",
                    autoClose:2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
      
          notify()
  }
}

const [studentSubjectRecentData, setStudentSubjectRecentData] = useState(null)
const fetchStudentSubjectRecentPerformance = async(subjectName) =>{
  try {
      await fetch(`${global._URL}/reports/studentSubjectRecentReports?studentId=${studentId}&subjectName=${subjectName}`)
         .then((res)=>{
          return res.json()
         })
         .then((result)=>{
          console.log({subjectRecentPerformance:result})
          if(!result.errorMessage){
              //Other functions
              setStudentSubjectRecentData(result)
          }else{
            console.log('Error Message',result.errorMessage)

             const notify = () => {
                  toast.error(`${result.errorMessage}`, {
                    position: "top-center",
                    autoClose:2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
      
          notify()
          }
          
         }).catch((err)=>{
          console.log('Server Error', err)
          const notify = () => {
                  toast.error("Internal server Error", {
                    position: "top-center",
                    autoClose:2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
      
          notify()
         })
  } catch (error) {
      console.log('Server Error, no connection')

      const notify = () => {
                  toast.warn("Server Error, no connection", {
                    position: "top-center",
                    autoClose:2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
      
          notify()
  }
}

const fetchStudentSubjectTopicPeformance = async(subject) =>{
  console.log({classId,studentId,subject})
  try {
    await fetch(`${global._URL}/exercisereports/exercise-studentsubjecttopicsperformance?studentId=${studentId}&classId=${classId}&subject=${subject}`)
       .then((res)=>{
        return res.json()
       })
       .then((result)=>{
        console.log({studentSubjectTopicsData:result})
        if(!result.errorMessage){
            //Other functions
            setTopcOverallAveragePerformance(result)
        }else{
          console.log('Error Message',result.errorMessage)

           const notify = () => {
                toast.error(`${result.errorMessage}`, {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
       // notify()
        }
        
       }).catch((err)=>{
        console.log('Server Error', err)
        const notify = () => {
                toast.error("Internal server Error", {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
      //  notify()
       })
} catch (error) {
    console.log('Server Error, no connection')

    const notify = () => {
                toast.warn("IServer Error, no connection", {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
       // notify()
}
}

const [studentFailedConcepts, setStudentFailedConcepts]= useState(null)
const fetchStudentFailedConcepts = async(subject) =>{
  try {
    await fetch(`${global._URL}/exercisereports/exercise-studentstrugglingconcepts?studentId=${studentId}&classId=${classId}&subject=${subject}`)
       .then((res)=>{
        return res.json()
       })
       .then((result)=>{
        console.log({studentFailedConceptsData:result})
        if(!result.errorMessage){
            //Other functions
            setStudentFailedConcepts(result.strugglingConcepts)
        }else{
          console.log('Error Message',result.errorMessage)

           const notify = () => {
                toast.error(`${result.errorMessage}`, {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
       // notify()
        }
        
       }).catch((err)=>{
        console.log('Server Error', err)
        const notify = () => {
                toast.error("Internal server Error", {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
      //  notify()
       })
} catch (error) {
    console.log('Server Error, no connection')

    const notify = () => {
                toast.warn("IServer Error, no connection", {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
       // notify()
}
}


const [SimilarStudents, setSimilarStudents]= useState(null)
const [selectedConcept, setSelectedConcept] = useState(null)
const fetchSimilarStrugglingStudents = async(concept) =>{
  try {
    await fetch(`${global._URL}/exercisereports/exercise-similarstrugglingstudents?classId=${classId}&subject=${selectedSubject}&concept=${concept}`)
       .then((res)=>{
        return res.json()
       })
       .then((result)=>{
        console.log({similarStudentsData:result})
        if(!result.errorMessage){
            //Other functions
            setSimilarStudents(result)
        }else{
          console.log('Error Message',result.errorMessage)

           const notify = () => {
                toast.error(`${result.errorMessage}`, {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
       // notify()
        }
        
       }).catch((err)=>{
        console.log('Server Error', err)
        const notify = () => {
                toast.error("Internal server Error", {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
      //  notify()
       })
} catch (error) {
    console.log('Server Error, no connection')

    const notify = () => {
                toast.warn("IServer Error, no connection", {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
       // notify()
}
}

const handleReportChoice = (focus) => {
  const selectedReportItem = focus.target.value
  //console.log(selectedReportItem)
  setSelectedReport(selectedReportItem)

  const activeReport = studentReportFormList?.filter(report => {
    return report._id === selectedReportItem
  })
  //console.log({activeReport})
  setSelectedReportDetails(activeReport[0])
  fetchReportPerformance(selectedReportItem)
}

const handleCAReportChoice = (focus) =>{
  const CAReport = focus/*.target.value*/
  const chosenCAReport = CAReportList.find((report)=>{
    return report._id === CAReport
  })
  setSelectedCAReport(focus/*.target.value*/)
  navigate('/CAPage', { state: { caId:chosenCAReport._id, studentId, studentInfo:studentInfo, caYear:chosenCAReport.year} })
}

const handleSubjectChange = (focus) => {
  const selectedSubjectID = focus.target.value
  console.log(selectedSubjectID)
  setSelectedSubject(selectedSubjectID)
  fetchStudentFailedConcepts(selectedSubjectID)
  fetchStudentSubjectTopicPeformance(selectedSubjectID)
  fetchStudentSubjectRecentPerformance(selectedSubjectID)
  //fetchSimilarStrugglingStudents()
}

  useEffect(()=>{
    setSelectedReportDetails(studentData?.ReportDetails?.report)
   fetchOverallPerformance()
   fetchReportPerformance(studentData?.reportId)
   fetchStudentReportList()
   fetchStudentRecentProgress()
  },[])

  useEffect(()=>{
   fetchOverallPerformance()
   fetchStudentReportList()
  },[reportsYear])

  //------------------------------GRAPHS----------------------------------//

  let BarChartdata, BarChartOptions
  if(studentRecentReportData !== null){

    const subjectColors = {
      Mathematics: "#ea5545",
      Science: "#f46a9b",
      English: "#ef9b20",
      Kiswahili: "#edbf33",
      Geography: "#ede15b",
      "Social Studies": "#C2FF33",
      "S/Studies":"#ede15b",
      Civics:"#bdcf32",
      History:"#bdcf32",
      French:"#87bc45",
      Arabic:"#27aeef",
      Religion:"#b33dc6",
      "C.M.E":"#71C562", //"#00FF00",
      "Health Care":"#f46a9b",
      "Environmental Care":"#f49436",
      "Writting":"#2d9de5",
      "Reading":"#3bbdbd",
      "Communication":"#27aeef",
      "Relation":"#27aeef",
      "V/SKILLS":"#27aeef",
      "Artistic":"#27aeef",
      "Arithmetics":"#ea5545",
      "Numeracy":"#ea5545",
      "H.C.E":"#e8615d",
      "D.S.A":"#27aeef"
    };
    
    function getColorForSubject(subject) {
      // Use the subjectColors object to get the color for the subject
      return subjectColors[subject] || 'rgba(0, 0, 0, 0.6)'; // Default to black if no color found
    }
  console.log(studentRecentReportData)
  
  const arrangedData = Object.fromEntries(Object.entries(studentRecentReportData).reverse());

  console.log(arrangedData);
  
  // Extract report names from the data
  const reportNames = Object.keys(arrangedData); 
  console.log({ reportNames });

  // Extract subject names from the first report
  //const subjects = Object.keys(studentRecentReportData[labels[0]]);
  //console.log({subjects})

  // Initialize an empty array to store unique subjects
 const uniqueSubjects = [];
 //let datasets,dataSubjectTEST

 // Iterate through the reports to collect unique subjects
 for (const reportName in arrangedData) {
  const report = arrangedData[reportName];

  for (const entry of report) {
    const subject = entry.subject;

    // Check if the subject is not already in the uniqueSubjects array
    if (!uniqueSubjects.includes(subject)) {
      uniqueSubjects.push(subject);
    }
  }
}

// Initialize datasets for each subject
const datasets = uniqueSubjects.map((subject) => {
  const data = reportNames.map((reportName) => {
    const report = studentRecentReportData[reportName];
    const entry = report.find((item) => item.subject === subject);
    return entry ? entry.marks : 0;
  });

  //const randomColor = `rgba(${Math.random() * 255},${Math.random() * 255},${Math.random() * 255},0.6)`;
  const backgroundColor = getColorForSubject(subject);

  return {
    label: subject,
    backgroundColor: backgroundColor,
    borderColor: backgroundColor,
    borderWidth: 1,
    data: data,
  };
});

/*const chartData = {
  labels: reportNames, // Use report names as labels on the x-axis
  datasets: datasets,
};*/

//const datasets = []

    BarChartdata = {
      labels: reportNames, // Use report names as labels on the x-axis
      datasets: datasets,
    };

    BarChartOptions = {
      maintainAspectRatio:false,
      scales: {
        x: [
          {
            barPercentage: 1,
            //categoryPercentage: 0.4,
          }
        ],
        y: [
          {
            id: 'y-axis-Registered',
          }
        ],
      },
      barPercentage: 0.4, 
      categoryPercentage: 0.6
    };
          
  }

  let ReportBarChartdata, ReportBarChartOptions
  if(studentReportData !== null){
    const subjectColors = {
      Mathematics: "#ea5545",
      Science: "#f46a9b",
      English: "#ef9b20",
      Kiswahili: "#edbf33",
      Geography: "#ede15b",
      "Social Studies": "#C2FF33",
      "S/Studies":"#ede15b",
      Civics:"#bdcf32",
      History:"#bdcf32",
      French:"#87bc45",
      Arabic:"#27aeef",
      Religion:"#b33dc6",
      "C.M.E":"#71C562", //"#00FF00",
      "Health Care":"#f46a9b",
      "Environmental Care":"#f49436",
      "Writting":"#2d9de5",
      "Reading":"#3bbdbd",
      "Communication":"#27aeef",
      "Relation":"#27aeef",
      "V/SKILLS":"#27aeef",
      "Artistic":"#27aeef",
      "Arithmetics":"#ea5545",
      "Numeracy":"#ea5545",
      "H.C.E":"#e8615d",
      "D.S.A":"#27aeef"
    };

    const backgroundColors = Object.keys(studentReportData.subjects).map(subject => subjectColors[subject]);
  const subjectsLabels = Object.keys(studentReportData.subjects)
 // console.log({subjectsLabels})

  const subjectExamScore = subjectsLabels.map((subject)=>studentReportData.subjects[subject].score)
  //console.log({subjectExamScore})
  
   ReportBarChartdata = {
      labels: subjectsLabels,
      datasets: [
        {
          label:'Score',
          backgroundColor: backgroundColors,//'rgba(99, 132, 0, 0.6)',
          borderColor: backgroundColors,//'rgba(99, 132, 0, 1)',
          data: subjectExamScore,
        }
      ],
    };

    ReportBarChartOptions = {
      scales: {
        xAxes: [
          {
            barPercentage: 1,
            //categoryPercentage: 0.4,
          },
        ],
        yAxes: [
          {
            id: 'y-axis-Registered',
          },
        ],
      },
      barPercentage: 0.4, 
      categoryPercentage: 0.6
    };
          
  }

  //-----------------Recent Subject Performance On Exams----------------------//
  /**
   * [
        {
            "reportName": "Weekly Test Nov 17",
            "marks": 20,
            "grade": "B"
        },
        {
            "reportName": "Weekly Test 02 February",
            "marks": 31,
            "grade": "A"
        }
    ]

   */

    let SubjectProgressBarChartdata, SubjectProgressBarChartOptions;
    if(studentSubjectRecentData !== null){
      const randomColor = `rgba(${Math.random() * 255},${Math.random() * 255},${Math.random() * 255},0.6)`;
      
      const arrangedStudentRecentData = studentSubjectRecentData?.slice().reverse()
      
      console.log(studentSubjectRecentData,arrangedStudentRecentData);
      

      const reportNames = arrangedStudentRecentData?.map((report)=>{
        return report.reportName
      })

      console.log(reportNames)

      const subjectDataSets = arrangedStudentRecentData?.map((report)=>{
        return report.marks
      })
      console.log(subjectDataSets)

      SubjectProgressBarChartdata = {
        labels: reportNames, // Use report names as labels on the x-axis
        datasets: [
          {
            label:`Marks`,
            backgroundColor: randomColor,//'rgba(99, 132, 0, 0.6)',
            borderColor: randomColor,//'rgba(99, 132, 0, 1)',
            data: subjectDataSets,
          }
        ] //subjectDataSets,
      };
  
      SubjectProgressBarChartOptions = {
        maintainAspectRatio:false,
        scales: {
          x: [
            {
              barPercentage: 1,
              //categoryPercentage: 0.4,
            }
          ],
          y: [
            {
              id: 'y-axis-Registered',
            }
          ],
        },
        barPercentage: 0.4, 
        categoryPercentage: 0.6
      };
    }
  

let topicsClassOverallData, topicsClassOverallOptions,topicsClassData
if(topicoverallAveragePerfomance !== null){
  topicsClassData = topicoverallAveragePerfomance?.studentTopicsPerformance?.allTopics
}

//console.log({topicsData,topicAveragePerfomance})
if(/*classTopicPerformance*/topicoverallAveragePerfomance !== null && topicsClassData !== undefined){
  
   // console.log({topicsClassData})
    const topics =topicsClassData?.map((item) => item.topic);
    const averageScores = /*classTopicPerformance?.classTopicPerformance*/topicsClassData?.map((item) => item.averageScore);
    const backgroundColor = `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 0.7)`;

   topicsClassOverallData =  {
        labels: topics || '',
        datasets: [
          {
            label: 'Average Score',
            backgroundColor: backgroundColor,
            borderColor: backgroundColor,
            borderWidth: 1,
            hoverBackgroundColor: backgroundColor,
            hoverBorderColor: backgroundColor,
            data: averageScores,
          },
        ],
      };
    
      topicsClassOverallOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            title: {
              display: true,
              text: 'Subject Topics',
            },
            beginAtZero: true,
            max: 100, // Adjust the maximum value as needed
          },
        },
        barPercentage: 0.4, 
        categoryPercentage: 0.6
      };
}

  const formattedDate = (dateString) =>{
    //const date = new Date(dateString)
    //return date.toLocaleString()

    const dateObject = new Date(dateString); 
    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1; // Month is 0-indexed, so we add 1
    const day = dateObject.getDate();

   // Format the components as a string (e.g., "03-08-2015")
   const formattedDate = `${day}-${month}-${year}`;
   return formattedDate
  }

  //-------------------CA TABLE-------------------------------
  const columns = [
    {
      title: 'CA_Name',
      dataIndex: 'CA_Name',
      render: (text) => <a>{text}</a>,
    },
    
    {
      title: 'CA_Status',
      dataIndex: 'CA_status',
    },
    /*{
      title: 'Age',
      dataIndex: 'age',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.age - b.age,
    }*/
    {
      title: 'Action',
      dataIndex: 'action',
      render:(text)=>(
        <Space size="middle">
          <a>View</a>
          <a>Download</a>
        </Space>
      )
    }
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  //---------------------------------------------------------
    
    return(
        <div className='studentanalytic_container'>
          <div className='studentanalytic_wrapper'>
            <div className='studentanalytic_HeaderContainer'>
              <span>Student Academic Records</span>
            </div>

            <div className='studentanalytic_studentDetailsContainer'>
              <p>Student Name: {/*studentReportData*/studentInfo?.studentname}</p>  
              <p>Class: {/*studentReportData*/studentInfo?.class}</p>
              <p>Reg No: {/*studentReportData*/studentInfo?.IDNumber}</p>
            </div>

            <div className='studentanalytic_overallTableContainer'>
              <div className='studentanalytic_overallTableContainer_header'>
                <span>Overall Student Performance</span> 
              </div>

              <div className='studentanalytic_overallTableContainer-table'>
              {studentOverallData ? (
               <table className='overall-table'>
                  <thead>
                    <tr>
                      <th>Subject</th>
                      <th>Tot. Marks</th>
                      <th>Tot. Reports</th>
                      <th>Average</th>
                      <th>Grade</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                    {studentOverallData !== null && Object.entries(studentOverallData).map(([subject, performanceData])=>(
                      <tr key={subject}>
                        <td>{subject}</td>
                        <td>{performanceData.totalMarks}</td>
                        <td>{performanceData.totalReports}</td>
                        <td>{performanceData.averageMarks}</td>
                        <td>{performanceData.grade}</td>
                      </tr>
                    ))}

                    {studentOverallAverage !== null && (
                      <tr>
                        <td></td>
                        <td></td>
                        <td>OVERALL</td>
                        <td>{Math.floor(studentOverallAverage.studentAverage)}</td>
                        <td>{studentOverallAverage.studentGrade}</td>
                      </tr>
                    )}
                    
                  </tbody>
                </table>
                ):(
                 <div>
                   <span>No Records</span>
                  </div>
                )}
              </div>
               
            </div>

           
            <div className='mx-3 px-2 py-2'>
            {/* <select
                value={selectedCAReport} 
                onChange={handleCAReportChoice}
              >
              <option key={1}>CA REPORTS</option>
              {CAReportList?.map((report)=>(
                <option key={report._id} 
                   value={report._id}
                   //onClick={showReport}
                   >
                  {report?.year}
                </option>
              ))}
            </select>*/}

            <Select
                defaultValue="Progressive Reports"
                style={{
                  width: 120,
                }}
                loading={loader}
                options={CAReportList?.map((report)=> ({
                  value:report._id,
                  label:report.year
                }))}
                onChange={handleCAReportChoice}
              />
            </div>

            <div className='studentanalytic_examSpecific-container'>
                <div className='studentanalytic_examSpecific-container_header'>
                  <span>Exams Performance</span>
                </div>
                <div className='studentanalytic_examSpecific-container_options'>
                 <div className="studentanalytic_examSpecific-container_options_yearSelect">
                   <DatePicker
                       selected={reportsYear}
                       onChange={handleReportsYearChange}
                       dateFormat="yyyy"
                       showYearPicker
                       placeholderText={"Year"}
                     />
                   </div>
                   <select
                     value={selectedReport} 
                     onChange={handleReportChoice}
                    >
                   {studentReportFormList?.map((report)=>(
                     <option key={report._id} 
                        value={report._id}
                        >
                       {report?.reportname}
                     </option>
                   ))}
                 </select>
                </div>
              {studentReportData !== null ? (
                 <div className='barChart_container'>
                 <Bar 
                  data={ReportBarChartdata} 
                  options={ReportBarChartOptions}
                 />
                </div>
               ):null}

                {studentRecentReportData !== null ? (
                 <div className='barChart_container'>
                 <Bar
                  data={BarChartdata} 
                 options={BarChartOptions}
                 />
                </div>
               ):null}
                
            </div>

            <div className='studentanalytic_deepAnalyticscontainer'>
            <div className='studentanalytic_deepAnalyticscontainer_header'>
              <span>Subjects Performance</span>
            </div>

            <div className='studentanalytic_deepAnalyticscontainer_selector'>
             <select value={selectedSubject?selectedSubject.subject : ''} onChange={handleSubjectChange}>
                     {subjectList.map((subjectItem)=>(
                      <option key={subjectItem.id} 
                         value={subjectItem.subject}
                         >
                         {subjectItem?.subject}
                       </option>
                      ))}
             </select>
            </div>
            
            <div className='studentanalytic_deepAnalyticscontainer_strugglingConcepts-header'>
                <span>Recent Exams Performance</span>
             </div>

            {studentSubjectRecentData !== null ? (
              <div className='barChart_container'>               
                <Line
                  data={SubjectProgressBarChartdata} 
                  options={SubjectProgressBarChartOptions} 
                />
              </div>
             ):null}
 
              <div className='studentanalytic_deepAnalyticscontainer_strugglingConcepts-header'>
                <span>Competence Analysis</span>
              </div>

            {topicoverallAveragePerfomance?.studentTopicsPerformance.allTopics !== undefined ? (
              <div className='barChart_container'>               
                <Bar 
                  data={topicsClassOverallData} 
                  options={topicsClassOverallOptions} 
                />
              </div>
             ):null}

              <div className='studentanalytic_deepAnalyticscontainer_strugglingConcepts'>
              {studentFailedConcepts !== null && studentFailedConcepts.length !== 0 ? (
                <div className='studentanalytic_deepAnalyticscontainer_strugglingConcepts-header'>
                <span>Struggling concepts</span>
              </div>
              ):null}
                
                <div className='studentanalytic_deepAnalyticscontainer_strugglingConcepts-details'>
                             {studentFailedConcepts !== null && studentFailedConcepts.length !== 0 ? (
                               <div>
                                  {studentFailedConcepts.map((concept,index)=>(
                                    <div
                                      key={index}
                                      className='studentanalytic_deepAnalyticscontainer_strugglingConcepts-details-card'
                                    >
                                     <span>{concept.topic} | </span>
                                     <span onClick={()=>{
                                      console.log("Clicked")
                                      setSelectedConcept({
                                        concept:concept.concept,
                                        topic:concept.topic
                                      })
                                      fetchSimilarStrugglingStudents(concept.concept)
                                     }}>{concept.concept} | </span>
                                     <span> averageScore {concept.passRate}</span>
                                  </div>
                                ))}
                               </div>
                             ):null}

                     {SimilarStudents !== null ? (
                      <div className='studentanalytic_deepAnalyticscontainer_strugglingConcepts-otherStudentsContainer'>
                        <div className='studentanalytic_deepAnalyticscontainer_strugglingConcepts-otherStudentsContainer-header'>
                          <span>{selectedConcept?.topic} | </span>
                          <span>{selectedConcept?.concept}</span>
                          <div className='studentanalytic_deepAnalyticscontainer_strugglingConcepts-otherStudentsContainer-sub-header'>
                            
                              <p>Other students with similar concept challenge</p>
                              <li>You can prepare group discussion or create special program to brush them</li>
                            
                          </div>
                        </div>
                        
                        <div className='studentanalytic_deepAnalyticscontainer_strugglingConcepts-otherStudentsContainer-list'>
                          
                          

                          <div className='studentanalytic_deepAnalyticscontainer_strugglingConcepts-otherStudentsContainer-list-wrapper'>
                            {SimilarStudents?.strugglingStudents.map((student)=>(
                              <div>
                                <p>{student.studentName}</p>
                              </div>
                            ))}
                          </div>
                        

                        {/*<div>
                          <div>
                            <p>To boost their performance, we suggest these group for discussion, 
                              with their peer students with high performance</p>
                          </div>
                          <div>
                            {SimilarStudents?.studyGroups.map((group)=>(
                              <div>
                                {group.map((student)=>(
                                  <p>{student.studentName}</p>
                                ))}
                              </div>
                            ))}
                          </div>
                                </div>*/}
                        </div>
                        </div>
                         ):null}
                   
                        </div>

                       {/*<div>
                        <Table
                         columns={columns}
                         dataSource={sampleTableData}
                         onChange={onChange}
                         showSorterTooltip={{
                           target: 'sorter-icon',
                         }}
                       />
                        </div>*/}
                    </div>
               </div>
          </div>

                 <ToastContainer
             position="top-center"
             autoClose={1500}
             hideProgressBar
             newestOnTop
             closeOnClick
             rtl={false}
             pauseOnFocusLoss
             draggable
             pauseOnHover
             theme="light"
            />
        </div>
    )
}

export default StudentAnalytics
