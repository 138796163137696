import React,{useState,useEffect} from 'react'
import { Link,useParams,useLocation, useNavigate } from "react-router-dom";
import {Page,Text,View,Document,StyleSheet,PDFDownloadLink} from "@react-pdf/renderer"
import DatePicker from 'react-datepicker'
import {
  Chart as Chartjs,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,ArcElement
    } from 'chart.js'
import * as xlsx from 'xlsx';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';    
import { Bar,Pie } from 'react-chartjs-2';
import './SpecificAnalytic.css'
import {Digital} from "react-activity"
import "react-activity/dist/library.css"
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
//import { ReportData } from '../../utils/fakeData';
import {gradesRange,bandsRange} from '../../utils/fakeData'
import {truncateString} from '../../utils/truncateString'
import DownloadSpecificReport from './DownloadSpecificReport'
import { handleGeneratePdf } from '../../utils/generatePDF';
import {formattedDate} from '../../utils/formatedDate'

Chartjs.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  ArcElement

)

const SpecificAnalytic = () =>{
  const [reportData, setReportData] = useState(null)
  const [reportsYear, setReportsYear] = useState(null)
  const [documentReady, setDocumentReady] = useState(false)
  const [classListLoader, setClassListLoader] = useState(false)
  const [selectedReport, setSelectedReport] = useState(null)
  const [selectedReportDetails, setSelectedReportDetails] = useState(null)
  const [selectedStream,setSelectedStream] = useState(null)
  const [classReportList, setClassReportList] = useState([])
  const [classInfo, setClassInfo] = useState(null)
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null)
  const [loader, setLoader] = useState(false)
  const [printReportDetails, setPrintReportDetails] = useState({
    schoolName:'',
    className:'',
    ReportName:''
  })

  const reportId = useParams().id
  const userCreds = JSON.parse(localStorage.getItem('userCreds'))
  const schoolId = userCreds.user.schoolId
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const className = urlParams.get('className')

  const navigate = useNavigate()
 
  //const ReportDetails = JSON.parse(decodeURIComponent(urlParams.get('reportData')))
  //console.log(ReportDetails)
  const classId = useParams().id//ReportDetails.report.classId
  //console.log(reportId)

 // console.log(JSON.parse(ReportData))

 useEffect(() => {
  const userCreds = JSON.parse(localStorage.getItem('userCreds'))
  const schoolID = userCreds.user.schoolId
  // Fetch classes
  fetch(`${global._URL}/classes/classInfo/${classId}`)
    .then(response => response.json())
    .then(result => {
      console.log(result)
      if(!result.errorMessage){
        setClassInfo(result.result);
      }
      })
      .catch((err)=>{
        console.log('Failed to get class streams',err)
        const notify = () => {
          toast.warn("Disconnected, check Internet", {
            position: "top-center",
            autoClose:3000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light"
           })
         };
  
         notify()
      })
  },[])

  //---------------------Add Query of Year from HERE---------------------//
 const classReports = async() =>{
  setClassListLoader(true)
  const currentDate = new Date()
  const choosenYear = new Date(reportsYear).getFullYear()
  const listedYear = choosenYear === 1970 ? currentDate.getFullYear() : choosenYear
  //console.log(classId, choosenYear)
    try {
        await fetch(`${global._URL}/schoolreportform/classreportformlist?classId=${classId}&year=${listedYear}`)
           .then((res)=>{
            return res.json()
           })
           .then((result)=>{
            setClassListLoader(false)
            console.log(result)
            if(!result.errorMessage){
              setClassReportList(result.result)

              if(result.result.length === 1){
                setSelectedReport(result.result[0]._id)
                setSelectedReportDetails(result.result[0])
              }
            }
            
           })
           .catch((err)=>{
            setClassListLoader(false)
            console.log('Failed to fetch class reports', err)
            const notify = () => {
              toast.warn("Disconnected, check Internet", {
                position: "top-center",
                autoClose:1500,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
                theme: "light"
               })
             };
  
             notify()
           })   
    } catch (error) {
        setClassListLoader(false)
        console.log('Low internet connection failed to fetch reports', error)
    }
}



useEffect(()=>{
  classReports()
},[reportsYear])

const handleReportsYearChange = (date) =>{
  console.log(date)
  setReportsYear(date)
}

  const fetchClassReportPerformance = async() =>{
    console.log({reportId,schoolId,selectedStream})
    //setStreamLoader(true)
    try {
      await fetch(`${global._URL}/reports/analytics?reportId=${selectedReport}&schoolId=${schoolId}&classId=${classId}&streamTag=${selectedStream}`)
          .then((res)=>{
            return res.json()
          })
          .then((result)=>{
           // setStreamLoader(false)
            //console.log(result.subjectAnalytics)
            
              if(!result.errorMessage){
                setReportData(result)
               // console.log({gradesAnalytics:result.gradesAnalytics,totalMarksAndPosition:result.totalMarksAndPosition})
                setDocumentReady(true)
              }else{
                console.log('No Report')
                /*const notify = () => {
                  toast.warn(`Server EE,${result.errorMessage}`, {
                       position: "top-left",
                       hideProgressBar: true,
                       closeOnClick: true,
                       draggable: true,
                       progress: undefined,
                       theme: "light"
                   })
                 };
      
                 notify()*/
              }
          }) 
          .catch((err)=>{
            //setStreamLoader(false)
            console.log('Failed to fetch report data',err)
            const notify = () => {
              toast.warn("Disconnected, check Internet", {
                   position: "top-left",
                   hideProgressBar: true,
                   closeOnClick: true,
                   draggable: true,
                   progress: undefined,
                   theme: "light"
               })
             };
  
             notify()
          })
    } catch (error) {
     // setStreamLoader(false)
      console.log('Failed to fetch studet overall performance',error)
      const notify = () => {
        toast.warn("Disconnected, check internet", {
             position: "top-left",
             hideProgressBar: true,
             closeOnClick: true,
             draggable: true,
             progress: undefined,
             theme: "light"
         })
       };

       notify()
    }
  }

  // Export data to Excel
  const exportToExcel = async () => {
    // Ensure data is available
    if (!reportData) return;

    // Extract data for excel
    const excelData = [];
    const totalMarksAndPosition = [];
    const headerRow = ['StudentName'];

    // Find all subjects and add them to the header row
    console.log({gradeDetails:reportData})

  for (const studentId in reportData.gradesAnalytics) {
    const studentData = reportData.gradesAnalytics[studentId];
    //console.log({gD_studentId:studentId})
    for (const subject in studentData.subjects) {
      if (!headerRow.includes(subject)) {
        headerRow.push(subject);
      }
    }
  }

  headerRow.push('Position');
  //console.log({headerRow})

  // Loop through gradesAnalytics
  for (const studentId in reportData.gradesAnalytics) {
    const studentData = reportData.gradesAnalytics[studentId];
    const student = { 'StudentName': studentData.studentname,'TotalMarks': 0};
    //console.log({studentD:student})
    // Add subject marks
    for (const subject in studentData.subjects) {
      student[subject] = studentData.subjects[subject].marks;
     // console.log({subjMarks:studentData.subjects[subject].marks})
      student.TotalMarks += parseFloat(studentData.subjects[subject].marks);
    }

    // Find student's position
    const position = reportData.totalMarksAndPosition.find(data => data.studentId === studentId).position;
    student.Position = position;

    excelData.push(student);
  }
  //console.log({excelData})

  //Subject Analytics
  let subjectAnalyticsData = []
  for (const subject in reportData.subjectAnalytics) {
    const subjectData = reportData.subjectAnalytics[subject];
  
    subjectAnalyticsData.push({
      Subject: subject,
      Total: subjectData.totalSubjectScore,
      Average: subjectData.averageScore,
      ScoreGrade: subjectData.subjectGrade,
    });
  }

  //let subjectGradesDistributionData = []
  const subjectGradesDistributionData = Object.keys(reportData.subjectPerformanceAnalytics).map((subject) => {
    const subjectData = reportData.subjectPerformanceAnalytics[subject];
    const subjectGradesObject = { Subject: subject };

    for (const gradeObj of subjectData) {
      const grade = gradeObj.grade;
      const numberOfGrade = gradeObj.numberOfgrade;

      subjectGradesObject[grade] = numberOfGrade;
    }

    return subjectGradesObject;
  });

  console.log({subjectGradesDistributionData})
  

  // Create Excel workbook
  const wb = XLSX.utils.book_new();

  const ws = XLSX.utils.json_to_sheet(excelData, { header: headerRow });
  const wsSubjectAnalytics = XLSX.utils.json_to_sheet(subjectAnalyticsData, { header: ['Subject', 'Total', 'Average', 'ScoreGrade'] });
  const wsSubjectGradesDistribution = XLSX.utils.json_to_sheet(subjectGradesDistributionData, { header: ['Subject', 'A', 'B', 'C', 'D', 'E', 'F'] });
  

  // Add sheet to workbook
  XLSX.utils.book_append_sheet(wb, ws, 'Students Grades');
  XLSX.utils.book_append_sheet(wb, wsSubjectAnalytics, 'Subject Analytics');
  XLSX.utils.book_append_sheet(wb, wsSubjectGradesDistribution, 'Grades Distribution');

  // Export the workbook to a file
  const fileName = `${selectedReportDetails?.reportname}.xlsx`//'grades-report.xlsx';
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
  FileSaver.saveAs(data, fileName);
  };
  

  const getUniqueSubjects = () =>{
    if(!reportData || !reportData.gradesAnalytics) return []

    const uniqueSubjects = new Set();
    Object.values(reportData.gradesAnalytics).forEach((student)=>{
      Object.keys(student.subjects).forEach((subject)=>{
        uniqueSubjects.add(subject)
      })
    })
    return Array.from(uniqueSubjects)
  }

  //Chart Data------------------
  function getRandomColor() {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgb(${r},${g},${b})`;
  }

  const subjectColors = {
    Mathematics: "#ea5545",
    Science: "#f46a9b",
    English: "#ef9b20",
    Kiswahili: "#edbf33",
    Geography: "#ede15b",
    "Social Studies": "#C2FF33",
    "S/Studies":"#ede15b",
    Civics:"#bdcf32",
    History:"#bdcf32",
    French:"#87bc45",
    Arabic:"#27aeef",
    Religion:"#b33dc6",
    "C.M.E":"#71C562", //"#00FF00",
    "Health Care":"#f46a9b",
    "Environmental Care":"#f49436",
    "Writting":"#2d9de5",
    "Reading":"#3bbdbd",
    "Communication":"#27aeef",
    "Relation":"#27aeef",
    "V/SKILLS":"#27aeef",
    "Artistic":"#27aeef",
    "Arithmetics":"#ea5545",
    "Numeracy":"#ea5545",
    "H.C.E":"#e8615d",
    "D.S.A":"#27aeef"
  };

  let BarChartdata, BarChartOptions,pieCharts,pieData,pieOptions
if(reportData !== null){

  const backgroundColors = Object.keys(reportData.subjectAnalytics).map(subject => subjectColors[subject]);

  BarChartdata = {
      labels: /*[  "Mathematics",  "Science",  "English",  "Kiswahili",  "Geography",  "Social Studies"]*/
         Object.keys(reportData.subjectAnalytics)
      ,
      datasets: [
        {
          label: 'Average Score',
          data: /*[ 39.9, 37.7, 42.2, 42.6, 34.9, 40.3 ]*/
          Object.entries(reportData.subjectAnalytics)?.map(([subject,analytics]) => analytics.averageScore),
          backgroundColor: backgroundColors,//'rgba(75, 192, 192, 0.6)', // You can customize the color
          borderColor: backgroundColors,
          borderWidth: 1,
        },
      ],
    };
    
    BarChartOptions = {
      scales: {
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: 'Average Score',
          },
        },
        x: {
          title: {
            display: true,
            text: 'Subjects',
          },
        },
      },
      maintainAspectRatio:false,
      barPercentage: 0.4, 
      categoryPercentage: 0.7
    };

  const pieGrades = {};

    // Group data by grades
    for (const [subject, data] of Object.entries(reportData.subjectPerformanceAnalytics)) {
      data.forEach(item => {
        const grade = item.grade;
        if (!pieGrades[grade]) {
          pieGrades[grade] = [];
        }
        pieGrades[grade].push({ subject, numberOfgrade: item.numberOfgrade });
      });
    } 

  const sortedGrades = Object.keys(pieGrades).sort();  
 
  //const pieSubjects = Object.keys(reportData.subjectPerformanceAnalytics);
  //const gradesInOrder = ["A", "B", "C", "D", "E", "F"];

  // Group data by grades
  /*pieSubjects.forEach(subject => {
    reportData.subjectPerformanceAnalytics[subject].forEach(item => {
      if (!pieGrades[item.grade]) {
        pieGrades[item.grade] = [];
      }
      pieGrades[item.grade].push({ subject, numberOfgrade: item.numberOfgrade });
    });
  });*/

  pieCharts = /*Object.entries(pieGrades)*/sortedGrades.map((grade) => {
    const subjectsData = pieGrades[grade] || []
    let labels,datasetData
    
    labels = subjectsData.map(item => item.subject);
    datasetData = subjectsData.map(item => item.numberOfgrade);
  
    

    //const backgroundColors = labels.map(() => getRandomColor()); // Define or generate colors
    const backgroundColors = labels.map(subject => subjectColors[subject]);
     pieData = {
      labels,
      datasets: [
        {
          data: datasetData,
          backgroundColor: backgroundColors,
        },
      ],
    };

     pieOptions = {
      title: {
        display: true,
        text: `Grade ${grade} Performance by Subject`,
      },
      scales: {
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: `Number of ${grade}`,
          },
        },
        x: {
          title: {
            display: true,
            text: 'Subjects',
          },
        },
      },
      barPercentage: 0.3, 
      categoryPercentage: 0.5
    };

    return (
      <div key={grade} className="chart-container">
        <h3>{`Grade ${grade} Performance`}</h3>
        <Bar
          style={
            {width:'30%'}
          }
          data={pieData} 
          options={pieOptions} 
        />
      </div>
    );

})

}

const styles = StyleSheet.create({
    page: {
      padding: 10,
      backgroundColor:'#F2F2F2'
    },
    mainHeader:{
      borderWidth:1,
      borderColor:'#333',
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
      marginBottom:'10px',
      paddingVertical:'5px',
      backgroundColor:'#142664'
     }, 
   title1:{
    fontSize:14,
    fontWeight:'bold',
    marginBottom:10,
    color:'#F2F2F2'
   },
   title2:{
    fontSize:12,
    fontWeight:'bold',
    marginBottom:10,
    color:'#D9C001'
   },
   title:{
    fontSize:12,
    fontWeight:'bold',
    color:'#F2F2F2'
   },
   table:{
    display:'table',
    width:'100%',
    
    borderStyle:'solid',
    //borderWidth:1,
    borderRightWidth:0,
    borderBottomWidth:0
   },
   mainTableHeaderRow:{
    //margin:'auto',
    justifyContent:'space-between',
    flexDirection:'row',
    paddingVertical:'5px',
    paddingHorizontal:'10px',
    borderBottomWidth:1
   },
   tableRow:{
    //margin:'auto',
    justifyContent:'space-between',
    flexDirection:'row',
    paddingVertical:'5px',
    paddingHorizontal:'10px',
    backgroundColor:'#cae3f5',
   },
   tableOverallRow:{
    //margin:'auto',
    justifyContent:'space-between',
    flexDirection:'row',
    paddingVertical:'5px',
    paddingHorizontal:'10px',
    backgroundColor:'#f7edcd',
   },
   tableRowHeaders:{
    //margin:'auto',
    justifyContent:'space-between',
    flexDirection:'row',
    paddingVertical:'5px',
    paddingHorizontal:'10px',
    borderBottomWidth:1,
    borderBottomColor:'#333'
    //backgroundColor:'#333',
   },
   tableRowMarks:{
    //margin:'auto',
    justifyContent:'space-evenly',
    alignItems:"center",
    flexDirection:'row',
    //backgroundColor:'#333',
    gap:'10px'
   },
   tableCell1:{
    //margin:'auto',
    marginTop:5,
    marginBottom:5,
    fontSize:10,
    width:'25%',
    //textAlign:'center',
    //borderWidth:1,
    borderLeftWidth:0,
    borderTopWidth:0
   },
   tableCell2:{
    //margin:'auto',
    marginTop:5,
    marginBottom:5,
    fontSize:10,
    width:'10%',
    //textAlign:'center',
    //borderWidth:1,
    borderLeftWidth:0,
    borderTopWidth:0
   },
   tableCell3:{
    //margin:'auto',
    marginTop:5,
    marginBottom:5,
    fontSize:10,
    width:'10%',
    //textAlign:'center',
    //borderWidth:1,
    borderLeftWidth:0,
    borderTopWidth:0
   },
   tableCell4:{
    //margin:'auto',
    marginTop:5,
    marginBottom:5,
    fontSize:10,
    width:'4%',
    //textAlign:'center',
    //borderWidth:1,
    borderLeftWidth:0,
    borderTopWidth:0
   },
   tableCell5:{
    //margin:'auto',
    marginTop:5,
    marginBottom:5,
    fontSize:10,
    width:'2%',
    //textAlign:'center',
    //borderWidth:1,
    borderLeftWidth:0,
    borderTopWidth:0
   },
   tableHeader1:{
   // backgroundColor:'#f2f2f2',
    fontSize:12,
    fontWeight:'ultrabold',
    width:'25%',
   },
   tableHeader2:{
   // backgroundColor:'#f2f2f2',
    fontSize:12,
    fontWeight:'bold',
    width:'10%',
   },
   tableHeader3:{
  //  backgroundColor:'#f2f2f2',
    fontSize:12,
    fontWeight:'bold',
    width:'10%',
   },
   tableHeader4:{
   // backgroundColor:'#f2f2f2',
    fontSize:12,
    fontWeight:'bold',
    width:'10%',
    textAlign:'center'
   },

   //Subject Analytics Table
   subjectAnalyticsTable:{
    marginVertical:'15px',
    /*borderWidth:1,*/
    width:'50%',
   },

   SubjectAnalytable:{
    display:'table',
    width:'100%',
    borderStyle:'solid',
    //borderWidth:1,
    borderRightWidth:0,
    borderBottomWidth:0
   },
   
   subjectAnalyticsHeader:{
    //borderWidth:1,
    borderBottomWidth:1,
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    marginBottom:'10px',
    paddingVertical:'5px',
    fontSize:13,
    backgroundColor:'#0F70B7'
   },

   subjAnalyTableHeader:{
    fontSize:12,
    width:'25%',
   },

   subjAnalyTableCell:{
    width:'25%',
    paddingHorizontal:'5px',
    fontSize:10,
   },

   subjAnalyTableRow:{
    justifyContent:'space-between',
    flexDirection:'row',
    paddingVertical:'5px',
    paddingHorizontal:'10px',
    borderBottomWidth:1,
    borderBottomColor:'#333'
   },

   /*-------Subject Performance TABLE----*/
   subjectPerformanceTable:{
    marginVertical:'15px',
    /*borderWidth:1,*/
    width:'50%',
   },

   subjectPerformanceHeader:{
     //borderWidth:1,
     borderBottomWidth:1,
     display:'flex',
     flexDirection:'column',
     alignItems:'center',
     marginBottom:'10px',
     paddingVertical:'5px',
     fontSize:13,
     backgroundColor:'#0F70B7'
   },

   SubjectPerformanceMainTable:{
    display:'table',
    width:'100%',
    borderStyle:'solid',
    //borderWidth:1,
    borderRightWidth:0,
    borderBottomWidth:0
   },

   subjectPerformanceTableHeaderRow:{
    justifyContent:'space-between',
    flexDirection:'row',
    alignItems:'center',
    /*paddingVertical:'5px',*/
    paddingHorizontal:'10px',
    borderBottomWidth:1,
    borderBottomColor:'#333',
   },

   subjectPerformanceTableRow:{
    justifyContent:'space-between',
    flexDirection:'row',
    alignItems:'center',
    paddingVertical:'5px',
    paddingHorizontal:'10px',
    borderBottomWidth:1,
    borderBottomColor:'#333',
    backgroundColor:'#cae3f5',
   },

   subjectPerformanceTableColumn:{
    //justifyContent:'space-between',
    flexDirection:'column',
    //paddingVertical:'5px',
    //paddingHorizontal:'10px',
   // borderBottomWidth:1,
   // borderBottomColor:'#333'
   },

   subjectPerformanceTableHeader1:{
    fontSize:10,
    width:'40%',
   },
   subjPerformanceTableHeader2:{
    fontSize:10,
    width:'10%',
   },

   subjectPerformanceTableCell1:{
     //margin:'auto',
     marginTop:5,
     marginBottom:5,
     fontSize:10,
     width:'40%',
     //textAlign:'center',
     //borderWidth:1,
     borderLeftWidth:0,
     borderTopWidth:0
   },
   subjectPerformanceTableCell2:{
     //margin:'auto',
     marginTop:5,
     marginBottom:5,
     fontSize:10,
     width:'10%',
     //textAlign:'center',
     //borderWidth:1,
     borderLeftWidth:0,
     borderTopWidth:0
   }
  })

  const DocPDF = () =>{

    //console.log(reportTimePeriod)

    return(
      <Document>
          <Page style={styles.page}>
            <View style={styles.mainHeader}>
              <Text style={styles.title1}>MINISTRY OF EDUCATION AND TECHNOLOGY</Text>
              <Text style={styles.title2}>{JSON.parse(localStorage.getItem('userCreds')).user.schoolname}</Text>
              <Text style={styles.title2}>{classInfo?.classInfo.classname}&nbsp;{`-`}&nbsp;{selectedReportDetails?.reportname}</Text>
            </View>
            
            {/**---------Grades Analytics Table---------- */}
            
            <View style={styles.table}>
              {/**---------Table Header--------- */}
              <View style={styles.mainTableHeaderRow}>
                <Text style={styles.tableHeader1}>Student Name</Text>
                  {getUniqueSubjects().map((subject)=>(
                    
                      <Text style={styles.tableHeader4}>{subject}</Text>
                       
                  ))}
                  <Text style={styles.tableHeader2}>Total</Text>
                  <Text style={styles.tableHeader2}>Grade</Text>
                  <Text style={styles.tableHeader3}>Pos</Text>
              </View>

              {/*------Table Body-------------*/}
              {reportData.totalMarksAndPosition.map(({studentId, studentname, totalMarks,position})=>(
                      <View key={studentId} style={styles.tableRowHeaders}>
                        <Text style={styles.tableCell1}>{studentname}</Text>
                        
                        {getUniqueSubjects().map((subject)=>{
                          const data = reportData.gradesAnalytics[studentId]?.subjects?.[subject] || {};
                          return(
                             <View key={subject} style={styles.tableRowMarks}>
                          
                               <Text style={styles.tableCell4}>{data.marks || '-'}</Text>
                               <Text style={styles.tableCell4}>{data.grade || '-'}</Text>
                          
                             </View> 
                          )
                         })}
                        
                        <Text style={styles.tableCell2}>{totalMarks}</Text>
                        <Text style={styles.tableCell2}>{marksToGrade(totalMarks,getUniqueSubjects().length)}</Text>
                        <Text style={styles.tableCell3}>{position}</Text>
                      </View>
                ))}
            </View>

            
            {/*-----------Small Table----------------*/}
            <View style={styles.subjectAnalyticsTable}>
                <View style={styles.subjectAnalyticsHeader}>
                  <Text style={styles.title}>Subject Analysis</Text>
                </View>
                  
                    <View style={styles.SubjectAnalytable}>
                        <View style={styles.subjAnalyTableRow}>
                          <Text style={styles.subjectPerformanceTableHeader1}>Subject</Text>
                          <Text style={styles.subjectPerformanceTableHeader1}>Total</Text>
                          <Text style={styles.subjectPerformanceTableHeader1}>Avg</Text>
                          <Text style={styles.subjectPerformanceTableHeader1}>Grade</Text>
                        </View>
                    
                        {Object.entries(reportData.subjectAnalytics).map(([subject,analytics])=>(
                          <View key={subject} style={styles.tableRow}>
                             <Text style={styles.subjAnalyTableCell}>{subject}</Text>
                             <Text style={styles.subjAnalyTableCell}>{analytics.totalSubjectScore}</Text>
                             <Text style={styles.subjAnalyTableCell}>{analytics.averageScore}</Text>
                             <Text style={styles.subjAnalyTableCell}>{analytics.subjectGrade}</Text>
                          </View>
                        ))}
                        <View style={styles.tableOverallRow}>
                          <Text style={styles.subjAnalyTableCell}>OVERALL</Text>
                          <Text style={styles.subjAnalyTableCell}>{``}</Text>
                          <Text style={styles.subjAnalyTableCell}>{Number(calculateSubjectsOverallAverage().average).toFixed(2)}</Text>
                          <Text style={styles.subjAnalyTableCell}>{calculateSubjectsOverallAverage().gradeKey}</Text>
                        </View>
                    </View>
                </View>

                <View style={styles.subjectPerformanceTable}>
                <View style={styles.subjectPerformanceHeader}>
                  <Text style={styles.title}>Grades Distribution</Text>
                </View>
                  <View>
                    <View style={styles.SubjectPerformanceMainTable}>
                        <View style={styles. subjectPerformanceTableHeaderRow}>
                          <Text style={styles.subjectPerformanceTableHeader1}>Subject</Text>
                          {gradesData.map(grade=>(
                            <Text style={styles.subjectPerformanceTableCell2} key={grade}>{grade}</Text>
                          ))}
                        </View>
                      
                      <View style={styles. subjectPerformanceTableColumn}>
                      {Object.keys(reportData.subjectPerformanceAnalytics).map((subject)=>(
                         <View key={subject}  style={styles. subjectPerformanceTableRow}>
                           <Text style={styles.subjectPerformanceTableCell1}>{subject}</Text>
                           {gradesData?.map(grade => {
                            const subjectGradeData = reportData.subjectPerformanceAnalytics[subject].find(item => item.grade === grade)
                            return (
                              <Text style={styles.subjectPerformanceTableCell2} key={`${subject}-${grade}`}>
                                {subjectGradeData ? subjectGradeData.numberOfgrade : ''}
                              </Text>
                            )
                           })}
                         </View>
                        ))}
                      </View>
                    </View>
                  </View>
                </View>
          </Page>
        </Document>
    )
  }

  const handleSubjectClick = (reportId, subject) =>{
    console.log({reportId, subject})
  }

  const handleStudentClick = (studentId, studentname) =>{
    console.log({studentId, studentname,reportId})
  }

  
  const handleReportChoice = (focus) => {
    console.log('Clicked')
    const selectedReportItem = focus.target.value
    //console.log(selectedReportItem)
    setSelectedReport(selectedReportItem)
  
    const activeReport = classReportList?.filter(report => {
      return report._id === selectedReportItem
    })
    console.log({activeReport})
    setSelectedReportDetails(activeReport[0])
  }
  
  useEffect(()=>{
    fetchClassReportPerformance()
  },[selectedReport,selectedStream])


  const gradesData = ['A','B','C','D','E','F']
  

    
    /*  useEffect(()=>{
       console.log('Fetch class report performance')
       fetchClassReportPerformance()
      },[selectedStream])*/

      
      const handleStreamChange = (focus) => {
        const selectedStreamItem = focus.target.value
        setSelectedStream(selectedStreamItem)
        console.log(selectedStreamItem)
      }

      const marksToGrade = (totalMarks, numberOfSubjects)=>{
        const studentExamAverage = totalMarks/numberOfSubjects
        for(const [gradeKey, range] of Object.entries(gradesRange)){
            if(studentExamAverage >=range.min && studentExamAverage <=range.max){
                return gradeKey
            }
        }
        return '-'
    }

    const calculateSubjectsOverallAverage = () =>{
      let subjectNames; 
      let totalSubjects;
      let overallAverage;
      let totalAverageScore = 0;
      if(reportData !== null && reportData !== undefined){
        subjectNames = Object.keys(reportData?.subjectAnalytics);
        totalSubjects = subjectNames.length
      

      

      subjectNames.forEach(subject => {
        totalAverageScore += reportData.subjectAnalytics[subject].averageScore;
      })
    

      overallAverage = totalAverageScore/totalSubjects;
     // console.log('Overall Average', overallAverage)
    

      for(const [gradeKey, range] of Object.entries(bandsRange)){
        if(overallAverage >=range.min && overallAverage <=range.max){
            return {gradeKey,average:overallAverage}
        }
      }
      return '-'
    }

      //return overallAverage;
    }

    return(
        <div className='analytic-Container'>
          <div className='analytics_reportChoice_container'>
            <div className='analytics_reportChoice_container-header'>
              <span>Choose Class Report</span>
            </div>
            <div className='cchoiceSelector_container'>
            {!classListLoader ? (
              <div className='selectors_wrapper'>
              <select
              value={selectedReport} 
              onChange={handleReportChoice}
            >
              <option
                value=""
                //disabled
              >
                Choose Report
              </option>
            {classReportList?.map((report)=>(
              <option key={report._id} 
                 value={report._id}
                 >
                {truncateString(report.reportname,30,30)}
              </option>
              
           ))}
          </select>

          <select 
                value={selectedStream} 
                onChange={handleStreamChange}
                >
                <option disabled>Streams</option>
                  <option key={1}
                    value='null'
                  >
                    All streams
                  </option>
                {classInfo?.streams?.map((streamItem,index)=>(
                  <option key={index + 1} 
                     value={streamItem.streamTag}
                     >
                    {streamItem?.streamTag}
                </option>
              ))}
            </select>

            <DatePicker
                selected={reportsYear}
                onChange={handleReportsYearChange}
                dateFormat="yyyy"
                showYearPicker
                placeholderText={"Enlisted Year"}
              />
          </div>
            ):(
            <div className='choiceSelector_container-loader'>  
            <Digital/>
            </div>
           )}
            </div>
            <div className='analytics_reportChoice_container-button'>
              <button
               onClick={()=>{
                navigate(`/classoverallanalytics/${classId}`)
               }}
              >
                Overall
              </button>
            </div>
          </div>
          
          
          <div className='analytics_mainContainer'>
          {selectedReportDetails !== null ? (
            <div className='analytic-Header'>
              <p>{/*ReportDetails.report.*/selectedReportDetails?.reportname}</p>  
              <p>{formattedDate(/*ReportDetails.report.*/selectedReportDetails?.createdAt)}</p>
            </div>
          ):null}

         {reportData && (
                  <div className='excelExport_wrapper'>
                   <button onClick={exportToExcel}>Export to Excel</button>
                 </div> 
                )}

          {reportData ? (
            <div className='specific_tables-container'>
            <div 
              className='specific_tables_maintable-container'
              style={{width:'100%', overflowX:'auto'}}
            >
                <table className='main_table'>
                  <thead>
                    <tr>
                      <th rowSpan="2">Student Name</th>
                      {getUniqueSubjects().map((subject)=>(
                        
                          <th 
                            key={subject} 
                            colSpan="2"
                            onClick={()=>handleSubjectClick(reportId, subject)}
                          >
                            <Link 
                              to={`/subjectanalytics/${reportId}?subjectData=${encodeURIComponent(JSON.stringify({ReportDetails:selectedReportDetails,subject,subjectList:getUniqueSubjects()}))}`}
                              style={{textDecoration:'none',color:'#000'}}
                              key={subject}
                             >
                           
                            {subject}
                            </Link>  
                          </th>
                         
                        ))}
                      <th rowSpan="2">Total</th>
                      <th rowSpan="2">Grade</th>
                      <th rowSpan="2">Pos</th>
                    </tr>
                    <tr>
                    {getUniqueSubjects().map((subject)=>(
                      <React.Fragment key={subject}>
                        <th>M</th>
                        <th>G</th>
                      </React.Fragment>  
                    ))}
                    </tr>
                  </thead>
                  <tbody>
                    {reportData.totalMarksAndPosition.map(({studentId, studentname, totalMarks,position})=>(
                      <tr key={studentId}
                          onClick={()=>handleStudentClick(studentId, studentname)}
                      >
                        
                        <td>
                        <Link 
                        to={`/studentanalytics/${studentId}?studentData=${encodeURIComponent(JSON.stringify({/*classId:selectedReportDetails.classId,*/reportId:reportId,ReportDetails:selectedReportDetails}))}`}
                        style={{textDecoration:'none',color:'#000'}}
                        key={reportId}
                      >
                          {studentname}
                        </Link>
                        </td>
                        
                        {getUniqueSubjects().map((subject)=>{
                          const data = reportData.gradesAnalytics[studentId]?.subjects?.[subject] || {};
                          return(
                          <React.Fragment key={subject}>
                          
                          <td>{data.marks || '-'}</td>
                          <td>{data.grade || '-'}</td>
                          
                          </React.Fragment> 
                          )
                         })}
                        
                        <td>{totalMarks}</td>
                        <td>{marksToGrade(totalMarks,getUniqueSubjects().length)}</td>
                        <td>{position}</td>
                        
                      </tr>
                    ))}
                  </tbody>
                  </table>
                </div>
              

                <div className='analytic-BarContainer'>
                  <div className='analytic-smallHeader'>
                    <p>Subjects Analysis</p>
                  </div>
                  <div className='subjectAnalytics_visual_container'>
                    <table className='main_table'>
                      <thead>
                        <tr>
                          <th>Subject</th>
                          <th>Total</th>
                          <th>Average</th>
                          <th>Score Grade</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.entries(reportData.subjectAnalytics).map(([subject,analytics])=>(
                          <tr key={subject}>
                             <td>{subject}</td>
                             <td>{analytics.totalSubjectScore}</td>
                             <td>{analytics.averageScore}</td>
                             <td>{analytics.subjectGrade}</td>
                          </tr>
                          
                        ))}
                        {reportData !== null && reportData !== undefined ? (
                          <tr>
                            <th></th>
                            <th></th>
                            <td>{Number(calculateSubjectsOverallAverage().average).toFixed(2)}</td>
                            <td>{calculateSubjectsOverallAverage().gradeKey}</td>
                          </tr>
                        ):null}
                        
                      </tbody>
                    </table>

                    <div className='analytics_Chart_container'>
                    <Bar 
                      style={
                        {
                          padding:'20px'
                      }
                      }
                      height={"80%"}
                      data={BarChartdata} 
                      options={BarChartOptions}
                    />
                  </div>

                  </div>
                  
                </div>


                <div className='analytic-smallContainer'>
                  <div className='analytic-smallHeader'>
                    <p>Subjects Performance</p>
                  </div>
                  <div className='subjectPerformance_visual-container'>
                    <table className='main_table'>
                      <thead>
                        <tr>
                          <th>Subject</th>
                          {gradesData.map(grade=>(
                            <th key={grade}>{grade}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(reportData.subjectPerformanceAnalytics).map((subject)=>(
                         <tr key={subject}>
                           <td>{subject}</td>
                           {gradesData?.map(grade => {
                            const subjectGradeData = reportData.subjectPerformanceAnalytics[subject].find(item => item.grade === grade)
                            return (
                              <td key={`${subject}-${grade}`}>
                                {subjectGradeData ? subjectGradeData.numberOfgrade : ''}
                              </td>
                            )
                           })}
                         </tr>
                          
                        ))}
                      </tbody>
                    </table>

                    <div className="charts-container">
                     {pieCharts}
                    </div>
                  </div>
                </div>
                <div>
                {documentReady && (
                   <PDFDownloadLink document={<DocPDF/>} fileName={`${classInfo?.classInfo.classname}-${selectedReportDetails?.reportname}.pdf`}>
                   {({blob,url,loading,error})=>(loading ? 'Loading document...':'Download Report')}
                  </PDFDownloadLink>
                 )}
                  {/*reportData ? (
                          <div className="downloadReportContainer">
                          <button onClick={()=>{
                            handleGeneratePdf('SpecificReportContainer')
                          }}>
                            Export
                          </button>
                         
                            <divid="SpecificReportContainer" style={{display:'none'}}>
                              <DownloadSpecificReport 
                                reportData = {reportData} 
                                selectedReportDetails={selectedReportDetails}
                              />
                            </div>
                          </div>
                  ):null*/} 
                </div>
                </div>
                ):(
                 <div>
                  {selectedReport !== null ? (
                    <div className='loaderContainer'>
                      <Digital/>
                    </div>
                  ):(
                    <div className='loaderContainer'>
                      <span>Choose Report To View Analytics..</span>
                    </div>
                  )}
                    
                  </div>
                 
                )}

                

            </div>
            <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        </div>
    )
}

export default SpecificAnalytic
