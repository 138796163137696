import React, { useState,useEffect } from "react"
import { useNavigate } from "react-router-dom"
import {Digital} from "react-activity"
import "react-activity/dist/library.css"
import { ToastContainer, toast } from 'react-toastify';
import DatePicker from 'react-datepicker'
import './ClassExercises.css'
import { Scatter, Bar } from 'react-chartjs-2';
import { 
  subjectList,
  clusterData,
  classTopicsPerformance,
  topicConceptsOverall,
  conceptPerformanceData,
  topicPerformanceData,
  classTopicPerformance,
  strugglingStudents } from "../../../utils/fakeData";
//import 'chartjs-adapter-date-fns';
import {
  Chart as Chartjs,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Legend,ArcElement,
  Tooltip,
    } from 'chart.js'


Chartjs.register(
      LineElement,
      PointElement,
      CategoryScale,
      LinearScale,
      BarElement,
      Tooltip,
      Legend,
      ArcElement,
      
)

const ClassExercises = () =>{
    const [DisplayAnalytics, setDisplayAnalytics] = useState(false)
    const [selectedTopic, setSelectedCTopic] = useState('')
    const [selectedClass, setSelectedClass] = useState(null)
    const [reportsYear, setReportsYear] = useState(null)
    const [selectedReport, setSelectedReport] = useState(null)
    const [selectedReportDetails, setSelectedReportDetails] = useState(null)
    const [classExerciseReportList, setClassExerciseReportList] = useState([])
    const [selectedSubject, setSelectedSubject] = useState(null)
    const [classes, setClasses] = useState([]);
    const [loader, setLoader] = useState(false)
    const [classLoader, setClassLoader] = useState(false)
    const [reportLoader, setReportLoader] = useState(false)
    const [buttonLoader, setButtonLoader] = useState(false)
    const [deleteReload, setDeleteReload] = useState(false)

    //---Analytics 
    const [topicAveragePerfomance, setTopcAveragePerformance] = useState(null)
    const [topicoverallAveragePerfomance, setTopcOverallAveragePerformance] = useState(null)
    const [topicsConceptsPerformance, setTopicsConceptsPerformance] = useState(null)
    const [examFailedConcepts, setExamFailedConcepts] = useState(null)
    const [examFailedStudents, setExamFailedStudents] = useState(null)
    const [OverallFailedStudents, setOverallFailedStudents] = useState(null)
    const [overallConceptFailureRate, setOverallConceptFailureRate] = useState(null)
    const [clusterDistribution, setClusterDistribution] = useState(null)

    const navigate = useNavigate()

   // console.log({conceptPerformanceData,strugglingStudents})

    useEffect(() => {
      const userCreds = JSON.parse(localStorage.getItem('userCreds'))
      const schoolID = userCreds.user.schoolId
      //console.log(schoolID)
      setLoader(true)
      // Fetch classes
      fetch(`${global._URL}/classes/schoolclasses?schoolId=${schoolID}`)
        .then(response => response.json())
        .then(classesData => {
          setLoader(false)
          //console.log(classesData)
          if(!classesData.message){
            setClasses(classesData);
            if(classesData.length !== 0){
              setSelectedClass(classesData[0]._id)
            }
            
          }
          })
          .catch((err)=>{
            setLoader(false)
            console.log('Failed to get classes',err)
            const notify = () => {
              toast.warn("Disconnected, check Internet", {
                position: "top-center",
                autoClose:3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
                theme: "light"
               })
             };
      
             notify()
          })
      },[])

      const [classDetails, setClassDetails] = useState(null)
      const handleClassChange = (focus) => {
        const selectedClassID = focus.target.value
        console.log(selectedClassID)
       const selectedClassDetails = classes.find((classdata)=> classdata._id === selectedClassID)
       //console.log(selectedClassDetails)
       setClassDetails(selectedClassDetails)
        setSelectedClass(selectedClassID)
        //fetchClassExerciseReports(selectedClassID)

      }

      const handleReportsYearChange = (date) =>{
        console.log(date)
        const fullYear = date.getFullYear()
        console.log(fullYear)
        setReportsYear(date)
        checkDate(date)
      }

      const [activeStreamOptions, setActiveStreamOptions] = useState([])
      useEffect(()=>{
        
       const activeStreamList = classes.filter((classItem)=> {
          return classItem._id === selectedClass
        })

        console.log(activeStreamList[0]?.streams)

       setActiveStreamOptions(activeStreamList[0]?.streams)
      },[selectedClass])

      const [selectedStream,setSelectedStream] = useState(null)
      const handleStreamChange = (focus) => {
        const selectedStreamItem = focus.target.value
        setSelectedStream(selectedStreamItem)
        console.log(selectedStreamItem)
      }
     

      useEffect(()=>{
        fetchClassExerciseReports(selectedClass)
      },[deleteReload])

      const handleReportChange = (focus) => {
        const selectedReportID = focus.target.value
        console.log(selectedReportID)
        const selectedReport = classExerciseReportList.find((report)=> report._id === selectedReportID)
        setSelectedReportDetails(selectedReport)
        setSelectedReport(selectedReportID)

        //fetchReportTopicPerformance()
        //fetchReportTopicConceptPerformance()
        //fetchReportFailedConcepts()
        //fetchReportFailedStudents()
      }

      const handleSubjectChange = (focus) => {
        const selectedSubjectID = focus.target.value
        console.log(selectedSubjectID)
       // const selectedClass = classList.find((classdata)=> classdata.id === selectedClassID)
        setSelectedSubject(selectedSubjectID)
       // fetchClassExerciseReports(selectedClass)
        /*setFocusSubject((prevItem)=>({
          ...prevItem,
          ['classId']:selectedSubjectID
        }))*/
        fetchOverallReportTopicPerformance(selectedSubjectID)
        fetchOverallReportFailedStudents(selectedSubjectID)
        fetchOverallReportFailedConcepts(selectedSubjectID)
        fetchSubjectClusterDistribution(selectedSubjectID)
      }

      useEffect(()=>{
        fetchClassExerciseReports(selectedClass,selectedSubject,selectedStream)
      },[selectedClass,selectedSubject,selectedStream,reportsYear])

      useEffect(()=>{
        fetchOverallReportTopicPerformance(selectedSubject)
        fetchOverallReportFailedStudents(selectedSubject)
        fetchOverallReportFailedConcepts(selectedSubject)
        fetchSubjectClusterDistribution(selectedSubject)
      },[reportsYear])

      

    const fetchClassExerciseReports = async(/*classId,subject*/) =>{
      const currentDate = new Date()
      const choosenYear = new Date(reportsYear).getFullYear()
      const listedYear = choosenYear === 1970 ? currentDate.getFullYear() : choosenYear

      if(selectedClass !== null && selectedSubject !== null){
        try {
          setReportLoader(true)
            await fetch(`${global._URL}/exercisereports/classexercisereports?classId=${/*classId*/selectedClass}&subject=${selectedSubject}&streamTag=${selectedStream}&year=${listedYear}`)
               .then((res)=>{
                return res.json()
               })
               .then((result)=>{
                //console.log(result)
                setReportLoader(false)
                if(!result.errorMessage){
                    //Other functions
                    setClassExerciseReportList(result.result)
                }else{
                  console.log('Error Message',result.errorMessage)
    
                   const notify = () => {
                        toast.error(`${result.errorMessage}`, {
                          position: "top-center",
                          autoClose:2000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light"
                         })
                       };
            
                notify()
                }
                
               }).catch((err)=>{
                setReportLoader(false)
                console.log('Server Error, failed to get class exercise report', err)
                const notify = () => {
                        toast.error("Internal server Error", {
                          position: "top-center",
                          autoClose:2000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light"
                         })
                       };
            
                notify()
               })
        } catch (error) {
            console.log('Server Error, no connection')
            setReportLoader(false)
            const notify = () => {
                        toast.warn("IServer Error, no connection", {
                          position: "top-center",
                          autoClose:2000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light"
                         })
                       };
            
                notify()
        }

      }
        
    }    
    

  const deleteExerciseReport = async() =>{
   // console.log('delete clicked')
   setButtonLoader(true)
    const options = {
      method:'DELETE'
    }
    try {
        await fetch(`${global._URL}/exercisereports/removeexercisereport/${selectedReport}`,options)
           .then((res)=>{
            return res.json()
           })
           .then((result)=>{
            setButtonLoader(false)
            setDeleteReload(!deleteReload)
            setSelectedReportDetails(null)
           // console.log(result)
            if(!result.errorMessage){
                //Other functions
                const notify = () => {
                  toast.success(`${result.successMessage}`, {
                    position: "top-center",
                    autoClose:2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
      
          notify()
            }else{
              console.log('Error Message',result.errorMessage)

               const notify = () => {
                    toast.error(`${result.errorMessage}`, {
                      position: "top-center",
                      autoClose:2000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light"
                     })
                   };
        
            notify()
            }
            
           }).catch((err)=>{
            setButtonLoader(false)
            console.log('Server Error, failed to delete exercise report', err)
            const notify = () => {
                    toast.error("Internal server Error", {
                      position: "top-center",
                      autoClose:2000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light"
                     })
                   };
        
            notify()
           })
    } catch (error) {
        setButtonLoader(false)
        console.log('Server Error, no connection')

        const notify = () => {
                    toast.warn("IServer Error, no connection", {
                      position: "top-center",
                      autoClose:2000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light"
                     })
                   };
        
            notify()
    }
}

//Fetch Report Analytics Details all at once
const fetchSubjectClusterDistribution = async(subject) =>{
  console.log('---------------------Run--------------------')
  const currentDate = new Date()
  const choosenYear = new Date(reportsYear).getFullYear()
  const listedYear = choosenYear === 1970 ? currentDate.getFullYear() : choosenYear
  try {
    await fetch(`${global._URL}/exercisereports/newclustering?classId=${selectedClass}&subject=${subject}&streamTag=${selectedStream}&year=${listedYear}`)
       .then((res)=>{
        return res.json()
       })
       .then((result)=>{
        //console.log({clusterData:result})
        if(!result.errorMessage){
            //Other functions
            setClusterDistribution(result)
        }else{
          console.log('Error Message',result.errorMessage)

           const notify = () => {
                toast.error(`${result.errorMessage}`, {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
       // notify()
        }
        
       }).catch((err)=>{
        console.log('Server Error', err)
        const notify = () => {
                toast.error("Internal server Error", {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
      //  notify()
       })
} catch (error) {
    console.log('Server Error, no connection')

    const notify = () => {
                toast.warn("IServer Error, no connection", {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
       // notify()
}
}

const fetchReportTopicPerformance = async() =>{
  console.log(selectedReportDetails)
  console.log({exerciseReportID:selectedReportDetails._id})
  try {
    await fetch(`${global._URL}/exercisereports/exercise-topicperformance?reportId=${selectedReportDetails._id}`)
       .then((res)=>{
        return res.json()
       })
       .then((result)=>{
       // console.log(result)
        if(!result.errorMessage){
            //Other functions
            setTopcAveragePerformance(result)
        }else{
          console.log('Error Message',result.errorMessage)

           const notify = () => {
                toast.error(`${result.errorMessage}`, {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
       // notify()
        }
        
       }).catch((err)=>{
        console.log('Server Error', err)
        const notify = () => {
                toast.error("Internal server Error", {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
      //  notify()
       })
} catch (error) {
    console.log('Server Error, no connection')

    const notify = () => {
                toast.warn("IServer Error, no connection", {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
       // notify()
}
}

const checkDate = (date) =>{
  console.log({datePar:date})
  const currentDate = new Date()
  //const choosenYear = new Date(date).getFullYear()
  //const listedYear = choosenYear === 1970 ? currentDate.getFullYear() : choosenYear
  let listedYear
  if(date === undefined ){
    listedYear =  currentDate.getFullYear() 
  }else{
    console.log({Run:reportsYear})
    listedYear = new Date(date).getFullYear()
  }
  return listedYear
}

const fetchOverallReportTopicPerformance = async(subject) =>{
  console.log(selectedReportDetails)
  const currentDate = new Date()
  const choosenYear = new Date(reportsYear).getFullYear()
  const listedYear = choosenYear === 1970 ? currentDate.getFullYear() : choosenYear
 /* console.log({listedYear,choosenYear,newDate:checkDate()})*/
  const year = checkDate()
  console.log({checkYear:year})
  try {
    await fetch(`${global._URL}/exercisereports/exercise-overalltopicperformance?classId=${selectedClass}&subject=${subject}&streamTag=${selectedStream}&year=${listedYear}`)
       .then((res)=>{
        return res.json()
       })
       .then((result)=>{
       // console.log(result)
        if(!result.errorMessage){
            //Other functions
            setTopcOverallAveragePerformance(result)
        }else{
          console.log('Error Message',result.errorMessage)

           const notify = () => {
                toast.error(`${result.errorMessage}`, {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
       // notify()
        }
        
       }).catch((err)=>{
        console.log('Server Error', err)
        const notify = () => {
                toast.error("Internal server Error", {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
      //  notify()
       })
} catch (error) {
    console.log('Server Error, no connection')

    const notify = () => {
                toast.warn("IServer Error, no connection", {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
       // notify()
}
}

const fetchReportTopicConceptPerformance = async() =>{
  try {
    await fetch(`${global._URL}/exercisereports/exercise-conceptperformance?reportId=${selectedReportDetails?._id}`)
       .then((res)=>{
        return res.json()
       })
       .then((result)=>{
        //console.log({topicConceptPerformance:result})
        if(!result.errorMessage){
            //Other functions
            setTopicsConceptsPerformance(result)
        }else{
          console.log('Error Message',result.errorMessage)
      
           const notify = () => {
                toast.error(`${result.errorMessage}`, {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
       // notify()
        }
        
       }).catch((err)=>{
        console.log('Server Error', err)
        const notify = () => {
                toast.error("Internal server Error", {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
      //  notify()
       })
} catch (error) {
    console.log('Server Error, no connection')

    const notify = () => {
                toast.warn("IServer Error, no connection", {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
       // notify()
}
}

const fetchReportFailedConcepts = async() =>{
  try {
    await fetch(`${global._URL}/exercisereports/exercise-failedconcepts?reportId=${selectedReportDetails._id}`)
       .then((res)=>{
        return res.json()
       })
       .then((result)=>{
        //console.log({result})
        if(!result.errorMessage){
            //Other functions
            setExamFailedConcepts(result.conceptFailureRates)
        }else{
          console.log('Error Message',result.errorMessage)

           const notify = () => {
                toast.error(`${result.errorMessage}`, {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
       // notify()
        }
        
       }).catch((err)=>{
        console.log('Server Error', err)
        const notify = () => {
                toast.error("Internal server Error", {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
      //  notify()
       })
} catch (error) {
    console.log('Server Error, no connection')

    const notify = () => {
                toast.warn("IServer Error, no connection", {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
       // notify()
}
}

const fetchOverallReportFailedConcepts = async(subject) =>{
  const currentDate = new Date()
  const choosenYear = new Date(reportsYear).getFullYear()
  const listedYear = choosenYear === 1970 ? currentDate.getFullYear() : choosenYear
  try {
    await fetch(`${global._URL}/exercisereports/exercise-overallfailedconcepts?classId=${selectedClass}&subject=${subject}&streamTag=${selectedStream}&year=${listedYear}`)
       .then((res)=>{
        return res.json()
       })
       .then((result)=>{
        //console.log({failedConcepts:result})
        if(!result.errorMessage){
            //Other functions
            setOverallConceptFailureRate(result)
        }else{
          console.log('Error Message',result.errorMessage)

           const notify = () => {
                toast.error(`${result.errorMessage}`, {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
       // notify()
        }
        
       }).catch((err)=>{
        console.log('Server Error', err)
        const notify = () => {
                toast.error("Internal server Error", {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
      //  notify()
       })
} catch (error) {
    console.log('Server Error, no connection')

    const notify = () => {
                toast.warn("IServer Error, no connection", {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
       // notify()
}
}

const fetchReportFailedStudents = async() =>{
  try {
    await fetch(`${global._URL}/exercisereports/exercise-underperformingstudents?reportId=${selectedReportDetails?._id}&classId=${selectedClass}`)
       .then((res)=>{
        return res.json()
       })
       .then((result)=>{
        console.log({failedStudents:result})
        if(!result.errorMessage){
            //Other functions
            setExamFailedStudents(result.underperformingStudents)
        }else{
          console.log('Error Message',result.errorMessage)

           const notify = () => {
                toast.error(`${result.errorMessage}`, {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
       // notify()
        }
        
       }).catch((err)=>{
        console.log('Server Error', err)
        const notify = () => {
                toast.error("Internal server Error", {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
      //  notify()
       })
} catch (error) {
    console.log('Server Error, no connection')

    const notify = () => {
                toast.warn("IServer Error, no connection", {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
       // notify()
}
}

const fetchOverallReportFailedStudents = async(subject) =>{
  const currentDate = new Date()
  const choosenYear = new Date(reportsYear).getFullYear()
  const listedYear = choosenYear === 1970 ? currentDate.getFullYear() : choosenYear
  console.log({listedYear})
  try {
    await fetch(`${global._URL}/exercisereports/exercise-overallunderperformingstudents?subject=${subject}&classId=${selectedClass}&streamTag=${selectedStream}&year=${listedYear}`)
       .then((res)=>{
        return res.json()
       })
       .then((result)=>{
        console.log({failedStudents:result})
        if(!result.errorMessage){
            //Other functions
            setOverallFailedStudents(result.underperformingStudents)
        }else{
          console.log('Error Message',result.errorMessage)

           const notify = () => {
                toast.error(`${result.errorMessage}`, {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
       // notify()
        }
        
       }).catch((err)=>{
        console.log('Server Error', err)
        const notify = () => {
                toast.error("Internal server Error", {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
      //  notify()
       })
} catch (error) {
    console.log('Server Error, no connection')

    const notify = () => {
                toast.warn("IServer Error, no connection", {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
       // notify()
}
}

/*const selectedTopicData = topicConceptsOverall?.find((topic)=> topic.topic === selectedTopic)
console.log(topicConceptsOverall)
//Prepare Data for display
const conceptLabels = selectedTopicData?.concepts.map((conceptData) => conceptData.concept) || []
console.log(conceptLabels)
const averageScores = selectedTopicData?.concepts.map((conceptData) => conceptData.averageScore) || []*/


  let conceptData, conceptOptions
  if(/*topicConceptsOverall*/topicsConceptsPerformance !== null) {
    console.log(topicsConceptsPerformance)
   
    const selectedTopicData = topicsConceptsPerformance?.find((topic)=> topic.topic === selectedTopic)

    //Prepare Data for display
    const conceptLabels = selectedTopicData?.concepts.map((conceptData) => conceptData.concept) || []
    const averageScores = selectedTopicData?.concepts.map((conceptData) => conceptData.averageScore) || []

    const backgroundColor = `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 0.7)`;

    conceptData = {
      labels:conceptLabels,
      datasets:[
        {
          label:'Concept Score',
          backgroundColor:backgroundColor,//'rgba(75,192,192,0.6',
          borderColor:backgroundColor,//'rgba(75,192,192,0.6',
          borderWidth:1,
          hoverBackgroundColor:backgroundColor,//'rgba(75,192,192,0.6',
          data:averageScores
        }
      ]
    }

    conceptOptions = {
      responsive:true,
      maintainAspectRatio:false,
      scales:{
        x: {
          title: {
            display: true,
            text: `${selectedTopic} Concepts`,
          },
          ticks: {
            stepSize: 1,
          },
        },
        y: {
          title: {
            display: true,
            text: 'Topic Score',
          },
          beginAtZero: true,
          suggestedMax: 100,
        }
      },
      barPercentage: 0.5, 
      categoryPercentage: 0.7
    }
  }

  let scatterRealData = null;
  let scatterRealOptions = null;

  if (clusterDistribution !== null) {
    scatterRealData = {
      datasets: Object.keys(clusterDistribution.clusters).map((key, index) => {
        const cluster = clusterDistribution.clusters[key];
        const backgroundColor = `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 0.7)`;

        return {
          label: key,
          data: cluster.map((student) => ({
            y: student.averageScores, // Use 'averageScores' instead of 'averageScore'
            x: index+1, // Y-coordinate represents the cluster
          })),
          backgroundColor,
        };
      }),
    };

    scatterRealOptions = {
      scales: {
        y: {
          title: {
            display: true,
            text: "Overall Score",
          },
          beginAtZero: true,
          suggestedMax: 100,
        },
        x: {
          title: {
            display: true,
            text: "Clusters",
          },
          ticks: {
            stepSize: 0.5,
            
          },
          beginAtZero: true,
          max:5
        },
      },
      maintainAspectRatio: true,
    };
  }


let topicsOverallData, topicsOverallOptions
let topicsData = topicAveragePerfomance?.classTopicsPerformance.allTopics
if(topicAveragePerfomance !== null && topicsData !== undefined){
  
    const topics =topicsData?.map((item) => item.topic);
    const averageScores = topicsData?.map((item) => item.averageScore);
    const backgroundColor = `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 0.7)`;

   topicsOverallData =  {
        labels: topics || '',
        datasets: [
          {
            label: 'Average Score',
            backgroundColor: backgroundColor,
            borderColor: backgroundColor,
            borderWidth: 1,
            hoverBackgroundColor: backgroundColor,
            hoverBorderColor: backgroundColor,
            data: averageScores,
          },
        ],
      };
    
      topicsOverallOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            title: {
              display: true,
              text: 'Exam/Exercise Topics',
            },
            beginAtZero: true,
            max: 100,
          },
        },
        barPercentage: 0.2, 
        categoryPercentage: 0.6
      };
}

let topicsClassOverallData, topicsClassOverallOptions
let topicsClassData = topicoverallAveragePerfomance?.classTopicsPerformance.allTopics
//console.log({topicsClassData ,topicoverallAveragePerfomance})
if(topicoverallAveragePerfomance !== null && topicsClassData !== undefined){
  
    //console.log({topicsData})
    const topics = topicsClassData?.map((item) => item.topic);
    //console.log({topics})
    const averageScores = topicsClassData?.map((item) => item.averageScore);
    const backgroundColor = `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 0.7)`;

   topicsClassOverallData =  {
        labels: topics || '',
        datasets: [
          {
            label: 'Average Score',
            backgroundColor: backgroundColor,
            borderColor: backgroundColor,
            borderWidth: 1,
            hoverBackgroundColor: backgroundColor,
            hoverBorderColor: backgroundColor,
            data: averageScores,
          },
        ],
      };
    
      topicsClassOverallOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            title: {
              display: true,
              text: 'Subject Topics',
            },
            beginAtZero: true,
            max: 100, // Adjust the maximum value as needed
          },
          barPercentage: 0.3, 
          categoryPercentage: 0.4
        },
      };
}

let conceptFailureRateData, conceptFailureRateOptions,conceptsData
//console.log(overallConceptFailureRate)

//console.log(concept)
if (overallConceptFailureRate !== null  ) {
  
  conceptsData = Object.keys(overallConceptFailureRate?.conceptFailureRates);
  //console.log({conceptData})
  
  const averageFailureRates = conceptsData.map(concept => overallConceptFailureRate?.conceptFailureRates[concept].averageFailureRate);

  const backgroundColors = conceptsData.map(() => `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 0.7)`);

  // Bar chart data
conceptFailureRateData = {
    labels: conceptsData || [],
    datasets: [
      {
        label: 'Average Failure Rate',
        backgroundColor: backgroundColors,
        borderColor: backgroundColors,
        borderWidth: 1,
        hoverBackgroundColor: backgroundColors,
        hoverBorderColor: backgroundColors,
        data: averageFailureRates,
      },
    ],
  };

  // Bar chart options
conceptFailureRateOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Most Failed Concepts',
        },
        beginAtZero: true,
        max: 100,
      },
      y: {
        title: {
          display: true,
          text: 'Average Failure Rate',
        },
        beginAtZero: true,
        max: 100,
      },
    },
    barPercentage: 0.5, 
    categoryPercentage: 0.8
  };
}

const handleSearchReport = () =>{
  fetchReportTopicPerformance()
  fetchReportTopicConceptPerformance()
  fetchReportFailedConcepts()
  fetchReportFailedStudents()

}

    
    return(
        <div className="ClassExer_wrapper">
          <div className="ClassExer_container">
          <div className="ClassExer_header">
            <span>Class Exercises Manager</span>
         </div>
         <div className="ClassExer_choicesWrapper">
           <div className="ClassExer_choiceHeader">
            <span>Choose Class Report</span>
           </div>
           <div className="classExer_choicesMainContainer">
            <div className="ClassExer_choicesContainer">
            <div className="ClassExer_choicesContainer-class">
             <span>Class</span>
              {classLoader ? (<Digital/>):(
               <div> 
               <select value={selectedClass?selectedClass._id : ''} onChange={handleClassChange}>
               <option
                 //disabled
                 value="null" 
                 key={0}
                >
                  Choose class
                </option>
               {classes.map((classItem)=>(
                 <option key={classItem._id} 
                    value={classItem._id}
                    >
                   {classItem?.classname}
                 </option>
                ))}
              </select>
              </div>
              )}  
            </div>

            <div className="ClassExer_choicesContainer-class">
             <span>Streams</span>
             <div>
             <select 
              value={selectedStream} 
              onChange={handleStreamChange}
              >
              <option 
                //disabled
                value="null"
                key={1}
              >
                Streams
              </option>
              {activeStreamOptions?.map((streamItem,index)=>(
                <option key={index} 
                   value={streamItem.streamTag}
                   >
                  {streamItem?.streamTag}
              </option>
              ))}
              </select>
             </div>

            </div> 

            {selectedClass ? (
             <>
              <div className="ClassExer_choicesContainer-reports">
                 <span>Subjects</span>
                 {reportLoader ? (<Digital/>):(
                 <select value={selectedSubject?selectedSubject : ''} 
                    onChange={handleSubjectChange}
                 >
                 <option 
                   value="null" 
                   //disabled
                   key={1}
                  >
                    Choose Subject
                  </option>
                 {subjectList.map((subjectItem)=>(
                  
                 <option key={subjectItem.id} 
                    value={subjectItem.subject}
                    >
                    {subjectItem?.subject}
                  </option>
                 ))}
                </select>
                )}
             </div>
            <div className="ClassExer_choicesContainer-reports">
            <span>Reports</span>
              {reportLoader ? (<Digital/>):(
               <select value={selectedReport?selectedReport._id : ''} onChange={handleReportChange}>
                <option 
                  value="null"
                  key={0} 
                  //disabled
                  >
                    Choose Report
                  </option>
               {classExerciseReportList.map((reportItem)=>(
                 <option key={reportItem._id} 
                    value={reportItem._id}
                    >
                   {reportItem?.exerciseReportName}
                 </option>
                ))}
              </select>
              )}
            </div>
            <div className="ClassExer_yearSelect">
             <DatePicker
                 selected={reportsYear}
                 onChange={handleReportsYearChange}
                 dateFormat="yyyy"
                 showYearPicker
                 placeholderText={"Enlisted year"}
               />
              </div>
            <button
              onClick={handleSearchReport}
            >
              View
            </button>
             </>
            ):null}
           
           
            <button
              onClick={()=>{
                navigate('/addclassexercise')
              }}
            >
                Add New
            </button>
           </div>
           <div className="classExer_buttonOptions">
           
            {/*<button
              onClick={()=>{
                navigate('/updateclassexercise')
              }}
            >
                Update
            </button>*/}
            {selectedReportDetails ? (
              <div className="classExercise_buttonContainer">
               <button
                 onClick={()=>{
                  navigate(`/fillexercisereport/${selectedReport}?reportData=${encodeURIComponent(JSON.stringify({classId:selectedClass,streamTag:selectedStream}))}`)
                 }}
               >
                Fill Report
               </button>
               <button
               onClick={deleteExerciseReport}
              >
                Delete {selectedReportDetails.exerciseReportName}
              </button>
              {buttonLoader ? (<Digital/>) : null}
              </div>
            ): null}
           
            
           </div>
           </div>
           
         </div>
         <div className="ClassExer_analyticsContainer">
          <div className="ClassExer_overallAnalytics">
           <div className="ClassExer_overallAnalytics_Header">
            {classDetails !== null && selectedSubject !== null ? (
              <span>{classDetails?.classname} {selectedSubject} Performance</span>
            ):(
              <span>Choose Class and Subject For Performance Analysis</span>
            )}
            
            <button
              onClick={()=>{
                setDisplayAnalytics(!DisplayAnalytics)
              }}
            >
                {DisplayAnalytics ? (<span>Close</span>):(<span>Open</span>)}
            </button>
           </div>
           {DisplayAnalytics ? (
              <div className="ClassExer_overallAnalytics_container">
               
               {clusterDistribution !== null ? (
                <div className="scatterChart_container">
                  <Scatter 
                    
                    data={scatterRealData} 
                    options={scatterRealOptions} />
                </div>
               ):null}
               {topicoverallAveragePerfomance?.classTopicsPerformance.allTopics !== undefined ? (
                <div className='overallBarChart_container'>               
                 <Bar 
                  
                   data={topicsClassOverallData} 
                   options={topicsClassOverallOptions} 
                 />
                </div>
               ):null}

              {overallConceptFailureRate !== null ? (
               <div className='overallBarChart_container'>               
                 <Bar 
                  
                   data={conceptFailureRateData} 
                   options={conceptFailureRateOptions} 
                 />
               </div>
               ):null}

               <div className="overall_recommendation_wrapper">
               {topicoverallAveragePerfomance !== null ? (
                 <div className="overall_recommendation_topicContainer">
                 <div className="overall_recommendation_topicHeader">
                   <h3>Under performing Topics</h3>
                   {topicoverallAveragePerfomance !== null && topicoverallAveragePerfomance?.classTopicsPerformance.lowPerformingTopics.length !== 0 ? (
                     <p>These topics have average score of below 50%, we suggest instructor/teacher 
                     to pay close attention to them
                   </p>
                   ):(<p>
                     All topics passed 50% mark performance
                   </p>
                   )}
                   
                 </div>
                 {topicoverallAveragePerfomance !== null && topicoverallAveragePerfomance?.classTopicsPerformance.lowPerformingTopics.length !== 0 ? (
                    <div className="overall_recommendation_topicList">
                    {topicoverallAveragePerfomance?.classTopicsPerformance.lowPerformingTopics.map((topic,index)=>(
                      <div 
                       className="overall_recommendation_topicList-card"
                        key={index}
                      >
                        <span>{topic.topic}</span>
                        <span>{` `} | {``}</span>
                        <span>overall-AverageScore {topic.averageScore}</span>
                        <span>{` `} | {``}</span>
                        <span>reportCount {topic.reportCount}</span>
                      </div>
                     ))}
                    </div>
                 ):null}
               </div>
               ):null}
                
                 
                
                {OverallFailedStudents !== null ?(
                  <div className="overall_recommendation_studentsContainer">
                  <div className="overall_recommendation_studentsHeader">
                    <h3>Struggling Students</h3>
                   {OverallFailedStudents !== null && OverallFailedStudents.length !== 0 ? (
                     <p>These students have below 50% average performance, we suggest instructor/teacher 
                        to pay close attention to them
                     </p>
                     ):(
                     <p>Flying colors class</p>
                    )}
                   
                  </div>

                 <div className="overall_recommendation_studentsList">
                 {OverallFailedStudents !== null && OverallFailedStudents.length !== 0 ? (
                   <>
                    {OverallFailedStudents.map((student)=>(
                     <div
                     className="overall_recommendation_studentsList-card"
                     key={student.studentId}
                     >
                       <span>{student.studentName} |</span>
                     <span>overall Score {student.averageScore} |</span>
                     <span>Reports {student.reportCount}</span>
                     </div>
                     
                    ))}
                   </>
                   ):null}
                  </div>
                </div>

                ):null}
                
                  
                  
                </div>
              </div>
                  
                  
               ):null}

           </div>
             
           
                



        
          <div className="ClassExer_reportAnalytics">
          <div className="ClassExer_reportAnalytics_Header">
            {selectedReportDetails !== null ? (
              <span>{selectedReportDetails?.exerciseReportName} Analytics</span>
            ):( 
              <span>Choose Report for Analytics</span>
            )}
            
          </div>

          <div className="ClassExer_reportAnalytics_container">
          {topicAveragePerfomance?.classTopicsPerformance.allTopics !== undefined ? (
              <div className='overallBarChart_container'>               
              <Bar 
                style={
                  {
                    padding:'10px'
                }
                }
                height={"100%"}
                data={topicsOverallData} 
                options={topicsOverallOptions} 
              />
              </div>
            ):null}
            
          {topicsConceptsPerformance !== null ? (
           <div>
                
                     <div>
                       <select
                         onChange={(e)=>setSelectedCTopic(e.target.value)}
                       >
                         <option value="">Select Topic</option>
                         
                         {/*topicConceptsOverall*/topicsConceptsPerformance?.map((topic,index)=>(
                           <option key={index}>
                           {topic.topic}
                         </option>
                         ))}
                       </select>
                     </div>

                  
                    <div className='overallBarChart_container'>
                     <Bar data={conceptData} options={conceptOptions}/>
                    </div>
               </div>
             ):null}

            <div className="specificExer_recommendation_wrapper">
              {topicAveragePerfomance !== null ? (
                <div className="specificExer_recommendation_topicContainer">
                <div className="specificExer_recommendation_topicHeader">
                  <h3>Under performing Topics</h3>
                  {topicAveragePerfomance.classTopicsPerformance
                     .lowPerformingTopics?.length !== 0 ? (
                    <p>These topics have average score of below 50%, we recommend instructor/teacher 
                    to pay attention to them
                    </p>
                  ):(
                    <p>
                      All topics passed 50% mark performance
                    </p>
                  )}
                </div>
                <div className="overall_recommendation_topicList">
                  {topicAveragePerfomance.classTopicsPerformance
                       .lowPerformingTopics?.length !== 0 ? (
                    <>
                   {topicAveragePerfomance.classTopicsPerformance
                     .lowPerformingTopics?.map((topic)=>(
                     <div 
                      className="overall_recommendation_topicList-card"
                     // key={index}
                     >
                      <span>{topic.topic}</span>
                      <span>{`  `}|{`  `}</span>
                      <span>average score {topic.averageScore}</span>
                    </div>
                   ))}   
                    </>
                  ):null}
                
                </div>
              </div>
              ):null}
                
              {examFailedConcepts !== null ? (
                <div className="specificExer_recommendation_conceptContainer">
                 
                    <div className="specificExer_recommendation_conceptHeader">
                    <h3>Notorious Concepts</h3>
                    {examFailedConcepts !== null && Object.keys(examFailedConcepts).length !== 0 ? (
                       <p>
                       The following concepts drag students down, we recommend teacher/instructor
                       to pay close attention to them
                     </p>
                    ):(
                      <p>
                        Performance was great all around
                      </p>
                    )}
                   
                  </div>
                  
                  
                  <div className="specificExer_recommendation_conceptList">
                  {examFailedConcepts !== null && Object.keys(examFailedConcepts).length !== 0 ? (
                      <>
                     {Object.keys(examFailedConcepts).map(conceptKey => {
                        const concept = examFailedConcepts[conceptKey]
                        return(
                          <div key={conceptKey}>
                            <p>Concept: {concept.concept}</p>
                            <p>Topic: {concept.topic}</p>
                            <p>Failure Rate: {Math.floor(concept?.averageFailureRate * 10)/10}%</p>
                          </div>
                        )
                      })}
                      </>
                    ):null}
                 </div>
                </div>
                ):null}

                {examFailedStudents !== null ?(
                   <div className="overall_recommendation_studentsContainer">
                   <div className="overall_recommendation_studentsHeader">
                     <h3>Below Average students</h3>
                   </div>
                   <div className="overall_recommendation_studentsList">
                     {examFailedStudents !== null && examFailedStudents.length !== 0 ? (
                       <>
                          {examFailedStudents.map((student)=>(
                            <div 
                              className="overall_recommendation_studentsList-card"
                              key={student.studentId}
                            >
                             <span>{student.studentName} |</span>
                             <span> averageScore {student.averageScore}</span>
                          </div>
                        ))}
                       </>
                     ):(
                       <div className="overall_recommendation_studentsList">
                         <span>Boys and Girls are great</span>
                       </div>
                     )}
                    
                   </div>
                 </div>

                ):null}
               
                </div>
          </div>
          <div>

          </div>
          </div>
        </div>
        </div>
        <ToastContainer
             position="top-center"
             autoClose={1500}
             hideProgressBar
             newestOnTop
             closeOnClick
             rtl={false}
             pauseOnFocusLoss
             draggable
             pauseOnHover
             theme="light"
            />
        </div>
    )
};

export default ClassExercises;