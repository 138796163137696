import React,{useState} from 'react'
import { Link } from "react-router-dom";
import {Dots,Sentry,Levels ,Bounce,Windmill,Digital} from "react-activity"
import "react-activity/dist/library.css"
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './AddTeacher.css'
const AddTeacher = () =>{
    const [buttonLoader, setButtonLoader] = useState(false)
    const [loader, setLoader] = useState(false)
    const [selectedRole, setSelectedRole] = useState('');
    const [addTeacher, setAddTeacher] = useState(false)
    const [focusTeacher, setFocusTeacher] = useState({
      teachername:'',
    })

    const userCreds = JSON.parse(localStorage.getItem('userCreds'))

    const handleTeacherSave = async() =>{
        setButtonLoader(true)
        console.log({
          schoolId:userCreds.user.schoolId,
          teacher_name:focusTeacher.teachername,
          gender:selectedRole
        })
        try {
          
          const options = {
            method:'POST',
            body:JSON.stringify({
              schoolId:userCreds.user.schoolId,
              teacher_name:focusTeacher.teachername,
              gender:selectedRole
            }),
            headers:{
              'Content-type':'application/json'
            }
          }
          await fetch(`${global._URL}/teachers/newschoolteacher`,options)
             .then((res)=>{
              return res.json()
             })
             .then((result)=>{
              setButtonLoader(false)
               console.log(result)
               if(result.successMessage){
               const notify = () => {
                toast.success(`Teacher Added successful,
                will be required to login using his/her name and school name as password for the first time`, {
                  position: "top-center",
                  autoClose:5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
      
               notify()
              }else{
                const notify = () => {
                  toast.error(`Error enrolling new teacher account,${result.errorMessage}`, {
                    position: "top-center",
                    autoClose:1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
        
                 notify()
              }
               //setSchoolTeachersList(result.result)
             })
             .catch((err)=>{
              setButtonLoader(false)
              console.log("Server Error, try again",err)
              const notify = () => {
                toast.warn("Server Error, try again", {
                  position: "top-center",
                  autoClose:1500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
      
               notify()
             })
        } catch (error) {
          setButtonLoader(false)
          console.log('Error saving teacher', error)
          const notify = () => {
            toast.error('Error enrolling new teacher', {
              position: "top-center",
              autoClose:1500,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light"
             })
           };
  
           notify()
        }
      }


    const handleInputChange = (e) =>{
        const {name,value} = e.target
        setFocusTeacher((prevItem)=>({
          ...prevItem,
          [name]:value
        }))
      }

      const handleRoleChange = (e) => {
        setSelectedRole(e.target.value);
      };
    return(
        <div className='addTeacher_container'>
            <div className='addTeacher_wrapper'>
             <div className='addTeacher_header'>
                <span>Fill Teacher Details</span>
             </div>
             <div className='addTeacher_details_container'>
               <input type='text' 
                   placeholder="teacher name"
                   name="teachername"
                   value={focusTeacher.teachername}
                   //onChange={(e)=>handleStudentChange(e)}
                   onChange={handleInputChange}
                 /> 

              <div className="addTeacherRadios_container">
                <span>
                    Gender
                </span>
                <label> 
                <input
                  type="radio"
                  name="role"
                  value="Male"
                  checked={selectedRole === "Male"}
                  onChange={handleRoleChange}
                />
                Male
               </label>
               <label> 
               <input
                 type="radio"
                 name="role"
                 value="Female"
                 checked={selectedRole === "Female"}
                 onChange={handleRoleChange}
               />
               Female
               </label>
                </div>
             </div>
             <div className='addTecher_buttons'>
                <button onClick={handleTeacherSave}>Add</button>
                {buttonLoader && (<Digital/>)}
             </div>
            </div>
            <ToastContainer
              position="top-center"
              autoClose={1500}
              hideProgressBar
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
        </div>
    )
}

export default AddTeacher

