import React,{useState,useEffect} from 'react'
import {Digital, Dots,Levels} from "react-activity"
import "react-activity/dist/library.css"
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Link,useLocation,useParams } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard"
import './Report.css'

const Report =() =>{
    const [ClassName, setClassName] = useState(null)
    const [SchoolDetails, setSchoolDetails] = useState(null)
    const [loader, setLoader] = useState(false)
    const [buttonLoader, setButtonLoader] = useState(false)
    const [subjectLoader, setSubjectLoader] = useState(false)
    const [copied, setCopied] = useState(false)

    const location = useLocation()
    const userCreds = JSON.parse(localStorage.getItem('userCreds'))
    const urlParams = new URLSearchParams(location.search)
    const className = urlParams.get('className')
    const reportData = JSON.parse(decodeURIComponent(urlParams.get('reportData')))

    console.log(reportData)
    const schoolId = userCreds.user.schoolId

    const reportId = useParams().id

    //Fetch school name and report class name to accompany headers

    const schoolReportDetails = async() =>{
      try {
          await fetch(`${global._URL}/schools/schooldetails?schoolId=${schoolId}`)
             .then((res)=>{
              return res.json()
             })
             .then((result)=>{
              console.log(result)
              if(!result.errorMessage){
                  //Other functions
                  setSchoolDetails(result.result.schoolname)
              }else{
                console.log('Error Message',result.errorMessage)
  
                 const notify = () => {
                      toast.error(`${result.errorMessage}`, {
                        position: "top-center",
                        autoClose:2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"
                       })
                     };
          
              notify()
              }
              
             }).catch((err)=>{
              console.log('Server Error', err)
              const notify = () => {
                      toast.error("Internal server Error", {
                        position: "top-center",
                        autoClose:2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"
                       })
                     };
          
              notify()
             })
      } catch (error) {
          console.log('Server Error, no connection')
  
          const notify = () => {
                      toast.warn("Server Error, no connection", {
                        position: "top-center",
                        autoClose:2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"
                       })
                     };
          
              notify()
      }
  }

  const classReportDetails = async() =>{
    try {
        await fetch(`${global._URL}/classes/classinfo/${reportData.classId}`)
           .then((res)=>{
            return res.json()
           })
           .then((result)=>{
            console.log(result)
            if(!result.errorMessage){
                //Other functions
                setClassName(result.result.classInfo.classname)
            }else{
              console.log('Error Message',result.errorMessage)

               const notify = () => {
                    toast.error(`${result.errorMessage}`, {
                      position: "top-center",
                      autoClose:2000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light"
                     })
                   };
        
            notify()
            }
            
           }).catch((err)=>{
            console.log('Server Error', err)
            const notify = () => {
                    toast.error("Internal server Error", {
                      position: "top-center",
                      autoClose:2000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light"
                     })
                   };
        
            notify()
           })
    } catch (error) {
        console.log('Server Error, no connection')

        const notify = () => {
                    toast.warn("IServer Error, no connection", {
                      position: "top-center",
                      autoClose:2000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light"
                     })
                   };
        
            notify()
    }
}

  useEffect(()=>{
   schoolReportDetails()
   classReportDetails()
  },[])

    const publishReport = async() =>{
        setButtonLoader(true)
        console.log(reportId)
        
       const option = {
          method:'PUT',
          body:JSON.stringify({
            status:'Closed'
          }),
          headers:{
            "Content-type":"application/json"
          }
        }
        try {
          await fetch(`${global._URL}/schoolreportform/updateschoolreportform/${reportId}`,option)
            .then((res)=>{
              return res.json()
            })
            .then((result)=>{
              console.log(result)
              setButtonLoader(false)
              if(!result.errorMessage){
                //fetchSubjects(result.classId)
                const notify = () => {
                  toast.success("Report published", {
                       position: "top-left",
                       hideProgressBar: true,
                       closeOnClick: true,
                       draggable: true,
                       progress: undefined,
                       theme: "light"
                   })
                 };
      
                 notify()
              }else{
                const notify = () => {
                  toast.error("Error publishing report", {
                       position: "top-left",
                       hideProgressBar: true,
                       closeOnClick: true,
                       draggable: true,
                       progress: undefined,
                       theme: "light"
                   })
                 };
      
                 notify()

              }
            })
            .catch((err)=>{
              setButtonLoader(false)
              console.log('Fail to publish, server error',err)
              const notify = () => {
                toast.error("Failed to publish report", {
                     position: "top-left",
                     hideProgressBar: true,
                     closeOnClick: true,
                     draggable: true,
                     progress: undefined,
                     theme: "light"
                 })
               };
    
               notify()
            })
        } catch (error) {
          setButtonLoader(false)
          console.log('Failed to update subject',error)
          const notify = () => {
            toast.warn("No Internet connection", {
                 position: "top-left",
                 hideProgressBar: true,
                 closeOnClick: true,
                 draggable: true,
                 progress: undefined,
                 theme: "light"
             })
           };

           notify()
        }
      }

      const deleteReportForm = async() =>{
        const options = {
          method:'DELETE'
        }
        try {
            await fetch(`${global._URL}/schoolreportform/removeschoolreportform/${reportId}`,options)
               .then((res)=>{
                return res.json()
               })
               .then((result)=>{
                console.log(result)
                if(!result.errorMessage){
                    //Other functions
                    const notify = () => {
                      toast.error(`${result.successMessage}`, {
                        position: "top-center",
                        autoClose:2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"
                       })
                     };
          
                    notify()
                }else{
                  console.log('Error Message',result.errorMessage)
    
                   const notify = () => {
                        toast.error(`${result.errorMessage}`, {
                          position: "top-center",
                          autoClose:2000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light"
                         })
                       };
            
                notify()
                }
                
               }).catch((err)=>{
                console.log('Server Error', err)
                const notify = () => {
                        toast.error("Internal server Error", {
                          position: "top-center",
                          autoClose:2000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light"
                         })
                       };
            
                notify()
               })
        } catch (error) {
            console.log('Server Error, no connection')
    
            const notify = () => {
                        toast.warn("IServer Error, no connection", {
                          position: "top-center",
                          autoClose:2000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light"
                         })
                       };
            
                notify()
        }
    }

      const formattedDate = (dateString) =>{
        const date = new Date(dateString)
        return date.toLocaleString()
      }

      const handleCopy = () =>{
        console.log('clicked')
          setCopied(true)
          const notify = () => {
            toast.success("Result link copied", {
                 position: "top-left",
                 hideProgressBar: true,
                 closeOnClick: true,
                 draggable: true,
                 progress: undefined,
                 theme: "light"
             })
           };

           notify()
      
      }
//s://schoolprogress.vercel.app
    return(
        <div className='reportPage_container'>
          <div className='reportPage-header'>
            <p>Report Details</p>
          </div>
          <div className='reportDetails'>
            <div className='reportInfo'>
              <p>{reportData.reportname}</p>
              <p>{ClassName}</p>
              <p>{reportData.reportType}</p>
              <p>Created:{formattedDate(reportData.date)}</p>
              {reportData?.status === 'Open' ? (
                <><p>Unpublished</p></>
                ):(
                <><p>Published</p></>
                )
              }
            </div>
            
            <div className='reportSubjects-container'>
              <div className='reportSubjects-header'>
               <p>Subjects associated</p> 
              </div>
              <div className='subjectsList'>
              {reportData.reportSubjects.map((subject)=>(
                <div className='subjects_card'>
                 {subject.subject}
                </div>
              ))}
              </div>
            </div>
            
            <div className='reportButtonsContainer'>
            
            {reportData?.status === 'Open' && (
              <div className='reportPublishButton'>
                <button onClick={publishReport}>Publish</button>
               <div>
                {buttonLoader && (<Digital/>)}
               </div>    
              </div>
            )}

           {reportData?.status === 'Closed' && (
              <div className='reportPublishButton'>
                {/*<Link to={`/exam/results/${reportId}?details=${encodeURIComponent(JSON.stringify({school:schoolId,class:reportData.classId}))}`}>*/}
                <CopyToClipboard 
                  text = {`https://schoolprogress.vercel.app/exam/results/${reportId}?details=${encodeURIComponent(JSON.stringify({school:schoolId,class:reportData.classId,reportname:reportData.reportname,reportDate:reportData.date,className:ClassName,schoolName:SchoolDetails}))}`}
                  onCopy={handleCopy}
                >
                  <button>
                    Share
                  </button>
                </CopyToClipboard>
                {/*</Link>*/}
              </div>
            )}
            <div className='reportPublishButton'>
            <Link 
                    to={`/report/update/${reportId}`}
                    style={{textDecoration:'none',color:'#000'}}
                    key={reportId}
                  >
                <button>Update</button>
            </Link>    
            </div>   

            <div className='reportPublishButton'>
              <button
                onClick={deleteReportForm}
              >
                Delete
              </button>  
            </div>    
            </div>
            {/*reportData?.status === 'Open' && (
              <div className='reportPublishButton'>
                <button onClick={publishReport}>Publish</button>
               <div>
                {buttonLoader && (<Digital/>)}
               </div>    
              </div>
            )*/}

           {/*reportData?.status === 'Closed' && (
              <div className='reportPublishButton'>
               }
                <CopyToClipboard 
                  text = {`https://schoolprogress.vercel.app/exam/results/${reportId}?details=${encodeURIComponent(JSON.stringify({school:schoolId,class:reportData.classId,reportname:reportData.reportname,reportDate:reportData.date,className:ClassName,schoolName:SchoolDetails}))}`}
                  onCopy={handleCopy}
                >
                  <button>
                    Share
                  </button>
                </CopyToClipboard>
              </div>
            )*/}
            
          </div>
         
          <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        </div>
    )
}

export default Report
