import React, { useEffect, useState } from "react";
import './ClassList.css'
import { Link } from "react-router-dom";
import {Dots} from "react-activity"
import "react-activity/dist/library.css"
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const ClassList = () =>{
  const [loader, setLoader] = useState(false)
    const [classData, setClassData] = useState([])

    //Fetch Students in classes
    const studentInClasses = async (class_id) =>{
        try {
         await fetch(`${global._URL}/classes/classstudents?classId=${class_id}`)
           .then((result=>{
             return result.json()
           }))
           .then((res)=>{
             console.log('students in Classes',res)
           })
        } catch (error) {
         console.log('Failed to fetch students in classes',error)
        }
     }

     //Fetch Classes in school
    /*const schoolClasses = async (school_id) =>{
      console.log({school_id})
        try {
         await fetch(`${global._URL}/classes/schoolclasses?schoolId=${school_id}`)
           .then((result=>{
             return result.json()
           }))
           .then((res)=>{
             console.log('schoolClasses',res)


             //Loop throgh classes to get students in each
               //store them in useState/Localstorage
               //studentInClasses(data)

            //Loop to get subjects in each

               //subjectsInClass(data)   
           })
        } catch (error) {
         console.log('Failed to fetch schoolclasses',error)
        }
     }*/
     const fetchClasses = (school_id)=>{
      return fetch(`${global._URL}/classes/schoolclasses?schoolId=${school_id}`)
         .then((response)=> response.json())
         .catch(error=>{
          console.log('Error fetching classes')
         })
     }

     const fetchStudents = (class_id)=>{
      return fetch(`${global._URL}/classes/classstudents?classId=${class_id}`)
         .then((response)=> response.json())
         .catch(error=>{
          console.log('Error fetching students in class',error)
         })
     }

    useEffect(()=>{
      setLoader(true)
      const LocalData = JSON.parse(localStorage.getItem('userCreds'))
      //console.log(LocalData)

      //Get subjects in class
      //schoolClasses(LocalData.user.schoolId)
      fetchClasses(LocalData.user.schoolId)
         .then(classes=>{
          console.log(classes)
          const fetchStudentPromises = classes?.map(cls=> fetchStudents(cls._id))

          Promise.all(fetchStudentPromises)
            .then(students=>{
              //console.log(students)
              const classListWithStudents = classes?.map((cls,index)=>({
                id:cls._id,
                className:cls.classname,
                students:students[index]
              }))

              //console.log(classListWithStudents)
              setClassData(classListWithStudents)
              setLoader(false)
            })
            .catch((err)=>{
              setLoader(false)
              console.log('Error fetching students',err)
              const notify = () => {
                toast.warn("Disconnected, check Internet", {
                  position: "top-center",
                  autoClose:1500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
      
               notify()
            })
         })
    },[])

    return(
      <div className="classAnalyticsList_container">
        <div className="classList_header">
           <span>Select class</span> 
        </div>
        
        {loader ? (
          <div className="loaderContainer">
            <Dots/>
          </div>
         ):(
          <div className="classList-cards">
          {classData?.map((classItem)=>(
          <Link 
            to={`/class/${classItem.id}?className=${classItem.className}&studentsData=${encodeURIComponent(JSON.stringify({studentsList:classItem.students,classId:classItem.id}))}`}
            style={{textDecoration:'none',color:'#000'}}
            key={classItem.id}
          >
            <div className="classList-card" key={classItem.id}>
              <span>{classItem.className}</span>
            </div>
           </Link>   
          ))}
         </div>
         )} 
      
        
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    )
}

export default ClassList