import React from "react";
import { useNavigate } from "react-router-dom";
import './FirstPage.css'
import logo from "../assets/fake_logo.png"


const FirstPage = () =>{
  const isUser = JSON.parse(localStorage.getItem('userCreds'))

    const navigate = useNavigate()
    return(
      <div className="firstPagecontainer">
        <div className="central_symbol">
          <div className="logo">
            <img src={logo} alt="Logo"/>
          </div>

         {/* <div className="symbol_title">
            <p>schoolProgress</p>






          </div>
         */}
        </div>
        
        {!isUser ? (
           <div className="login-button">
              <button
                onClick={()=>{
                navigate("/login")
               }}
              >
                Login
             </button>
          </div>
        ):(
          <>
           {isUser.role === 'admin' || isUser.role === 'teacher' ? (
            <div className="login-button">
            <button
              onClick={()=>{
              navigate("/home")
             }}
            >
              Continue
           </button>
        </div>
           ):(
            <div className="login-button">
              <button
                onClick={()=>{
                navigate("/parentshome")
               }}
              >
                Continue
             </button>
          </div>
           )}
          </>
        )}
      </div>
    )
}

export default FirstPage
