import React,{useEffect, useState} from 'react'
import { useParams,useNavigate } from "react-router-dom";
import {Digital} from "react-activity"
import "react-activity/dist/library.css"
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './ClassDetails.css'

const ClassDetails = () =>{
    const [classDetails, setClassDetails] = useState(null)
    const [updateStream, setUpdateStream] = useState(false)
    const [streamId, setStreamId] = useState(null)
    const [selectedClassStream, setSelectedClassStream] = useState(null)
    const [classInput, setClassInput] = useState('')
    const [streamInput, setStreamInput] = useState('')
    const [addStream, setAddStream] = useState(false)
    const [reload, setReload] = useState(false)
    const [updateClassPage, setUpdateClassPage] = useState(false)
    const [buttonLoader, setButtonLoader] = useState(false)
    const classID = useParams()  
    const navigate = useNavigate()
    console.log({classID})
    const fetchClassInfo = async() =>{
        try {
            await fetch(`${global._URL}/classes/classinfo/${classID.id}`)
               .then((res)=>{
                return res.json()
               })
               .then((result)=>{
                console.log(result)
                if(!result.errorMessage){
                    setClassDetails(result.result)
                }
               })
               .catch((err)=>{
                console.log('Server Error', err)
               })
            
        } catch (error) {
            console.log('Disconnected, check Internet',error)
        }
    }

    useEffect(()=>{
       fetchClassInfo()
    },[reload])

    const handleClassInput = (e) =>{
        setClassInput(e.target.value)
     }

     const handleStreamInput = (e) =>{
      setStreamInput(e.target.value)
    }  

    const updateClass = async() =>{
        setButtonLoader(true)
        console.log({classInput,classId:classID.id})
        const options = {
          method:'PUT',
          body:JSON.stringify({
            classname:classInput
          }),
          headers:{
            "Content-type":"application/json"
          }
        }
  
        try {
          await fetch(`${global._URL}/classes/updateclass?classId=${classID.id}`,options)
             .then((res)=>{
              return res.json()
             })
             .then((result)=>{
              setButtonLoader(false)
              setClassInput("")
              console.log({resultClass:result})
              if(!result.errorMessage){
                setReload(!reload)
                setUpdateClassPage(false)
                //Notification
              const notify = () => {
                toast.success("Class Updated successfully", {
                  position: "top-center",
                  autoClose:1500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
               notify()
                
              }else{
                //alert("Network Problem, try again")
                const notify = () => {
                  toast.error("Server Error, try again", {
                    position: "top-center",
                    autoClose:1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
      
                 notify()
              }
  
             }).catch((err)=>{
              setClassInput("")
              setButtonLoader(false)
              console.log("Server error fail to update",err)
              const notify = () => {
                toast.error("Server Error, try again", {
                  position: "top-center",
                  autoClose:1500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
               notify()
             })
        } catch (error) {
          setClassInput("")
          setButtonLoader(false)
          console.log('Failed to update class',error)
  
          const notify = () => {
            toast.error("Disconnected, check Internet", {
              position: "top-center",
              autoClose:1500,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light"
             })
           };
  
           notify()
        }
      }

      const addClassStream = async() =>{
        setButtonLoader(true)
        console.log({classInput,classId:classID.id})
        const options = {
          method:'PUT',
          body:JSON.stringify({
            streamTag:streamInput
          }),
          headers:{
            "Content-type":"application/json"
          }
        }
  
        try {
          await fetch(`${global._URL}/classes/addClassStream?classId=${classID.id}`,options)
             .then((res)=>{
              return res.json()
             })
             .then((result)=>{
              setButtonLoader(false)
              setStreamInput("")
              console.log({result:result})
              if(!result.errorMessage){
                setReload(!reload)
                setUpdateClassPage(false)
                //Notification
              const notify = () => {
                toast.success(`${result.successMessage}`, {
                  position: "top-center",
                  autoClose:1500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
               notify()
                
              }else{
                //alert("Network Problem, try again")
                const notify = () => {
                  toast.error(`Server Error, ${result.errorMessage}`, {
                    position: "top-center",
                    autoClose:1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
      
                 notify()
              }
  
             }).catch((err)=>{
              setClassInput("")
              setButtonLoader(false)
              console.log("Server error failed to add stream",err)
              const notify = () => {
                toast.error("Server Error, try again", {
                  position: "top-center",
                  autoClose:1500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
               notify()
             })
        } catch (error) {
          setStreamInput("")
          setButtonLoader(false)
          console.log('Failed to add stream',error)
  
          const notify = () => {
            toast.error("Disconnected, check Internet", {
              position: "top-center",
              autoClose:1500,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light"
             })
           };
  
           notify()
        }
      }

      const [choosenStream, setChosenStream] = useState(null)
      const removeClassStream = async(selectedStream) =>{
        setButtonLoader(true)
        console.log({classId:classID.id,selectedStream})
        const options = {
          method:'PUT',
          headers:{
            "Content-type":"application/json"
          }
        }
  
        try {
          await fetch(`${global._URL}/classes/removeClassStream/${classID.id}/stream/${selectedStream}`,options)
             .then((res)=>{
              return res.json()
             })
             .then((result)=>{
              setButtonLoader(false)
              setStreamInput("")
              console.log({result:result})
              if(!result.errorMessage){
                setReload(!reload)
                setUpdateClassPage(false)
                //Notification
              const notify = () => {
                toast.success(`${result.successMessage}`, {
                  position: "top-center",
                  autoClose:1500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
               notify()
                
              }else{
                //alert("Network Problem, try again")
                const notify = () => {
                  toast.error(`Server Error, ${result.errorMessage}`, {
                    position: "top-center",
                    autoClose:1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
      
                 notify()
              }
  
             }).catch((err)=>{
              setClassInput("")
              setButtonLoader(false)
              console.log("Server error failed to remove stream",err)
              const notify = () => {
                toast.error("Server Error, try again", {
                  position: "top-center",
                  autoClose:1500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
               notify()
             })
        } catch (error) {
          setStreamInput("")
          setButtonLoader(false)
          console.log('Failed to remove stream',error)
  
          const notify = () => {
            toast.error("Disconnected, check Internet", {
              position: "top-center",
              autoClose:1500,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light"
             })
           };
  
           notify()
        }
      }

      const updateClassStream = async() =>{
        //-----------
        setButtonLoader(true)
        console.log({classId:classID.id,streamInput,streamId})
        const options = {
          method:'PUT',
          body:JSON.stringify({
            newTag:streamInput
          }),
          headers:{
            "Content-type":"application/json"
          }
        }
  
        try {
          await fetch(`${global._URL}/classes/updateClassStreamName/${classID.id}/stream/${streamId}`,options)
             .then((res)=>{
              return res.json()
             })
             .then((result)=>{
              setButtonLoader(false)
              setStreamInput("")
              console.log({result:result})
              if(!result.errorMessage){
                setReload(!reload)
                setUpdateClassPage(false)
                //Notification
              const notify = () => {
                toast.success(`${result.successMessage}`, {
                  position: "top-center",
                  autoClose:1500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
               notify()
                
              }else{
                //alert("Network Problem, try again")
                const notify = () => {
                  toast.error(`Server Error, ${result.errorMessage}`, {
                    position: "top-center",
                    autoClose:1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
      
                 notify()
              }
  
             }).catch((err)=>{
              setClassInput("")
              setButtonLoader(false)
              console.log("Server error failed to update stream",err)
              const notify = () => {
                toast.error("Server Error, try again", {
                  position: "top-center",
                  autoClose:1500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
               notify()
             })
        } catch (error) {
          setStreamInput("")
          setButtonLoader(false)
          console.log('Failed to update stream',error)
  
          const notify = () => {
            toast.error("Disconnected, check Internet", {
              position: "top-center",
              autoClose:1500,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light"
             })
           };
  
           notify()
        }
      }

      const deleteClass = async() =>{
        setButtonLoader(true)
        const options = {
          method:'DELETE'
        }
  
        try {
          await fetch(`${global._URL}/classes/removeclass/${classID.id}`,options)
             .then((res)=>{
              return res.json()
             })
             .then((result)=>{
              setButtonLoader(false)
              
              console.log({resultClass:result})
              if(!result.errorMessage){
                //Notification
              const notify = () => {
                toast.success('Class Deleted successfully', {
                  position: "top-center",
                  autoClose:1500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
               notify()
               //navigate('/tools/class-control')
                
              }else{
                //alert("Network Problem, try again")
                const notify = () => {
                  toast.error("Server Error, try again", {
                    position: "top-center",
                    autoClose:1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
      
                 notify()
              }
  
             }).catch((err)=>{
              setClassInput("")
              setButtonLoader(false)
              console.log("Server error fail to update",err)
              const notify = () => {
                toast.error("Server Error, try again", {
                  position: "top-center",
                  autoClose:1500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
               notify()
             })
        } catch (error) {
          setButtonLoader(false)
          console.log('Failed to delete class',error)
  
          const notify = () => {
            toast.error("Disconnected, check Internet", {
              position: "top-center",
              autoClose:1500,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light"
             })
           };
  
           notify()
        }
      }
    
    return(
        <div className='classDetails_container'>
            {!updateClassPage ? (
            <div className='wrapper'>
               <div className='classDetails_header'>
              <span>Class Details</span>
            </div>
            <div className='classDetails_wrapper'>
              <span>Class Name: {classDetails?.classInfo.classname}</span>
              <span>Students: {classDetails?.numberOfStudents}</span>
            </div>
            <div className='buttonOptions'>
                 <button onClick={()=>{setUpdateClassPage(true)}}>
                    Update
                </button>
                <button onClick={deleteClass}>
                    Delete
                </button> 
                
            </div> 
            </div>
            ):(
             <div className='updateClass_container'>
                <div className='updateClass_header'>
                    <span>Update Class</span>
                </div>
                {!addStream?(
                  <div className='updateInput_container'>
                  <input 
                    type="text" 
                    placeholder="Update class name"
                    onChange={handleClassInput}
                  />
                  </div>
                ):null}
                
                {addStream ? (
                  <div className='classStreams_wrapper'>
                  <div className='classStreams_container'>
                    <div className='classStreams_header'>
                      <span>Current class streams</span>
                    </div>
                    {classDetails.classInfo.streams?.map((stream)=>(
                      <div className='classStream_card'>
                      <div>
                        <span>{stream.streamTag}</span>
                      </div>
                      <div className='classStream_card-actions'>
                        {/*!updateStream ? (
                        <button
                          onClick={()=>{
                            setUpdateStream(true)
                            setSelectedClassStream(stream.streamTag)
                            setStreamInput(stream.streamTag)
                            setStreamId(stream._id)
                          }}
                        >
                          Update
                        </button>
                        ):(
                          <button
                          onClick={()=>{
                            setUpdateStream(false)
                          }}
                        >
                          Cancel
                         </button>
                        )*/}
                        <button
                          onClick={()=>{
                            setUpdateStream(true)
                            setSelectedClassStream(stream.streamTag)
                            setStreamInput(stream.streamTag)
                            setStreamId(stream._id)
                          }}
                        >
                          Update
                        </button>
                        <button
                          onClick={()=>{
                            removeClassStream(stream.streamTag)
                          }}
                        >
                          Delete
                        </button>
                      </div>  
                    </div>  
                    ))}
                    
                  </div>    
                  <div className='updateInput_container'>
                    <input 
                      type="text" 
                      placeholder='stream name'
                      onChange={handleStreamInput}
                      value={streamInput}
                    />
                  </div>  
                  </div>
                ):null}

                 
                <div className='updateButton_container'>
                    {!addStream?(
                    <button
                      onClick={updateClass/*addClassStream*/}
                    >
                        Save changes
                    </button>
                    ):(
                    <>
                    
                       <button
                       onClick={updateClassStream}
                      >
                        Update stream
                      </button>
                   
                      <button
                       onClick={addClassStream}
                      >
                        Save stream
                      </button>
                   
                      
                    </>  
                    
                    )}
                    {buttonLoader && (<Digital/>)}
                </div>
                <div className='updateButton_container'>
                    <button
                      onClick={()=>setAddStream(!addStream)}
                    >
                      {!addStream ? 'Streams' : 'Cancel'}
                    </button>
                 </div>
                <div className='closeButton'>
                 <button onClick={()=>{setUpdateClassPage(false)}}>Close</button>
                </div>
             </div>
            )}
           <ToastContainer
             position="top-center"
             autoClose={1500}
             hideProgressBar
             newestOnTop
             closeOnClick
             rtl={false}
             pauseOnFocusLoss
             draggable
             pauseOnHover
             theme="light"
            />
             
        </div>
    )
}

export default ClassDetails

