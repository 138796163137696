import React, { useEffect, useState,useContext } from "react";
import { AuthContext } from "../../../GlobalStates/GlobalStates";
import './ManageClasses.css'
import { Link } from "react-router-dom";
import {Dots} from "react-activity"
import {useNavigate} from 'react-router-dom'
import useApiRequest from "../../../Hooks/AuthHooks";
import "react-activity/dist/library.css"
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
//import api from "../../../Helpers/Axios";
//import axios from "axios";
//import ApiCaller from "../../../Helpers/Axios";

const ManageClasses = () =>{
  //const [authState,setAuthState] = useContext(AuthContext);
  const [loader, setLoader] = useState(false)
    const [classList, setClassList] = useState([])
    const [errorMessage, setErrorMessage] = useState('')

    const userCreds = JSON.parse(localStorage.getItem('userCreds'))
    const schoolID = userCreds.user.schoolId

    //console.log({TOKEN:authState.TOKEN})

    //const api = ApiCaller()


    //const TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTBkYjVhY2U0N2M2MmI5ZDk5NDQ4NmQiLCJyb2xlIjo0NTYsImlhdCI6MTcwMTcxMTY4MH0.pAa9GTIq1srW9Sq3M4DWGCXxz6w2-VeU2nOakZ1DcCc`


    const navigate = useNavigate()
    const fetchClasses = async(schoolID) =>{
      setLoader(true)
        try {
            await fetch(`${global._URL}/classes/classes-details?schoolId=${schoolID}`,
            {
              method: 'GET',
              headers: new Headers({
                TOKEN: '',
              }),
            })
              .then((res)=>{
                return res.json()
              })
              .then((result)=>{
                setLoader(false)
                console.log(result)
                if(!result.errorMessage){
                  setClassList(result.result)
                }else{
                  setErrorMessage(result.errorMessage)
                  const notify = () => {
                    toast.error("Internal server error", {
                      position: "top-center",
                      autoClose:1500,
                      hideProgressBar: true,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light"
                     })
                   };
          
                   notify()
                }
              })
              .catch((err)=>{
                setLoader(false)
                console.log('Failed to fetch school classes',err)
                const notify = () => {
                  toast.warn("Disconnected, check Internet", {
                    position: "top-center",
                    autoClose:1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
        
                 notify()
              })
        } catch (error) {
          setLoader(false)
            console.log('Failed to get classes',error)
        }
    } 


    /*const fetchClasses = async(schoolID) =>{

   

      setLoader(true)
      const config = {
        headers:{
          'TOKEN':`${authState.TOKEN}`
        }
      }
      try {
        const response = await api.get(
          `/classes/classes-details?schoolId=${schoolID}`
           //config
       )

       setLoader(false)
       console.log({output:response.data})

        if(!response.data.errorMessage){
          setClassList(response.data.result)
        }else{
          //setErrorMessage('Failed to load classes')
          const notify = () => {
            toast.error("Internal server error", {
              position: "top-center",
              autoClose:1500,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light"
             })
           };
  
           notify()
        }
      } catch (error) {
        setLoader(false)
            console.log('Failed to get classes',error)
            //console.log('Failed to fetch school classes',err)
                const notify = () => {
                  toast.warn("Failed to get classes", {
                    position: "top-center",
                    autoClose:1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
        
                 notify()
      }
    }

    //Custom hook
    const apiUrl = `${global._URL}/classes/classes-details?schoolId=${schoolID}`
    const requestOptions = {
      method:'GET',
      headers:{
        TOKEN: `${authState.TOKEN}`
      }
    }*/

    /*const {response, error} = useApiRequest(apiUrl, requestOptions)

    console.log({response,error})*/
    



    /*useEffect(() =>{
      if(response){
        console.log({hook:response})
      }

      if(error){
        console.log({hookError:error})
      }
      
      
    },[response, error])*/

    useEffect(()=>{
    const userCreds = JSON.parse(localStorage.getItem('userCreds'))
    const schoolID = userCreds.user.schoolId
    console.log(schoolID)
    fetchClasses(schoolID)

    /*if(response){
      console.log({hook:response})
    }

    if(error){
      console.log({hookError:error})
    }*/
      
    },[])

    

    



    return(
        <div className="classManager_container">
          <div className="classManager_header">
           <span>School classes</span>
          </div>
          {loader ? (
           <div className="loaderContainer">
            <Dots/>
           </div>
           ):(
          <div className="classList_wrapper">
          {loader ? (
           <div className="loaderContainer">
            <Dots/>
           </div>
           ):(
            <div className="classList_container"> 
            {!errorMessage ? (
              <>
                {classList?.length !== 0 ?(
              <>
                {classList?.map((classItem)=>(
              <Link 
                to={`/class/class-details/${classItem.classId}`}
                style={{textDecoration:'none',color:'#000'}}
                key={classItem.classId}
               >    
                <div className="classManager_card" key={classItem.classId}>
                 <div className="classManager_title">
                   <span>{classItem.classname}</span>
                 </div>  
                 <div className="classManager_details">
                   <span>No.stds {classItem.numberOfStudents}</span>
                 </div>
               </div>   
              </Link>  
             ))}
             </>
            ):(
             <div className="noClasses_container">
               <span>No classes yet</span>
             </div>
            )}
              </>
              ):(
              <>
               <div className="noClasses_container">
               <span>Network problems</span>
             </div>
              </>
              )}
            
           
             </div>
           )} 
          </div>
         )}
         
            <div className="addClassButton">
             <button
               onClick={()=> navigate('/class/add-class')}
             >
              Add New
            </button>
            </div> 
          
          
         
         
          <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        </div>
    )
}

export default ManageClasses
