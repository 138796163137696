import React, { useEffect, useState,  } from "react";
import './ManageStudents.css'
import { Link,useNavigate } from "react-router-dom";
import personAvatar from '../../assets/person2.png'
import {Digital, Dots} from "react-activity"
import 'react-datepicker/dist/react-datepicker.css'
import "react-activity/dist/library.css"
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const ManageStudents = () =>{
  const [loader, setLoader] = useState(false)
  const [ClassLoader, setClassLoader] = useState(false)
  const [selectedMoveClass, setSelectedMoveClass] = useState(null)
  const [buttonLoader, setButtonLoader] = useState(false)
  const [IDNumberInput, setIDNumberInput] = useState(null)
  const [moveStudents, setMoveStudents] = useState(false)
  const [studentsMoveList, setStudentsMoveList] = useState([])
  const [studentList, setStudentList] = useState([])
  const [classList, setClassList] = useState([])
  const [activeClassMembers, setActiveClassMembers] = useState(null)
   
    //const [classInput, setClassInput] = useState('')
    const [selectedClass, setSelectedClass] = useState(null)

    const navigate =  useNavigate()

    const userCreds = JSON.parse(localStorage.getItem('userCreds'))
    const schoolID = userCreds.user.schoolId

    const fetchClasses = async(schoolID) =>{
      setClassLoader(true)
        try {
            await fetch(`${global._URL}/classes/schoolclasses?schoolId=${schoolID}`)
              .then((res)=>{
                return res.json()
              })
              .then((result)=>{
                setClassLoader(false)
                //console.log(result)
                if(!result.message){
                  setClassList(result)
                  
                }
                //if(result.length === 1){
                  setSelectedClass(result[0]._id)
                  
               // }
                
              })
              .catch((err)=>{
                setClassLoader(false)
                console.log('Failed to fetch school classes',err)
                const notify = () => {
                  toast.warn("Classes not found", {
                    position: "top-center",
                    autoClose:1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
        
                 notify()
              })
        } catch (error) {
          setClassLoader(false)
            console.log('Failed to get classes',error)
            const notify = () => {
              toast.warn("Disconnected, check Internet", {
                position: "top-center",
                autoClose:1500,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
                theme: "light"
               })
             };
    
             notify()
        }
    } 

    const fetchStudents = async(schoolID) =>{
      try {
          await fetch(`${global._URL}/students/schoolstudents?schoolId=${schoolID}`)
            .then((res)=>{
              return res.json()
            })
            .then((result)=>{
              console.log(result.length)
              if(!result.message){
                setStudentList(result)
              }
              
            })
            .catch((err)=>{
              console.log('Failed to fetch school classes',err)
            })
      } catch (error) {
          console.log('Failed to get classes',error)
      }
  } 

    useEffect(()=>{
    const userCreds = JSON.parse(localStorage.getItem('userCreds'))
    const schoolID = userCreds.user.schoolId
    console.log(schoolID)
      fetchClasses(schoolID)
      fetchStudents(schoolID)
    },[])

    const IDNumberInputChange = (e) =>{
      const IDNumberData = e.target.value
      //console.log(IDNumberData)
      setIDNumberInput(IDNumberData)
    }

    const searchStudentById = async () =>{
      setLoader(true)
      console.log("Here")
      //console.log(selectedClass)
      console.log({
        schoolId:schoolID,
        studentname:IDNumberInput
      })
      try {
        await fetch(`${global._URL}/students/search-byId?schoolId=${schoolID}&studentname=${IDNumberInput}`)
          .then((res)=>{
            return res.json()
          })
          .then((result)=>{
            console.log(result)
            setLoader(false)
            if(!result.errorMessage){
              //console.log(result.result)
              setActiveClassMembers([result])
            }else{
              const notify = () => {
                toast.error(`Error, ${result.errorMessage}`, {
                     position: "top-left",
                     hideProgressBar: true,
                     closeOnClick: true,
                     draggable: true,
                     progress: undefined,
                     theme: "dark"
                 })
               };
    
               notify()
               setActiveClassMembers(null)
            }
          })
          .catch((err)=>{
            setLoader(false)
            console.log('Failed to search student',err)
          })
      } catch (error) {
        setLoader(false)
        console.log('Failed to search student')
      }
    }

    const handleClassChange = (focus) => {
      const selectedClassItem = focus.target.value
      //setSelectedClass(selectedClassItem)
      console.log(selectedClassItem)

      //Loop Students to get class students
      const activeStudentsInClass = studentList.filter((student)=>{
        //console.log(student)
        const classMembers = student.classId === selectedClassItem//classId
        return classMembers 
      })

      //Set Active Class in select option
       const activeClassChoice = classList.filter((classItem)=>{
        //console.log(student)
        const activeClass = classItem._id === selectedClassItem//classId
        //console.log(activeClass)
        return activeClass
       // return setSelectedClass(activeClass)
      })

      //console.log(activeClassChoice)
      setSelectedClass(activeClassChoice[0]._id)


      console.log(activeStudentsInClass)
      setActiveClassMembers(activeStudentsInClass)
    }

    const handleMoveClassChange = (focus) => {
      const selectedClassItem = focus.target.value
      setSelectedMoveClass(selectedClassItem)
     // console.log(selectedClassItem)
      }


   

    
    const handleStudentMove = (studentId,regNumber) =>{
     // console.log({selected:{studentId,regNumber}})
      const isSelected = studentsMoveList.some(
        (student) => student.studentId === studentId
      )

      if(isSelected){
        const updateStudents = studentsMoveList.filter(
          (student) => student.studentId !== studentId
        )
        setStudentsMoveList(updateStudents)
      }else{
        const updatedStudents = [
          ...studentsMoveList,
          {studentId,regNumber}
        ]
        setStudentsMoveList(updatedStudents)
      }
    }



    const [moveLoader, setMoveLoader] = useState(false)


    const saveStudentsMove = async() =>{
      console.log({studentsMoveList,selectedMoveClass,selectedClass})

      if(selectedMoveClass === selectedClass){
        const notify = () => {
          toast.warn("Can't move to the same class", {
               position: "top-left",
               hideProgressBar: true,
               closeOnClick: true,
               draggable: true,
               progress: undefined,
               theme: "dark"
           })
         };
         notify()
         return
      }

      
      setMoveLoader(true)


     


      const options = {
        method:'PUT',
        body:JSON.stringify({
          studentsList:studentsMoveList
        }),
        headers:{
          'Content-type':'application/json'
        }
      }
              
     try {
        await fetch(`${global._URL}/students/moveStudentsClass?classId=${selectedMoveClass}`,options)
          .then((res)=>{
            return res.json()
          })
          .then((result)=>{
            console.log({result})
            setMoveLoader(false)
            if(!result.errorMessage){

              
               //Notification
              const notify = () => {
              toast.success("Students moved to class successfully !!", {
                   position: "top-left",
                   hideProgressBar: true,
                   closeOnClick: true,
                   draggable: true,
                   progress: undefined,
                   theme: "dark"
               })
             };
             notify()
            }else{
              const notify = () => {
                toast.error(`Error, ${result.errorMessage}`, {
                     position: "top-left",
                     hideProgressBar: true,
                     closeOnClick: true,
                     draggable: true,
                     progress: undefined,
                     theme: "dark"
                 })
               };
    
               notify()
            }
          })
          .catch((err)=>{
            setMoveLoader(false)
            console.log('Failed to move students',err)
          })
      } catch (error) {
        setMoveLoader(false)
        console.log('Failed to move students')
      }
    }

    return(
        <div className="studentManager_container">
          <div className="functionalities_container">
          <div className="studentManager_header">
            <span>Manage Students</span>
          </div>
            <div className="searchWrapper">
              <div className="inputSearch_wrapper">
               <input type="text" 
                 placeholder="search by name"
                 value={IDNumberInput}
                 onChange={IDNumberInputChange}
               />
               <button onClick={searchStudentById}>seach</button>
              </div>
              {/*<div className="searchOptions">
                <div className="searchOptions_header">
                  <span>choose student status</span>
                </div>
                <div className="searchOptions_options">
                  <div>
                    <label> 
                     <input
                       type="radio"
                       name="role"
                       value="Active"
                       //checked={selectedRole === "Male"}
                       //onChange={handleRoleChange}
                     />
                     Active
                    </label>
                  </div>
                
                  <div>
                    <label> 
                     <input
                       type="radio"
                       name="role"
                       value="Left"
                       //checked={selectedRole === "Male"}
                       //onChange={handleRoleChange}
                     />
                     Left
                    </label>
                  </div>
                  <div>
                    <label> 
                     <input
                       type="radio"
                       name="role"
                       value="Graduate"
                       //checked={selectedRole === "Male"}
                       //onChange={handleRoleChange}
                     />
                     Graduate
                    </label>
                  </div>
                </div> 
          </div>*/}
            </div>
            <div className="searchOptions_tools">
            <div className="chooseClassWrapper">
              <span>Go to class</span>
              {ClassLoader ? (<Digital/>):(
               <select 
                value={selectedClass} 
                onChange={handleClassChange}
               >
              <option  
                  selected 
                  value="null"
                  //disabled
                  key={1}
                  >
                    Select class
                  </option>
              {classList.map((classItem)=>(
                <option key={classItem._id} 
                   value={classItem._id}
                   >
                  {classItem?.classname}
                </option>
              ))}
             </select>
              )}
            </div>
            <div className="searchByYear">
            <div className="addButton">
              {!moveStudents ? (
                <button
                onClick={()=>setMoveStudents(true)}
               >
                 Move
               </button>
              ):(
                <button
               onClick={()=>setMoveStudents(false)}
              >
                Cancel
              </button>
              )}
              
            </div>
            <div className="category">
                <span>Class</span>
                <select 
                  //disabled
                  value={selectedMoveClass} 
                  onChange={handleMoveClassChange}
                 >
                  <option  
                  selected 
                  value="null"
                  //disabled
                  key={1}
                  >
                    Select class
                  </option>
                {classList.map((classItem)=>(
                  <option key={classItem._id} 
                     value={classItem._id}
                     >
                    {classItem?.classname}
                  </option>
                ))}
              </select>
            </div>
            <div className="category">
              {moveStudents ? (
                <div className="addButton">
                <button
                 onClick={saveStudentsMove}
                >
                  Save
                </button>
              </div>
              ):null}
             
            </div>
            </div>
            <div className="addButton">
              <button
               onClick={()=>navigate('/student/add-student')}
              >
                Enroll
              </button>
            </div>


            {moveLoader ? (<Digital/>):null}


               {moveLoader ? (<Digital/>):null} 

          </div>
        </div>

          <div className="searchResults_wrapper">
           <div className="searchResult_header">
            <span>Search Results</span>
           </div>
           {!loader ? (
            <>
              {activeClassMembers !== null && activeClassMembers.length !== 0 ? (
            <div className="searchResults">
            {!moveStudents ? (
              <>
                {activeClassMembers?.map((student)=>(
              <Link 
                style={{textDecoration:'none',color:'#000'}}
                to={`/student/update-student/${student._id}`}
                key={student._id}
              >
               <div className="studentCard">
                <div className="studentAvatar">
                  <img src={personAvatar} alt="Student avatar"/>
                </div>
                <span>{student.studentname}</span>
                <span>{student.IDNumber}</span>
              </div>
             </Link>
            ))}
              </>
            ):(
              <>
              {activeClassMembers.length !== 0 ? (
               <>
                {activeClassMembers?.map((student)=>(
                 <div 
                  className='studentcard'
                   key={student._id}
                 >
                   <label>
                     <input
                       type="checkbox"
                       onChange={(e)=> handleStudentMove(student._id,student.IDNumber,e.target.value)}
                     />
                     {`   `} {student.IDNumber}{`  `}{student.studentname}
                   </label>
                 </div>
               ))}
               </>
              ):(
                <div className="noStudents">
                 <span>Class is empty!</span>
                </div>
              )}
                
              </>
            )}  
            
           </div>
           ):(
            <div className="noStudents">
              <span>Choose class to view students</span>
            </div>
           )}
            </>
           ):(
            <div className="noStudents"><Dots/></div>
           )}
          
          </div>  
         
         <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
         />
        </div>
    )
}

export default ManageStudents
