import React,{useEffect,useState} from 'react'
import { Link,useLocation } from "react-router-dom";
import {Dots,Levels} from "react-activity"
import "react-activity/dist/library.css"
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './ClassReportList.css'

const ClassReportList = () =>{
    const [loader, setLoader] = useState(false)   
    const [openMarksReport,setMarksReport] = useState(false)
    const [openCBDReport,setCBDReport] = useState(false)
    const [reportSubjectList, setReportSubjectList] = useState([])
    const [subjectLoader, setSubjectLoader] = useState(false)
    const [reportList, setReportList] = useState([])
    const [CBDreportList, setCBDReportList] = useState([])
    const [selectedSubject, setSelectedSubject] = useState({reportId: null, subject: null })/*null*/
    const location = useLocation()
    const urlParams = new URLSearchParams(location.search)
    const className = urlParams.get('className')
    const studentsData = JSON.parse(decodeURIComponent(urlParams.get('studentsData')))
    //const studentsList = studentsData.studentsList
    const classID = studentsData.classId

    
    console.log(classID)

    const handleSubjectSelect = (reportId,subject)=>{
      console.log(subject)
      setSelectedSubject({reportId,subject})
      /*setSelectedSubject((prevSelectedSubjects) => ({
        ...prevSelectedSubjects,
        [reportId]: subject,
      }));*/
    }

    const fetchReports = async(classItem) =>{
      const currentDate = new Date()
      const listedYear =  currentDate.getFullYear() 
      console.log({classId:classItem})
      //setLoader(true)
      try {
          await fetch(`${global._URL}/schoolreportform/classreportformlist?classId=${classItem}&year=${listedYear}`)
            .then((res)=>{
              return res.json()
            })
            .then((result)=>{
              console.log(result.result)
              //setLoader(false)
              if(result.result){
                setReportList(result.result)
              }
            })
            .catch((err)=>{
              setLoader(false)
              console.log('Failed to fetch class reports',err)
            })
      } catch (error) {
          setLoader(false)
          console.log('Failed to get class reports',error)
      }
  }

  const sortedReportList = reportList?.slice().sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const fetchCBReports = async(classItem) =>{
    console.log({classId:classItem})
    //setLoader(true)
    try {
        await fetch(`${global._URL}/CBD/classCBD?classId=${classItem}`)
          .then((res)=>{
            return res.json()
          })
          .then((result)=>{
            console.log(result.result)
           // setLoader(false)
            if(result.result){
              setCBDReportList(result.result)
            }
          })
          .catch((err)=>{
            setLoader(false)
            console.log('Failed to fetch class reports',err)
          })
    } catch (error) {
      //setLoader(false)
        console.log('Failed to get class reports',error)
    }
}

const formatDate = (createdAt) => {
  const today = new Date();
  const createdDate = new Date(createdAt);
  
  const isToday = (
    today.getDate() === createdDate.getDate() &&
    today.getMonth() === createdDate.getMonth() &&
    today.getFullYear() === createdDate.getFullYear()
  );

  const isYesterday = (
    today.getDate() - 1 === createdDate.getDate() &&
    today.getMonth() === createdDate.getMonth() &&
    today.getFullYear() === createdDate.getFullYear()
  );

  if (isToday) {
    // Format time for today
    return `Today at ${createdDate.toLocaleTimeString()}`;
  } else if (isYesterday) {
    // Format time for yesterday
    return `Yesterday at ${createdDate.toLocaleTimeString()}`;
  } else {
    // Format time for other days
    return createdDate.toLocaleString(); // You can adjust the format as needed
  }
};


    useEffect(()=>{
      setLoader(true)
       //fetchReports(classID)
       Promise.all([fetchCBReports(classID),fetchReports(classID)])
          .then(()=>{
            setLoader(false)
          })
          .catch((err)=>{
            console.log('Error in fetching',err)
          })
    },[])

     const groupedCBDReportList = Object.values(
        CBDreportList.reduce((acc, report)=>{
          if(!acc[report.reportId]){
            acc[report.reportId] = {
              reportId:report.reportId,
              reportname:report.reportname,
              data:[]
            }
          }
          acc[report.reportId].data.push({
            CBDReportId:report._id,
            MarkReportId:report.reportId,
            subjectname:report.subjectname,
            numberOfQuestions:report.numberOfQuestions
          });
          return acc;
        },{})
      )

    const testCBDReport = () =>{
      console.log(groupedCBDReportList)
    }

    return(

      <div className="main-page">
      <div className="listHeader">
            <span>{className} Reports</span>
      </div>
      {loader ? (
        <div className="loaderContainer">
          <Dots/>
        </div>
        ):(
      <div className='body_wrapper'>
      
        <div className='reportType_container'>
          <div className='reportType_header'>
            <p>Report Type</p>
          </div>
           
            <div className='reportType_options'>
            <button
              onClick={()=>{
                setMarksReport(true)
                setCBDReport(false)
              }}
            >
              Marks Reports
            </button>
            <button
              onClick={()=>{
                setMarksReport(false)
                setCBDReport(true)
                testCBDReport()
              }}
              disabled
            >
              Competence Reports
            </button>
            
            </div>
         
          
        </div> 

      {openMarksReport && (
        <div className="studentList">
        {sortedReportList?.map((report)=>(
          <div className='reportCard'>
            <p>{report.reportname}</p>
            <p>{report.reportType}</p>
            <p>{report.status}</p>
            <p>{formatDate(report.createdAt)}</p>
            <div>
              <p>Subjects</p>
              <ul>
                {report.subjects?.map((subject)=>(
                  <li key={subject._id}>
                    <button
                      onClick={()=>{
                        handleSubjectSelect(report._id,subject)
                      }}
                    >
                     {subject.subject}
                    </button>
                  </li>
                ))}
              </ul>
            </div>

          {selectedSubject.reportId === report._id /*!== null*/ && (
            <div className='reportCardButton'> 
            <Link 
            style={{textDecoration:'none',color:'#000'}}
            to={`/studentreport/${classID}?reportDetails=${encodeURIComponent(JSON.stringify({
              reportName:report.reportname,
              reportType:report.reportType,
              reportId:report._id,
              subjectname:selectedSubject.subject.subject,
              numberOfQuestions:selectedSubject.subject.numberOfQuestions || '',
              subjectId:selectedSubject.subject._id
              }))}`}
              >
               <button>
                 Fill {selectedSubject.subject.subject} Report 
               </button>
                  {/*<img src={forwardIcon}/>*/}
             </Link> 
            </div>
             )}      
           </div>     
          ))}
         </div>
        )}

        {openCBDReport && (
        <div>
         {groupedCBDReportList?.map((report)=>(
          <div className='CBDReportCard'>
            <div className='CBDReportCard-header'>
              <p>{report.reportname}</p>
            </div>
            {report.data.map((subject)=>(
              <div className='reportCardButton'>
              <Link 
               style={{textDecoration:'none',color:'#000'}}
               to={`/CBDReport/${subject.CBDReportId}?CBDReportData=${encodeURIComponent(JSON.stringify({reportId:subject.MarkReportId,reportname:report.reportname,subjectname:subject.subjectname,numberOfQuestions:subject.numberOfQuestions}))}`}>
                <button>
                  {subject.subjectname}
                </button>
              </Link> 
            </div>
            ))}     
          </div>     
          ))}
          </div>
          )}      
        
    
      </div>
      )}
      <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
     
    )
}

export default ClassReportList