import React,{useState,useEffect} from 'react'
import {Digital, Dots,Levels} from "react-activity"
import "react-activity/dist/library.css"
import { ToastContainer, toast } from 'react-toastify';
import { Link,useNavigate } from "react-router-dom";
import './AddReport.css'
const AddReport = () =>{
    const [classes, setClasses] = useState([]);
    const [selectedClass, setSelectedClass] = useState(null)
    const [selectedSubjectItems, setSelectedSubjectItems] = useState([])
    //const [subjectList, setSubjectList] = useState([])
    const [loader, setLoader] = useState(false)
    const [buttonLoader, setButtonLoader] = useState(false)
    const [focusSubject, setFocusSubject] = useState({
        reportname:undefined,
        reportType:undefined,
        classId:undefined
      })

      const reportTypes = [
        {id:1, report:'Weekly'},
        {id:2, report:'Monthly'},
        {id:3, report:'Special'},
      ] 

      const subjectList = [
        {id:1, subject:'Mathematics'},
        {id:2, subject:'Arithmetics&Counting'},
        {id:3, subject:'Reading Skills'},
        {id:4, subject:'Writting Skills'},
        {id:5, subject:'Science'},
        {id:6, subject:'English'},
        {id:7, subject:'Kiswahili'},
        {id:8, subject:'C.M.E'},
        {id:9, subject:'S/Studies'},
        {id:10, subject:'French'},
        {id:11, subject:'Arabic'},
        {id:12, subject:'Religion'},
        {id:13, subject:'Reading'},
        {id:14, subject:'Writting'}, 
        {id:15, subject:'Numeracy'},
        {id:16, subject:'H.C.E'},
        {id:17, subject:'D.S.A'},
        {id:18, subject:'Communication'},
        {id:19, subject:'Arithmetics'},
        {id:20, subject:'Health Care'},
        {id:21, subject:'Environment Care'},
        {id:22, subject:'Relation'},
        {id:23, subject:'Artistic'},
        {id:24, subject:'V/SKILLS'},
        {id:25, subject:'Listerning'},
        {id:26, subject:'Culture'}, 
        {id:27,subject:'Geography'},
        {id:28,subject:'History'}, 
        {id:29,subject:'Sports'},
        { id: 32, subject: "Science" },
        { id: 36, subject: "Chemistry" },
        { id: 37, subject: "Computer Science" },
        { id: 38, subject: "Biology" },
        { id: 39, subject: "Physics" },
        { id: 40, subject: "Commerce" },
        { id: 41, subject: "B/Keeping" } 
      ]

      const navigate = useNavigate()

      useEffect(() => {
        setLoader(true)
        const userCreds = JSON.parse(localStorage.getItem('userCreds'))
        const schoolID = userCreds.user.schoolId
        //console.log(schoolID)
        setLoader(true)
        // Fetch classes
        fetch(`${global._URL}/classes/schoolclasses?schoolId=${schoolID}`)
          .then(response => response.json())
          .then(classesData => {
            setLoader(false)
            //console.log(classesData)
            if(!classesData.message){
              setClasses(classesData);
              if(classesData.length !== 0){
                setSelectedClass(classesData[0]._id)
              }
              
            }
            })
            .catch((err)=>{
              setLoader(false)
              console.log('Failed to get classes',err)
              const notify = () => {
                toast.warn("Disconnected, check Internet", {
                  position: "top-center",
                  autoClose:3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
        
               notify()
            })
        },[])

      const saveReport = async() =>{
        console.log(selectedSubjectItems)
        const userCreds = JSON.parse(localStorage.getItem('userCreds'))
        const schoolID = userCreds.user.schoolId
          setButtonLoader(true)
         //console.log(focusSubject,selectedSubjectItems)
       console.log({
          reportname:focusSubject.reportname,
            reportType:focusSubject.reportType,
            classId:selectedClass,
            subjects:selectedSubjectItems,
            schoolId:schoolID
         })

         const checkNumberOfQuestions = selectedSubjectItems.filter(subject => !subject.numberOfQuestions)
        // console.log(checkNumberOfQuestions)

         if(checkNumberOfQuestions.length !== 0){
          const notify = () => {
            toast.error('Fill number of questions to all subjects', {
              position: "top-center",
              autoClose:1500,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light"
             })
           };

           notify()
           setButtonLoader(false)
           return
         }

         console.log('Continue')

         const option = {
          method:'POST',
          body:JSON.stringify({
            reportname:focusSubject.reportname,
            reportType:focusSubject.reportType,
            classId:selectedClass,
            subjects:selectedSubjectItems,
            schoolId:schoolID
          }),
          headers:{
            'Content-type':'application/json'
          }
        }
      
         try {
            await fetch(`${global._URL}/schoolreportform/newschoolreportform`,option)
              .then((res)=>{
                return res.json()
              })
              .then((result)=>{
                setButtonLoader(false)
                //console.log(result)
                if(!result.errorMessage){
                  //alert("Subject saved successful")
                   //Notification
                   const notify = () => {
                    toast.success("New report added", {
                      position: "top-center",
                      autoClose:3000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light"
                     })
                   };
        
                   notify()
                  //fetchSubjects(result.classId)
                }else{
                  const notify = () => {
                    toast.error(`${result.errorMessage}`, {
                      position: "top-center",
                      autoClose:3000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light"
                     })
                   };
        
                   notify()
                }
              })
              .catch((err)=>{
                setButtonLoader(false)
                console.log('Failed to update',err)
      
                const notify = () => {
                  toast.error("Server Error, try again", {
                    position: "top-center",
                    autoClose:3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
        
                 notify()
              })
          } catch (error) {
            setButtonLoader(false)
            console.log('Failed to save report',error)
      
            const notify = () => {
              toast.warn("Disconnected, check Internet", {
                position: "top-center",
                autoClose:3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
                theme: "light"
               })
             };
      
             notify()
          }
        }

    const handleReportNameChange = (focus) =>{
        const reportname = focus.target.value
        //console.log(reportname)
        setFocusSubject((prevItem)=>({
          ...prevItem,
          ['reportname']:reportname
        }))
      }

      const chooseReportType = (focus) =>{
        const choice = focus.target.value
        //console.log(choice)
        setFocusSubject((prevItem)=>({
          ...prevItem,
          ['reportType']:choice
        }))
      }

      /*const handleCBFormCheckbox = (event) =>{
        setAddCBForm(event.target.checked)
        if(!event.target.checked){
          //Remove noOfQuestion
          const clearedSelected = selectedSubjectItems.map(({numberOfQuestions,noOfQuestions,...rest})=> rest)
          setSelectedSubjectItems(clearedSelected)
        }
      }*/

      const handleSelectedSubject = (item) =>{
        //console.log({item,selected:selectedSubjectItems})
        const isSelected = selectedSubjectItems.find(selected => selected.id === item.id)
        //console.log(isSelected)
        if(isSelected){
          const updatedItems = selectedSubjectItems.filter(selected=>selected.id !== item.id)
          setSelectedSubjectItems(updatedItems)
        }else{
          //console.log({saved:item})
          setSelectedSubjectItems([...selectedSubjectItems, item])
        }
      }

      const handleNumberChange = (event,subject) =>{
        const newNoOfQuestions = parseInt(event.target.value || 0)
        if(!isNaN(newNoOfQuestions)){
          const updatedSubject = selectedSubjectItems.map((subj)=>
          subj.id === subject.id ? {...subj, numberOfQuestions:newNoOfQuestions}:subj
        )
        setSelectedSubjectItems(updatedSubject)
      }
        
        if(newNoOfQuestions===0){
          const updatedZeroSubject = selectedSubjectItems.map((subj)=>
          subj.id === subject.id ? {...subj, numberOfQuestions:newNoOfQuestions}:subj
        )
          //const clearedSelected = selectedSubjectItems.map(({numberOfQuestions,...rest})=> rest)
          setSelectedSubjectItems(/*clearedSelected*/updatedZeroSubject)
        }
      }

      const handleClassChange = (focus) => {
        const selectedClassID = focus.target.value
        console.log(selectedClassID)
       // const selectedClass = classList.find((classdata)=> classdata.id === selectedClassID)
        setSelectedClass(selectedClassID)
        setFocusSubject((prevItem)=>({
          ...prevItem,
          ['classId']:selectedClassID
        }))
      }

    return(
        <div className='addReport_container'>
            <div className='addReport_wrapper'>
                <div className='addReport_header'>
                    <span>Fill Report Details</span>
                </div>
                <div className='addReport_inputContainer'>
                    <div className="addReport_selectClass">
                    <select value={selectedClass?selectedClass._id : ''} onChange={handleClassChange}>
                     {classes.map((classItem)=>(
                       <option key={classItem._id} 
                          value={classItem._id}
                          >
                         {classItem?.classname}
                       </option>
                      ))}
                    </select>
                    </div>
                   <input type='text' 
                      placeholder='Report Name'
                      id='reportname'
                      onChange={handleReportNameChange}
                    />

                   <div className='addReportContainer-reportType'>
                      <div className='addReportContainer-reportType_header'>
                        <span>Report Type</span>
                      </div>
                      {reportTypes.map((report)=>(
                        <div key={report.id} className='newReportContainer-reportCard'>
                          <label> 
                           <input
                             type="radio"
                             name="reportType"
                             value={report.report}
                             //checked={selectedReportType === {report.report}}
                             onChange={chooseReportType}
                           />
                           {report.report}
                          </label>
                        </div>  
                      ))}
                    </div>
                    <div className='addReportContainer-reportSubjects'>
                      <div className='addReportContainer-reportSubject_header'>
                        <span>Included Subjects</span>
                      </div>
                      {subjectList.map((subject)=>(
                        <div key={subject.id} className='newReportContainer-reportSubjectCard'>
                        <input
                          type="checkbox"
                          checked={selectedSubjectItems.some(selected=>selected.id === subject.id)}
                          onChange={()=>handleSelectedSubject(subject)}
                        />
                       <label>{subject.subject}</label>
                      </div>
                      ))}

                      {/*<div className='addReportContainer-addPerformanceCheck'>
                        <span>Add Competence Form</span>
                        <input 
                          type="checkbox"
                          checked={addCBForm}
                          onChange={handleCBFormCheckbox}
                        />
                      </div>*/}
                    </div>
                </div>
                <div className='CB_container'> 
                   {selectedSubjectItems.length !== 0 ? (            
                        <div className='numberOfQuestions_header'>
                          <span>Total Marks</span>
                        </div>
                    ): null }
                  
                      {selectedSubjectItems?.map((subject)=>(
                        <div className='CB_card'>
                          <p>{subject.subject}</p>
                          <input 
                            type="number"
                            value={subject.numberOfQuestions}
                            onChange={(e) => handleNumberChange(e,subject)} 
                          />
                        </div>  
                        
                      ))}
                  </div>
                 
                 <div className='addReport_buttons'>
                    <button onClick={()=>{
                      navigate('/tools/schoolreports')
                    }}>
                      Cancel
                    </button>
                    <button onClick={saveReport}>
                      Save
                    </button>
                    {buttonLoader && (<Digital/>)}
                 </div>
                
            </div>
            <ToastContainer
             position="top-center"
             autoClose={1500}
             hideProgressBar
             newestOnTop
             closeOnClick
             rtl={false}
             pauseOnFocusLoss
             draggable
             pauseOnHover
             theme="light"
            />
        </div>
    )
}

export default AddReport

