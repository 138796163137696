import React, { useEffect, useState, useReducer } from "react";
import {Digital, Dots,Levels} from "react-activity"
import "react-activity/dist/library.css"
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './ManageSubjects.css'

const ManageSubjects = () =>{
    const [subjectList, setSubjectList] = useState([])
    const [qualitiesList, setQualitiesList] = useState([])
    const [selectedQualityItems, setSelectedQualityItems] = useState([])
    const [loader, setLoader] = useState(false)
    const [buttonLoader, setButtonLoader] = useState(false)
    const [subjectLoader, setSubjectLoader] = useState(false)
    const [subjectInput,setSubjectInput] = useState('')
    const [classes, setClasses] = useState([]);
    const [selectedClass, setSelectedClass] = useState(null)
    const [openQualities, setOpenQualities] = useState(false)
    const [focusSubject, setFocusSubject] = useState({
      _id:'',
      subjectname:'',
      classId:''
    })

    const userCreds = JSON.parse(localStorage.getItem('userCreds'))
    const schoolID = userCreds.user.schoolId


  useEffect(() => {
    setLoader(true)
    const userCreds = JSON.parse(localStorage.getItem('userCreds'))
    const schoolID = userCreds.user.schoolId
    console.log(schoolID)
    // Fetch classes
    fetch(`${global._URL}/classes/schoolclasses?schoolId=${schoolID}`)
      .then(response => response.json())
      .then(classesData => {
        setLoader(false)
        console.log(classesData)
        if(!classesData.message){
          setClasses(classesData);

        



            if(classesData.length != 0){
                setSelectedClass(classesData[0]._id) 
            }

        }
        })
        .catch((err)=>{
          setLoader(false)
          console.log('Failed to get classes',err)
        })   
       
  }, []);
 

    const fetchSubjects = async(classItem) =>{
      setSubjectLoader(true)
      try {
          await fetch(`${global._URL}/subjects/classsubjects?classId=${classItem._id}`)
            .then((res)=>{
              return res.json()
            })
            .then((result)=>{
              setSubjectLoader(false)
              console.log({subjectList:result})
              if(!result.message){
                setSubjectList(result)
              }
            })
            .catch((err)=>{
              setSubjectLoader(false)
              console.log('Failed to fetch class subjects',err)
            })
      } catch (error) {
        setSubjectLoader(false)
          console.log('Failed to get class subjects',error)
      }
  } 

  const saveSubject = async() =>{
    console.log(selectedQualityItems)
    setButtonLoader(true)
    console.log({
      subjectname:focusSubject.subjectname,
      classId:selectedClass,
      qualities:selectedQualityItems//focusSubject.classId
    })
    const option = {
      method:'POST',
      body:JSON.stringify({
        subjectname:focusSubject.subjectname,
        classId:selectedClass,
        qualities:selectedQualityItems
      }),
      headers:{
        'Content-type':'application/json'
      }
    }

    try {
      await fetch(`${global._URL}/subjects/newsubject`,option)
        .then((res)=>{
          return res.json()
        })
        .then((result)=>{
          setButtonLoader(false)
          console.log(result)
          setSelectedQualityItems([])
          if(result.successMessage){
            //alert("Subject saved successful")
             //Notification
             const notify = () => {
              toast.success("New Subject added successfully", {
                position: "top-center",
                autoClose:1500,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
                theme: "light"
               })
             };
  
             notify()
            //fetchSubjects(result.classId)
          }
        })
        .catch((err)=>{
          setButtonLoader(false)
          console.log('Failed to update',err)

          const notify = () => {
            toast.error("Server Error, try again", {
              position: "top-center",
              autoClose:1500,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light"
             })
           };
  
           notify()
        })
    } catch (error) {
      setButtonLoader(false)
      console.log('Failed to update subject',error)

      const notify = () => {
        toast.warn("Disconnected, check Internet", {
          position: "top-center",
          autoClose:1500,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          theme: "light"
         })
       };

       notify()
    }
  }

  const updateSubject = async() =>{
    setButtonLoader(true)
    console.log({
      _id:focusSubject.subjectId,
      subjectname:focusSubject.subjectname,
      classId:selectedClass,
      qualities:selectedQualityItems//focusSubject.classId
    })
    const option = {
      method:'PUT',
      body:JSON.stringify({
        subjectname:focusSubject.subjectname,
        classId:selectedClass,
        qualities:selectedQualityItems//focusSubject.classId
      }),
      headers:{
        "Content-type":"application/json"
      }
    }
    try {
      await fetch(`${global._URL}/subjects/updatesubject/${focusSubject.subjectId}`,option)
        .then((res)=>{
          return res.json()
        })
        .then((result)=>{
          console.log(result)
          setButtonLoader(false)
          setSelectedQualityItems([])
          if(!result.message){
            //fetchSubjects(result.classId)
            const notify = () => {
              toast.success("Subject updated", {
                   position: "top-left",
                   hideProgressBar: true,
                   closeOnClick: true,
                   draggable: true,
                   progress: undefined,
                   theme: "dark"
               })
             };
  
             notify()
          }
        })
        .catch((err)=>{
          setButtonLoader(false)
          console.log('Failed to update',err)
        })
    } catch (error) {
      setButtonLoader(false)
      console.log('Failed to update subject',error)
    }
  }

  const handleInputChange = (e) =>{
    const {name,value} = e.target
    //const newInput = e.target.value
    //.log(newInput)
    setFocusSubject((prevItem)=>({
      ...prevItem,
      [name]:value
    }))
  }

  const handleFocusSubject = (subjectdata) =>{
    console.log(subjectdata._id)

    setFocusSubject({
      subjectId:subjectdata._id,
      subjectname:subjectdata.subjectname,
      classId:subjectdata.classId
    })
  }

  const handleClassChange = (focus) => {
    const selectedClassID = focus.target.value
    console.log(selectedClassID)
   // const selectedClass = classList.find((classdata)=> classdata.id === selectedClassID)
    setSelectedClass(selectedClassID)
    setFocusSubject((prevItem)=>({
      ...prevItem,
      ['classId']:selectedClassID
    }))
  }

  const handleSelectedQuality = (item) =>{
    console.log({item})
    const isSelected = selectedQualityItems.find(selected => selected.id === item.id)
    console.log(isSelected)
    if(isSelected){
      const updatedItems = selectedQualityItems.filter(selected=>selected.id !== item.id)
      setSelectedQualityItems(updatedItems)
    }else{
      console.log({saved:item})
      setSelectedQualityItems([...selectedQualityItems, item])
    }
  }

    return(
      <div className="manageSubjects_wrapper">
        <div className="manageSubjects_header">
          <span>School Subjects</span>
        </div>
        <div className="manageSubjects_choiceOptions">
          <div className="choiceOptions_header">
            <span>Choose class</span>
          </div>
          <div className="choiceOPtions_classSelect">
          <select value={classes?classes._id : ''} onChange={handleClassChange}>
                     {classes.map((classItem)=>(
                       <option key={classItem._id} 
                          value={classItem._id}
                          >
                         {classItem?.classname}
                       </option>
                      ))}
              </select>
          </div>
        </div>
        <div className="manageSubjectslassList">
          <div className="manageSubjectsClassList_header">
            <span>Class One Subjects</span>
          </div>
          <div className="classList_list">
            {subjectList.map((subject)=>(
              <div className="classSubject_card">
                <span>{subject.subjectname}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="manageSubjects_addNew">
          <button>Add New Subject</button>
        </div>
      </div>
  )
}

export default ManageSubjects
