import react,{useState,useEffect} from 'react'
import {Dots} from "react-activity"
import "react-activity/dist/library.css"
import { ToastContainer, toast } from 'react-toastify';
import { Link, useNavigate,useLocation} from "react-router-dom";
import { sampleTableData } from '../../../utils/fakeData';
import './ManageSchoolReports.css'

import { Radio, Select,Table,Space } from 'antd';
//const plainOptions = ['Apple', 'Pear', 'Orange'];
const options = [
  {
    label: 'Exams',
    value: 'Exams',
    title: 'Exams',
  },
  {
    label: 'C.A',
    value: 'C.A',
  },
];

const ManageSchoolReports = () =>{
    const [classes, setClasses] = useState([]);
    const [CADetail, setCADetail] = useState(null)
    const [selectedClass, setSelectedClass] = useState(null)
    const [CAReportList, setCAReportList] = useState(null)
    //const [subjectList, setSubjectList] = useState([])
    const [loader, setLoader] = useState(false)
    const [buttonLoader, setButtonLoader] = useState(false)
    const [reportList, setReportList] = useState([])
    const [subjectLoader, setSubjectLoader] = useState(false)
    const [CAExist, setCAExist] = useState(null)

      const navigate = useNavigate()

    useEffect(() => {
        setLoader(true)
        const userCreds = JSON.parse(localStorage.getItem('userCreds'))
        const schoolID = userCreds.user.schoolId
        //console.log(schoolID)
        setLoader(true)
        // Fetch classes
        fetch(`${global._URL}/classes/schoolclasses?schoolId=${schoolID}`)
          .then(response => response.json())
          .then(classesData => {
            setLoader(false)
            console.log(classesData)
            if(!classesData.message){
              setClasses(classesData);
              if(classesData.length !== 0){
                setSelectedClass(classesData[0]._id)
              }
              
            }
            })
            .catch((err)=>{
              setLoader(false)
              console.log('Failed to get classes',err)
              const notify = () => {
                toast.warn("Disconnected, check Internet", {
                  position: "top-center",
                  autoClose:3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
        
               notify()
            })
        },[])

        const [classChosen, setClassChosen] = useState(null)
        const handleClassChange = (focus) => {
            const selectedClassID = focus//.target.value
            console.log(selectedClassID)
            console.log(/*selectedClassID,*/focus)
            const classChoice = classes.find((classItem)=> classItem._id === selectedClassID)
            console.log(classChoice)
            setSelectedClass(/*selectedClassID*/focus)
            setClassChosen(classChoice)
            /*setFocusSubject((prevItem)=>({
              ...prevItem,
              ['classId']:selectedClassID
            }))*/
          }

        useEffect(()=>{
            const fetchReports = async(classItem) =>{
              const currentDate = new Date()
              const listedYear =  currentDate.getFullYear() 
                console.log({classId:classItem})
                setSubjectLoader(true)
                try {
                    await fetch(`${global._URL}/schoolreportform/classreportformlist?classId=${classItem}&year=${listedYear}`)
                      .then((res)=>{
                        return res.json()
                      })
                      .then((result)=>{
                        setSubjectLoader(false)
                        console.log(result.result)
                        
                        if(result.result){
                          setReportList(result.result)
                        }
                      })
                      .catch((err)=>{
                        setSubjectLoader(false)
                        console.log('Failed to fetch class reports',err)
                      })
                } catch (error) {
                  setSubjectLoader(false)
                    console.log('Failed to get class reports',error)
                    const notify = () => {
                      toast.warn("Disconnected, check Internet", {
                        position: "top-center",
                        autoClose:3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"
                       })
                     };
                     notify()
                }
            }
           
            fetchReports(selectedClass)

            const checkCAReports = async(classItem) =>{
              const currentDate = new Date()
              const listedYear =  currentDate.getFullYear() 
                console.log({classId:classItem})
                try {
                    await fetch(`${global._URL}/CA/classCAReports?classId=${classItem}`)
                      .then((res)=>{
                        return res.json()
                      })
                      .then((result)=>{
                        setSubjectLoader(false)
                        console.log(result)
                        
                        if(result){
                          setCAExist(result.reportExist)
                          setCADetail(result.caReport)
                        }
                      })
                      .catch((err)=>{
                        setSubjectLoader(false)
                        console.log('Failed to fetch class reports',err)
                      })
                } catch (error) {
                  setSubjectLoader(false)
                    console.log('Failed to get class reports',error)
                    const notify = () => {
                      toast.warn("Disconnected, check Internet", {
                        position: "top-center",
                        autoClose:3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"
                       })
                     };
            
                     notify()
                }
            }

            checkCAReports(selectedClass)
        },[selectedClass])  

    const [reportType, setReportType] = useState('Exams');

    const  changeReportType = ({ target: { value } }) => {
      console.log('radio3 checked', value);
      setReportType(value);
    };

    //-------------------CA TABLE-------------------------------
    const columns = [
      {
        title: 'CA_Name',
        dataIndex: 'CA_Name',
        render: (text) => <a>{text}</a>,
      },
      
      {
        title: 'CA_Status',
        dataIndex: 'CA_status',
      },
      /*{
        title: 'Age',
        dataIndex: 'age',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.age - b.age,
      }*/
      {
        title: 'Action',
        dataIndex: 'action',
        render:(text)=>(
          <Space size="middle">
            <a>Update</a>
            <a>Delete</a>
          </Space>
        )
      }
    ];
  
    const onChange = (pagination, filters, sorter, extra) => {
      console.log('params', pagination, filters, sorter, extra);
    };

    //---------------------------------------------------------


    return(
      <div className='manageReportsContainer'>
         <div className='mainContainer-header'>
            <span>School Reports Portal</span>
         </div>

        <div className='classChoice_container'>
          {/*    <div className='classChoice_header'>
              <span>Report Type</span>
            </div>
            <div className='bodyContainer-selection'>
            <div className="role-choice">
           
             <Radio.Group 
               options={options} 
               onChange={changeReportType} 
               value={reportType} 
               optionType="button" 
             />
           
            </div>
          </div>*/}

            <div className='classChoice_header'>
              <span>Choose Class</span>
            </div>
            <div className='bodyContainer-selection'>
              {/*<select value={selectedClass?selectedClass._id : ''} onChange={handleClassChange}>
                {classes.map((classItem)=>(
                  <option key={classItem._id} 
                     value={classItem._id}
                     >
                    {classItem?.classname}
                  </option>
                ))}
               </select>*/}
               <Select
                defaultValue="Classes"
                style={{
                  width: 120,
                }}
                loading={loader}
                options={classes.map((classItem)=> ({
                  value:classItem._id,
                  label:classItem.classname
                }))}
                onChange={handleClassChange}
              />
              </div>
             </div> 
             
              <div className='reportsListContainer'>
                  <div className='reportsListContainer-header'>
                  {reportType === 'Exams' ? (
                    <span>Exam Reports</span>
                   ):(
                    <span>Continuos Assessment Reports</span>
                   )}
                  </div>

                  
                  {reportType === 'Exams' ? (
                    <div className='reportList_wrapper'>
                     {reportList.map((report)=>(
                      <div className='reportList-card' key={report._id}>
                    <Link 
                    to={`/report/${report._id}?className=${report.className}&reportData=${encodeURIComponent(JSON.stringify({
                      reportname:report.reportname,
                      reportType:report.reportType,
                      reportSubjects:report.subjects,
                      status:report.status,
                      date:report.createdAt,
                      classId:selectedClass
                    }))}`}
                    style={{textDecoration:'none',color:'#000'}}
                    key={report._id}
                  >
                    <div>
                     <p>{report.reportname}</p>
                    </div>
                  </Link>  
                  </div>
                  
                   ))}
                 </div> 
                  ):(
                   {/* <div>
                     <Table
                         columns={columns}
                         dataSource={CAReportList}
                         onChange={onChange}
                         showSorterTooltip={{
                           target: 'sorter-icon',
                         }}
                       />
                      </div> */} 
                  )} 
                    
              </div>
              <div className='addNewButton_container'>
                {reportType === 'Exams' ? (
                  <div className="gap-2 flex">
                  <button 
                 onClick={()=>{
                  navigate('/schoolreports/add-report')
                  }}>
                    New Exam 
                  </button>

                  {!CAExist && selectedClass !== null ? (
                     <button 
                     onClick={()=>{
                      navigate('/schoolreports/add-CA-report', {state:{classId:selectedClass}})
                      }}>
                        New C.A 
                    </button>
                  ):(
                    <button 
                     onClick={()=>{
                     navigate(`/report/updateCA`, {state:{classChosen:classChosen,caToUpdate:CADetail}})
                   }}>
                    Update C.A 
                   </button>
                   )}
                 </div>
                 ):(
                  <></>
                  )}
                   
              </div>
      <ToastContainer
        position="top-center"
        autoClose={1500}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      </div>
  )
}

export default ManageSchoolReports