import React, { useEffect, useState, useRef } from "react";
import './ParentsPage.css'
import { Link,useParams,useNavigate,Navigate } from "react-router-dom";
import jsPDF from 'jspdf'
import html2canvas from "html2canvas";
import {Page,Text,View,Document,StyleSheet,PDFDownloadLink, renderToStream} from "@react-pdf/renderer"
import logo from '../assets/fake_logo.png';
import personAvatar from '../assets/person2.png'
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import {Dots,Digital} from "react-activity"
import "react-activity/dist/library.css"
import ParentReport from "./parentReport";
import { subjectList,subjectColors } from '../../utils/fakeData';
import { truncateString } from "../../utils/truncateString";

import {
  Chart,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,ArcElement,
  LineElement
    } from 'chart.js'
import { Bar,Pie,Line } from 'react-chartjs-2';

Chart.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  ArcElement,
  LineElement
)
//import { visibility } from "html2canvas/dist/types/css/property-descriptors/visibility";
//import monthlyReports from "../../utils/monthlyReports"

const ParentsPage = () =>{
  const [loader, setLoader] = useState(false)
  const [documentReady, setDocumentReady] = useState(false)
  const [reportHeader, setReportHeader] = useState()
  const [studentOverallData,setStudentOverallData] = useState(null)
  const [studentReportData,setStudentReportData] = useState(null)
  const [availableReportList,setAvailableReportList] = useState([])
  const [showReport, setShowReport] = useState(false)
  const [reportDetails, setReportDetails] = useState(null)
  const [studentDetails, setStudentDetails] = useState(null)
  const [selectedSubject, setSelectedSubject] = useState(null)
  const [TopicOverallPerformance, setTopicOverallPerformance] = useState(null)
  const [displayPDF, setDisplayPDF] = useState(false)
  const [CAReportList, setCAReportList] = useState(null)

  const navigate = useNavigate()
  const reportTemplateRef = useRef()

  function getColorForSubject(subject) {
    // Use the subjectColors object to get the color for the subject
    return subjectColors[subject] || 'rgba(0, 0, 0, 0.6)'; // Default to black if no color found
  }

  const handleGeneratePdf = () =>{
    /*const input = document.getElementById('ParentContainer')

    input.style.display = 'block';
    //setDisplayPDF(true)
   const doc = new jsPDF({
      format:'a4',
      unit:'px'
    })

    doc.setFont('Inter-Regular','normal')

    const element = document.getElementById('ParentContainer')

    doc.html(reportTemplateRef.current,{
      async callback(doc){
        await doc.save('document')
      }
    })*/

    /*const logoImage = document.getElementById('logo-image')
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    canvas.width = logoImage.width
    canvas.height = logoImage.height
    ctx.drawImage(logoImage,0,0)
    const logoImageData = canvas.toDataURL('image/png')*/

 
    const input = document.getElementById('ParentContainer')

    input.style.display = 'block';

    const imageLogo = new Image()

    //imageLogo.src = studentDetails.schoolDetails.schoolLogo + '?noCache=' + Math.random().toString()
    //imageLogo.source = studentDetails.schoolDetails.schoolLogo
    

    //imageLogo.crossOrigin = "anonymous"

    html2canvas(input).then((canvas)=>{
      const imgData = canvas.toDataURL('image/png')

      

      /*const pdf = new jsPDF({
        format:'a4',
        unit:'px',
        orientation:'landscape'
      });*/
      let pdf
      if(canvas.width > canvas.height){
        console.log({width:'Bigger'})
        pdf = new jsPDF('l','px',[canvas.width,canvas.height]);
      }else{
        console.log({height:'Bigger',width:canvas.width,height:canvas.height})
        if(canvas.width < 1500 && canvas.height < 1500){
          pdf = new jsPDF('1','px',[canvas.width * 2,canvas.height * 2]);
        }
        pdf = new jsPDF('1','px',[canvas.width,canvas.height]);
      }

      let imageHeight,imageWidth;
      if(canvas.width < 1500 && canvas.height < 1500){
        imageWidth = canvas.width * 2 
        imageHeight = canvas.height * 2
      }
        imageWidth = canvas.width 
        imageHeight = canvas.height 
      

     // console.log({imageWidth,imageHeight})
      pdf.addImage(imgData, 'PNG', 0,0,imageWidth,imageHeight);
     // pdf.addImage(imageLogo, 'PNG', 0,0, 100, 100)
      pdf.save(`${studentDetails?.studentname} Academic Report.pdf`)





      input.style.display = 'none';
    })
  }


  useEffect(()=>{
    const parentsDetail = JSON.parse(localStorage.getItem('@parentCreds'))
    if(!parentsDetail){
      return <Navigate to= "/login"/>
    }else{
      const studentId = parentsData._id
     studentProfileData(studentId)
    }
   
   fetchOverallPerformance()
   //fetchNewReportPerformance()
   studentReports()
  },[])

  const parentsData = JSON.parse(localStorage.getItem('@parentCreds'))
  //console.log(parentsData)

  const studentProfileData = async(studentId) =>{
    console.log(studentId)
    try {
      await fetch(`${global._URL}/students/studentdetails/${studentId}`)
        .then((res)=>{
          return res.json()
        })
        .then((result)=>{
          console.log({studentDetails:result})
          if(result.successMessage){
           setStudentDetails(result.result) 
          }
        })
        .catch((err)=>{
          console.log('Failed to get student profile',err)
        })
    } catch (error) {
      console.log('Failed to bring student profile',error)
    }
  }


 

  const studentID = parentsData._id

  const fetchOverallPerformance = async() =>{
    console.log({studentID})
    const year = new Date().getFullYear()
    try {
      await fetch(`${global._URL}/reports/studentoverallperformance?studentId=${studentID}&year=${year}`)
          .then((res)=>{
            return res.json()
          })
          .then((result)=>{
            console.log(result)
            if(!result.errorMessage){
              setStudentOverallData(result.overallPerformance)
            }
          }) 
          .catch((err)=>{
            console.log('Failed to fetch report data',err)
          })
    } catch (error) {
      console.log('Failed to fetch studet overall performance',error)
    }
  }

  const fetchNewReportPerformance = async(reportId) =>{
    setLoader(true)
    console.log({
      studentId:studentID,
      reportId:reportId,
      schoolId:parentsData.schoolId,
      classId:studentDetails.classId
    })
    try {
      await fetch(`${global._URL}/reports/studentperformanceNew?studentId=${studentID}&reportId=${reportId}&schoolId=${parentsData.schoolId}&classId=${studentDetails.classId}`)
          .then((res)=>{
            return res.json()
          })
          .then((result)=>{
            console.log(result)
            setLoader(false)
            
            if(!result.errorMessage){
              setReportDetails(true)
              setStudentReportData(result)
              setDocumentReady(true)
            }
          }) 
          .catch((err)=>{
            setReportDetails(false)
            setLoader(false)
            console.log('Failed to fetch report data',err)
          })
    } catch (error) {
      setLoader(false)
      setReportDetails(false)
      console.log('Failed to fetch studet report data',error)
    }
  }


  //Functionality that fetch and make available reports allowed by school
  //When parents choose report it display the report
  let localReportHeader;
  const [forceRender, setForceRender] = useState(false)
  const studentReports = async() =>{
    //Use studentId and classId to find reports with this student
    try {
        await fetch(`${global._URL}/schoolreportform/studentreportformlist?studentId=${studentID}`)
       .then((res)=>{
        return res.json()
       })
       .then((result)=>{
        //console.log(result.result)
        if(!result.errorMessage){
          setAvailableReportList(result.result)
          
          setSelectedReport(result.result[0]._id)
          setReportHeader(result.result[0].reportname)
          setForceRender((prev) => !prev)
          localReportHeader = result.result[0].reportname
         // console.log({theReportHeader:result.result[0].reportname})
        }
       })
       .catch((err)=>{
        console.log('Failed to fetch class reports', err)
       })   
    } catch (error) {
        console.log('Low internet connection failed to fetch reports', error)
    }
}

const handleLogOut = () =>{
  localStorage.clear('userCreds')
  localStorage.clear('@parentsCreds')
  navigate('/login')
}


const [selectedReport, setSelectedReport] = useState(null)
const handleChangeReport = (e) =>{
   setSelectedReport(e.target.value)
}

useEffect(()=>{
  
  if(availableReportList !== null && availableReportList.length > 1){
    const activeReportDetails = availableReportList.filter((report) => {
      const activeReport = report._id === selectedReport
      return activeReport
    })
    console.log({firstNinja:availableReportList[0].reportname})
    setReportHeader(activeReportDetails.reportname)
  }
 
  //localReportHeader = activeReportDetails.reportname
  fetchNewReportPerformance(selectedReport)
},[selectedReport,studentDetails])



let subjectsWithLowGrades
if(studentOverallData !== null){
  subjectsWithLowGrades= Object.entries(studentOverallData)
   .filter(([subject,data])=>data.grade !=='A' && data.grade !== 'B')
   .map(([subject,data])=>({name:subject,grade:data.grade}))

  // console.log({subjectsWithLowGrades})
}

//---------------Subject Deep Analytics Fetch---------------------//

const [studentSubjectRecentData, setStudentSubjectRecentData] = useState(null)
const fetchStudentSubjectRecentPerformance = async(subjectName) =>{
  try {
      await fetch(`${global._URL}/reports/studentSubjectRecentReports?studentId=${studentID}&subjectName=${subjectName}`)
         .then((res)=>{
          return res.json()
         })
         .then((result)=>{
          console.log({subjectRecentPerformance:result})
          if(!result.errorMessage){
              //Other functions
              setStudentSubjectRecentData(result)
          }else{
            console.log('Error Message',result.errorMessage)

             const notify = () => {
                  toast.error(`${result.errorMessage}`, {
                    position: "top-center",
                    autoClose:2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
      
          notify()
          }
          
         }).catch((err)=>{
          console.log('Server Error', err)
          const notify = () => {
                  toast.error("Internal server Error", {
                    position: "top-center",
                    autoClose:2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
      
          notify()
         })
  } catch (error) {
      console.log('Server Error, no connection')

      const notify = () => {
                  toast.warn("Server Error, no connection", {
                    position: "top-center",
                    autoClose:2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
      
          notify()
  }
}

const fetchStudentSubjectTopicPeformance = async(subject) =>{
  console.log({classId:studentDetails.classId,studentID,subject})
  try {
    await fetch(`${global._URL}/exercisereports/exercise-studentsubjecttopicsperformance?studentId=${studentID}&classId=${studentDetails?.classId}&subject=${subject}`)
       .then((res)=>{
        return res.json()
       })
       .then((result)=>{
        console.log({studentSubjectTopicsData:result})
        if(!result.errorMessage){
            //Other functions
            setTopicOverallPerformance(result)
        }else{
          console.log('Error Message',result.errorMessage)

           const notify = () => {
                toast.error(`${result.errorMessage}`, {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
       // notify()
        }
        
       }).catch((err)=>{
        console.log('Server Error', err)
        const notify = () => {
                toast.error("Internal server Error", {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
      //  notify()
       })
} catch (error) {
    console.log('Server Error, no connection')

    const notify = () => {
                toast.warn("IServer Error, no connection", {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
       // notify()
}
}

//CA Reports
const fetchCAReports = async() =>{
  const currentDate = new Date()
  const listedYear =  currentDate.getFullYear() 
    console.log({classId:studentDetails?.classId})
    try {
        await fetch(`${global._URL}/CA/classCAReports?classId=${studentDetails?.classId}`)
          .then((res)=>{
            return res.json()
          })
          .then((result)=>{
            console.log(result)
            
            if(result){
              setCAReportList(result)

              //Put output on a table for visibility of details
            }
          })
          .catch((err)=>{
            console.log('Failed to fetch class reports',err)
          })
    } catch (error) {
        console.log('Failed to get class reports',error)
        const notify = () => {
          toast.warn("Disconnected, check Internet", {
            position: "top-center",
            autoClose:3000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light"
           })
         };

         notify()
    }
}


useEffect(()=>{
  fetchCAReports()
},[])




//------------------------------------GRAPHS-----------------------------//
let OverallBarChartdata, OverallBarChartOptions,overallSubjectData
if(studentOverallData !== null){
  overallSubjectData = studentOverallData
}
if(studentOverallData !== null){
  console.log(overallSubjectData )
   // Extract report names from the data
   const subjectNames = Object.keys(overallSubjectData ); 
   const subjectAverageMarks = subjectNames.map((subject)=>overallSubjectData[subject].averageMarks)
   console.log({ subjectNames,subjectAverageMarks });

   const backgroundColors = subjectNames.map(subject => subjectColors[subject]);
 
   OverallBarChartdata = {
       labels: subjectNames, // Use report names as labels on the x-axis
       datasets:[
      {
        label:'Overall Marks',
        backgroundColor: backgroundColors,//'rgba(99, 132, 0, 0.6)',
        borderColor: backgroundColors,//'rgba(99, 132, 0, 1)',
        data: subjectAverageMarks,
      }
     ],
     };
 
     OverallBarChartOptions = {
       maintainAspectRatio:false,
       AspectRatio:1,
       scales: {
         x: [
           {
             barPercentage: 1,
             //categoryPercentage: 0.4,
           }
         ],
         y: [{
          ticks:{
            stepSize:10,
            min:0,
            max:100,
          }
        
        }],

        /* y: [
           {
             id: 'y-axis-Registered',
           }
         ],*/
       },
       barPercentage: 0.6, 
       categoryPercentage: 0.6
     };
}

//-------------Exam Specifc
let ReportBarChartdata, ReportBarChartOptions,reportSubjects
console.log({studentReportData:studentReportData})
if(studentReportData !== null){
  reportSubjects = studentReportData?.subjectsAnalytics.subjects
}

if(studentReportData !== null && studentReportData !== undefined){

 const backgroundColors = Object.keys(reportSubjects).map(subject => subjectColors[subject]);
 const subjectsLabels = Object.keys(reportSubjects)
 console.log({subjectsLabels})

const subjectExamScore = subjectsLabels.map((subject)=>reportSubjects[subject].score)
console.log({subjectExamScore})

ReportBarChartdata = {
    labels: subjectsLabels,
    datasets: [
      {
        label:'Score',
        backgroundColor: backgroundColors,//'rgba(99, 132, 0, 0.6)',
        borderColor: backgroundColors,//'rgba(99, 132, 0, 1)',
        data: subjectExamScore,
      }
    ],
  };

  /*ReportBarChartOptions = {
    scales: {
      xAxes: [
        {
          barPercentage: 1,
          //categoryPercentage: 0.4,
        },
      ],
      yAxes: [
        {
          id: 'y-axis-Registered',
        },
      ],
    },
    barPercentage: 0.6, 
    categoryPercentage: 0.6
  };*/
  ReportBarChartOptions = {
    maintainAspectRatio:false,
    scales: {
      x: [
        {
          barPercentage: 1,
          //categoryPercentage: 0.4,
        }
      ],
      y: [
        {
          id: 'y-axis-Registered',
        }
      ],
    },
    barPercentage: 0.6, 
    categoryPercentage: 0.6
  };

}

//-----------------Deep Analytics
let topicsClassOverallData, topicsClassOverallOptions,topicsClassData
if(TopicOverallPerformance !== null){
  topicsClassData = TopicOverallPerformance?.studentTopicsPerformance?.allTopics
}

//console.log({topicsData,topicAveragePerfomance})
if(TopicOverallPerformance !== null && topicsClassData !== undefined){
  
   // console.log({topicsClassData})
    const topics =topicsClassData?.map((item) => item.topic);
    const averageScores = topicsClassData?.map((item) => item.averageScore);
    const backgroundColor = `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 0.7)`;

   topicsClassOverallData =  {
        labels: topics || '',
        datasets: [
          {
            label: 'Average Score',
            backgroundColor: backgroundColor,
            borderColor: backgroundColor,
            borderWidth: 1,
            hoverBackgroundColor: backgroundColor,
            hoverBorderColor: backgroundColor,
            data: averageScores,
          },
        ],
      };
    
      topicsClassOverallOptions = {
        //responsive: true,
        maintainAspectRatio: false,
        scaleOverride:true,
        scales: {
          x: {
            title: {
              display: true,
              text: 'Subject Topics',
            },
            beginAtZero: true,
            max: 100, // Adjust the maximum value as needed
          },
          y: {
              ticks:{
                stepSize:10,
                min:0,
                max:100,
              }
            
            },
        },
        barPercentage: 0.6, 
        categoryPercentage: 0.6
      };
}

const truncateReportNames = (reportNames, maxLength) => {
  return reportNames.map(name => {
    if(name.length > maxLength){
      return name.substring(0,maxLength)
    }else{
      return name
    }
  })
}

let SubjectProgressBarChartdata, SubjectProgressBarChartOptions;
    if(studentSubjectRecentData !== null){
      const randomColor = `rgba(${Math.random() * 255},${Math.random() * 255},${Math.random() * 255},0.6)`;
      
      const arrangedStudentRecentData = studentSubjectRecentData?.slice().reverse()
      
      console.log(studentSubjectRecentData,arrangedStudentRecentData);
      

      const reportNames = arrangedStudentRecentData?.map((report)=>{
        return report.reportName
      })

      console.log(reportNames)
      const newReportNames = truncateReportNames(reportNames,20)

      const subjectDataSets = arrangedStudentRecentData?.map((report)=>{
        return report.marks
      })
      console.log(subjectDataSets)

      SubjectProgressBarChartdata = {
        labels: newReportNames, // Use report names as labels on the x-axis
        datasets: [
          {
            label:`Marks`,
            backgroundColor: randomColor,//'rgba(99, 132, 0, 0.6)',
            borderColor: randomColor,//'rgba(99, 132, 0, 1)',
            data: subjectDataSets,
          }
        ] //subjectDataSets,
      };
  
      SubjectProgressBarChartOptions = {
        maintainAspectRatio:false,
        AspectRatio:1,
        scales: {
          x: 
            {
              barPercentage: 1,
              //categoryPercentage: 0.4,
            }
          ,
          y: {
            beginAtZero:true,
            ticks:{
              stepSize:5,
              min:0,
              max:50,
            }
          
          },
        },
        barPercentage: 0.4, 
        categoryPercentage: 0.6
      };
    }

//--------------Handle Subject Change--------------//
const handleSubjectChange = (focus) => {
  const selectedSubjectID = focus.target.value
  console.log(selectedSubjectID)
  setSelectedSubject(selectedSubjectID)
  fetchStudentSubjectTopicPeformance(selectedSubjectID)
  fetchStudentSubjectRecentPerformance(selectedSubjectID)
}
                             
//------------------//


    return (
        <div className="main_wrapper">
          <div className="header-container">
            <div className="header">
              <img src={logo} alt="Logo" className="companyLogo" />
            </div>
            <div className="logout-button">
              <button onClick={handleLogOut}>Log Out</button>
            </div>
          </div>
          
          <div className="body_wrapper">
            <div className="student-info">
              <div className="student-info_avatar">
                <img src={[personAvatar]} alt="Avatar" className="avatar" />
              </div>
              <div className="student-info_student-details">
                <p>{studentDetails?.studentname}</p>
                <p>{studentDetails?.school}</p>
                <p>{studentDetails?.class}</p>
              </div>
            </div>

            <div className="parentMessages-button">
              <button
                onClick={()=>{navigate(`/parent-message-portal/${studentID}`)}}
              >
                Messages {'&'} Announcements
              </button>
            </div>

            
            <div className="studentoverall_container">

              <div className='studentanalytic_header'>
                <p>Overall Performance</p>  
              </div>
             
             <div className="overall_Analytics_container">
              <div className='studentanalytic_overall-table'
                style={{width:'100%', overflowX:'auto'}}
               >
                <table className="overall_table">
                  <thead>
                    <tr>
                      <th>Subject</th>
                      {/*<th>Marks</th>*/}
                      <th>Reports</th>
                      <th>Score</th>
                      <th>Grade</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                    {studentOverallData !== null && Object.entries(studentOverallData).map(([subject, performanceData])=>(
                      <tr key={subject}>
                        <td>{subject}</td>
                        {/*<td>{performanceData.totalMarks}</td>*/}
                        <td>{performanceData.totalReports}</td>
                        <td>{performanceData.averageMarks}</td>
                        <td>{performanceData.grade}</td>
                      </tr>
                    ))}
                    
                  </tbody>
                </table>
            </div>
            
             {studentOverallData !== null ? (
                 <div>
                 <Bar 
                  data={OverallBarChartdata} 
                  height="200px"
                  options={OverallBarChartOptions}
                 />
                </div>
               ):null}
           
            </div>
          </div>  
          
           
            {/*showReport && (*/
              <div className="report_container">
                <div className='studentanalytic_header'>
                  <p>Specific Exam/Test Results</p>  
                </div>
                <div>
                {availableReportList.length !== 0 ? (
                  <select 
                  value={selectedReport} 
                  onChange={handleChangeReport}
                  >
                  <option
                   value='null' 
                   key={0}
                   disabled
                  >
                    Student Reports
                  </option>
                  {availableReportList?.map((report)=>(
                  <option key={report._id} 
                     value={report._id}
                     >
                    {truncateString(report.reportname,30,30)}
                   </option>
                   ))}
                 </select>
                ):null}  
                
                </div>  
                {loader ? (
                      <div className="loaderContainer">
                        <Digital/>
                      </div>
                     ):(
                    <>
                    {reportDetails ? (
                      <div>
                      <div className="reportTable_container">
                        {/*documentReady && (
                         <PDFDownloadLink document={<DocPDF/>} fileName="docPDF.pdf">
                            {({blob,url,loading,error})=>(loading ? 'Loading document...':'Download Report')}
                         </PDFDownloadLink>
                        )*/}
                        {studentReportData ? (
                          <div className="downloadReportContainer">
                          <button onClick={()=>{
                            handleGeneratePdf()
                          }}>
                            Export
                          </button>
                         
                            <div /*ref={reportTemplateRef}*/ id="ParentContainer" style={{display:'none'}}>
                              <ParentReport studentReportData = {studentReportData} reportHeader={reportHeader} studentDetails={studentDetails} subjectsWithLowGrades={subjectsWithLowGrades}/>
                            </div>
                          </div>
                         ):null}

                        {reportHeader !== null && 
                         <div className="reportTable_header">
                          <p>{reportHeader/*localReportHeader*/}</p>
                        </div>
                        }
                       
                       <div className="specificReport_Analytics_container">
                       <div className="studentanalytic_specific-table">
                       <table className="overall_table">
                          <thead>
                            <tr>
                              <th>Subject</th>
                              <th>Marks</th>
                              <th>Grade</th>
                            </tr>
                          </thead>
                          <tbody>
                            
                            {studentReportData !== null && Object.entries(studentReportData?.subjectsAnalytics.subjects).map(([subject, performanceData])=>(
                              <tr key={subject}>
                                <td>{subject}</td>
                                <td>{performanceData.score}</td>
                                <td>{performanceData.grade}</td>
                              </tr>
                            ))}
                            
                          </tbody>
                        </table>
                       </div>
                       

                       {studentReportData !== null ? (
                        <div>
                         <Bar 
                          data={ReportBarChartdata} 
                          height="200px"
                          options={ReportBarChartOptions}
                         />
                        </div>
                       ):null}
                       </div>
                       </div>
                       </div>
                      ):(
                        <div className="loaderContainer">
                          <p>Server / Network error</p>
                        </div> 
                      )}
                    </>
                  )}
              </div>
          /* )*/}

          

          <div className="report_container">
           <div className='studentanalytic_header'>
                  <p>Subjects Progress & Competence</p>  
            </div>

           <select value={selectedSubject?selectedSubject.subject : ''} onChange={handleSubjectChange}>
                     {subjectList.map((subjectItem)=>(
                      <option key={subjectItem.id} 
                         value={subjectItem.subject}
                         >
                         {subjectItem?.subject}
                       </option>
                      ))}
             </select>

             <div className="subjectAnalytics_charts">
             {studentSubjectRecentData !== null ? (
              <div>               
                <Line
                  data={SubjectProgressBarChartdata} 
                  height="350px"
                  options={SubjectProgressBarChartOptions} 
                />
              </div>
             ):null}

             {TopicOverallPerformance?.studentTopicsPerformance.allTopics !== undefined ? (
              <div>               
                <Bar 
                  data={topicsClassOverallData} 
                  height="250px"
                  options={topicsClassOverallOptions} 
                />
              </div>
             ):null}
             </div>
          </div>   
          
 
          </div>
          <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          />
        </div>
      );
}

export default ParentsPage
