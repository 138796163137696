import React, { useEffect, useState } from "react";
import { useNavigate,Navigate} from "react-router-dom"
import {Dots} from "react-activity"
import "react-activity/dist/library.css"

import './Home.css'
//import logo from '../../assets/fake_logo.jpeg'
import logo from '../assets/fake_logo.png';
import ClassList from "../subComponents/ClassList/ClassList";
import ManageClasses from "../subComponents/ManageClasses/ManageClasses";
//import StudentList from "../StudentList/StudentList";
import ManageStudents from "../subComponents/ManageStudents/ManageStudents";
import ManageSubjects from "../subComponents/ManageSubjects/ManageSubjects";
import ManageTeachers from "../subComponents/ManageTeachers/ManageTeachers";
import ManageSchoolReports from "../subComponents/ManageSchoolReports/ManageSchoolReports";
import Analytics from "../subComponents/Analytics/Analytics";
import SchoolProfile from "../subComponents/SchoolProfile/SchoolProfile";
import ClassExercises from "../subComponents/ClassExercises/ClassExercises";
import MessagePortal from "../subComponents/MessagePortal/MessagePortal";

const Home = () => {
  const [loader, setLoader] = useState(false)
  const [schoolhighlights, setSchoolHighlights] = useState(null)
  const [userDetails, setUserDetails] = useState({
    user_name:'',
    user_id:'',
    schoolId:'',
    role:''
  })
  const [activeFunctionality, setActiveFunctionality] = useState(null);
  const navigate = useNavigate()

  const fetchSchoolHighlights = async() =>{
    setLoader(true)
    try {
      const schoolCreds = localStorage.getItem('userCreds')
      const schoolID = JSON.parse(schoolCreds).user.schoolId
      //console.log({schoolID})
      await fetch(`${global._URL}/schools/schoolhighlights?schoolId=${schoolID}`)
        .then((res)=>{return res.json()})
        .then((result)=>{
          setLoader(false)
          console.log(result)
          if(!result.errorMessage){
             setSchoolHighlights(result)
          }
        }).catch((err)=>{
          setLoader(false)
          console.log('Internal server error',err)
        })
    } catch (error) {
      setLoader(false)
      console.log('Failed to get school highlights data')
    }
  }

  useEffect(()=>{
    //Validate If User Logged In
    const isLoggedIn = localStorage.getItem('userCreds')
    if(!isLoggedIn){
       return <Navigate to= "/login"/>
    }else{
      const userData = JSON.parse(isLoggedIn)
      //console.log(userData)
        setUserDetails({
          user_name:userData.user.username,
          user_id:userData.user.userId,
          school_id:userData.user.schoolId,
          school_name:userData.user.schoolname,
          role:userData.role
        })

        fetchSchoolHighlights()
    }
  },[])
  
  
    const today = new Date().toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
    
      const functionalities = [
      {
        id:1,
        title: 'Grade Tracker',
        routeName:'grade-tracker',
        description: 'Access reports, record marks & competencies',
        component: <ClassList />
      },
      {
        id:2,
        title: 'Performance Insights',
        routeName:'performance-insights',
        description: 'View student performance analytics',
        component: <Analytics />
      },
      {
        id:3,
        title: 'School Classes',
        routeName:'class-control',
        description: 'Create, Update, Delete school classes',
        component: <ManageClasses/>
      },
      {
        id:4,
        title: 'Students Portal',
        description: 'Enroll, Remove and Update Students Info.',
        routeName:'students-manager',
        component: <ManageStudents/>
      },
      /*{
        id:5,
        title: 'Manage Subjects',
        routeName:'subject-manager',
        description: 'Create, Remove and Update School Subjects.',
        component: <ManageSubjects/>
      },*/
      {
        id:6,
        title: 'Teacher Admin',
        routeName:'schoolteachers',
        description: 'Add, Freeze, Remove school teachers.',
        component: <ManageTeachers/>
      },
      {
        id:7,
        title: 'Report Manager',
        routeName:'schoolreports',
        description: 'Manage School Academic Reports',
        component: <ManageSchoolReports/>
      },
      ,
      {
        id:8,
        title: 'Competence',
        description: 'Deep competence analytics',
        routeName:'class-quiz',
        component: <ClassExercises/>
      },
      {
        id:9,
        title: 'Message Portal',
        description: 'Broadcast Announcements to parents',
        routeName:'message-portal',
        component: <MessagePortal/>
      },
      {
        id:10,
        title: 'School Profile',
        routeName:'school-profile',
        component: <SchoolProfile/>
      }
      
      // Add more functionality objects as needed
    ];

    /*const getGreetingBasedOnTime = () =>{
        const currentHour = new Date().getHours()
        if(currentHour<12){
            return 'Good Morning'
        }else if(currentHour < 18){
            return 'Good Afternoon'
        }else{
            return 'Good Everning'
        }
    }*/

    const handleFunctionalityClick = (functionalityId) => {
      navigate(`/tools/${functionalityId}`);
    };

    const handleLogOut = () =>{
      localStorage.clear('userCreds')
      navigate('/login')
    }

    return (
      <div className="main-page">
       <div className="header-part">
        <div className="logo-container">
         <img src={logo} alt="Company Logo" />
        </div>
        <div className="logout-button">
          <button onClick={handleLogOut}>Log Out</button>
        </div>
       </div> 
        
        <div className="topHeader_container">
        <div className="date-container">
          <p>
            {userDetails.school_name}
          </p>
        </div>
        <div className="greetings-container">
            <p>{today}</p>
        </div>
        </div>
        {!activeFunctionality && (
        <div className="functionalities-container">
          <h2 className="functionalities-heading">Functionalities</h2>
          <div className="functionalities">
            {functionalities.map((functionality) => (
              
              <div
                className="functionality-card"
                key={functionality.id}
                onClick={() => handleFunctionalityClick(functionality.routeName)}
              >
                <p>{functionality.title}</p>
                <p>{functionality.description}</p>
              </div>
             
            ))}
          </div>
        </div>
        )}
       
       {!activeFunctionality && schoolhighlights !== null && (
        <div className="highlightsContainer">
          {loader ? (<div><Dots/></div>):(
            <>
            <div className="highlight">
              <div className="highlight-header">
                <p>Classes</p>
              </div>
              <div className="highlights-card">
                <p>{schoolhighlights?.numberOfClasses}</p>
              </div>
             </div>
            
             <div className="highlight">
               <div className="highlight-header">
                 <p>Students</p>
               </div> 
               <div className="highlights-card">
                <p>{schoolhighlights?.numberOfStudents}</p>
               </div>
            </div>  
            <div className="highlight">
              <div className="highlight-header">
                 <p>Teachers</p>
               </div>
               <div
                 className="highlights-card">
                 <p>{schoolhighlights?.numberOfTeachers}</p>
               </div>
             </div>  
            </>
          )}
          </div>
       )}
      
        </div>
    );
  };
  
  
  export default Home;