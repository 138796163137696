import React,{useState,useEffect} from 'react'
import { Bar,Pie } from 'react-chartjs-2';
import { formattedDate } from "../../utils/formatedDate"
import {gradesRange,bandsRange,subjectColors} from '../../utils/fakeData'

import {
  Chart as Chartjs,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,ArcElement
    } from 'chart.js'

Chartjs.register(
      BarElement,
      CategoryScale,
      LinearScale,
      Tooltip,
      Legend,
      ArcElement
    
    )    

const DownloadSpecificReport = ({reportData,selectedReportDetails}) =>{
    //console.log('Form External', studentReportData,reportHeader,studentDetails,subjectsWithLowGrades)
    const styles = {
        

           page: {
            /*//fontFamily: 'Arial',
            backgroundColor:'#F2F2F2',
            */
            //padding: 10,
            //margin:5,
            fontSize: 18,
            width:'835px',
            display:'flex',
            flexDirection:'column',
            //alignItems:'center',
          }
            
    }

    /*const logoImage = document.getElementById('logo-image')
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    canvas.width = logoImage.width
    canvas.height = logoImage.height
    ctx.drawImage(logoImage,0,0)
    const logoImageData = canvas.toDataURL('image/png')*/

    const getUniqueSubjects = () =>{
      if(!reportData || !reportData.gradesAnalytics) return []
  
      const uniqueSubjects = new Set();
      Object.values(reportData.gradesAnalytics).forEach((student)=>{
        Object.keys(student.subjects).forEach((subject)=>{
          uniqueSubjects.add(subject)
        })
      })
      return Array.from(uniqueSubjects)
    }

    const marksToGrade = (totalMarks, numberOfSubjects)=>{
      const studentExamAverage = totalMarks/numberOfSubjects
      for(const [gradeKey, range] of Object.entries(gradesRange)){
          if(studentExamAverage >=range.min && studentExamAverage <=range.max){
              return gradeKey
          }
      }
      return '-'
  }

  const calculateSubjectsOverallAverage = () =>{
    let subjectNames; 
    let totalSubjects;
    let overallAverage;
    let totalAverageScore = 0;
    if(reportData !== null && reportData !== undefined){
      subjectNames = Object.keys(reportData?.subjectAnalytics);
      totalSubjects = subjectNames.length
    
    subjectNames.forEach(subject => {
      totalAverageScore += reportData.subjectAnalytics[subject].averageScore;
    })
  
    overallAverage = totalAverageScore/totalSubjects;
    //console.log('Overall Average', overallAverage)

    for(const [gradeKey, range] of Object.entries(bandsRange)){
      if(overallAverage >=range.min && overallAverage <=range.max){
          return {gradeKey,average:overallAverage}
      }
    }
    return '-'
  }

    //return overallAverage;
  }

  const gradesData = ['A','B','C','D','E','F']

  let BarChartdata, BarChartOptions,pieCharts,pieData,pieOptions
  if(reportData !== null){
  
    const backgroundColors = Object.keys(reportData.subjectAnalytics).map(subject => subjectColors[subject]);
  
    BarChartdata = {
        labels: /*[  "Mathematics",  "Science",  "English",  "Kiswahili",  "Geography",  "Social Studies"]*/
           Object.keys(reportData.subjectAnalytics)
        ,
        datasets: [
          {
            label: 'Average Score',
            data: /*[ 39.9, 37.7, 42.2, 42.6, 34.9, 40.3 ]*/
            Object.entries(reportData.subjectAnalytics)?.map(([subject,analytics]) => analytics.averageScore),
            backgroundColor: backgroundColors,//'rgba(75, 192, 192, 0.6)', // You can customize the color
            borderColor: backgroundColors,
            borderWidth: 1,
          },
        ],
      };
      
      BarChartOptions = {
        scales: {
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: 'Average Score',
            },
          },
          x: {
            title: {
              display: true,
              text: 'Subjects',
            },
          },
        },
        maintainAspectRatio:false,
        aspectRatio:1,
        barPercentage: 0.6, 
        categoryPercentage: 0.6
      };
  
    const pieGrades = {};
  
      // Group data by grades
      for (const [subject, data] of Object.entries(reportData.subjectPerformanceAnalytics)) {
        data.forEach(item => {
          const grade = item.grade;
          if (!pieGrades[grade]) {
            pieGrades[grade] = [];
          }
          pieGrades[grade].push({ subject, numberOfgrade: item.numberOfgrade });
        });
      } 
  
    const sortedGrades = Object.keys(pieGrades).sort();  
  
    pieCharts = /*Object.entries(pieGrades)*/sortedGrades.map((grade) => {
      const subjectsData = pieGrades[grade] || []
      let labels,datasetData
      
      labels = subjectsData.map(item => item.subject);
      datasetData = subjectsData.map(item => item.numberOfgrade);
    
      
  
      //const backgroundColors = labels.map(() => getRandomColor()); // Define or generate colors
      const backgroundColors = labels.map(subject => subjectColors[subject]);
       pieData = {
        labels,
        datasets: [
          {
            data: datasetData,
            backgroundColor: backgroundColors,
          },
        ],
      };
  
       pieOptions = {
        title: {
          display: true,
          text: `Grade ${grade} Performance by Subject`,
        },
        scales: {
          y: {
            /*beginAtZero: true,*/
            title: {
              display: true,
              text: `Number of ${grade}`,
            },
          },
          x: {
            title: {
              display: true,
              text: 'Subjects',
            },
          },
        },
        barPercentage: 0.3, 
        categoryPercentage: 0.5
      };
  
      return (
        <div key={grade} className="chart-container">
          <h3>{`Grade ${grade}`}</h3>
          <Pie
            style={
              {width:'30%'}
            }
            data={pieData} 
            options={pieOptions} 
          />
        </div>
      );
  
  })
  
  }

  const subjAnaly = 'SUBJECTS-PERFORMANCES'
    
    return(
      <div className='analytics_mainContainer'>
      {selectedReportDetails !== null ? (
        <div className='analytic-Header'>
          <p>OASIS PRIMARY SCHOOL</p>
          <p>GRADE  7 - {/*ReportDetails.report.*/selectedReportDetails?.reportname}</p>  
          {/*<p>{formattedDate(selectedReportDetails?.createdAt)}</p>*/}
        </div>
      ):null}

      <div className='specific_tables-container'>
            <div 
              className='specific_tables_maintable-container'
              style={{width:'100%', overflowX:'auto'}}
            >
                <table className='main_table'>
                  <thead>
                    <tr>
                      <th rowSpan="2">Student Name</th>
                      {getUniqueSubjects().map((subject)=>(
                        
                          <th 
                            key={subject} 
                            colSpan="2"
                          >
                            {subject} 
                          </th>
                         
                        ))}
                      <th rowSpan="2">Total</th>
                      <th rowSpan="2">Grade</th>
                      <th rowSpan="2">Pos</th>
                    </tr>
                    <tr>
                    {getUniqueSubjects().map((subject)=>(
                      <React.Fragment key={subject}>
                        <th>M</th>
                        <th>G</th>
                      </React.Fragment>  
                    ))}
                    </tr>
                  </thead>
                  <tbody>
                    {reportData.totalMarksAndPosition.map(({studentId, studentname, totalMarks,position})=>(
                      <tr key={studentId}>
                        <td>
                          {studentname}
                        </td>
                        
                        {getUniqueSubjects().map((subject)=>{
                          const data = reportData.gradesAnalytics[studentId]?.subjects?.[subject] || {};
                          return(
                          <React.Fragment key={subject}>
                          
                          <td>{data.marks || '-'}</td>
                          <td>{data.grade || '-'}</td>
                          
                          </React.Fragment> 
                          )
                         })}
                        
                        <td>{totalMarks}</td>
                        <td>{marksToGrade(totalMarks,getUniqueSubjects().length)}</td>
                        <td>{position}</td>
                        
                      </tr>
                    ))}
                  </tbody>
                  </table>
                </div>
              

                <div className='analytic-BarContainer'>
                  <div className='analytic-smallHeader'>
                    <span>{`${subjAnaly}`}</span>
                  </div>
                  <div className='subjectAnalytics_visual_container'>
                    <table className='main_table'>
                      <thead>
                        <tr>
                          <th>Subject</th>
                          <th>Total</th>
                          <th>Average</th>
                          <th>Score Grade</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.entries(reportData.subjectAnalytics).map(([subject,analytics])=>(
                          <tr key={subject}>
                             <td>{subject}</td>
                             <td>{analytics.totalSubjectScore}</td>
                             <td>{analytics.averageScore}</td>
                             <td>{analytics.subjectGrade}</td>
                          </tr>
                          
                        ))}
                        {reportData !== null && reportData !== undefined ? (
                          <tr>
                            <th></th>
                            <th></th>
                            <td>{Number(calculateSubjectsOverallAverage().average).toFixed(2)}</td>
                            <td>{calculateSubjectsOverallAverage().gradeKey}</td>
                          </tr>
                        ):null}
                        
                      </tbody>
                    </table>

                    <div className='analytics_Chart_container'>
                    <Bar
                      style={
                        {
                          padding:'20px'
                      }
                      }
                      height={"80%"}
                      data={BarChartdata} 
                      options={BarChartOptions}
                    />
                    </div>

                  </div>
                  
                </div>


                <div className='analytic-smallContainer'>
                  <div className='analytic-smallHeader'>
                    <p>GRADES - DISTRIBUTION</p>
                  </div>
                  <div className='subjectPerformance_visual-container'>
                    <table className='main_table'>
                      <thead>
                        <tr>
                          <th>Subject</th>
                          {gradesData.map(grade=>(
                            <th key={grade}>{grade}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(reportData.subjectPerformanceAnalytics).map((subject)=>(
                         <tr key={subject}>
                           <td>{subject}</td>
                           {gradesData?.map(grade => {
                            const subjectGradeData = reportData.subjectPerformanceAnalytics[subject].find(item => item.grade === grade)
                            return (
                              <td key={`${subject}-${grade}`}>
                                {subjectGradeData ? subjectGradeData.numberOfgrade : ''}
                              </td>
                            )
                           })}
                         </tr>
                          
                        ))}
                      </tbody>
                    </table>

                    {/*<div className="charts-container">
                     {pieCharts}
                    </div>*/}
                  </div>
                 </div>
                </div>
      </div>
    )
}

export default DownloadSpecificReport
