import React,{useState} from 'react'
import { useLocation,useParams } from "react-router-dom";
import {Digital} from "react-activity"
import "react-activity/dist/library.css"
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './TeacherDetails.css'


const TeacherDetails = () =>{
const [buttonLoader, setButtonLoader] = useState(false)
const location = useLocation()
const urlParams = new URLSearchParams(location.search)
const teacherData = JSON.parse(decodeURIComponent(urlParams.get('teacherData')))
//console.log(teacherData)
const teacherID = useParams()

const changeTeacherRole = async() =>{
 // alert('Not yet implemented')
  /*const notify = () => {
    toast.info("Coming Soon feature", {
      position: "top-center",
      autoClose:1500,
      hideProgressBar: true,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
      theme: "light"
     })
   };

   notify()*/
  let teacherRoleStatus = ['Active','Frozen']
  let newRoleStatus

  if(teacherData.teacherDetails.roleStatus === 'Frozen'){
    newRoleStatus = teacherRoleStatus[0]
  }else{
    newRoleStatus = teacherRoleStatus[1]
  }

    const teacherId = teacherID.id
    const options = {
        method:'PUT',
        body:JSON.stringify({
          roleStatus:newRoleStatus
        }),
        headers:{
          "Content-type":"application/json"
        }
      }

      try {
        await fetch(`${global._URL}/teachers/updateteacher/${teacherId}`,options)
           .then((res)=>{
            return res.json()
           })
           .then((result)=>{
            console.log(result)
            if(result.successMessage){
              const notify = () => {
                toast.success(`${result.successMessage}`, {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
              };
  
            notify()
            }else{
              const notify = () => {
                toast.error(`${result.errorMessage}`, {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
              };
  
            notify()
            }
            
           })
           .catch((err)=>{
            console.log("Server error fail to update",err)
            const notify = () => {
              toast.error("Internal server error", {
                position: "top-center",
                autoClose:2000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
                theme: "light"
               })
            };

          notify()
           })
       }catch(error){
        console.log('Failed to update',error)
        const notify = () => {
          toast.warn("Disconnected, check Internet", {
            position: "top-center",
            autoClose:2000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light"
           })
        };

      notify()
       }
    }  

    const removeTeacherAccount = async() =>{
    const teacherId = teacherID.id
   // alert('Not yet implemented')
   /* const notify = () => {
      toast.info("Coming Soon feature", {
        position: "top-center",
        autoClose:1500,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light"
       })
     };

     notify()*/
     const options = {
      method:'DELETE'
    }

      try {
        await fetch(`${global._URL}/teachers/removeteacher/${teacherId}`,options)
           .then((res)=>{
            return res.json()
           })
           .then((result)=>{
            //console.log(result)
            if(result.successMessage){
              const notify = () => {
              toast.success(`${result.successMessage}`, {
                position: "top-center",
                autoClose:2000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
                theme: "light"
               })
            };

          notify()
            }else{
              const notify = () => {
                toast.error(`${result.errorMessage}`, {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
              };
  
            notify()

            }
           })
           .catch((err)=>{
            console.log("Server error fail to update",err)
            const notify = () => {
            toast.error("Internal server error", {
              position: "top-center",
              autoClose:1500,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light"
             })
     };

     notify()
           })
       }catch(error){
        console.log('Failed to update',error)
        const notify = () => {
        toast.error("Disconnected, check Interner", {
          position: "top-center",
          autoClose:1500,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          theme: "light"
         })
     };

     notify()
       }
    }

    /*const updateTeacherDetails = async() =>{
      setButtonLoader(true)
      const teacherId = teacherID.id
  
        try {
          await fetch(`${global._URL}/teachers/updateteacher/${teacherId}`)
             .then((res)=>{
              return res.json()
             })
             .then((result)=>{
              setButtonLoader(false)
              console.log(result)
             })
             .catch((err)=>{
              setButtonLoader(false)
              console.log("Server error fail to update",err)
             })
         }catch(error){
          setButtonLoader(false)
          console.log('Failed to update',error)
         }
      }*/
    
    

    return(
        <div className='teacherContainer'>
          <div className='teacherProfile_header'>
            <p>Teacher Details</p>
          </div>
          <div className='teacherDetails_container'>
            <p>{teacherData.teacherDetails.teacher_name}</p>
            <p>{teacherData.teacherDetails.gender}</p>
            <p>Role Status:{teacherData.teacherDetails.roleStatus}</p>
          </div>
          <div className='teacherButtons_options'>
            {teacherData.teacherDetails.roleStatus === 'Active' ? (
              <button onClick={changeTeacherRole}>
                Deactivate
              </button>
            ):(
              <button onClick={changeTeacherRole}>
                Activate
              </button>
            )}
            
            <button onClick={removeTeacherAccount}>
              Remove
            </button>
            {buttonLoader && (<Digital/>)}
          </div>
         {/*} <div className='teacher-name'>
            <p>{teacherData.teacher_name}</p>
          </div>
          <div className='teacher-Buttons'>
            <button onClick={freezeAccount}>{teacherData.status}</button>
            <button onClick={removeTeacherAccount}>Remove</button> 
        </div>*/}
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        </div>
    )
}

export default TeacherDetails