import React,{useState,useEffect} from 'react'
import {Digital} from "react-activity"
import "react-activity/dist/library.css"
import { ToastContainer, toast } from 'react-toastify';
import { Timeline } from 'antd';
import { useNavigate } from "react-router-dom";
import { Radio } from 'antd';
import { classLevels } from '../../utils/fakeData';
import './AddClassExercise.css'

const AddClassExercise = () =>{
  const [formData, setFormData] = useState({
    className: '',
    reportName: '',
    subject: '',
    dateTestDone: '',
    numQuestions: null,
    questionDetails: [],
  });
  const [formNumberOfQuestions, setFormNumberOfQuestions] = useState({
    className: '',
    reportName: '',
    subject: '',
    dateTestDone: '',
    numQuestions: '',
  });

  const [taggedTopics, setTaggedTopics] = useState([]);
  const [taggedConcepts, setTaggedConcepts] = useState([])
  const [lastQuestionTagged, setLastQuestionTagged] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(null)
  const [selectedRubricLevel, setSelectedRubricLevel] = useState(null)
  const [classLoader, setClassLoader] = useState(false)
  const [buttonLoader, setButtonLoader] = useState(false)
  const [classes, setClasses] = useState([]);
  const [rubricLevels, setRubricLevels] = useState(null)
  const [selectedClass, setSelectedClass] = useState(null)

  //--------------------Tag Options0----------------------//

  const options = [
    {
      label: 'Competences',
      value: 'Competences',
      title: 'Competences',
    },
    {
      label: 'Specifics',
      value: 'Specifics',
      title: 'Specifics',
    },
  ];

  const [TagType, setTagType] = useState('Competences');

  const  changeTagType = ({ target: { value } }) => {
    console.log('tag chnged', value);
    setTagType(value);
  };

  //----------------------------------------------------------//
 

  const navigate = useNavigate()


  useEffect(() => {
    setClassLoader(true)
    const userCreds = JSON.parse(localStorage.getItem('userCreds'))
    const schoolID = userCreds.user.schoolId
    //console.log(schoolID) 
    setClassLoader(true)
    // Fetch classes
    fetch(`${global._URL}/classes/schoolclasses?schoolId=${schoolID}`)
      .then(response => response.json())
      .then(classesData => {
        setClassLoader(false)
        console.log(classesData)
        if(!classesData.message){
          setClasses(classesData);
          if(classesData.length !== 0){
            setSelectedClass(classesData[0]._id)
          }
          
        }
        })
        .catch((err)=>{
          setClassLoader(false)
          console.log('Failed to get classes',err)
          const notify = () => {
            toast.warn("Disconnected, check Internet", {
              position: "top-center",
              autoClose:3000,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light"
             })
           };
    
           notify()
        })
    },[])

    const checkEmptyTopicAndConcepts = (questionArray) =>{
      const emptyQuestions = [];
     console.log(selectedRubricLevel)
      if(selectedRubricLevel === 'Primary'){
        questionArray.forEach((topicObj)=>{
          topicObj.questions.forEach((question)=>{
            if(!question.topic || !question.concept){
              emptyQuestions.push(question)
            }
          })
        })
      }else{
      questionArray.forEach((topicObj)=>{
        topicObj.questions.forEach((question)=>{
          if(!question.topic){
            emptyQuestions.push(question)
          }
        })
      })
     }

      return emptyQuestions
    }

    const saveExerciseReport = async() =>{
      setButtonLoader(true)
      const userCreds = JSON.parse(localStorage.getItem('userCreds'))
      const schoolID = userCreds.user.schoolId
      console.log({
        reportName:formData.reportName,
        subject:selectedRubricSubject,
        numQuestions:formNumberOfQuestions.numQuestions,//formData.numQuestions,
        questionDetails:taggedTopics,
        schoolId:schoolID,
        classId:selectedClass,
        streamTag:selectedStream
      })

      const emptyQuestions = checkEmptyTopicAndConcepts(taggedTopics)

      if(emptyQuestions.length > 0){
        console.log("Unfilled questions",emptyQuestions)

        setButtonLoader(false)

        const questionsList = emptyQuestions.map(({questionNumber})=>questionNumber)

        const notify = () => {
          toast.warn(`Fill specifics on question ${questionsList.join(',')} before saving`, {
            position: "top-center",
            autoClose:2000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light"
           })
         };

        notify()
        return;
      }

      const options = {
        method:'POST',
        body:JSON.stringify({
          reportName:formData.reportName,
          subject:selectedRubricSubject,//selectedSubject,
          numQuestions:formNumberOfQuestions.numQuestions,
          questionDetails:taggedTopics,
          schoolId:schoolID,
          classId:selectedClass,
          streamTag:selectedStream
        }),
        headers:{
          'Content-type':'application/json'
        }
      }
     try {
          await fetch(`${global._URL}/exercisereports/newexercisereport/`,options)
             .then((res)=>{
              return res.json()
             })
             .then((result)=>{
              setButtonLoader(false)
              console.log(result)
              if(!result.errorMessage){
                  //Other functions
                  const notify = () => {
                    toast.success(`${result.successMessage}`, {
                      position: "top-center",
                      autoClose:2000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light"
                     })
                   };
        
            notify()
              }else{
                console.log('Error Message',result.errorMessage)
  
                 const notify = () => {
                      toast.error(`${result.errorMessage}`, {
                        position: "top-center",
                        autoClose:2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"
                       })
                     };
          
              notify()
              }
              
             }).catch((err)=>{
              setButtonLoader(false)
              console.log('Server Error', err)
              const notify = () => {
                      toast.error("Internal server Error", {
                        position: "top-center",
                        autoClose:2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"
                       })
                     };
          
              notify()
             })
      } catch (error) {
        setButtonLoader(false)
          console.log('Server Error, no connection')
  
          const notify = () => {
                      toast.warn("IServer Error, no connection", {
                        position: "top-center",
                        autoClose:2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"
                       })
                     };
          
              notify()
              }
  }  

  //------------------------------------------------------//
  
  const fetchRubricLevels = async() =>{
    setButtonLoader(true)
    
   try {
        await fetch(`${global._URL}/rubrics/level/`)
           .then((res)=>{
            return res.json()
           })
           .then((result)=>{
            setButtonLoader(false)
            console.log(result)
            if(!result.errorMessage){
                setRubricLevels(result.levels)
            }
            
           }).catch((err)=>{
            setButtonLoader(false)
            console.log('Server Error', err)
            const notify = () => {
                    toast.error("Internal server Error", {
                      position: "top-center",
                      autoClose:2000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light"
                     })
                   };
        
            notify()
           })
    } catch (error) {
      setButtonLoader(false)
        console.log('Server Error, no connection')

        const notify = () => {
                    toast.warn("IServer Error, no connection", {
                      position: "top-center",
                      autoClose:2000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light"
                     })
                   };
        
            notify()
    }
} 

useEffect(()=>{
  fetchRubricLevels() 
},[])

const [classLevels, setClassLevels] = useState(null)
  const fetchRubricLevelClasses = async(rubricLevel) =>{
    setButtonLoader(true)
    
   try {
        await fetch(`${global._URL}/rubrics/classes/${rubricLevel}`)
           .then((res)=>{
            return res.json()
           })
           .then((result)=>{
            setButtonLoader(false)
            console.log(result)
            if(!result.errorMessage){
                setClassLevels(result)
            }
            
           }).catch((err)=>{
            setButtonLoader(false)
            console.log('Server Error', err)
            const notify = () => {
                    toast.error("Internal server Error", {
                      position: "top-center",
                      autoClose:2000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light"
                     })
                   };
        
            notify()
           })
    } catch (error) {
      setButtonLoader(false)
        console.log('Server Error, no connection')

        const notify = () => {
                    toast.warn("IServer Error, no connection", {
                      position: "top-center",
                      autoClose:2000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light"
                     })
                   };
        
            notify()
    }
} 

  //------------------------------------------------------//
    
  const [rubricSubjects, setRubricSubjects] = useState(null)
  const fetchRubricSubjects = async(selectedLevel) =>{
    setButtonLoader(true)
    
   try {
        await fetch(`${global._URL}/rubrics/subjects/${selectedRubricLevel}/${selectedLevel}`)
           .then((res)=>{
            return res.json()
           })
           .then((result)=>{
            setButtonLoader(false)
            console.log(result)
            if(!result.errorMessage){
                setRubricSubjects(result)
            }
            
           }).catch((err)=>{
            setButtonLoader(false)
            console.log('Server Error', err)
            const notify = () => {
                    toast.error("Internal server Error", {
                      position: "top-center",
                      autoClose:2000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light"
                     })
                   };
        
            notify()
           })
    } catch (error) {
      setButtonLoader(false)
        console.log('Server Error, no connection')

        const notify = () => {
                    toast.warn("IServer Error, no connection", {
                      position: "top-center",
                      autoClose:2000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light"
                     })
                   };
        
            notify()
    }
}  

const [rubricSubjectCompetences, setRubricSubjectCompetences] = useState(null)
const fetchRubricSubjectCompetences = async() =>{
  setButtonLoader(true)
  
 try {
      await fetch(`${global._URL}/rubrics/competences/${selectedRubricLevel}/${selectedClassLevel}/${selectedRubricSubject}`)
         .then((res)=>{
          return res.json()
         })
         .then((result)=>{
          setButtonLoader(false)
          console.log(result)
          if(!result.errorMessage){
            setRubricSubjectCompetences(result)
          }
          
         }).catch((err)=>{
          setButtonLoader(false)
          console.log('Server Error', err)
          const notify = () => {
                  toast.error("Internal server Error", {
                    position: "top-center",
                    autoClose:2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
      
          //notify()
         })
  } catch (error) {
    setButtonLoader(false)
      console.log('Server Error, no connection')

      const notify = () => {
                  toast.warn("IServer Error, no connection", {
                    position: "top-center",
                    autoClose:2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
      
          notify()
  }
}  

const [rubricCompetenceSpecifics, setRubricCompetenceSpecifics] = useState(null)
const fetchRubricCompetenceSpecifics = async(competence) =>{
  setButtonLoader(true)
  
 try {
      await fetch(`${global._URL}/rubrics/specifics/${selectedRubricLevel}/${selectedClassLevel}/${selectedRubricSubject}/${competence}`)
         .then((res)=>{
          return res.json()
         })
         .then((result)=>{
          setButtonLoader(false)
          console.log(result)
          if(!result.errorMessage){
            setRubricCompetenceSpecifics(result)
          }
          
         }).catch((err)=>{
          setButtonLoader(false)
          console.log('Server Error', err)
          const notify = () => {
                  toast.error("Internal server Error", {
                    position: "top-center",
                    autoClose:2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
      
         // notify()
         })
  } catch (error) {
    setButtonLoader(false)
      console.log('Server Error, no connection')

      const notify = () => {
                  toast.warn("IServer Error, no connection", {
                    position: "top-center",
                    autoClose:2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
      
          notify()
  }
}  
  

const handleInputChange = (e) => {
  const { name, value } = e.target;
  // Add debug logs
//console.log(`Input Name: ${name}`);
//console.log(`Input Value: ${value}`);
//setNoQuest({formAttr:name,attrValue:value})

  setFormNumberOfQuestions({ ...formNumberOfQuestions, [name]: value })
  //setExerciseNumberOfQuestions(value)

  setFormData({ ...formData, [name]: value });
};

const [exerciseNumberOfQuestions, setExerciseNumberOfQuestions] = useState(null)
const [noQuest, setNoQuest] = useState(null)
  const handleQuestionInputChange = (e) => {
    const { name, value } = e.target;
    // Add debug logs
  //console.log(`Input Name: ${name}`);
 // console.log(`Input Value: ${value}`);
  setNoQuest({formAttr:name,attrValue:value})
  };

  const saveNumberOfQuestions = () =>{
    setExerciseNumberOfQuestions(noQuest?.attrValue)
    setFormNumberOfQuestions({ ...formNumberOfQuestions, [noQuest.formAttr]: noQuest?.attrValue })
    setFormData({ ...formData, [noQuest.formAttr]: noQuest?.attrValue });
  }

const handleClassChange = (focus) => {
  const selectedClassID = focus.target.value
  console.log(selectedClassID)
 // const selectedClass = classList.find((classdata)=> classdata.id === selectedClassID)
  setSelectedClass(selectedClassID)
  
}


     const [activeStreamOptions, setActiveStreamOptions] = useState([])
      useEffect(()=>{
        
       const activeStreamList = classes.filter((classItem)=> {
          return classItem._id === selectedClass
        })

        console.log(activeStreamList[0]?.streams)

       setActiveStreamOptions(activeStreamList[0]?.streams)
      },[selectedClass])

      const [selectedStream,setSelectedStream] = useState(null)
      const handleStreamChange = (focus) => {
        const selectedStreamItem = focus.target.value
        setSelectedStream(selectedStreamItem)
        console.log(selectedStreamItem)
      }

      const handleRubricLevelChange = (focus) => {
        const selectedClassItem = focus.target.value
        setSelectedRubricLevel(selectedClassItem)
        console.log(selectedClassItem)

        //fetchRubricSubjects(selectedClassItem)
        fetchRubricLevelClasses(selectedClassItem)
      }

      const [selectedClassLevel, setSelectedClassLevel] = useState(null)
      const handleClassLevelChange = (focus) => {
        const selectedClassItem = focus.target.value
        setSelectedClassLevel(selectedClassItem)
        console.log(selectedClassItem)
        fetchRubricSubjects(selectedClassItem)
      }

      const [selectedRubricSubject, setSelectedRubricSubject] = useState(null)
      const handleRubricSubjectChange = (focus) => {
        const selectedSubjectItem = focus.target.value
        setSelectedRubricSubject(selectedSubjectItem)
        console.log(selectedSubjectItem)
      }

      const [selectedRubricCompetence, setSelectedRubricCompetence] = useState(null)
      const handleRubricSubjectCompetenceChange = (focus) => {
        const selectedCompetenceItem = focus.target.value
        setSelectedRubricCompetence(selectedCompetenceItem)
        setFormData({ ...formData, ["topic"]: selectedCompetenceItem });
        console.log(selectedCompetenceItem)
      }

      const [selectedRubricCompetenceSpecific, setSelectedRubricCompetenceSpecific] = useState(null)
      const handleRubricCompetenceSpecificChange = (focus) => {
        const selectedSpecificItem = focus.target.value
        setSelectedRubricCompetenceSpecific(selectedSpecificItem)
        setFormData({ ...formData, ["concept"]: selectedSpecificItem });
        console.log(selectedSpecificItem)
      }

      useEffect(()=>{
       fetchRubricSubjectCompetences()
      },[selectedRubricSubject,selectedClassLevel])

   

      //------------------New Codes----------------
      const [selectedQuestions, setSelectedQuestions] = useState([]);

      const handleQuestionSelection = (index) => {
      if (selectedQuestions.includes(index)) {
       setSelectedQuestions(selectedQuestions.filter((selectedIndex) => selectedIndex !== index));
       } else {
        setSelectedQuestions([...selectedQuestions, index]);
       }
      };

      const isSelected = (index) => selectedQuestions.includes(index);

      const handleAddTaggedTopicOLD = () => {
        const { topic } = formData;
        const selectedQuestionsWithTopics = selectedQuestions.map((index) => ({
          questionNumber: index + 1, // Adding 1 to convert from 0-indexed to 1-indexed question numbers
          topic,
          concept: '', // You can add the concept later
          studentData: [], // Initialize with an empty array
        }));
      
        const newTaggedTopic = {
          topic,
          questions: selectedQuestionsWithTopics,
        };

        console.log({newTaggedTopic})
      
        setTaggedTopics([...taggedTopics, newTaggedTopic]);
      
        const remainingQuestions = formData.numQuestions - selectedQuestionsWithTopics.length;
      
        if (remainingQuestions === 0) {
          setLastQuestionTagged(true);
        }
      
        // Reset form data
        setFormData({
          ...formData,
          topic: '',
          numQuestions: remainingQuestions,
        });
      
        // Reset selected questions
        setSelectedQuestions([]);
      
        console.log('Updated Tagged Topics:', taggedTopics);
        console.log('Updated Form Data:', formData);
      };

      const removeDuplicateQuestions = (topics) => {
        // Initialize an empty object to store unique questions
        const uniqueQuestions = {};
      
        // Iterate over each topic
        topics.forEach((topic) => {
          // Get the topic name
          const topicName = topic.topic;
      
          // Initialize an array to store unique questions for the current topic
          const uniqueQuestionsForTopic = [];
      
          // Iterate over each question in the current topic
          topic.questions.forEach((question) => {
            // Get the question number
            const questionNumber = question.questionNumber;
      
            // Check if the question number is not already present for the current topic
            if (!uniqueQuestions[topicName]?.includes(questionNumber)) {
              // Add the question number to the list of unique questions for the current topic
              uniqueQuestionsForTopic.push(question);
      
              // Add the question number to the list of unique questions
              if (!uniqueQuestions[topicName]) {
                uniqueQuestions[topicName] = [];
              }
              uniqueQuestions[topicName].push(questionNumber);
            }
          });
      
          // Replace the questions array in the current topic with unique questions
          topic.questions = uniqueQuestionsForTopic;
        });
      
        return topics;
      };
      

      const handleAddTaggedTopic = () => {
        const { topic } = formData;
      
        // Initialize an array to keep track of tagged questions
        const taggedQuestions = [];
      
        // Update existing tagged topics with the new topic for selected questions
        const updatedTaggedTopics = taggedTopics.map((taggedTopic) => {
          const updatedQuestions = taggedTopic.questions.map((question) => {
            if (selectedQuestions.includes(question.questionNumber - 1)) {
              console.log("Am here")
              // Check if the question is already tagged with a different topic
              if (question.topic !== topic) {
                // Update the topic for the question
                question.topic = topic;
                question.concept = '';
                taggedTopic.topic = topic
              }
              taggedQuestions.push(question.questionNumber - 1);
            }
            return question;
          });
          return { ...taggedTopic, questions: updatedQuestions };
        });
      
        // Tag the selected questions with the new topic if they are not already tagged
        selectedQuestions.forEach((index) => {
          if (!taggedQuestions.includes(index)) {
            updatedTaggedTopics.push({
              topic,
              questions: [
                {
                  questionNumber: index + 1,
                  topic,
                  concept: '', // You can add the concept later
                  studentData: [], // Initialize with an empty array
                },
                ...updatedTaggedTopics.find((taggedTopic) => taggedTopic.topic === topic)?.questions ?? [],
              ],
            });
          }
        });

        const remainingQuestions = formData.numQuestions - selectedQuestions.length;
      
        if (remainingQuestions === 0) {
          setLastQuestionTagged(true);
        }
        console.log({updatedTaggedTopics})

        const filteredTaggedTopics = removeDuplicateQuestions(updatedTaggedTopics);

        console.log(filteredTaggedTopics)
      
        // Set the updated tagged topics
        setTaggedTopics(filteredTaggedTopics);
      
        // Reset form data
        setFormData({
          ...formData,
          topic: '',
          numQuestions: formData.numQuestions - selectedQuestions.length,
        });
      
        // Reset selected questions
        setSelectedQuestions([]);
      
        console.log('Updated Tagged Topics:', updatedTaggedTopics);
        console.log("filtered List", filteredTaggedTopics)
        console.log('Updated Form Data:', formData);
      };
      
      

      const handleAddTaggedConcept = () => {
        const { concept } = formData;
      
        if (concept && selectedQuestions.length > 0) {
          // Create an array to store the tagged concepts
          const concepts = [];
      
          // Loop through the selected questions
          selectedQuestions.forEach((index) => {
            // Create a new tagged concept object
            const newTaggedConcept = {
              concept,
              questionNumber: index + 1, // Adjust question number index (assuming 1-based index)
            };
      
            // Add the tagged concept to the concepts array
            concepts.push(newTaggedConcept);
          });
      
          // Update the tagged concepts state
          setTaggedConcepts([...taggedConcepts, ...concepts]);
      
          // Update the tagged topics array with concepts for each question
          const updatedTaggedTopics = taggedTopics.map((taggedTopic) => {
            // Update concepts for questions in the selected range
            taggedTopic.questions.forEach((question) => {
              if (selectedQuestions.includes(question.questionNumber - 1)) { // Adjust index
                question.concept = concept;
              }
            });
            return taggedTopic;
          });
      
          // Update the tagged topics state
          setTaggedTopics(updatedTaggedTopics);
      
          // Reset the concept input
          setFormData({ ...formData, concept: '' });

          setSelectedQuestions([]);
      
          // Log the changes
          console.log('Updated Tagged Concepts:', taggedConcepts);
          console.log('Updated Tagged Topics:', updatedTaggedTopics);
        }
      };
      
      

      useEffect(() => {
        // Find the tagged topics related to the selected questions
        const selectedTaggedTopics = taggedTopics.filter((topicObj) => {
          return selectedQuestions.some((index) => {
            return topicObj.questions.some((question) => question.questionNumber === index + 1);
          });
        });
      
        // Extract the competence from the tagged topics
        const competences = selectedTaggedTopics.map((topicObj) => topicObj.topic);
      
        // Fetch specifics based on the obtained competences
        if (competences.length > 0) {
          fetchRubricCompetenceSpecifics(competences[0]); 
        }
      
      }, [selectedQuestions,selectedRubricSubject,selectedClassLevel,
        selectedRubricCompetence,taggedTopics]);


      //-----------------------COLORING-------------------
  const [topicColors, setTopicColors] = useState({});

  // Generate random color for each topic
 useEffect(() => {
    const topics = [...new Set(taggedTopics.map((topic) => topic.topic))];
    const colors = {};
    topics.forEach((topic) => {
      colors[topic] = getRandomColor();
    });
    setTopicColors(colors);
  }, [taggedTopics]);
  
  const getRandomColor = () => {
    return '#' + Math.floor(Math.random() * 16777215).toString(16);
  };

  const getTopicForQuestion = (questionIndex) => {
    // Iterate through the tagged topics
    for (const taggedTopic of taggedTopics) {
      // Iterate through the questions of each tagged topic
      for (const question of taggedTopic.questions) {
        // Check if the questionNumber matches the given questionIndex
        if (parseInt(question.questionNumber) === questionIndex + 1) {
          return taggedTopic.topic; // Return the topic if a match is found
        }
      }
    }
    return null; // Return null if no matching topic is found for the question
  };

  const sortedQuestionsArray = taggedTopics?.sort((a, b) => {
    const questionNumberA = a.questions[0].questionNumber;
    const questionNumberB = b.questions[0].questionNumber;
    return questionNumberA - questionNumberB;
});
  

  //------------------------------------------
   
       return(
        <div className='AddExer_Report_wrapper'>
          <div className='AddExer_Report_container'>
           <div className='AddExer_Report_header'>
            <span>Fill Class Exercise Report</span>
           </div>
           <div className='AddExer_ReportDetails'>
            {classLoader ? (
              <Digital/>
              ):(
                <div className='AddExer_Report-class'>
                <select value={selectedClass?selectedClass._id : ''} onChange={handleClassChange}>
                  <option >School Classes</option>  
                {classes.map((classItem)=>(
                  <option key={classItem._id} 
                     value={classItem._id}
                     >
                    {classItem?.classname}
                  </option>
                 ))}
               </select>

               <select 
                value={selectedStream} 
                onChange={handleStreamChange}
                > 
                <option /*disabled*/>Streams</option>
                {activeStreamOptions?.map((streamItem,index)=>(
                  <option key={index} 
                     value={streamItem.streamTag}
                     >
                    {streamItem?.streamTag}
                </option>
              ))}
            </select> 
           </div>
          )}

          {/* -----------Class Level------------*/}
          <div>
            {/*<span>Class Level</span>*/}
            <select value={selectedRubricLevel?selectedRubricLevel:''} onChange={handleRubricLevelChange}>
            <option /*disabled*/>Class Level</option>
            {rubricLevels?.map((classItem,index)=>(
                 <option key={index} 
                    value={classItem}
                    >
                    {classItem}
                  </option>
              ))}
          </select>
          </div>

            {/* -----------Class Level Classes------------*/}
            <div>
            {/*<span>Class Level</span>*/}
            <select value={selectedClassLevel?selectedClassLevel:''} onChange={handleClassLevelChange}>
            <option /*disabled*/>Class Level</option>
            {classLevels?.map((classItem)=>(
                 <option key={classItem} 
                    value={classItem}
                    >
                    {classItem}
                  </option>
              ))}
          </select>
          </div>
          
           {/**-----Class Level Will Determine subjects----- */}
           <select value={selectedRubricSubject?selectedRubricSubject : ''} onChange={handleRubricSubjectChange}>
                <option>Choose Subject</option>
               {rubricSubjects?.map((subjectItem,index)=>(
                 <option 
                    value={subjectItem}
                    key={index}
                    >
                    {subjectItem}
                  </option>
                 ))}
            </select>

             <div className='AddExer_ReportDetails_reportname'>
              <span>Test Name</span>
              <input type="text" name="reportName" value={formData.reportName} onChange={handleInputChange} />
             </div>
             <div className='AddExer_ReportDetails_reportname'>
              <span>Number Of Questions</span>
              <input type="number" 
                     name="numQuestions" 
                     value={/*formData.numQuestions*/exerciseNumberOfQuestions} 
                     onChange={handleQuestionInputChange}
              />
              <button onClick={saveNumberOfQuestions}>Set</button>
              
             </div>
             
             {/**--------Competences Loaded based on Subject Chosen and Class */}

             <div>
               <Radio.Group 
                       options={options} 
                       onChange={changeTagType} 
                       value={TagType} 
                       optionType="button" 
                />
              </div> 

             {formData.numQuestions !== null ? (
              <div className='AddExer_ReportDetails_reportname'>
               {/*!lastQuestionTagged ? (
                 <div>
                   <span>Tag Competences</span>
                 </div> 
               ):(
                  <div>
                  <span>Tag Specifics</span>
                 </div>
               )*/} 
               

       <div className='exerciseForm_questionButtons_wrapper'>
        <div className='exerciseForm_questionButtons_header'>
          <span>Exercise questions list</span>
        </div>
        {formData !== null ? (
           <div className='exerciseForm_questionButtons_questionList'>
           {[...Array(parseInt(exerciseNumberOfQuestions))].map((_, index) => (
           <button
             key={index}
             className={isSelected(index) ? 'selected' : ''}
             style={{
              borderColor: topicColors[getTopicForQuestion(index)],
              borderWidth: isSelected(index) ? '1px' : '3px'
            }}
             onClick={() => handleQuestionSelection(index)}
           >
             {index + 1}
          </button>
           ))}
           </div>
        ):null}
       
      </div>
 
        {/*!lastQuestionTagged*/ TagType === "Competences" ? (
           <>
              <select value={selectedRubricCompetence?selectedRubricCompetence : ''} onChange={handleRubricSubjectCompetenceChange}>
               <option>Choose competence</option>
               {rubricSubjectCompetences.competences?.map((competenceItem)=>(
                 <option 
                    value={competenceItem.competence}
                    >
                    {competenceItem.competence}
                  </option>
                 ))}
             </select>
             <button onClick={handleAddTaggedTopic}>Add</button>
           </>
        ):null}
        </div>
        ) : null}

        {/*lastQuestionTagged*/ TagType === "Specifics" ? (
          <div className='AddExer_ReportDetails_conceptContainer'>
                

                
                <div className='AddExer_ConceptInput_container'>

                 <select value={selectedRubricCompetenceSpecific?selectedRubricCompetenceSpecific : ''} onChange={handleRubricCompetenceSpecificChange}>
                 <option>Choose Specifics</option>
                 {rubricCompetenceSpecifics?.specifics?.map((specificItem)=>(
                 <option 
                    value={specificItem}
                    >
                    {specificItem}
                  </option>
                 ))}
               </select>

                 </div>
                
               <button
                onClick={handleAddTaggedConcept}
               >
                Add
              </button>
          </div>
        ) : null}


             <div className='AddExer_ButtonsOptions'>
              <button
                onClick={saveExerciseReport}
              >
                Save
              </button>
              <button
               onClick={()=> navigate('/tools/class-quiz/')}
              >
                Cancel
              </button>
              {buttonLoader ? (<Digital/>):null}
             </div>
             
             <div>
             <Timeline
               items={sortedQuestionsArray.flatMap(({ questions }) =>
               questions.map(({ questionNumber, topic, concept }) => ({
                 color: topic === 'Number Skills' ? 'blue' : 'green',
                 children: (
                   <div>
                     <p>Question Number: {questionNumber}</p>
                     <p>Topic: {topic}</p>
                     <p>Concept: {concept}</p>
                   </div>
                 )
               }))
              ).sort((a, b) => a.questionNumber - b.questionNumber)}
               
              />
             </div>

             
           </div>
         </div>
         <ToastContainer
             position="top-center"
             autoClose={1500}
             hideProgressBar
             newestOnTop
             closeOnClick
             rtl={false}
             pauseOnFocusLoss
             draggable
             pauseOnHover
             theme="light"
            />
        </div>
    )
}

export default AddClassExercise
