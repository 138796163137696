import react,{useState,useEffect} from "react"
import { Link, useNavigate,useParams } from "react-router-dom";
import {Dots,Sentry,Levels ,Bounce,Windmill,Digital} from "react-activity"
import "react-activity/dist/library.css"
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './StudentSendMessage.css'

const StudentSendMessage = () =>{
    const [selectedClass, setSelectedClass] = useState(null)
    const [ButtonLoader, setButtonLoader] = useState(false)
    const [messageContent, setMessageContent] = useState('')

    const navigate = useNavigate()
    const userCreds = JSON.parse(localStorage.getItem('userCreds'))
    const schoolID = userCreds.user.schoolId
    const studentId = useParams().id

    const generateCode = (codeLength) =>{
      const alphaNum = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890abcdefghijklmnopqrstuvwxyz'
      let result = ''
      for(let i=0;i<codeLength;i++){
        result += alphaNum.charAt(Math.floor(Math.random() * alphaNum.length))
      }
      return result;
    }

    const handleSendMessage = async() => {
      const messageCode = generateCode(5)
      console.log({studentId,schoolID,messageContent,messageCode})
      setButtonLoader(true)

      const options = {
        method:'POST',
        body:JSON.stringify({
          studentId:studentId,
          schoolId:schoolID,
          messageContent:messageContent,
          messageCode
        }),
        headers:{
          'Content-type':'application/json'
        }
      }

      
       try {
          await fetch(`${global._URL}/comments/student-send`,options)
             .then((res)=>{
              return res.json()
             }).then((result)=>{
              console.log(result)
              setButtonLoader(false)
              if(result.successMessage){
                const notify = () => {
                  toast.success("Messages sent successfully", {
                    position: "top-center",
                    autoClose:3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
        
                 notify()
              }

              if(result.errorMessage){
                console.log({error:result.errorMessage})
                const notify = () => {
                  toast.error(`Error failed to send message`, {
                    position: "top-center",
                    autoClose:3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
        
                 notify()
              }
             })
             .catch((err)=>{
              setButtonLoader(false)
              console.log('Server error failed to send messages',err)
              const notify = () => {
                toast.error("Server Error, try again", {
                  position: "top-center",
                  autoClose:3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
      
               notify()
             })
       } catch (error) {
        setButtonLoader(false)
          console.log('Failed to send messages',error)
          const notify = () => {
            toast.warn("Disconnected, check Internet", {
              position: "top-center",
              autoClose:3000,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light"
             })
           };
  
           notify()
       }
  
    };

    const MessageInputChange = (e) =>{
      const message = e.target.value
      //console.log(IDNumberData)
      setMessageContent(message)
    }


    return(
        <div className="studentsendMessage_container">
          <div className="studentsendMessage-functionalities_container">
          <div className="studentsendMessage_header">
            <span>Message Portal</span>
          </div>
           
          <div className="studentsenMessage-textArea_wrapper">
           <textarea 
             placeholder="Write message here.."
             onChange={MessageInputChange}
            />
          </div>

          <div className="sendMessage-button_group">
            <button onClick={()=>navigate(-1)}>Cancel</button>
            <button onClick={handleSendMessage}>Send</button>
          </div>
        </div>

            
         
         <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
         />
        </div>
    )
}

export default StudentSendMessage