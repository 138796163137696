import React,{useState, useEffect} from "react"
import { Link,useParams,useNavigation,useLocation } from "react-router-dom";
import './subjectAnalytics.css'
import { Dots} from "react-activity"
import "react-activity/dist/library.css"
import * as FileSaver from 'file-saver'
import DatePicker from 'react-datepicker'
import XLSX from 'sheetjs-style';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Line } from 'react-chartjs-2';
import {
  Chart as Chartjs,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement
    } from 'chart.js'

Chartjs.register(
      LineElement,
      PointElement,
      CategoryScale,
      LinearScale
)

const SubjectAnalytics = () =>{
  const [loader, setLoader] = useState(false)
  const [reportData, setReportData] = useState(null)  
  const [CBReportData, setCBReportData] = useState(null)
  const [reportsYear, setReportsYear] = useState(null)
  const [subjectOverallData, setSubjectOverallData] = useState(null)
  const [reportCBData, setReportCBData] = useState(null)  
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const userCreds = JSON.parse(localStorage.getItem('userCreds'))
  const schoolId = userCreds.user.schoolId
  const subjectDetails = JSON.parse(decodeURIComponent(urlParams.get('subjectData')))
  const reportId = useParams().id
  const subjectname = subjectDetails.subject
  const reportname = subjectDetails.ReportDetails./*report.*/reportname
  const classId = subjectDetails.ReportDetails./*report.*/classId

  //console.log(subjectDetails)

  const fetchSubjectOverallPerformance = async() =>{
    const currentDate = new Date()
    const choosenYear = new Date(reportsYear).getFullYear()
    const listedYear = choosenYear === 1970 ? currentDate.getFullYear() : choosenYear
    console.log({
      subjectname,classId
    })
    try {
      await fetch(`${global._URL}/reports/subjectoverall?subjectname=${subjectname}&classId=${classId}&year=${listedYear}`)
          .then((res)=>{
            return res.json()
          })
          .then((result)=>{
            console.log(result)
            
              if(!result.errorMessage){
                console.log({output:result.result})
                if(result.result === undefined ){
                  const notify = () => {
                    toast.warn("No Records on that year", {
                      position: "top-center",
                      autoClose:3000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light"
                     })
                   };
          
                   notify()
                   setSubjectOverallData(null)
                   return
                }else{
                  
                   setSubjectOverallData(result.result)

                }
                
                //setDocumentReady(true)
              }
          }) 
          .catch((err)=>{
            console.log('Failed to fetch report data',err)
          })
    } catch (error) {
      console.log('Failed to fetch studet overall performance',error)
    }
  }

    const fetchClassReportPerformance = async() =>{
        try {
          await fetch(`${global._URL}/reports/analytics?reportId=${reportId}&schoolId=${schoolId}`)
              .then((res)=>{
                return res.json()
              })
              .then((result)=>{
                console.log(result.gradesAnalytics)
                
                  if(!result.errorMessage){
                    setReportData(result)
                    //setDocumentReady(true)
                  }
              }) 
              .catch((err)=>{
                console.log('Failed to fetch report data',err)
              })
        } catch (error) {
          console.log('Failed to fetch studet overall performance',error)
        }
      }

      const fetchCBDReportPerformance = async() =>{
        setLoader(true)
        try {
          await fetch(`${global._URL}/CBD/CBanalytics?reportId=${reportId}&subjectname=${subjectname}&classId=${classId}`)
              .then((res)=>{
                return res.json()
              })
              .then((result)=>{
                setLoader(false)
                console.log(result)
                
                  if(!result.errorMessage){
                    setCBReportData(result)                    //setDocumentReady(true)
                  }
              }) 
              .catch((err)=>{
                setLoader(false)
                console.log('Failed to fetch report data',err)
                const notify = () => {
                  toast.warn("Disconnected, check Internet", {
                    position: "top-center",
                    autoClose:3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
        
                 notify()
              })
        } catch (error) {
          console.log('Failed to fetch studet overall performance',error)
          const notify = () => {
            toast.warn("Disconnected, check Internet", {
              position: "top-center",
              autoClose:3000,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light"
             })
           };
  
           notify()
        }
      }

      useEffect(()=>{
        //console.log(ReportDetails)
        //setReportData(ReportDetails)
        fetchSubjectOverallPerformance()
      },[reportsYear])
    
      useEffect(()=>{
        //console.log(ReportDetails)
        //setReportData(ReportDetails)
        fetchSubjectOverallPerformance()
         //fetchCBDReportPerformance()
        fetchClassReportPerformance()
      },[])

      const handleReportsYearChange = (date) =>{
        console.log(date)
        const fullYear = date.getFullYear()
        console.log(fullYear)
        setReportsYear(date)
      }

      function getRandomColor() {
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        return `rgb(${r},${g},${b})`;
      }   

let lineOptions, lineData      
if(subjectOverallData !== null){
  console.log({subjectOverallData})
  //subjectOverallData === undefined || 
  if(subjectOverallData === null) return;
  console.log('I run after return')
  const examLabels = Object.keys(subjectOverallData);
  const subjects = Object.keys(subjectOverallData[examLabels[0]]);

  const subjectDatasets = subjects.map(subject => ({
    label: `${subject} Average Marks`,
    data: examLabels.map(exam => subjectOverallData[exam][subject]?.averageMarks || null),
    fill: false,
    borderColor: "#00FF00"//getRandomColor(),
  }));

  lineData = {
    labels: examLabels,
    datasets: subjectDatasets,
    tension:0.4
  };

  lineOptions = {
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Average Marks',
        },
        grid: {
          drawBorder: false,
          color: '#333',
      },
      },
      x: {
        },
    },
    maintainAspectRatio:false

  };
      }
     //else{
      //  console.log(' Subject overall equals to null')
      //  console.log({subjectOverallData})
      //  return;
      //*/}

      // Export data to Excel
  const exportToExcel = async () => {
    // Ensure data is available
    if (!subjectOverallData) return;

    const flattenedData = [];
    for (const test in subjectOverallData) {
      const testResult = subjectOverallData[test];
      for (const subject in testResult) {
        const subjectData = testResult[subject];
        const row = {
          Exam: test,
          //Subject: subject,
          TotalMarks: subjectData.totalMarks,
          TotalStudents: subjectData.totalStudents,
          AverageMarks: subjectData.averageMarks,
          AverageGrade: subjectData.averageGrade,
        };
        flattenedData.push(row);
      }
    }

  // Create Excel workbook
  const wb = XLSX.utils.book_new();

  const ws = XLSX.utils.json_to_sheet(flattenedData);

  // Add sheet to workbook
  
  XLSX.utils.book_append_sheet(wb, ws, 'Subject Performance Records');

  // Export the workbook to a file
  const fileName = `subjectPerformance.xlsx`//'grades-report.xlsx';
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
  FileSaver.saveAs(data, fileName);
  };

  return(
    <div className="subjectAnaly_container">
        <div className="subjectAnaly_header">
          <span>
           Subject-specific Analytics
          </span>
          <span>
           {subjectname}
          </span>
        </div>
        <div className="subjectAnaly_yearSelect">
        <DatePicker
            selected={reportsYear}
            onChange={handleReportsYearChange}
            dateFormat="yyyy"
            showYearPicker
            placeholderText={"Select year"}
          />
        </div>
        {loader ? (
           <div className="loaderContainer">
            <Dots/>
          </div>
        ) : (
          <>
           {subjectOverallData !== null && (
              <div className='excelExport_wrapper'>
               <button onClick={exportToExcel}>Export to Excel</button>
             </div> 
            )}
           {subjectOverallData !== null && subjectOverallData !== undefined ? (
            <div className="subjectAnalytics_tableContainer">
              <table className='main_table'>
                <thead>
                  <tr>
                    <th>Reports</th>
                    <th>Tot.Marks</th>
                    <th>No. Students</th>
                    <th>Avg Marks</th>
                    <th>Grade</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(subjectOverallData).map(reportName => {
                    const reportData = subjectOverallData[reportName];
                    return Object.keys(reportData).map(subject => {
                      const {
                        totalMarks,
                        totalStudents,
                        averageMarks,
                        averageGrade
                      } = reportData[subject];
        
                      return (
                        <tr key={`${reportName}-${subject}`}>
                          <td>{reportName}</td>
                          <td>{totalMarks?.toFixed(2)}</td>
                          <td>{totalStudents}</td>
                          <td>{averageMarks}</td>
                          <td>{averageGrade}</td>
                        </tr>
                      );
                    });
                  })}
                </tbody>
              </table>
                

              {subjectOverallData !== null && subjectOverallData !== undefined && (
                <div className="subjectAnalytics_graphContainer">
                <div className="subjectAnalytics_graphHeader">
                  <span>Graphical Visualization</span>
                </div>
                <div className="subjectAnalytics_graph_container">
                <Line 
                  data={lineData} 
                  options={lineOptions}
                  style={
                    {
                      padding:'20px'
                  }
                  }
                  height={"80%"}
                />
                </div>
              </div>
                )}

              
              </div>

              
              
             ):(
              <div className="noReport_Container">
                <span>No Records...</span>
              </div>  
            )}

            
          </>
        )}
        <ToastContainer
      position="top-center"
      autoClose={3000}
      hideProgressBar
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
        </div>
        
)
}

export default SubjectAnalytics