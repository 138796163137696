import react,{useState,useEffect} from "react"
import { Link, useNavigate } from "react-router-dom";
import {Dots,Sentry,Levels ,Bounce,Windmill,Digital} from "react-activity"
import "react-activity/dist/library.css"
import { ToastContainer, toast } from 'react-toastify';
import { truncateString } from "../../../utils/truncateString";
import "react-toastify/dist/ReactToastify.css";
import './MessagePortal.css'

const MessagePortal = () =>{
    const navigate =  useNavigate()
    
    const [ClassLoader, setClassLoader] = useState(false)
    const [Loader, setLoader] = useState(false)
    const [classList, setClassList] = useState([])
    const [selectedClass, setSelectedClass] = useState(null)
    const [selectedStream,setSelectedStream] = useState(null)

    const [messages, setMessages] = useState(null/*[
      {
        _id:'12345',
        createdAt:'2023-06-10T00:00:10.000Z',
        comment:'Parent meeting on Saturday 15 June 2023',
        classId:'class A'
      },
      {
        _id:'12346',
        createdAt:'2023-06-10T00:00:00.000Z',
        comment:'School tour Announcement',
        classId:'class B'
      },
      {
        _id:'12347',
        createdAt:'2024-06-10T00:00:00.000Z',
        comment:'Parent meeting on Saturday 15 July 2023',
        classId:'class A'
      }
    ]*/)

    const fetchClasses = async(schoolID) =>{
      setClassLoader(true)
        try {
            await fetch(`${global._URL}/classes/schoolclasses?schoolId=${schoolID}`)
              .then((res)=>{
                return res.json()
              })
              .then((result)=>{
                setClassLoader(false)
                //console.log(result)
                if(!result.message){
                  setClassList(result)
                  
                }
                //if(result.length === 1){
                  setSelectedClass(result[0]._id)
                  
               // }
                
              })
              .catch((err)=>{
                setClassLoader(false)
                console.log('Failed to fetch school classes',err)
                const notify = () => {
                  toast.warn("Classes not found", {
                    position: "top-center",
                    autoClose:1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
        
                 notify()
              })
        } catch (error) {
          setClassLoader(false)
            console.log('Failed to get classes',error)
            const notify = () => {
              toast.warn("Disconnected, check Internet", {
                position: "top-center",
                autoClose:1500,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
                theme: "light"
               })
             };
    
             notify()
        }
    } 

    useEffect(()=>{
      const userCreds = JSON.parse(localStorage.getItem('userCreds'))
      const schoolID = userCreds.user.schoolId
      console.log(schoolID)
        fetchClasses(schoolID)
      },[])

  const handleClassChange = (focus) => {
      const selectedClassItem = focus.target.value
      //setSelectedClass(selectedClassItem)
      console.log(selectedClassItem)
      

      //Set Active Class in select option
       const activeClassChoice = classList.filter((classItem)=>{
        //console.log(student)
        const activeClass = classItem._id === selectedClassItem//classId
        //console.log(activeClass)
        return activeClass
       // return setSelectedClass(activeClass)
      })

      //console.log(activeClassChoice)
      setSelectedClass(activeClassChoice[0]._id)
    }


   /* const fetchMessages = (scholId, className) => {
      return promises.resolve([
        {
          createdAt:'2023-06-10T00:00:00.000Z',
          comment:'Parent meeting on Saturday 15 June 2023',
          className:'class A'
        },
        {
          createdAt:'2023-06-10T00:00:00.000Z',
          comment:'School tour Announcement',
          className:'class B'
        },
        {
          createdAt:'2023-06-10T00:00:00.000Z',
          comment:'Parent meeting on Saturday 15 July 2023',
          className:'class A'
        }
      ])
    }

    useEffect(()=>{
      fetchMessages(schoolId,selectedClass)
        .then((data)=> setMessages(data))
        .catch((err)=> console.log(err))
    },[schoolId, selectedClass])*/

    const fetchClassMessages = async() =>{
      console.log({selectedClass})
      setLoader(true)
      try {
        await fetch(`${global._URL}/comments/class-messages/${selectedClass}`)
            .then((res)=>{
              return res.json()
            })
            .then((result)=>{
              setLoader(false)
              console.log(result)
              
                if(!result.errorMessage){
                  setMessages(result.classMessageList)
                }else{
                  console.log('No Messages in class')
                  /*const notify = () => {
                    toast.warn(`Server EE,${result.errorMessage}`, {
                         position: "top-left",
                         hideProgressBar: true,
                         closeOnClick: true,
                         draggable: true,
                         progress: undefined,
                         theme: "light"
                     })
                   };
        
                   notify()*/
                }
            }) 
            .catch((err)=>{
              setLoader(false)
              console.log('Failed to fetch messages',err)
              const notify = () => {
                toast.warn("Disconnected, check Internet", {
                     position: "top-left",
                     hideProgressBar: true,
                     closeOnClick: true,
                     draggable: true,
                     progress: undefined,
                     theme: "light"
                 })
               };
    
               notify()
            })
      } catch (error) {
       setLoader(false)
        console.log('Failed to fetch class messages',error)
        const notify = () => {
          toast.warn("Disconnected, check internet", {
               position: "top-left",
               hideProgressBar: true,
               closeOnClick: true,
               draggable: true,
               progress: undefined,
               theme: "light"
           })
         };
  
         notify()
      }
    }

    useEffect(()=>{
      fetchClassMessages()
    },[selectedClass])

    

    const groupMessagesByYear = (messages) => {
      const groups = {}
      messages.forEach((message) => {
        const year = new Date(message?.createdAt).getFullYear();
        if(!groups[year]){
          groups[year] = {messages:[]/*, classes:{}*/}
        }
        groups[year].messages.push(message);
       /* if(!groups[year].classes[message.classId]){
          groups[year].classes[message.classId] = []
        }
        groups[year].classes[message.classId].push(message)*/
      });
      return groups
    }

    const formatDate = (date) => {
      return new Date(date).toLocaleString('default',{month:'long',day:'numeric'})
    }

   

    const renderYear = ({year,groupedMessages})=>(
      <div className="messagePortal-messages_container">
         <h3>{year}</h3>
    
              <div className="messagePortal-messages_message-card">
                {groupedMessages[year].messages.map((message) => (
                  <Link 
                  style={{textDecoration:'none',color:'#333'}}
                  to={`/viewmessage/${message?._id}?messageInfo=${encodeURIComponent(JSON.stringify({messageDetails:message}))}`}
                  key={message?._id}
                  >
                  <p key={message?.createdAt}>
                    <span>{formatDate(message?.createdAt)}</span> - {truncateString(message?.comment,50,50)}
                  </p>
                 </Link> 
                ))}
              </div>
              
      </div>
    )

    const renderMessages = () => {
      //let groupedMessages //= groupMessagesByYear(messages);
      if(messages === null){
        return
      }
      const groupedMessages = groupMessagesByYear(messages);
        console.log({groupedMessages})
      
      
      //renderYear({groupedMessages})
      return Object.entries(groupedMessages).map(([year, messages]) => (
        <div key={year}>
          {renderYear({year, messages,groupedMessages})}
        </div>
      ))
    }

    return(
        <div className="messagePortal_container">
         <div className="messagePortal-functionalities_container">
          <div className="messagePortal_header">
            <span>Message Portal</span>
          </div>

          <div className="messagePortal-chooseClass_wrappper">
          <select 
                value={selectedClass} 
                onChange={handleClassChange}
               >
              <option  
                  selected 
                  value="null"
                  //disabled
                  key={1}
                  >
                    Select class
                  </option>
              {classList.map((classItem)=>(
                <option key={classItem._id} 
                   value={classItem._id}
                   >
                  {classItem?.classname}
                </option>
              ))}
             </select>

          </div>

          <div className="messagePortal-messages_wrapper">
            <div className="messagePortal-messages_header">
              <span>Recent Messages</span>
            </div>

            {Loader ? (
              <div className="loaderContainer"><Digital/></div>
            ):(
              <>
                {messages?.length !== 0 ? (
                <>
                  {renderMessages()}
                </>
              ):(
               <span>No Messages sent in this class yet</span>
              )
             }
              </>
            )}

           
          </div>  
          <div className="messagePortal_button">
              <button
               onClick={()=>navigate('/sendmessage')}
              >
                New
              </button>
          </div>
         </div>
        </div>
      )
}

export default MessagePortal