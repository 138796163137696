export const formattedDate = (dateString) =>{
    //const date = new Date(dateString)
    //return date.toLocaleString()

    const dateObject = new Date(dateString); 
    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1; // Month is 0-indexed, so we add 1
    const day = dateObject.getDate();

   // Format the components as a string (e.g., "03-08-2015")
   const formattedDate = `${day}-${month}-${year}`;
   return formattedDate
  }