import React,{useState,useEffect} from 'react'
import {Dots} from "react-activity"
import "react-activity/dist/library.css"
import { ToastContainer, toast } from 'react-toastify';
import { Link } from "react-router-dom";
import './Analytics.css'

const Analytics = () =>{
    const [loader, setLoader] = useState(false)
    const [classData, setClassData] = useState([])

    useEffect(()=>{
        const userCreds = JSON.parse(localStorage.getItem('userCreds'))
        const school_id = userCreds.user.schoolId
        console.log(school_id)

       const fetchClassList = async () =>{
        setLoader(true)
        try {
            await fetch(`${global._URL}/classes/schoolclasses?schoolId=${school_id}`)
            .then((res)=>{
              return res.json()
            })
            .then((result)=>{
                setLoader(false)
              //console.log(result)
              if(!result.error){
                setClassData(result)
              }else{
                const notify = () => {
                    toast.error(result.message || result.errorMessage, {
                      position: "top-center",
                      autoClose:1500,
                      hideProgressBar: true,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light"
                     })
                   };
          
                   notify()
              }
            })
            .catch((err)=>{
                setLoader(false)
              console.log('Failed to fetch classes',err)
              const notify = () => {
                toast.warn("Disconnected, check Internet", {
                  position: "top-center",
                  autoClose:1500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
      
               notify()
            })    
        } catch (error) {
            setLoader(false)
            console.log('Low Internet connnection, failed to fetch classes')
            const notify = () => {
                toast.warn("Disconnected, check Internet", {
                  position: "top-center",
                  autoClose:1500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
      
               notify()
        } }

       fetchClassList()
    },[])

    //?className=${classItem.className}&studentsData=${encodeURIComponent(JSON.stringify({studentsList:classItem.students,classId:classItem.id}))}
    return(
        <div className='analytics_container'>
          <div className='analytics_header'>
              <span>Choose class to view analytics</span>  
          </div>

          {loader ? (
             <div className="loaderContainer"> 
               <Dots/>
             </div>
            ):(
             <div className="analytics-cards">
             {classData?.map((classItem)=>(
             <Link 
               //to={`/analytics/${classItem._id}`}
               to={`/classanalytics/${classItem._id}`}
               style={{textDecoration:'none',color:'#000'}}
               key={classItem._id}
             >
              <div className="analytics-card" key={classItem._id}>
                <span>{classItem.classname}</span>
              </div>
             </Link>   
            ))}
          </div>   
          )}
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        </div>
    )
}

export default Analytics