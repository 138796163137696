import React,{useEffect,useState} from 'react'
import { Link,useLocation, useNavigate, useParams } from "react-router-dom";
import {Dots,Digital} from "react-activity"
import "react-activity/dist/library.css"
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './CBDReport.css'

const CBDReport = () =>{
    const [loader, setLoader] = useState(false)
    const [buttonLoader, setButtonLoader] = useState(false)   
    const [studentParticipated, setNumberOfStudentParticipated] = useState(null)
    const [questionData, setQuestionData] = useState([])
    const location = useLocation()
    const urlParams = new URLSearchParams(location.search)
    const CBDReportData = JSON.parse(decodeURIComponent(urlParams.get('CBDReportData')))

    const navigation = useNavigate()

    //console.log(CBDReportData)
    const {subjectname,reportname} = CBDReportData
    const reportId = CBDReportData.reportId
    const numberOfQuestions = CBDReportData.numberOfQuestions
    const CBDReportId = useParams().id
    //console.log({subjectname,reportId,CBDReportId,numberOfQuestions})


    const GetNumberOfStudentsParticipated = async() =>{
        setLoader(true)
        try {
            await fetch(`${global._URL}/reports/reportbyname?subjectname=${subjectname}&reportId=${reportId}`)
               .then((res)=>{
                return res.json()
               })
               .then((result)=>{
                console.log(result.result.length)
                setLoader(false)
                if(!result.errorMessage){
                    if(result.result.length !== 0){
                      setNumberOfStudentParticipated(result.result.length)  
                    }else{
                        setNumberOfStudentParticipated(0) 
                        const notify = () => {
                            toast.info("Marks are not filled yet", {
                              position: "top-center",
                              autoClose:3000,
                              hideProgressBar: true,
                              closeOnClick: true,
                              draggable: true,
                              progress: undefined,
                              theme: "light"
                             })
                           };
                
                           notify()

                           navigation.goBack()
                    }
                    
                }else{
                  const notify = () => {
                    toast.error(`${result.errorMessage}`, {
                      position: "top-center",
                      autoClose:3000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light"
                     })
                   };
        
                   notify()
                }
               }).catch((err)=>{
                //setLoader(false)
                console.log('Server error failed to get participants',err)
               })
        } catch (error) {
            //setLoader(false)
            console.log('Failed to get students participants')
        }
    }

    const previousCBData = async(Id) =>{
        await fetch(`${global._URL}/CBD/subjectCBD/${Id}`)
               .then((res)=>{
                return res.json()
               })
               .then((result)=>{
                console.log(result)
                setLoader(false)
                if(!result.errorMessage){
                    const savedData = result.result.performanceData
                    const initialQuestionData = Array.from({length:numberOfQuestions},(_,index)=>{
                        const savedQuestion = savedData[index] || {};
                        return{
                            questionNo:index + 1,
                        correctResponse:savedQuestion.correctResponse || 0,
                        incorrectResponse:studentParticipated - savedQuestion.correctResponse || 0//savedQuestion.incorrectResponse || ''
                        }
                        
                    });
                    setQuestionData(initialQuestionData)

                }
               }).catch((err)=>{
                setLoader(false)
                console.log('Server error failed to get participants',err)
               })
    }

    const updateResponsesIfNeeded = (savedData) =>{
      const sumResponses = savedData.reduce((sum,question) => sum + (question.correctResponse) + (question.incorrectResponse),0)

      if(studentParticipated !== sumResponses){
        const updatedQuestionData = savedData.map((question,index)=>({
            questionNo:index +1,
            correctResponse:studentParticipated - question.incorrectResponse,
            incorrectResponse:question.incorrectResponse
        }))
        setQuestionData(updatedQuestionData)
      }

    }
    useEffect(()=>{
        previousCBData(CBDReportId)
        GetNumberOfStudentsParticipated()
    },[])

    const saveCBReport = async() =>{
        //console.log(performanceData)
        const invalidQuestion = questionData.find(question=>{
          return question.incorrectResponse > studentParticipated
        })

        if(invalidQuestion){
          const notify = () => {
            toast.warn(`You exceed number of students on Qn.${invalidQuestion.questionNo}`, {
              position: "top-center",
              autoClose:2000,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light"
             })
           };

           notify()
           return;
        }

        const performanceData =  questionData.filter(
          question =>question.incorrectResponse !== '' && !isNaN(question.incorrectResponse)
          )
        console.log(performanceData)
        //console.log(questionData)
        setButtonLoader(true)
        const options = {
          method:'POST',
          body:JSON.stringify({
          performanceData:performanceData
         }),
         headers:{
            'Content-type':'application/json; charset=UTF-8'
         }
       }
       try{
            await fetch(`${global._URL}/CBD/fillCBD/${CBDReportId}`,options)
               .then((res)=>{
                return res.json()
               })
               .then((result)=>{
                setButtonLoader(false)
                console.log(result)
                setQuestionData(Array.from({length:numberOfQuestions},(_,index)=>{
                        //const savedQuestion = savedData[index] || {};
                        return{
                            questionNo:index + 1,
                        correctResponse: 0,
                        incorrectResponse: ''
                        }   
                    }));
                if(!result.errorMessage){
                  const notify = () => {
                    toast.success("Report saved successful", {
                      position: "top-center",
                      autoClose:2000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light"
                     })
                   };
        
                   notify()
                }else{
                  const notify = () => {
                    toast.error(`Server Error.${result.errorMessage}`, {
                      position: "top-center",
                      autoClose:2000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light"
                     })
                   };
        
                   notify()
                }    
               }).catch((err)=>{
                setButtonLoader(false)
                console.log('Server error failed to get participants',err)
                const notify = () => {
                  toast.error(`Server Error,${err}`, {
                    position: "top-center",
                    autoClose:2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
      
                 notify()
               })
        } catch (error) {
            setButtonLoader(false)
            console.log('Failed to get students participants')
        }
    }

   const handleIncorrectChange = (questionNo,value) =>{
    console.log(questionNo,parseInt(value))
    setQuestionData(prevData=>{
      const updatedQuestionData = prevData.map(question =>{
        if(question.questionNo === questionNo){
            const correctResponse = studentParticipated - parseInt(value)
            
            return{
                ...question,
                incorrectResponse:parseInt(value),
                correctResponse:correctResponse > 0 ? correctResponse:0
            }
        }
        
        return question
    })
    
    return updatedQuestionData
    })

   }


    return(
        <div className='CBD_wrapper'>
            <div className='CBD_wrapper-header'>
              <span>{reportname}</span> 
              <span>{subjectname} Competence Form</span> 
            </div>
            {loader ? <div className='loaderContainer'><Dots/></div>:(
            <>
              {!studentParticipated?.length > 0 ? (
              <div className='CBDStudentNumber_wrapper'>
                <p>Marked Students {studentParticipated}</p>  
              </div>
             ):(
            <div className='CBDStudentNumberError_wrapper'>
              <p>Fill Student Marks first</p>
            </div>
             )}  
          
          {!studentParticipated?.length > 0 && (
            <div>
              <div className='questions_wrapper'>
                {questionData.map(question=>(
                  <div className='questions_card' key={question.questionNo}>
                     <div className='question_card-number'>
                       <p>
                        Question {question.questionNo}
                       </p> 
                     </div>
                     <div className='question_card-details'>
                     <span>
                          Incorrect
                      </span>
                      <input
                        type='text'
                        //value={question.incorrectResponse}
                        onChange={e=> handleIncorrectChange(question.questionNo, e.target.value)}
                      />
                      
                      {studentParticipated && (
                          <p>
                              Out of {studentParticipated}
                          </p>
                      )}  
                     </div>     
               </div>
           
              ))}
          </div>
           <div className='CBDSaveButton'>
            {buttonLoader && (
                <Digital/>
            )}
            <button
              onClick={saveCBReport}
            >
                Save
            </button>
          </div>
          </div>
          )}
          </>
          )}
          
          <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        </div>
    )
}

export default CBDReport