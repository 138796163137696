import React,{useState,useEffect} from 'react'
import {Dots} from "react-activity"
import "react-activity/dist/library.css"
import { ToastContainer, toast } from 'react-toastify';
import { Link,useParams,useLocation } from "react-router-dom";
import './ExamResult.css'
const ExamResult = () =>{
    const [reportData, setReportData] = useState(null)
    const location = useLocation()
    const urlParams = new URLSearchParams(location.search)
    const ReportDetails = JSON.parse(decodeURIComponent(urlParams.get('details')))
    console.log(ReportDetails)
    const classId = ReportDetails.class
    const schoolId = ReportDetails.school

    const reportId = useParams().id
    
    const fetchClassReportPerformance = async() =>{
        console.log({reportId,schoolId})
        try {
          await fetch(`${global._URL}/reports/analytics?reportId=${reportId}&schoolId=${schoolId}&classId=${classId}`)
              .then((res)=>{
                return res.json()
              })
              .then((result)=>{
                console.log(result.subjectAnalytics)
                
                  if(!result.errorMessage){
                    setReportData(result)
                    console.log({gradesAnalytics:result.gradesAnalytics,totalMarksAndPosition:result.totalMarksAndPosition})
                    
                  }else{
                    const notify = () => {
                      toast.warn(`Server error,${result.errorMessage}`, {
                           position: "top-left",
                           hideProgressBar: true,
                           closeOnClick: true,
                           draggable: true,
                           progress: undefined,
                           theme: "light"
                       })
                     };
          
                     notify()
                  }
              }) 
              .catch((err)=>{
                console.log('Failed to fetch report data',err)
                const notify = () => {
                  toast.warn("Disconnected, check Internet", {
                       position: "top-left",
                       hideProgressBar: true,
                       closeOnClick: true,
                       draggable: true,
                       progress: undefined,
                       theme: "light"
                   })
                 };
      
                 notify()
              })
        } catch (error) {
          console.log('Failed to fetch studet overall performance',error)
          const notify = () => {
            toast.warn("Disconnected, check internet", {
                 position: "top-left",
                 hideProgressBar: true,
                 closeOnClick: true,
                 draggable: true,
                 progress: undefined,
                 theme: "light"
             })
           };
    
           notify()
        }
      }

      useEffect(()=>{
        fetchClassReportPerformance()
      },[])

      const getUniqueSubjects = () =>{
        if(!reportData || !reportData.gradesAnalytics) return []
    
        const uniqueSubjects = new Set();
        Object.values(reportData.gradesAnalytics).forEach((student)=>{
          Object.keys(student.subjects).forEach((subject)=>{
            uniqueSubjects.add(subject)
          })
        })
        return Array.from(uniqueSubjects)
      }

      const formattedDate = (dateString) =>{
        const dateObject = new Date(dateString); 
        const year = dateObject.getFullYear();
        const month = dateObject.getMonth() + 1; // Month is 0-indexed, so we add 1
        const day = dateObject.getDate();
    
       // Format the components as a string (e.g., "03-08-2015")
       const formattedDate = `${day}-${month}-${year}`;
       return formattedDate
      }

    return(
        <div className='TotalResults_container'>
          <div className='TotalResults-Header'>
              <span>{ReportDetails?.schoolName}</span>
              <span>{ReportDetails?.reportname}</span> 
              <span>{ReportDetails?.className}</span> 
              {/*<span>{formattedDate(ReportDetails?.reportDate)}</span>  */}
            </div> 
          
             <div className='tableContainer'>
             {reportData ? ( 
              <table className='main_table'>
                  <thead>
                    <tr>
                      <th rowSpan="2">Student Name</th>
                      {getUniqueSubjects().map((subject)=>(
                        
                          <th 
                            key={subject} 
                            colSpan="2"
                          >
                            {subject} 
                          </th>
                         
                        ))}
                      <th rowSpan="2">Total</th>
                      <th rowSpan="2">Position</th>
                    </tr>
                    <tr>
                    {getUniqueSubjects().map((subject)=>(
                      <React.Fragment key={subject}>
                        <th>M</th>
                        <th>G</th>
                      </React.Fragment>  
                    ))}
                    </tr>
                  </thead>
                  <tbody>
                    {reportData.totalMarksAndPosition.map(({studentId, studentname, totalMarks,position})=>(
                      <tr key={studentId}
                      >
                        <td>
                          {studentname}
                        </td>
                        
                        {getUniqueSubjects().map((subject)=>{
                          const data = reportData.gradesAnalytics[studentId]?.subjects?.[subject] || {};
                          return(
                          <React.Fragment key={subject}>
                          
                          <td>{data.marks || '-'}</td>
                          <td>{data.grade || '-'}</td>
                          
                          </React.Fragment> 
                          )
                         })}
                        
                        <td>{totalMarks}</td>
                        <td>{position}</td>
                        
                      </tr>
                    ))}
                  </tbody>
                  </table>
                  ):<div classNmae="loaderContainer">
                     <Dots/>
                 </div>
                  }
                </div>
                
                <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
                </div>
    )
}

export default ExamResult

