export const subjectList = [
  {id:2, subject:'Arithmetics&Counting'},
  {id:1, subject:'Mathematics'},
  {id:3, subject:'Reading Skills'},
  {id:4, subject:'Writting Skills'},
  {id:5, subject:'Science'},
  {id:6, subject:'English'},
  {id:7, subject:'Kiswahili'},
  {id:8, subject:'C.M.E'},
  {id:9, subject:'S/Studies'},
  {id:10, subject:'French'},
  {id:11, subject:'Arabic'},
  {id:12, subject:'Religion'},
  {id:13, subject:'Reading'},
  {id:14, subject:'Writting'}, 
  {id:15, subject:'Numeracy'},
  {id:16, subject:'H.C.E'},
  {id:17, subject:'D.S.A'},
  {id:18, subject:'Communication'},
  {id:19, subject:'Arithmetics'},
  {id:20, subject:'Health Care'},
  {id:21, subject:'Environment Care'},
  {id:22, subject:'Relation'},
  {id:23, subject:'Artistic'},
  {id:24, subject:'V/SKILLS'},
  {id:25, subject: 'Culture'}, 
  {id:26, subject: 'Listerning'},
  {id:27, subject: 'Physics'}, 
  {id:28, subject:'Chemistry'},
  {id:29, subject:'Geography'},
  {id:30, subject: 'Biology'} 
]

export const gradesRange = {
  A: { min: 41, max: 50 },
  B: { min: 31, max: 40 },
  C: { min: 21, max: 30 },
  D: { min: 11, max: 20 },
  E: { min: 1, max: 10 },
}; 

export const bandsRange = {
  "BAND 1": { min: 45.1, max: 50 },
  "BAND 2": { min: 40.1, max: 45 },
  "BAND 3": { min: 35.1, max: 40 },
  "BAND 4": { min: 30.1, max: 35 },
  "BAND 5": { min: 25.1, max: 30 },
  "BAND 6": { min: 20.1, max: 25 },
  "BAND 7": { min: 15.1, max: 20 },
  "BAND 8": { min: 10.1, max: 15 },
  "BAND 9": { min: 5.1, max: 10 },
  "BAND 10": { min: 0, max: 5 },
}; 

//export const classLevels = [1,2,3,4,5,6,7]

export const subjectColors = {
  Mathematics: "#ea5545",
  Science: "#f46a9b",
  English: "#ef9b20",
  Kiswahili: "#edbf33",
  Geography: "#ede15b",
  "Social Studies": "#C2FF33",
  "S/Studies":"#ede15b",
  Civics:"#bdcf32",
  History:"#bdcf32",
  French:"#87bc45",
  Arabic:"#27aeef",
  Religion:"#b33dc6",
  "C.M.E":"#71C562", //"#00FF00",
  "Health Care":"#f46a9b",
  "Environmental Care":"#f49436",
  "Writting":"#2d9de5",
  "Reading":"#3bbdbd",
  "Communication":"#27aeef",
  "Relation":"#27aeef",
  "V/SKILLS":"#27aeef",
  "Artistic":"#27aeef",
  "Arithmetics":"#ea5545",
  "Numeracy":"#ea5545",
  "H.C.E":"#e8615d",
  "D.S.A":"#27aeef"
};

export const fakeStudents = [
  {studentId:'1001', studentName:'John Alice'},
  {studentId:'1002', studentName:'Salum Abdallah'},
  {studentId:'1003', studentName:'Amina Alex'},
  {studentId:'1004', studentName:'John Kjaz'},
  {studentId:'1005', studentName:'Ally Muohammed'},
]

export const FakeReportData = {
  "_id": "65131e0df77b86395e0eb5a5",
  "exerciseReportName": "Math Quiz 1",
  "subjectname": "Arithmetics&Counting",
  "classId": "6512921c19a80557bddb5774",
  "numberOfQuestions": 5,
  "questionDetails": [
    {
      "questionNumber": 1,
      "topic": "Algebra",
      "concept": "Linear Algebra",
      "studentData": []
    },
    {
      "questionNumber": 2,
      "topic": "Geometry",
      "concept": "Linear Geometry",
      "studentData": []
    },
    {
      "questionNumber": 3,
      "topic": "Trigonometry",
      "concept": "Linear Equations",
      "studentData": []
    },
    {
      "questionNumber": 4,
      "topic": "Statistics",
      "concept": "Linear Statistics",
      "studentData": []
    },
    {
      "questionNumber": 5,
      "topic": "Set",
      "concept": "Linear Set",
      "studentData": []
    }
  ]
  }     

export const classTopicPerformance = {
  "classTopicPerformance": [
    {
      "topic": "Algebra",
      "averageScore": 75
    },
    {
      "topic": "Geometry",
      "averageScore": 82
    },
    {
      "topic": "Trigonometry",
      "averageScore": 68
    },
    {
      "topic": "Calculus",
      "averageScore": 90
    },
    {
      "topic": "Statistics",
      "averageScore": 72
    }
    // Add more topics and average scores as needed
  ]
}

export const topicConceptsOverall = [
  {
    topic:'Algebra',
    concepts:[
      {concept:'Equations',averageScore:80},
      {concept:'Inequalities',averageScore:70},
      {concept:'Polynomials',averageScore:60}
    ]
  },
  {
    topic:'Geometry',
    concepts:[
      {concept:'Triangles',averageScore:90},
      {concept:'Circles',averageScore:70},
      {concept:'Quadrilaterals',averageScore:85},
    ]
  }
]

const ReportData = {
  "gradesAnalytics": {
      "64c20ad185c4949a68f9be1f": {
          "studentname": "Unknown",
          "subjects": {
              "Science": {
                  "grade": "B",
                  "marks": 40
              }
          }
      },
      "64c0ec8242cc1165adde8fc4": {
          "studentname": "Unknown",
          "subjects": {
              "Science": {
                  "grade": "",
                  "marks": 70
              },
              "Mathematics": {
                  "grade": "",
                  "marks": 69
              }
          }
      },
      "64c0eccf42cc1165adde8fc9": {
          "studentname": "Unknown",
          "subjects": {
              "Science": {
                  "grade": "",
                  "marks": 80
              },
              "Mathematics": {
                  "grade": "",
                  "marks": 79
              }
          }
      },
      "64c0ed1a42cc1165adde8fcd": {
          "studentname": "Unknown",
          "subjects": {
              "Science": {
                  "grade": "B",
                  "marks": 40
              }
          }
      },
      "64c20972b030c0a91241e8f2": {
          "studentname": "Unknown",
          "subjects": {
              "Science": {
                  "grade": "",
                  "marks": 97
              }
          }
      }
  },
  "totalMarksAndPosition": [],
  "subjectAnalytics": {
      "Mathematics": {
          "totalSubjectScore": 279,
          "totalStudents": 7,
          "averageScore": 39.857
      },
      "Science": {
          "totalSubjectScore": 253,
          "totalStudents": 7,
          "averageScore": 36.143
      },
      "English": {
          "totalSubjectScore": 285,
          "totalStudents": 7,
          "averageScore": 40.714
      }
  },
  "subjectPerformanceAnalytics": {
      "Mathematics": [
          {
              "grade": "A",
              "numberOfgrade": 5
          },
          {
              "grade": "B",
              "numberOfgrade": 2
          }
      ],
      "Science": [
          {
              "grade": "B",
              "numberOfgrade": 1
          },
          {
              "grade": "A",
              "numberOfgrade": 4
          },
          {
              "grade": "C",
              "numberOfgrade": 2
          }
      ],
      "English": [
          {
              "grade": "A",
              "numberOfgrade": 5
          },
          {
              "grade": "B",
              "numberOfgrade": 2
          }
      ]
  }
}

export const clusterData = {
    "clusters": [
      {
        "cluster_id": 1,
        "students": [
          {
            "student_id": "S1001",
            "overall_score": 85
          },
          {
            "student_id": "S1004",
            "overall_score": 88
          },
          {
            "student_id": "S1007",
            "overall_score": 90
          }
        ]
      },
      {
        "cluster_id": 2,
        "students": [
          {
            "student_id": "S1002",
            "overall_score": 55
          },
          {
            "student_id": "S1005",
            "overall_score": 60
          },
          {
            "student_id": "S1008",
            "overall_score": 58
          }
        ]
      },
      {
        "cluster_id": 3,
        "students": [
          {
            "student_id": "S1003",
            "overall_score": 75
          },
          {
            "student_id": "S1006",
            "overall_score": 70
          },
          {
            "student_id": "S1009",
            "overall_score": 73
          }
        ]
      }
    ]
  }

  export const classTopicsPerformances = {
    "classTopicsPerformance": [
      {
        "topic": "Algebra",
        "averageScore": 75
      },
      {
        "topic": "Geometry",
        "averageScore": 82
      },
      {
        "topic": "Trigonometry",
        "averageScore": 68
      },
      {
        "topic": "Calculus",
        "averageScore": 90
      },
      {
        "topic": "Statistics",
        "averageScore": 72
      }
      // Add more topics and average scores as needed
    ]
  }
  

//export default ReportData



export const topicPerformanceData = {
  
    "topicPerformance": [
      {
        "topic": "Algebra",
        "averageScore": 55,
        "passScore": 50,
        "reportCount": 10
      },
      {
        "topic": "Geometry",
        "averageScore": 45,
        "passScore": 50,
        "reportCount": 12
      },
      {
        "topic": "Trigonometry",
        "averageScore": 48,
        "passScore": 50,
        "reportCount": 8
      },
      {
        "topic": "Calculus",
        "averageScore": 42,
        "passScore": 50,
        "reportCount": 7
      }
      // Add more topics as needed
    ]
}

export const conceptPerformanceData = {
    "conceptPerformance": [
      {
        "topic": "Algebra",
        "concept": "Quadratic Equations",
        "averageScore": 35,
        "passScore": 50,
        "reportCount": 10
      },
      {
        "topic": "Geometry",
        "concept": "Circle Geometry",
        "averageScore": 40,
        "passScore": 50,
        "reportCount": 12
      },
      {
        "topic": "Trigonometry",
        "concept": "Trigonometric Identities",
        "averageScore": 38,
        "passScore": 50,
        "reportCount": 8
      },
      {
        "topic": "Calculus",
        "concept": "Integration Techniques",
        "averageScore": 30,
        "passScore": 50,
        "reportCount": 7
      }
      // Add more concepts as needed
    ]
  }

 export const strugglingStudents = {
  "strugglingStudents": [
    {
      "name": "Alice Johnson",
      "age": 18,
      "passRate": 45,
      "reportCount": 10
    },
    {
      "name": "Bob Smith",
      "age": 17,
      "passRate": 42,
      "reportCount": 12
    },
    {
      "name": "Charlie Brown",
      "age": 19,
      "passRate": 48,
      "reportCount": 8
    },
    {
      "name": "David Lee",
      "age": 16,
      "passRate": 38,
      "reportCount": 7
    }
    // Add more students as needed
  ]
}



export const specificStudentSubjectData = {
  "studentId": "S1001",
  "subject": "Math",
  "averageScores": [
    {
      "reportDate": "2023-09-01",
      "averageScore": 80
    },
    {
      "reportDate": "2023-09-15",
      "averageScore": 75
    },
    {
      "reportDate": "2023-09-30",
      "averageScore": 70
    }
  ],
  "strugglingConcepts": [
    {
      "topic": "Algebra",
      "concept": "Quadratic Equations",
      "passRate": 45,
      "similarStudents": [
        {
          "studentName": "John Smith",
          "passRate": 40
        },
        {
          "studentName": "Emma Johnson",
          "passRate": 42
        },
        {
          "studentName": "Michael Brown",
          "passRate": 38
        }
      ]
    },
    {
      "topic": "Geometry",
      "concept": "Trigonometry",
      "passRate": 48,
      "similarStudents": [
        {
          "studentName": "Olivia Davis",
          "passRate": 50
        },
        {
          "studentName": "Liam Wilson",
          "passRate": 46
        },
        {
          "studentName": "Ava White",
          "passRate": 49
        }
      ]
    }
  ]
}

export const sampleTableData = [
  {
    key: '1',
    name: 'John Brown',
    age: 32,
    address: 'New York No. 1 Lake Park',
  },
  {
    key: '2',
    name: 'Jim Green',
    age: 42,
    address: 'London No. 1 Lake Park',
  },
  {
    key: '3',
    name: 'Joe Black',
    age: 32,
    address: 'Sydney No. 1 Lake Park',
  },
  {
    key: '4',
    name: 'Jim Red',
    age: 32,
    address: 'London No. 2 Lake Park',
  },
];

