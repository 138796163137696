import React,{useState,useEffect} from 'react'
import { Link,useParams,useNavigation,useLocation } from "react-router-dom";
import {Dots} from "react-activity"
import "react-activity/dist/library.css"
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './ClassReportAnalytics.css'

const ClassReportAnalytics = () =>{
  const [loader, setLoader] = useState(false)
    const [classReportList, setClassReportList] = useState([])
    const [studentReportList, setStudentReportList] = useState([])
    const [classStudents, setClassStudents] = useState([])
    const [selectedStudent, setSelectedStudent] = useState(null)
    const [studentsList, setStudentsList] = useState([])
    const [focusStudent, setFocusStudent] = useState({
        _id:'',
        studentId:''
      })

    const classID = useParams()  
    console.log({classId:classID})

    //Use classId to fetch class reports
    const classReports = async() =>{
      setLoader(true)
      console.log(classID)
      //`${global._URL}/schoolreportform/classreportformlist?classId=${classID.id}`
        try {
            await fetch(`${global._URL}/schoolreportform/classreportformlist?classId=${classID.id}`)
               .then((res)=>{
                return res.json()
               })
               .then((result)=>{
                setLoader(false)
                console.log(result)
                if(!result.errorMessage){
                  setClassReportList(result.result)
                }
               })
               .catch((err)=>{
                setLoader(false)
                console.log('Failed to fetch class reports', err)
                const notify = () => {
                  toast.warn("Disconnected, check Internet", {
                    position: "top-center",
                    autoClose:1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
      
                 notify()
               })   
        } catch (error) {
            setLoader(false)
            console.log('Low internet connection failed to fetch reports', error)
        }
    }

    const studentReports = async() =>{
        //Use studentId and classId to find reports with this student
        try {
            await fetch(`${global._URL}/schoolreportform/studentreportformlist?studentId=${selectedStudent}`)
           .then((res)=>{
            return res.json()
           })
           .then((result)=>{
            console.log(result.result)
            if(!result.errorMessage){
              setStudentReportList(result.result)
            }
           })
           .catch((err)=>{
            console.log('Failed to fetch class reports', err)
           })   
        } catch (error) {
            console.log('Low internet connection failed to fetch reports', error)
        }
    }

    useEffect(()=>{
      studentReports()
    },[selectedStudent])

    useEffect(()=>{
       //classReports()
       const fetchClassStudents = async() =>{
        console.log(classID)
        try {
            await fetch(`${global._URL}/students/classstudents?classId=${classID.id}`)
               .then((res)=>{
                return res.json()
               })
               .then((result)=>{
                console.log(result.result)
                setStudentsList(result.result)
               })
               .catch((err)=>{
                console.log('Failed to fetch class reports', err)
               })   
        } catch (error) {
            console.log('Low internet connection failed to fetch reports', error)
        }
    }

    fetchClassStudents()
    classReports()
    },[])

    const handleStudentChange = (focus) => {
        const selectedStudentID = focus.target.value
        console.log(selectedStudentID)
       // const selectedClass = classList.find((classdata)=> classdata.id === selectedClassID)
        setSelectedStudent(selectedStudentID)
        setFocusStudent((prevItem)=>({
          ...prevItem,
          ['studentId']:selectedStudentID
        }))
      }

      const formattedDate = (dateString) =>{
        //const date = new Date(dateString)
        //return date.toLocaleString()
    
        const dateObject = new Date(dateString); 
        const year = dateObject.getFullYear();
        const month = dateObject.getMonth() + 1; // Month is 0-indexed, so we add 1
        const day = dateObject.getDate();
    
       // Format the components as a string (e.g., "03-08-2015")
       const formattedDate = `${day}-${month}-${year}`;
       return formattedDate
      }

    return(
        <div className='classreports_main'>
           <div className='classreport_mainHeader'>
              <p>Class Reports</p>  
            </div>
           {loader ? (
            <div className='loaderContainer'>
             <Dots/>
            </div> 
           ):(
            <>
             <div>
              <Link to={`/classoverallanalytics/${classID.id}`}>
                <p>Overall</p>
              </Link>
             </div>
              <div className='classreport_list'>
            {classReportList.length !== 0 ? (
              <>
                 {classReportList?.map((report)=>(
                <div className='classreport_list-card' key={report._id}>
                    <Link 
                      to={`/classanalytics/${report._id}?reportData=${encodeURIComponent(JSON.stringify({report:report}))}`}
                      style={{textDecoration:'none',color:'#000'}}
                      key={report._id}
                    >
                     <div>
                       <p>{report.reportname}</p>
                       <p>{formattedDate(report.createdAt)}</p>
                     </div>    
                    </Link>
                </div>
             ))}
              </>
            ):(
              <div className='classreport_list-noReport'>
                <p>Class has no report</p>
              </div>
            )} 
           </div>

           
            </>

           )}
           <ToastContainer
             position="top-center"
             autoClose={1500}
             hideProgressBar
             newestOnTop
             closeOnClick
             rtl={false}
             pauseOnFocusLoss
             draggable
             pauseOnHover
             theme="light"
          />
        </div>
    )
}

export default ClassReportAnalytics