import React,{useState} from 'react'
import {Dots,Sentry,Levels ,Bounce,Windmill,Digital} from "react-activity"
import "react-activity/dist/library.css"
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './AddClass.css'

const AddClass = () =>{
    const [classInput, setClassInput] = useState('')
    const [loader, setLoader] = useState(false)
    const [buttonLoader, setButtonLoader] = useState(false)
    const userCreds = JSON.parse(localStorage.getItem('userCreds'))
    const schoolID = userCreds.user.schoolId

    const saveClass = async() =>{
        setButtonLoader(true)
        console.log(classInput,schoolID)
        const options = {
          method:'POST',
          body:JSON.stringify({
            classname:classInput,
            schoolId:schoolID
          }),
          headers:{
            'Content-type':'application/json'
          }
        }
        try {
          await fetch(`${global._URL}/classes/newclass`,options)
            .then((res)=>{
              return res.json()
            })
            .then((result)=>{
              setButtonLoader(false)
              
              console.log(result)
              if(!result.errorMessage){
                setClassInput("")
                const notify = () => {
                  toast.success("New Class Added successfully", {
                    position: "top-center",
                    autoClose:1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
      
                 notify()
              }else{
                const notify = () => {
                  toast.error(`Error, ${result.errorMessage}`, {
                    position: "top-center",
                    autoClose:1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
      
                 notify()
              }
            }).catch((err)=>{
              setClassInput("")
              console.log('Server Error',err)
              setButtonLoader(false)
  
              const notify = () => {
                toast.error("Server Error, try again", {
                  position: "top-center",
                  autoClose:1500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
      
               notify()
            })
        } catch (error) {
          setClassInput("")
          setButtonLoader(false)
          console.log('Failed to save class')
          const notify = () => {
            toast.warn("Disconnected, check Internet", {
              position: "top-center",
              autoClose:1500,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light"
             })
           };
  
           notify()
        }
      }

      const handleClassInput = (e) =>{
        setClassInput(e.target.value)
     }

    return(
        <div className='newClass_container'>
            <div className='newClass_header'>
                <span>New Class Details</span>
            </div>
            <div className='newClassInputs'>
                <div className='classInput_configs'>
                 <input 
                   type="text" 
                   placeholder='class name'
                   value={classInput}
                   onChange={handleClassInput}
                 />
                </div>
                <div className='classInput_button'>
                   {buttonLoader && (<Digital/>)}
                    <button
                      onClick = {()=>saveClass()}
                    >
                        Save
                    </button>
                </div>
            </div>
            <ToastContainer
              position="top-center"
              autoClose={1500}
              hideProgressBar
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
             />
        </div>
    )
}

export default AddClass

