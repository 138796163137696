import React,{useEffect,useState} from 'react'
import {useLocation, useParams } from "react-router-dom";
import {Digital, Dots,Levels } from "react-activity"
import * as xlsx from 'xlsx';
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style';
import "react-activity/dist/library.css"
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './ClassReporting.css'

const ClassReporting = () =>{
    const [classStudentList, setClassStudentList] = useState([])
    const [studentsMarks,setStudentsMarks] = useState([])
    const [previousMarks, setPreviousMarks] = useState([])
    const [reloadPage, setReloadPage] = useState(0)
    const [loader, setLoader] = useState(false)
    const [buttonLoader, setButtonLoader] = useState(false)
    const [studentsExcelMarks, setStudentsExcelMarks] = useState(null)
  
    const location = useLocation()
    const urlParams = new URLSearchParams(location.search)
    const reportDetails = JSON.parse(decodeURIComponent(urlParams.get('reportDetails')))
    //console.log(reportDetails)
    //const studentsList = studentsData.studentsList
    const classID = useParams().id
    //console.log(reportDetails,classID)

    const userCreds = JSON.parse(localStorage.getItem('userCreds'))

    const fetchclassStudents = async() =>{
      setLoader(true)
        try {
          await fetch(`${global._URL}/students/classstudents?classId=${classID}`)
          .then((res)=>{
              return res.json()
          }).then((data)=>{
           //   console.log(data)
              setLoader(false)
              if(data.result.length !== 0){
                setClassStudentList(data.result)
              }
          })
          .catch((err)=>{
            setLoader(false)
              console.log('Server error failed to fetch reports',err)
          })
        } catch (error) {
          setLoader(false)
          console.log('Failed to fetch reports',error)
        }
      }
  
      useEffect(()=>{
         fetchclassStudents()
         getExistingClassMarks()
      },[reloadPage])
    
      const getExistingClassMarks = async() =>{
        const reportId = reportDetails.reportId
        const subjectId = reportDetails.subjectId
       // console.log({reportId,subjectId})
         try {
           await fetch(`${global._URL}/reports/previousclassreport?reportId=${reportId}&subjectId=${subjectId}`)
             .then((res)=>{
              return res.json()
             }).then((result)=>{
            //  console.log(result)
              if(result.result.length !==0){
                const data = result.result
                const marksByStudentId = {}
                data.forEach((mark)=>{
                  marksByStudentId[mark.studentId] = mark.subject_score?.marks
                })
                setPreviousMarks(marksByStudentId)

              }
              if(result.errorMessage){
                const notify = () => {
                  toast.error("Server Error, try again", {
                    position: "top-center",
                    autoClose:3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
        
                 notify()
                }
             }).catch((err)=>{
              console.log('Server Error failed to retrieve reports',err)
              const notify = () => {
                  toast.warn("Check your Internet connection", {
                    position: "top-center",
                    autoClose:3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
        
                 notify()
             })
         } catch (error) {
          console.log('Failed to retrieve class reports',error)
         }
      }
    

      const handleMarksChange = (student, marks) => {
        console.log()

       if(marks === ''){
          setStudentsMarks((prevStudents)=>
            prevStudents.filter((prevStudent)=>prevStudent.studentId !== student._id)
          )
        }else{
          setStudentsMarks((prevStudents)=>[
            ...prevStudents.filter((prevStudent)=> prevStudent.studentId !== student._id),
            {
              reportname:reportDetails.reportName,
              reportType:reportDetails.reportType,
              reportId:reportDetails.reportId,
              studentId:student._id,
              //...student, 
              subject_score:{
                marks:marks
              },
              
              subjectname:reportDetails.subjectname,
              numberOfQuestions:reportDetails.numberOfQuestions,
              subjectId:reportDetails.subjectId,
              signedBy:userCreds.user.userId
            }
          ])
        }
      };
    
    
      
      const handleSaveReport = async() => {
       
       // console.log(studentsMarks)
        setButtonLoader(true)

        //Check student marks value validity
        const checkMarksValidity = studentsMarks.filter((mark)=>{
          return mark.subject_score.marks > reportDetails.numberOfQuestions
        })

        console.log(checkMarksValidity)
        if(checkMarksValidity.length !== 0){
          const notify = () => {
            toast.error(`Marks should not exceed ${reportDetails.numberOfQuestions}, double check and change`, {
              position: "top-center",
              autoClose:3000,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light"
             })
           };
  
           notify()
           setButtonLoader(false)
           return
        }

        const options = {
          method:'POST',
          body:JSON.stringify({
            studentsMarks
          }),
          headers:{
            'Content-type':'application/json'
          }
        }

        
         try {
            await fetch(`${global._URL}/reports/studentreport`,options)
               .then((res)=>{
                return res.json()
               }).then((result)=>{
                console.log(result)
                setButtonLoader(false)
                if(result.successMessage){
                  setReloadPage((prev)=> prev+1)
                  const notify = () => {
                    toast.success("Report saved successfully", {
                      position: "top-center",
                      autoClose:3000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light"
                     })
                   };
          
                   notify()
                }

                if(result.errorMessage){
                  const notify = () => {
                    toast.error("Server Error, check your input and try again", {
                      position: "top-center",
                      autoClose:3000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light"
                     })
                   };
          
                   notify()
                }
               })
               .catch((err)=>{
                setButtonLoader(false)
                console.log('Server error failed to submit report',err)
                const notify = () => {
                  toast.error("Server Error, try again", {
                    position: "top-center",
                    autoClose:3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
        
                 notify()
               })
         } catch (error) {
          setButtonLoader(false)
            console.log('Failed to submit student report',error)
            const notify = () => {
              toast.warn("Disconnected, check Internet", {
                position: "top-center",
                autoClose:3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
                theme: "light"
               })
             };
    
             notify()
         }
    
      };

      const readUploadFile = (e) => {
        e.preventDefault();
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = xlsx.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = xlsx.utils.sheet_to_json(worksheet,{raw:false,dateNF:'yyyy-mm-dd'});
                console.log(json);
                setStudentsExcelMarks(json)
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
    }

    const saveStudentsExcelMarks = async() =>{
     // console.log('Data SaVED',studentsExcelMarks)
      let updatedData;
      if(studentsExcelMarks !== null){
        updatedData = studentsExcelMarks.map(obj =>{
          return {...obj,
                  reportname:reportDetails.reportName,
                  reportType:reportDetails.reportType,
                  reportId:reportDetails.reportId, 
                  subjectname:reportDetails.subjectname,
                  numberOfQuestions:reportDetails.numberOfQuestions,
                  subjectId:reportDetails.subjectId,
                  signedBy:userCreds.user.userId
                  }
        })

        
      }

      //console.log(updatedData)

      setButtonLoader(true)

      const options = {
        method:'POST',
        body:JSON.stringify({
          studentsMarks:updatedData
        }),
        headers:{
          'Content-type':'application/json'
        }
      }

      
       try {
          await fetch(`${global._URL}/reports/studentexcelreport`,options)
             .then((res)=>{
              return res.json()
             }).then((result)=>{
              console.log(result)
              setButtonLoader(false)
              if(result.successMessage){
                setReloadPage((prev)=> prev+1)
                const notify = () => {
                  toast.success("Report saved successfully", {
                    position: "top-center",
                    autoClose:3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
        
                 notify()
              }

              if(result.errorMessage){
                //"Server Error, check your input and try again"
                const notify = () => {
                  toast.error(`Server Error, ${result.errorMessage}`, {
                    position: "top-center",
                    autoClose:3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
        
                 notify()
              }
             })
             .catch((err)=>{
              setButtonLoader(false)
              console.log('Server error failed to submit report',err)
              const notify = () => {
                toast.error("Server Error, try again", {
                  position: "top-center",
                  autoClose:3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
      
               notify()
             })
       } catch (error) {
        setButtonLoader(false)
          console.log('Failed to submit student report',error)
          const notify = () => {
            toast.warn("Disconnected, check Internet", {
              position: "top-center",
              autoClose:3000,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light"
             })
           };
  
           notify()
       }

    }

   // const sortedStudents = [...classStudentList]?.sort((a,b)=> a.studentname.localeCompare(b.studentname))


  
  

    


    const sortedStudents = [...classStudentList]?.sort((a,b)=> {
    if(a.gender === 'Male' && b.gender === 'Female') return -1;
    if(a.gender === 'Female' && b.gender === 'Male') return 1;
    return  a.studentname.localeCompare(b.studentname)
    //return 0;

    })

    const classReportTemplate = sortedStudents.map((student)=>{
       return {
        'RegNo':`${student.IDNumber}`,
          'Name':student.studentname,
          'Marks':previousMarks[student._id] || '',
       } 
    })

  //  console.log({classReportTemplate})

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const fileName = 'studentsMarksTemplate'

    const exportToExcel = async() =>{
      const ws = XLSX.utils.json_to_sheet(classReportTemplate)
      const wb = {Sheets: {'data':ws},SheetNames:['data']}
      const excelBuffer = XLSX.write(wb, {bookType:'xlsx', type:'array'});
      const data = new Blob([excelBuffer],{type:fileType})
      FileSaver.saveAs(data, fileName + fileExtension);
    }


      const renderStudentRows = () =>{
        //const sortedList = [...classStudentList]?.sort((a,b)=> a.studentname.localeCompare(b.studentname))


        //const sortedList = [...classStudentList]?.sort((a,b)=> a.studentname.localeCompare(b.studentname))
        const sortedList = [...classStudentList]?.sort((a,b)=> {
          if(a.gender === 'Male' && b.gender === 'Female') return -1;
          if(a.gender === 'Female' && b.gender === 'Male') return 1;
            return  a.studentname.localeCompare(b.studentname)
          //return 0;
          })
       // console.log({sortedList})
        return sortedList?.map((student,index)=>{
          const previousMark = previousMarks[student._id] || ''

          return (
            <tr key={student._id}>
              <td>{index + 1}</td>
                <td>{student.studentname}</td>
                <td>{student.gender}</td>
                <td>
                  <input
                    type="number"
                    max={100}//{reportDetails?.numberOfQuestions}//{100}
                    //max <= reportDetails.numberOfuestions <---Total Marks
                   // value={previousMark?previousMark.subject_score.marks : ''}
                   //value={previousMark.subject_score.marks}
                    onChange={(e) => handleMarksChange(student, e.target.value)}
                  />
                </td>
                <td>{previousMark}</td>
              </tr>
          )
        
      })
      }
      
      return (
        <div className="report-form-container">
          <h2 className="report-form-title">Report Form</h2>
          {loader ? (
           <div className="loaderContainer">
            < Digital/>
           </div>
           ):(
          <>
          <div className="report-details">
            <label className="subject-label">Exam/Test:</label>
            <span className="subject-value">{reportDetails.reportName}</span>
          </div>
          <div className="report-details">
            <label className="subject-label">Subject:</label>
            <span className="subject-value">{reportDetails.subjectname}</span>
          </div>

          <div className='offlineOptions_container'>
            <div className='downloadTemplate'>
              <button onClick={(e)=>exportToExcel()}>Download Template</button>
            </div>
            <div className='saveTemplate'>
            <form>
              {/*<label htmlFor="upload">Upload File</label>*/}
                <input
                    type="file"
                    name="upload"
                    id="upload"
                    onChange={readUploadFile}
                />
            </form>
             
            <button onClick={saveStudentsExcelMarks}>Save Template</button>
             
            </div>
          </div>

          <div className='reportSave-container'>
            <button className="save-report-btn" onClick={handleSaveReport}>
            Save Report
           </button>
          {buttonLoader && (
            <Dots/>
          )}
          </div>
          
          <table className="report-table">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Student Name</th>
                <th>Gender</th>
                <th>Marks</th>
                {previousMarks?.length !== 0 && (<th>Prev.Marks</th>)}
              </tr>
            </thead>
            <tbody>
              {renderStudentRows()}
            </tbody>
          </table>
          </>
          )}
          <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        </div>
      );
}

export default ClassReporting
