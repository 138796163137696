import React,{useState,useEffect} from "react"
import {Digital, Dots} from "react-activity"
import "react-activity/dist/library.css"
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './SchoolProfile.css'
import logo from '../../assets/fake_logo.png';

import {uploadFile} from "react-s3"
import {Buffer} from "buffer";
Buffer.from("anything", "base64");
window.Buffer = window.Buffer || require("buffer").Buffer

const S3_BUCKET = "asapbusinessdata"
const REGION = "ap-south-1"
const ACCESS_KEY = "AKIAXSWQIX4AYKRZYXEO"
const SECRET_ACCESS_KEY = "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"
const DIR_NAME = "uploads"

const config = {
  bucketName:S3_BUCKET,
  dirName:DIR_NAME,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY
}

const SchoolProfile = () =>{
    const [schoolData, setSchoolData] = useState(null)
    const [schooInfo, setSchooolInfo] = useState(null)
    const [newSchoolLogo, setNewSchoolLogo] = useState(null)
    const [buttonLoader, setButtonLoader] = useState(false)
    const [loader, setLoader] = useState(false)

    const schoolCreds = JSON.parse(localStorage.getItem('userCreds'))

    const schoolId = schoolCreds.user.schoolId

    const fetchSchoolData = async() =>{
        setLoader(true)
        try {
            await fetch(`${global._URL}/schools/schooldetails?schoolId=${schoolId}`)
               .then((res)=>{
                return res.json()
               }).then((result)=>{
                setLoader(false)
                console.log(result)
                if(!result.errorMessage){
                    setSchooolInfo(result.result)
                }else{
                    const notify = () => {
                        toast.error(`Error ${result.errorMessage}`, {
                          position: "top-center",
                          autoClose:3000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light"
                         })
                       };
                
                       notify()
                }
               }).catch((err)=>{
                setLoader(false)
                const notify = () => {
                    toast.error("Server error,try again", {
                      position: "top-center",
                      autoClose:3000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light"
                     })
                   };
            
                   notify()
                console.log('Server error',err)
               })
        } catch (error) {
            setLoader(false)
            console.log('Server error',error)
            const notify = () => {
                toast.warn("Network is down,check internet", {
                  position: "top-center",
                  autoClose:3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
        
               notify()
        }
    }

    useEffect(()=>{
      fetchSchoolData()
    },[])


    const fetchBackUpData = async() =>{
        setButtonLoader(true)
        try {
            await fetch(`${global._URL}/reports/backup-studentdata?schoolId=${schoolId}`)
               .then((res)=>{
                return res.json()
               }).then((result)=>{
                setButtonLoader(false)
                console.log(result)
                if(!result.errorMessage){
                    setSchoolData(result)
                }else{
                    const notify = () => {
                        toast.error(`Error ${result.errorMessage}`, {
                          position: "top-center",
                          autoClose:3000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light"
                         })
                       };
                
                       notify()
                }
               }).catch((err)=>{
                setButtonLoader(false)
                const notify = () => {
                    toast.error("Server error,try again", {
                      position: "top-center",
                      autoClose:3000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light"
                     })
                   };
            
                   notify()
                console.log('Server error',err)
               })
        } catch (error) {
            setButtonLoader(false)
            console.log('Server error',error)
            const notify = () => {
                toast.warn("Network is down,check internet", {
                  position: "top-center",
                  autoClose:3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
        
               notify()
        }
    }

    const downloadSchoolData = () =>{
        if(schoolData){
            const dataStr = JSON.stringify(schoolData, null, 2)
            const blob = new Blob([dataStr],{type:'application/json'})
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.click();
            URL.revokeObjectURL(url)
        }
    }

    const downloadFile = ({data, fileName,fileType}) =>{
        const blob = new Blob([data], {type:fileType})

        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view:window,
            bubbles:true,
            cancelable:true
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const exportToJson = (e) =>{
        e.preventDefault()
        downloadFile({
            data:JSON.stringify(schoolData),
            fileName:'school-data.json',
            fileType:'text/json'
        })
    }

    const [schoolLogo, setSchoolLogo] = useState(null)
    const [logoLoader, setLogoLoader] = useState(false)
    //Logo Functionalities
    const saveSchoolLogo = async(photo) =>{
      
      console.log({
        logo:photo
      })
      const options = {
        method:"PUT",
        body: JSON.stringify({
          schoolLogo:photo
        }),
        headers:{
            "Content-type":"application/json; charset=UTF-8"
        }
    }
    try {
        await fetch(`${global._URL}/schools/update-school/${schoolId}`,options)
         .then((res)=> {return res.json()})
         .then((result)=>{
          setLogoLoader(false)
            console.log(result)
            if(!result.errorMessage){
            const notify = () => {
              toast.success("School Logo saved successfully", {
                position: "top-center",
                autoClose:3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
                theme: "light"
               })
              }
             
      
             notify()

             fetchSchoolData()
            }else{
              const notify = () => {
              toast.error("Internal server error, failed to save", {
                position: "top-center",
                autoClose:3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
                theme: "light"
               })
              }
             
      
             notify()
            }
           })
         .catch((err)=> {
          setLogoLoader(false)
          console.log("Server Error", err)
          const notify = () => {
          toast.error("Failed to save school logo", {
            position: "top-center",
            autoClose:3000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light"
           })
          }
         
  
         notify()
        })
    } catch (error) {
        setLogoLoader(false)
        console.log("Failed to update business menu", error)
    }
    }

    const handleNewSchoolLogo = (focus) => {
      setSchoolLogo(focus.target.files[0])
    };

    const handleS3Upload = async(file) =>{
      setLogoLoader(true)
      uploadFile(file, config)
          .then(data =>{
             // console.log(data)
             const notify = () => {
             toast.success("School Logo uploaded", {
              position: "top-center",
              autoClose:3000,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light"
             })
            }
    
          // notify()
            //return ImgURL
          setSchoolLogo(null)
          const ImgURL = data.location
          console.log({ImgURL: ImgURL})
          return ImgURL
          }).then((res)=>{
            console.log(res)
            const URL = res
            saveSchoolLogo(URL)
          }) .catch((err) => {
            console.error(err)
            const notify = () => {
            toast.error("Failed to upload", {
              position: "top-center",
              autoClose:3000,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light"
             })
            }
  
             notify()
            
           });
    }
      

    return(
        <div className="schoolProfile_container">
           <div className="schoolProfile_header">
            <p>School Profile</p>
           </div>
            {loader ? (
                <div className="loaderContainer">
                  <Dots/>
                </div>
             ):(
              
              <div className="schoolDetails_container">
              {schooInfo !== null ? (
                <div className="schoolProfile_wrapper">
                <div className="schoolProfile_main">
                  <div className="schoolInfo_schoolLogo">
                    <div className="schoolInfo_schoolLogo-changeButton">
                    <input type='file' onChange={handleNewSchoolLogo}/>
                    {schoolLogo ? (
                      <button onClick={()=> handleS3Upload(schoolLogo)}>Save</button>
                    ):null}
                    
                    </div>
                    {logoLoader ? (
                      <div className="loaderContainer">
                       <Digital/>
                      </div>
                    ):(
                      <img src={schooInfo?.schoolLogo }/> 
                    )}
                     
                  </div>  
                   
                  <div className="schoolInfo_container">
                    <p>{schooInfo?.schoolname}</p>
                    <p>{schooInfo?.schoolLocation}</p>
                    <p>{schooInfo?.schoolEmail}</p>
                    <p>{schooInfo?.schoolPhoneNumber}</p>
                  </div>
                   
                {/*<div className="buttonsContainer">
                  <span>Back Up school data</span>
                  <button onClick={fetchBackUpData}>
                    Fetch School Data
                  </button>
  
                  {buttonLoader && <Digital/>}
  
                  {!buttonLoader && schoolData && (
                     <button onClick={exportToJson}>
                       Download Now
                     </button>
                   )}
              
                  </div>*/}
              </div> 
               
              </div>
              ):(
                <div className="noContentMessage">
                    <p>Data Missing, reload page</p>
                </div>
              )}
              
            </div>
              )}
          <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        </div>
    )
}

export default SchoolProfile