import react,{useState,useEffect} from "react"
import { Link, useNavigate } from "react-router-dom";
import {Digital} from "react-activity"
import "react-activity/dist/library.css"
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './SendMessage.css'

const SendMessage = () =>{
    const [selectedClass, setSelectedClass] = useState(null)
    const [loader, setLoader] = useState(false)
    const [buttonLoader, setButtonLoader] = useState(false)
    const [ClassLoader, setClassLoader] = useState(false)
    const [classList, setClassList] = useState([])
    const [selectedStream,setSelectedStream] = useState(null)
    const [messageContent, setMessageContent] = useState('')

    const userCreds = JSON.parse(localStorage.getItem('userCreds'))
    const schoolID = userCreds.user.schoolId

    const navigate = useNavigate()

    const fetchClasses = async(schoolID) =>{
        setClassLoader(true)
          try {
              await fetch(`${global._URL}/classes/schoolclasses?schoolId=${schoolID}`)
                .then((res)=>{
                  return res.json()
                })
                .then((result)=>{
                  setClassLoader(false)
                  //console.log(result)
                  if(!result.message){
                    setClassList(result)
                    
                  }
                  //if(result.length === 1){
                    setSelectedClass(result[0]._id)
                    
                 // }
                  
                })
                .catch((err)=>{
                  setClassLoader(false)
                  console.log('Failed to fetch school classes',err)
                  const notify = () => {
                    toast.warn("Classes not found", {
                      position: "top-center",
                      autoClose:1500,
                      hideProgressBar: true,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light"
                     })
                   };
          
                   notify()
                })
          } catch (error) {
            setClassLoader(false)
              console.log('Failed to get classes',error)
              const notify = () => {
                toast.warn("Disconnected, check Internet", {
                  position: "top-center",
                  autoClose:1500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
      
               notify()
          }
      } 

      useEffect(()=>{
        const userCreds = JSON.parse(localStorage.getItem('userCreds'))
        const schoolID = userCreds.user.schoolId
        console.log(schoolID)
          fetchClasses(schoolID)
        },[])

    const handleClassChange = (focus) => {
        const selectedClassItem = focus.target.value
        //setSelectedClass(selectedClassItem)
        console.log(selectedClassItem)
        
  
        //Set Active Class in select option
         const activeClassChoice = classList.filter((classItem)=>{
          //console.log(student)
          const activeClass = classItem._id === selectedClassItem//classId
          //console.log(activeClass)
          return activeClass
         // return setSelectedClass(activeClass)
        })
  
        //console.log(activeClassChoice)
        setSelectedClass(activeClassChoice[0]._id)
      }

     
      const handleStreamChange = (focus) => {
        const selectedStreamItem = focus.target.value
        setSelectedStream(selectedStreamItem)
        console.log(selectedStreamItem)
      }

      const [activeStreamOptions, setActiveStreamOptions] = useState([])
      useEffect(()=>{
        
       const activeStreamList = classList.filter((classItem)=> {
          return classItem._id === selectedClass
        })

        console.log(activeStreamList[0]?.streams)

       setActiveStreamOptions(activeStreamList[0]?.streams)
      },[selectedClass])

      
      const generateCode = (codeLength) =>{
        const alphaNum = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890abcdefghijklmnopqrstuvwxyz'
        let result = ''
        for(let i=0;i<codeLength;i++){
          result += alphaNum.charAt(Math.floor(Math.random() * alphaNum.length))
        }
        return result;
      }

      const handleSendMessage = async() => {
        const messageCode = generateCode(5)
        console.log({selectedClass,schoolID,messageContent,messageCode})
        setButtonLoader(true)

        const options = {
          method:'POST',
          body:JSON.stringify({
            classId:selectedClass,
            schoolId:schoolID,
            messageContent:messageContent,
            messageCode
          }),
          headers:{
            'Content-type':'application/json'
          }
        }

        
         try {
            await fetch(`${global._URL}/comments/bulk-send`,options)
               .then((res)=>{
                return res.json()
               }).then((result)=>{
                console.log(result)
                setButtonLoader(false)
                if(result.successMessage){
                  const notify = () => {
                    toast.success("Messages sent successfully", {
                      position: "top-center",
                      autoClose:3000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light"
                     })
                   };
          
                   notify()
                }

                if(result.errorMessage){
                  const notify = () => {
                    toast.error("Server Error, check your input and try again", {
                      position: "top-center",
                      autoClose:3000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light"
                     })
                   };
          
                   notify()
                }
               })
               .catch((err)=>{
                setButtonLoader(false)
                console.log('Server error failed to send messages',err)
                const notify = () => {
                  toast.error("Server Error, try again", {
                    position: "top-center",
                    autoClose:3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
        
                 notify()
               })
         } catch (error) {
          setButtonLoader(false)
            console.log('Failed to send messages',error)
            const notify = () => {
              toast.warn("Disconnected, check Internet", {
                position: "top-center",
                autoClose:3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
                theme: "light"
               })
             };
    
             notify()
         }
    
      };

      const MessageInputChange = (e) =>{
        const message = e.target.value
        //console.log(IDNumberData)
        setMessageContent(message)
      }


    return(
        <div className="sendMessage_container">
          <div className="sendMessage-functionalities_container">
          <div className="sendMessage_header">
            <span>Message Portal</span>
          </div>
         
          <div className="sendMessage-classChoice_wrapper">
              <div className="sendMessage-classChoice_wrapper_header">
               <span>Choose class</span>
              </div>  
              {ClassLoader ? (
                <div>
                 <Digital/>
               </div>
               ):(
               <div className="sendMessage-classChoice-select-options"> 
               <select 
                value={selectedClass} 
                onChange={handleClassChange}
               >
              <option  
                  selected 
                  value="null"
                  //disabled
                  key={1}
                  >
                    Select class
                  </option>
              {classList.map((classItem)=>(
                <option key={classItem._id} 
                   value={classItem._id}
                   >
                  {classItem?.classname}
                </option>
              ))}
             </select>
            </div>
              )}
          </div>

          <div className="senMessage-textArea_wrapper">
           <textarea
             type="text"
             placeholder="Write message here.."
             value={`${messageContent}`}
             onChange={MessageInputChange}
           />
          </div>

          <div className="sendMessage-button_group">
            <button onClick={()=>navigate(-1)}>Cancel</button>
            <button onClick={handleSendMessage}>Send</button>

            {buttonLoader ? (<Digital/>):null}


          </div>
        </div>

            
         
         <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
         />
        </div>
    )
}

export default SendMessage
