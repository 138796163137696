const ParentReport = ({studentReportData,reportHeader,studentDetails,subjectsWithLowGrades}) =>{
    //console.log('Form External', studentReportData,reportHeader,studentDetails,subjectsWithLowGrades)
    const styles = {
        

           page: {
            /*//fontFamily: 'Arial',
            backgroundColor:'#F2F2F2',
            */
            //padding: 10,
            //margin:5,
            fontSize: 18,
            width:'835px',
            display:'flex',
            flexDirection:'column',
            //alignItems:'center',
          },
          mainHeader:{
            padding: 40,
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            backgroundColor:'#142664',//'#0F70B7',
            marginBottom:5,
            /*//paddingVertical:'50px',
            width:'100%'*/
            /*padding: 40,
            display:'flex',
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center'*/
           },
           title1:{
            fontSize:27,
            fontWeight:900,
            //fontWeight:'extrabold',
            marginBottom:5,
            color:'#D9C001'
           },
           title2:{
            fontSize:18,
            fontWeight:600,
            marginBottom:10,
            color:'#fff'
           },

           tableIntroWrapper:{
            //border:`1px solid #333`,
            marginBottom:5
           },

           tableIntroContainer:{
            margin:5,
            //border:`1px solid #0F70B7`,
            display:'flex',
            alignItems:'center',
            flexDirection:'row',
            width:'90%',
            padding:11,//`5 15`,
            /*marginBottom:20,
            border:`1px solid #0F70B7`,
            //borderWidth:1,
            //borderColor:'#333',
            display:'flex',
            alignItems:'center',
            flexDirection:'row'*/
           },

           tableSubjectsContainer:{
            marginBottom:'10px',
            //borderWidth:1,
            //borderColor:'#333',
            display:'flex',
            alignItems:'center',
            flexDirection:'column',
            
        
           },
           IntroPart1:{
            flex:1
           },
           IntroPart2:{
            borderWidth:1,
            borderColor:'#333',
            flex:1
           },
           detailPart:{
            display:'flex',
            flexDirection:'row',
            borderBottom:`1px solid #fff`,
            
           },
           detail:{
            flex:1,
            borderWidth:1,
            borderColor:'#333',
            padding:10//`10 15`
           },
           detailHeader:{
            backgroundColor:/*'#5181f8',*/'#0F70B7',
            flex:1,
            color:'#fff',
            padding:10//`10 15`
           },
           IntroTextHeader:{
            fontSize:18,
            color:'#fff',
            fontWeight:600
           },
           IntroTextNormal:{
            fontSize:18,
            color:'#333',
            fontWeight:500
           },

           /*mainTable:{
            borderCollapse: 'collapse',
            width: '100%',
            color: '#333',
           },
           tableSubjectsRow:{
            //margin:'auto',
            justifyContent:'space-between',
            flexDirection:'row',
            //paddingVertical:'5px',
           // paddingHorizontal:'10px',
            alignItems:'center'
            //backgroundColor:'#333',
           },
           */
           mainTableWrapper:{
            //border:`1px solid #333`,
            marginBottom:5,
            //display:'flex',
            //flexDirection:'row',
            //justifyContent:'center',
            //alignItems:'center'
            marginBottom:20
           },
           mainTableContainer:{
             //border:'1px solid #313131',
             display:'flex',
             flexDirection:'column',
             justifyContent:'flex-start',
             padding:10,//`5 15`,
             margin:5,
             width:'90%'
             //margin:20,
             //marginBottom:20
           },
           mainTable:{
            borderCollapse: 'collapse',
            width: '100%',
            color: '#333',
           },
           thead:{
            fontWeight: 600,
            //borderColor:'#ccc',
            //padding: 15,
            //textAlign: 'left',
            backgroundColor:/*'#5181f8',*/'#0F70B7',
            color:'#fff',
          },
          th:{
            //fontWeight: 500,
            //backgroundColor:'#0F70B7',
            padding: 15,
            textAlign: 'left',
            color:'#fff'
          },
          td:{
            fontWeight: 450,
            borderColor:'#ccc',
            padding: 10,
            textAlign: 'left'
          },
          /* tableHeader:{
            // backgroundColor:'#f2f2f2',
             fontSize:14,
             fontWeight:600,
             width:'33.33%',
             borderWidth:1,
             borderColor:'#333',
             //padding:8
            },
            thead:{
              fontWeight: 600,
              borderColor:'#ccc',
              //padding: 15,
              textAlign: 'left',
              backgroundColor:'#0F70B7',
              color:'#fff',
            },
            td:{
              //fontWeight: 450,
              borderColor:'#ccc',
              //padding: 10,
              textAlign: 'left'
            },*/
        
            positionTablesContainer:{
              //border:`1px solid #333`,
              display:'flex',
              flexDirection:'row',
              justifyContent:'center',
              gap:5,
              alignItems:'center',
              //padding:15,
              padding:10,//`5 15`,
              margin:10,
              width:'90%'
            },
        
            
            positionDetailsTableContainer:{
              //border:'1px solid #333',
              backgroundColor:'#cae3f5',//'#3388ff',
              display:'flex',
              flexDirection:'column',
              flex:1.5
            },
            positionDetail:{
              display:'flex',
              flexDirection:'row',
              justifyContent:'space-between',
              alignItems:'center',
              padding:8,
              borderBottom:'1px solid #fff',
              width:'100%',
            },
            positionDetailText:{
              //flex:1.5
              paddingHorizontal:14,
              color:'#333'
            },
            overallTable:{
              //border:'1px solid #333',
              backgroundColor:'#f7edcd',//'#eee8aa',
              //padding:15,
              padding:10,//`5 15`,
              flex:1,
              display:'flex',
              flexDirection:'column',
              alignItems:'flex-start',
              justifyContent:'center',
              gap:10
            },
            overallHeader:{
              padding:5,
              color:'#333'
            },
            overallNumber:{
              padding:5,
              fontSize:20,
              fontWeight:600,
              color:'#142664'
            },

            notesAndGradingContainer:{
              //border:`1px solid #333`,
              display:'flex',
              flexDirection:'row',
              justifyContent:'center',
              gap:5,
              alignItems:'center',
              padding:15,
              //padding:`5 15`,
              margin:10,
              width:'90%'
            },
            studentNotesContainer:{
              //border:'1px solid #333',
              backgroundColor:'#cae3f5',
              flex:2,
              display:'flex',
              flexDirection:'column',
              alignItems:'flex-start',
              justifyContent:'center',
              gap:15,
              padding:15,
            },

            gradingHeader:{
              //padding:`0 5`,
              fontWeight:600
            },
            gradingNumbers:{
              //padding:`0 5`,
              display:'flex',
              flexDirection:'column',
              alignItems:'flex-start',
              justifyContent:'center',
            },
            NotesHeader:{
              //padding:`3 5`,
              fontWeight:600
            },
            NotesDetails:{
              //padding:5,
            },
            schoolDetails:{
              display:'flex',
              justifyContent:'center',
              alignItems:'center',
              gap:10,
              color:'#fff',
              marginBottom:14
            }
            
    }

    /*const logoImage = document.getElementById('logo-image')
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    canvas.width = logoImage.width
    canvas.height = logoImage.height
    ctx.drawImage(logoImage,0,0)
    const logoImageData = canvas.toDataURL('image/png')*/
    
    return(
        <div style={styles.page}>
          <div style={styles.mainHeader}>
              <span style={styles.title1}>{studentDetails?.school}</span>
    {/* <div style={styles.schoolDetails}>
              {studentDetails.schoolDetails.schoolEmail ?(
                <span>Email:{studentDetails.schoolDetails.schoolEmail}</span>
              ):null}

              {studentDetails.schoolDetails.schoolLocation ?(
                <span>Location:{studentDetails.schoolDetaLocation}</span>
              ):null}

              {studentDetails.schoolDetails.schoolPhoneNumber ? (
                <span>Contacts:{studentDetails.schoolDetails.schoolPhoneNumber}</span>
              ):null}
              </div>*/} 
              <span style={styles.title2}>{reportHeader} Report</span> 
           </div> 
           
           <div style={styles.tableIntroWrapper}>
           <div style={styles.tableIntroContainer}>
            <div style={styles.IntroPart1}>
              <div style={styles.detailPart}>
                <div style={styles.detailHeader}>
                  <p style={styles.IntroTextHeader}>Student Name</p>
                </div>
                <div style={styles.detail}>
                  <span style={styles.IntroTextNormal}>{studentDetails?.studentname}</span>
                </div>
              </div>  
              <div style={styles.detailPart}>
              <div style={styles.detailHeader}>
                  <span style={styles.IntroTextHeader}>Reg.No</span>
                </div>
                <div style={styles.detail}>
                  <span style={styles.IntroTextNormal}>{studentDetails?.IDNumber}</span>
                </div>
              </div>
            </div>
            <div style={styles.IntroPart2}>
            <div style={styles.detailPart}>
                <div style={styles.detailHeader}>
                  <span style={styles.IntroTextHeader}>Class</span>
                </div>
                <div style={styles.detail}>
                  <span style={styles.IntroTextNormal}>{studentDetails?.class}</span>
                </div>
             </div>
            <div style={styles.detailPart}>
                <div style={styles.detailHeader}>
                  <span style={styles.IntroTextHeader}>Stream</span>
                </div>
                <div style={styles.detail}>
                  <span style={styles.IntroTextNormal}>{studentDetails?.streamTag}</span>
                </div>
              </div>
            </div>
          </div>
          </div>
         
          <div style={styles.mainTableWrapper}>
         <div style={styles.mainTableContainer}>
          <table style={styles.mainTable}>
                <thead style={styles.thead}>
                 <tr>
                  <th style={styles.td}>Subject</th>
                  <th style={styles.td}>Marks</th>
                  <th style={styles.td}>Grade</th>
                 </tr>
                </thead>
                <tbody>     
                 {studentReportData !== null && Object.entries(studentReportData?.subjectsAnalytics.subjects).map(([subject, performanceData])=>(
                     <tr key={subject}>
                      <td style={styles.td}>{subject}</td>
                      <td style={styles.td}>{performanceData.score}</td>
                      <td style={styles.td}>{performanceData.grade}</td>
                    </tr>
                ))}          
                </tbody>
            </table>
           </div> 
           </div>

           <div style={styles.mainTableWrapper}>
              <div style={styles.positionTablesContainer}>
              <div style={styles.positionDetailsTableContainer}>
                <div style={styles.positionDetail}>
                  <div style={styles.positionDetailText}>
                    <span>Total Marks</span>
                  </div>
                  <div style={styles.positionDetailText}>
                   <span>{studentReportData?.totalMarks}</span>
                  </div>
                </div>
                <div style={styles.positionDetail}>
                  <div style={styles.positionDetailText}>
                    <span>Position</span>
                  </div>
                  <div style={styles.positionDetailText}>
                    <span>{studentReportData?.studentPosition}</span>
                  </div>
                </div>
                <div style={styles.positionDetail}>
                  <div style={styles.positionDetailText}>
                    <span>Number of Students</span>
                  </div>
                  <div style={styles.positionDetailText}>
                   <span>{studentReportData?.totalStudents}</span>
                  </div>
                </div>
              </div>
          

             <div style={styles.overallTable}>
               <div style={styles.overallHeader}>
                 <span>OVERALL GRADE</span>
               </div>
               <div style={styles.overallNumber}>
                 <span>{studentReportData?.averageScore}</span>
               </div>
             </div>
          </div>
         </div>

         <div style={styles.mainTableWrapper}>
          <div style={styles.notesAndGradingContainer}>
            <div style={styles.studentNotesContainer}>
             <div style={styles.NotesHeader}>
               <span>Notes</span>
             </div>
            
            <div style={styles.NotesDetails}>
               {subjectsWithLowGrades?.length !== 0 ? (
                 <p>We appreciate {studentDetails?.studentname}'s positive attitude and dedication. 
                 While {studentDetails.gender === 'Male' ? (`he`):(`she`)} excel in many areas, 
                 there are challenges in the following subjects:{` `}
                 <span>
                   {subjectsWithLowGrades?.map((subject,index)=>(
                     <span>
                       {subject.name} Grade {subject.grade},{` `}
                     </span>
                    ))}
                 </span>{` `}
                 <br></br>
                 <span>Your support in encouraging consistent study habits will greatly benefit {studentDetails.gender === 'Male' ? (`his`):(`her`)} progress. 
                 Thank you for your partnership in {studentDetails.gender === 'Male' ? (`his`):(`her`)} education.</span>
                 </p>
                  ):(
               <p>
                  {studentDetails?.studentname} is making significant progress in all subjects. {studentDetails.gender === 'Male' ? (`His`):(`Her`)} dedication and hard work have paid off, and we encourage {studentDetails.gender === 'Male' ? (`him`):(`her`)} to maintain this momentum. Your support is instrumental in {studentDetails.gender === 'Male' ? (`his`):(`her`)} success.
                  Thank you for your partnership.
               </p>
               )}
              
              </div>
          </div>
          

          <div style={styles.overallTable}>
            <div style={styles.gradingHeader}>
              <p>Grading System :</p>
            </div>
            <div style={styles.gradingNumbers}>
               <span>A {` - `} 41 - 50</span>
               <span>B {` - `} 31 - 40</span>
               <span>C {` - `} 21 - 30</span>
               <span>D {` - `} 11 - 20</span>
               <span>E {` - `} 1 - 10</span>
            </div>
          </div>
          </div>
         </div> 
        </div>
      
      
    )
}

export default ParentReport
