import React,{useState, useEffect} from "react"
import {useParams,useLocation} from "react-router-dom";
import './classAnalytics.css'
import {Dots} from "react-activity"
import "react-activity/dist/library.css"
import * as FileSaver from 'file-saver'
import DatePicker from 'react-datepicker'
import XLSX from 'sheetjs-style'; 
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Line,Bar } from 'react-chartjs-2';
import {
  Chart as Chartjs,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Legend,ArcElement,
  Tooltip,
    } from 'chart.js'

Chartjs.register(
      LineElement,
      PointElement,
      CategoryScale,
      LinearScale,
      BarElement,
      Tooltip,
      Legend,
      ArcElement
)


const ClassAnalytics = () =>{
  const [loader, setLoader] = useState(false)
  const [classOverallData, setClassOverallData] = useState(null)
  const [gradeDistributionData, setGradeDistributionData] = useState(null)
  const [reportsYear, setReportsYear] = useState(null)
  const location = useLocation()
  const [selectedReportIds, setSelectedReportIds] = useState([]);
  const [highlightedRow, setHighlightedRow] = useState(null);


 /* useEffect(()=>{
   console.log("New Report Added")
  },[highlightedRow])*/
  
  
  const classId = useParams().id


 // console.log(classId)

  const fetchClassSubjectsOverallPerformance = async() =>{
    setLoader(true)
    console.log({classId})
    const currentDate = new Date()
    const choosenYear = new Date(reportsYear).getFullYear()
    const listedYear = choosenYear === 1970 ? currentDate.getFullYear() : choosenYear
    try {
      await fetch(`${global._URL}/reports/class-subjectsoverall?classId=${classId}&year=${listedYear}`)
          .then((res)=>{
            return res.json()
          })
          .then((result)=>{
            setLoader(false)
            console.log(result.result)
            
              if(!result.errorMessage){
                if(result.result !== undefined){
                  setClassOverallData(result.result)
                }else{
                  const notify = () => {
                    toast.error("No report retrieved", {
                         position: "top-left",
                         hideProgressBar: true,
                         closeOnClick: true,
                         draggable: true,
                         progress: undefined,
                         theme: "light"
                     })
                   };
            
                   notify()

                   setClassOverallData(null)
                }
                
                //setDocumentReady(true)
              }else{
                const notify = () => {
                  toast.error(`Error, ${result.errorMessage}`, {
                       position: "top-left",
                       hideProgressBar: true,
                       closeOnClick: true,
                       draggable: true,
                       progress: undefined,
                       theme: "light"
                   })
                 };
          
                 notify()
              }
          }) 
          .catch((err)=>{
            setLoader(false)
            console.log('Failed to fetch report data',err)
            const notify = () => {
              toast.error("Internal server error", {
                   position: "top-left",
                   hideProgressBar: true,
                   closeOnClick: true,
                   draggable: true,
                   progress: undefined,
                   theme: "light"
               })
             };
      
             notify()
          })
    } catch (error) {
      setLoader(false)
      console.log('Failed to fetch studet overall performance',error)
      const notify = () => {
        toast.warn("Disconnected, check internet", {
             position: "top-left",
             hideProgressBar: true,
             closeOnClick: true,
             draggable: true,
             progress: undefined,
             theme: "light"
         })
       };

       notify()
    }
  }
    
      useEffect(()=>{
        fetchClassSubjectsOverallPerformance()
      },[reportsYear])

  const handleReportsYearChange = (date) =>{
        console.log(date)
        const fullYear = date.getFullYear()
        console.log(fullYear)
        setReportsYear(date)
  }    

      // Export data to Excel
  const exportToExcel = async () => {
    // Ensure data is available
    if(classOverallData === null) return;

    const flattenedData = [];
   // Collect all subjects from all exams
   const allSubjects = new Set();
   for (const testDate in classOverallData) {
     const testResult = classOverallData[testDate];
     const subjects = Object.keys(testResult);
     subjects.forEach((subject) => allSubjects.add(subject));
   }
 
   // Header row
   const headerRow = ["Examination", ...Array.from(allSubjects)];
   flattenedData.push(headerRow);
 
   // Data rows
   for (const testDate in classOverallData) {
     const testResult = classOverallData[testDate];
     const rowData = [testDate];
 
     allSubjects.forEach((subject) => {
       const subjectData = testResult[subject];
       rowData.push(subjectData ? subjectData.averageMarks : '');
     });
 
     flattenedData.push(rowData);
   }

  // Create Excel workbook
  const wb = XLSX.utils.book_new();

  const ws = XLSX.utils.json_to_sheet(flattenedData);
  
  // Add sheet to workbook
  XLSX.utils.book_append_sheet(wb, ws, 'Exams Performance');

  // Export the workbook to a file
  const fileName = `Overall Class Performance.xlsx`//'grades-report.xlsx';
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
  FileSaver.saveAs(data, fileName);
  };

      let subjects,reportNames,reportIds
      if(classOverallData !== null){
        subjects = Object.keys(classOverallData);
        reportNames = [...new Set(subjects.flatMap(subject => classOverallData[subject].map(report => report.reportname)))];
        reportIds = [...new Set(subjects.flatMap(subject => classOverallData[subject].map(report => {
          return {reportId:report.reportId,reportname:report.reportname}
        })))];
       //console.log({reportIds:reportIds})
      }

      const fetchReportGradeDistribution = async() =>{
        //setLoader(true)
        const options = {
          method:'POST',
          body:JSON.stringify({
            reports:selectedReportIds
          }),
          headers:{'Content-type':'application/json'}
        }
        try {
          await fetch(`${global._URL}/reports/class-subjectgrades`,options)
              .then((res)=>{
                return res.json()
              })
              .then((result)=>{
                //setLoader(false)
                console.log(result)
                
                  if(!result.errorMessage){
                    if(result !== undefined || Object.keys(result).length !== 0){
                      setGradeDistributionData(result)
                    }
                  }else{
                    const notify = () => {
                      toast.error(`Error, ${result.errorMessage}`, {
                           position: "top-left",
                           hideProgressBar: true,
                           closeOnClick: true,
                           draggable: true,
                           progress: undefined,
                           theme: "light"
                       })
                     };
              
                     notify()
                  }
              }) 
              .catch((err)=>{
                //setLoader(false)
                console.log('Failed to fetch report data',err)
                const notify = () => {
                  toast.error("Internal server error", {
                       position: "top-left",
                       hideProgressBar: true,
                       closeOnClick: true,
                       draggable: true,
                       progress: undefined,
                       theme: "light"
                   })
                 };
          
                 notify()
              })
        } catch (error) {
          //setLoader(false)
          console.log('Failed to fetch report grade distribution',error)
          const notify = () => {
            toast.warn("Disconnected, check internet", {
                 position: "top-left",
                 hideProgressBar: true,
                 closeOnClick: true,
                 draggable: true,
                 progress: undefined,
                 theme: "light"
             })
           };
    
           notify()
        }
      }

      useEffect(()=>{
        if(selectedReportIds !== null){
          fetchReportGradeDistribution()
        }
        
      },[selectedReportIds])


      const handleRowClick = (reportId) => {
        //console.log(reportId)

       const reportIdMatch =  reportIds.filter(report => {
        return report.reportname === reportId
      })
       //console.log({reportIdMatch:reportIdMatch[0].reportId})
       const actualReportId = reportIdMatch[0].reportId
        // Toggle selection
        if (selectedReportIds?.includes(actualReportId)) {
          setSelectedReportIds((prevSelectedIds) =>
            prevSelectedIds.filter((id) => id !== actualReportId)
          );
        } else {
          setSelectedReportIds((prevSelectedIds) => [...prevSelectedIds, actualReportId]);
        }
    
        console.log({selectedReportIds})
    
        // Highlight the row
        //setHighlightedRow(reportId);
        setHighlightedRow(selectedReportIds);
      };

      const choiceHighlight = (reportname) =>{
        const reportIdMatch =  reportIds.filter(report => {
          return report.reportname === reportname
        })
        const actualReportId = reportIdMatch[0].reportId

        return highlightedRow?.includes(actualReportId) ? 'selected' : ''
      }

      const colorMapping = {
        "Mathematics": "rgb(255, 99, 132)", // Red
        "Science": "rgb(54, 162, 235)",    // Blue
        "English": "rgb(75, 192, 192)",    // Green
        // Add more subjects and their corresponding colors
      };

      function getColorByIndex(index) {
        const colors = [
          "#ea5545", // Red
          "#f46a9b", // Blue
          "#ef9b20",
          /*"#edbf33"*/"#00FF00",
          "#ede15b",
          "#C2FF33",
          "#bdcf32",
          "#bdcf32",
          "#87bc45",
          "#27aeef",
          
          "#b33dc6"
           // Green
          // Add more colors as needed
        ];
      
        return colors[index % colors.length];
      }

      let lineData, lineOptions
      if(classOverallData !== null){
        const examLabels = classOverallData[Object.keys(classOverallData)[0]].map(exam => exam.reportname);
        const datasets = Object.keys(classOverallData).map((subject,index) => {
          const subjectExamData = classOverallData[subject].map(exam => parseFloat(exam.averageMarks));
          return {
            label: `${subject} Average Marks`,
            data: subjectExamData,
            fill: false,
            borderColor: getColorByIndex(index),//colorMapping[subject] || "rgba(0, 0, 0, 0.2)", // Default color if not specified,
          };
        });
      
        lineData = {
          labels: examLabels,
          datasets: datasets,
        };
      
        lineOptions = {
          scales: {
           /* y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'Average Marks',
              },
              fontColor:'#333'
            },*/
            y:{
              title: {
                display: true,
                text: 'Average Marks',
              },
              grid: {
                  drawBorder: false,
                  color: '#333',
              },
              /*ticks:{
                  beginAtZero: true,
                  color: '#333',
                  fontSize: 12,
              }*/
          },
            x: {
              title: {
                display: true,
                text: 'Exams',
              },
            },
          },
          maintainAspectRatio:false,
          barPercentage: 0.4, 
          categoryPercentage: 0.7
        };
      }

  let BarChartdata, BarChartOptions
  if(gradeDistributionData !== null ){
    console.log({gradeDistributionData})
         // Extract report names from the data
  const examLabels = Object.keys(gradeDistributionData);
  console.log({ examLabels });

  // Extract subject names from the first report
  const subjects = Object.keys(gradeDistributionData[examLabels[0]]);

   // Define an array of colors for each subject
   const colors = [
    "#ea5545", // Red
          "#f46a9b", // Blue
          "#ef9b20",
          /*"#edbf33"*/"#00FF00",
          "#ede15b",
          "#C2FF33",
          "#bdcf32",
          "#bdcf32",
          "#87bc45",
          "#27aeef",
          
          "#b33dc6"
  ];

  // Create datasets dynamically
  const datasets = subjects.map((subject,index) => {
    const data = examLabels.map((report) => gradeDistributionData[report][subject].averageMarks);

    return {
      label: subject,
      backgroundColor: colors[index % colors.length],//'rgba(99, 132, 0, 0.6)',
      borderColor: 'rgba(99, 132, 0, 1)',
      data: data,
    };
  }); 

    BarChartdata = {
      labels: examLabels,
      datasets: datasets,
    };

    BarChartOptions = {
      scales: {y:{
          title: {
            display: true,
            text: 'Average Marks',
          }
      },
        x: {
          title: {
            display: true,
            text: 'Exams',
          },
        },
     },
      maintainAspectRatio: false ,
      barPercentage: 0.5, 
      categoryPercentage: 0.5
    };    
  }

    return(
        <div className="classAnaly_container">
            <div className="classAnaly_header">
              <p>
               Class Subjects Analytics
              </p>
            </div>
            <div className="classAnaly_listedYear">
            <DatePicker
                selected={reportsYear}
                onChange={handleReportsYearChange}
                dateFormat="yyyy"
                showYearPicker
                placeholderText={"Select Year"}
            />
            </div>
            {loader ? (
               <div className="loaderContainer">
                <Dots/>
               </div>
              ) : (
              <div className="classOverall-tableContainer">
               {classOverallData && (
                  <div className='excelExport_wrapper'>
                   <button onClick={exportToExcel}>Export to Excel</button>
                 </div> 
                )}

               {classOverallData !== null ? (
                 <div className="classOverall_visual-container">
                  <table className='main_table'>
                   <thead>
                     <tr>
                       <th>Report Name</th>
                       {subjects.map(subject => (
                         <th key={subject}>{subject}</th>
                       ))}
                     </tr>
                   </thead>
                   <tbody>
                     {reportNames.map(reportname => (
                       <tr 
                         key={reportname}
                         onClick={() => handleRowClick(reportname)}
                         className={choiceHighlight(reportname)}
                       >
                         <td>{reportname}</td>
                         {Object.keys(classOverallData).map(subject => {
                           const report = classOverallData[subject].find(report => report.reportname === reportname);
                           return (
                             <td key={subject}>
                               {report ? report.averageMarks : ''}
                             </td>
                           );
                         })}
                       </tr>
                     ))}
                   </tbody>
                 </table>
                 <div className="visualization_container">
                  <div className="chartHeader">
                    <span>Class Subjects Performance Visualization</span>
                  </div>
                  <div className="classAnalytics_graphContainer">
                 <Line 
                   data={lineData}
                   options={lineOptions}
                   style={
                    {
                      padding:'20px'
                  }
                  }
                  height={"80%"}
                  /> 
                  </div>
                  </div>

                  {gradeDistributionData !== null ? (
                  <div className="visualization_container">
                    <div className="chartHeader">
                      <span>Grade Distribution</span>
                    </div>
                    <div className="classAnalytics_graphContainer">
                     <Bar 
                      style={
                        {
                          padding:'10px'
                      }
                      }
                      height={"100%"}
                      data={BarChartdata} 
                      options={BarChartOptions}
                     />
                    </div>
                    </div>
                    ):null}
                </div>
                ):(
                  <div className="noReport_Container">
                    <span>No Records...</span>
                  </div>  
                )}
               
              
              </div>
            )}
            <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
            </div>
            
    )
}

export default ClassAnalytics