import React, { useEffect, useState} from "react";
import {Digital} from "react-activity"
import "react-activity/dist/library.css"
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './TeacherProfile.css'

const TeacherProfile = () =>{
  const [buttonLoader, setButtonLoader] = useState(false)
  const [changePassword, setChangePassword] = useState(false)
  const [userDetails, setUserDetails] = useState(null)
  const [passwordData, setPasswordData] = useState({
    old_password:'',
    new_password:'',
    new_password_again:''
  })

  const userData = JSON.parse(localStorage.getItem('userCreds'))

  const fetchTeacherDetails = async() =>{
    const teacherId = userData.user.userId
   // console.log(teacherId)
    try {
      await fetch(`${global._URL}/teachers/teacherdetails/${teacherId}`)
         .then((res)=>{
          return res.json()
         })
         .then((result)=>{
           console.log(result)
           setUserDetails(result.result)
         })
         .catch((err)=>{
          console.log("Server Error, try again",err)
         })
     } catch (error) {
      console.log('Failed to get teacher details',error)
     }
  }

  useEffect(()=>{
     fetchTeacherDetails()
  },[])

  const changeTeacherPassword = async() =>{
    setButtonLoader(true)
    /*console.log({
      old_password:passwordData.old_password,
      new_password:passwordData.new_password,
      new_password_again:passwordData.new_password_again
        })*/
    const teacherId = userData.user.userId
    if(passwordData.new_password !== passwordData.new_password_again){
      setButtonLoader(false)
      const notify = () => {
        toast.warn("Passwords do not match", {
          position: "top-center",
          autoClose:3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          theme: "light"
         })
       };

       notify()
      return console.log('Password do not match')
     } 
   
        try {
          
          const options = {
            method:'PUT',
            body:JSON.stringify({
              old_password:passwordData.old_password,
              new_password:passwordData.new_password
            }),
            headers:{
              'Content-type':'application/json'
            }
          }
          await fetch(`${global._URL}/teachers/teacherchangepassword/${teacherId}`,options)
             .then((res)=>{
              return res.json()
             })
             .then((result)=>{
              setButtonLoader(false)
              console.log(result)
              if(!result.errorMessage){
                const notify = () => {
                  toast.success("Password successful changed", {
                    position: "top-center",
                    autoClose:3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
        
                 notify()
              }else{
                const notify = () => {
                  toast.error(`Error ${result.errorMessage}`, {
                    position: "top-center",
                    autoClose:3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
        
                 notify()
              }
              
             })
             .catch((err)=>{
              setButtonLoader(false)
              console.log("Server error fail to update",err)
              const notify = () => {
                toast.error("Failed to change password", {
                  position: "top-center",
                  autoClose:3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
      
               notify()
             })
         }catch(error){
          setButtonLoader(false)
          console.log('Failed to update',error)
          const notify = () => {
            toast.error("Disconnected, failed to change password", {
              position: "top-center",
              autoClose:3000,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light"
             })
           };
  
           notify()
         }
  }

  const handleChangePassword = (focus) =>{
     const {name, value} = focus.target
     console.log({name,value})
     setPasswordData((prevData)=>({
         ...prevData,
         [name]:value
     }))
  }
  
    return(
        <div className="teacherProfile_container">
          <div className="teacherProfile_header">
            <p>Teacher Profile</p>
          </div>
          <div className="accountContainer">
              <p>{userDetails?.teacher_name}</p>
              <p>{userDetails?.roleStatus}</p>
            <div className="changePasswordButton">
              <button onClick={()=>{
                setChangePassword(!changePassword)
               }}>Change Password</button>
            </div>
              
          </div>
          {changePassword&&(
            <div className="configs_container">
              <div className="configs-inputs">
                <input 
                  type="password" 
                  name="old_password"
                  placeholder='old password'
                  value={passwordData.old_password}
                  onChange={handleChangePassword}
                /> 
                <input 
                  type="password"
                  name="new_password" 
                  placeholder='New password'
                  value={passwordData.new_password}
                  onChange={handleChangePassword}
                /> 
                <input 
                  type="password" 
                  placeholder='New password again'
                  name="new_password_again" 
                  value={passwordData.new_password_again}
                  onChange={handleChangePassword}
                />
              </div>
              <div className="buttonContainer">
                <button onClick={changeTeacherPassword}>Save</button>
                {buttonLoader && (<><Digital/></>)}
              </div>
            </div>
          )}
          <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        </div>
    )
}

export default TeacherProfile
