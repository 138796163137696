import React,{useEffect, useState} from 'react'
import {Digital, Dots,Levels} from "react-activity"
import "react-activity/dist/library.css"
import * as xlsx from 'xlsx';
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style';
import { Link,useParams,useNavigation,useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

import './FillExerciseReport.css'
const FillExerciseReport = () =>{
const [formData, setFormData] = useState(/*FakeReportData*/null)
const [questionUpdates, setQuestionUpdates] = useState(null)
const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
const [classStudents, setClassStudents] = useState(null)
const [reportDetails, setReportDetails] = useState(null)
const [questionLoader, setQuestionLoader] = useState(false)
const [studentsLoader, setStudentsLoader] = useState(false)
const [saveLoader,setSaveLoader] = useState(false)
const [studentsExcelMarks, setStudentsExcelMarks] = useState(null)

const reportID = useParams()  
console.log({reportId:reportID})

const location = useLocation()
const urlParams = new URLSearchParams(location.search)
const className = urlParams.get('className')
   
const reportData = JSON.parse(decodeURIComponent(urlParams.get('reportData')))
//console.log(reportData)

//console.log({reportID,questionUpdates})

const fetchReportDetails = async() =>{
 setQuestionLoader(true)
  try {
     await fetch(`${global._URL}/exercisereports/reportdetails?reportId=${reportID.id}`)
        .then((res)=>{
         return res.json()
        })
        .then((result)=>{
          setQuestionLoader(false)
         console.log(result)
         if(!result.errorMessage){
             setReportDetails(result.result)
             setFormData(result.result)
         }else{
           console.log('Error Message',result.errorMessage)
 
            const notify = () => {
                 toast.error(`${result.errorMessage}`, {
                   position: "top-center",
                   autoClose:2000,
                   hideProgressBar: true,
                   closeOnClick: true,
                   draggable: true,
                   progress: undefined,
                   theme: "light"
                  })
                };
     
         notify()
         }
         
        }).catch((err)=>{
          setQuestionLoader(false)
         console.log('Server Error, failed to get report details', err)
         const notify = () => {
                 toast.error("Internal server Error", {
                   position: "top-center",
                   autoClose:2000,
                   hideProgressBar: true,
                   closeOnClick: true,
                   draggable: true,
                   progress: undefined,
                   theme: "light"
                  })
                };
     
         notify()
        })
 } catch (error) {
  setQuestionLoader(false)
     console.log('Server Error, no connection')
 
     const notify = () => {
                 toast.warn("IServer Error, no connection", {
                   position: "top-center",
                   autoClose:2000,
                   hideProgressBar: true,
                   closeOnClick: true,
                   draggable: true,
                   progress: undefined,
                   theme: "light"
                  })
                };
     
         notify()
 }
 }

const fetchClassActiveStudents = async() =>{
  setStudentsLoader(true)
  console.log({
    classId:reportData.classId,
    streamTag:reportData.streamTag
  })
 try {
    await fetch(`${global._URL}/students/classstudents?classId=${reportData.classId}&streamTag=${reportData.streamTag}`)
       .then((res)=>{
        return res.json()
       })
       .then((result)=>{
        setStudentsLoader(false)
        console.log(result)
        if(!result.errorMessage){
            setClassStudents(result.result)
        }else{
          console.log('Error Message',result.errorMessage)

           const notify = () => {
                toast.error(`${result.errorMessage}`, {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
        notify()
        }
        
       }).catch((err)=>{
        setStudentsLoader(false)
        console.log('Server Error, failed to get class students', err)
        const notify = () => {
                toast.error("Internal server Error", {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
        notify()
       })
} catch (error) {
  setStudentsLoader(false)
    console.log('Server Error, no connection')
    const notify = () => {
                toast.warn("IServer Error, no connection", {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
        notify()
}
}

useEffect(()=>{
   fetchClassActiveStudents()
   fetchReportDetails()
},[])

const handleStudentPerformanceChange = (questionIndex, studentId, checked) =>{
  //console.log({questionIndex,studentId,checked})
   const updateReport = {.../*formData*/reportDetails}
   console.log(reportDetails)
   
   //const questionToUpdate = updateReport.questionDetails[questionIndex-1]
   //const questionToUpdate = updateReport.questionDetails[questionIndex]
   const questionToUpdate = updateReport.questionDetails.filter((question)=>{
    return question.questionNumber === questionIndex
   })

   console.log({questionToUpdate})

   if(questionToUpdate[0].studentData.length === 0){
    classStudents.map((student)=>{
      questionToUpdate[0].studentData.push({
        studentId:student._id,
        correctAnswer:true
      })
    })
   }

   const studentDataIndex = questionToUpdate[0].studentData.findIndex(
    (studentData) => studentData.studentId === studentId
   ) 
   //console.log({studentDataIndex})

   if(studentDataIndex === -1){
    questionToUpdate[0].studentData.push({
      studentId,
      correctAnswer:!checked
    })
   }else{
    questionToUpdate[0].studentData[studentDataIndex].correctAnswer = !checked
   }
   //console.log({result:questionToUpdate})
   setQuestionUpdates(questionToUpdate[0])
   //setFormData(updateReport)
}

const handleAllCorrectPerformanceChange = (questionIndex,markAllCorrect) => {
  const updateReport = { .../*formData*/reportDetails };

  //const questionToUpdate = updateReport.questionDetails[questionIndex - 1];

  const questionToUpdate = updateReport.questionDetails.filter((question)=>{
    return question.questionNumber === questionIndex
   })

  if(markAllCorrect){
    console.log('Good')
    // Mark all students as correct for the specific question
    questionToUpdate[0].studentData.forEach((studentData) => {
      studentData.correctAnswer = true;
    });
    console.log({allPassed:questionToUpdate[0]})
  }

  setQuestionUpdates(questionToUpdate[0]);
  // setFormData(updateReport)
};

const moveToNextQuestion = () => {
  const totalQuestions = formData.questionDetails.length
  // Check if there are more questions
  if (currentQuestionIndex < totalQuestions - 1) {
    // Increment the question number to move to the next question
    const nextQuestionNumber = currentQuestionIndex + 1;
    console.log({
      questions:formData.questionDetails,
      totalQuestions,currentQuestionIndex
    })
    setCurrentQuestionIndex(nextQuestionNumber);
  } else {
    console.log('No more questions to move to.');
    const notify = () => {
    toast.success(`Compeleted, No more questions`, {
      position: "top-center",
      autoClose:2000,
      hideProgressBar: true,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
      theme: "light"
     })
    }

     notify()
   };
  }

  const switchToQuestion = (questionIndex) =>{
    console.log({button:questionIndex})
   // console.log(reportDetails)
    questionIndex > 0 ? setCurrentQuestionIndex(questionIndex -1) : setCurrentQuestionIndex(questionIndex)
    console.log({buttonNew:questionIndex})
    //setCurrentQuestionIndex(questionIndex)
  }

const saveStudentRecords = async() =>{
  console.log({
    id:reportID.id,
    quNo:questionUpdates.questionNumber,
    studentData:questionUpdates.studentData
  })

  const option = {
    method:'PUT',
    body:JSON.stringify({studentData:questionUpdates.studentData}),
    headers:{'Content-type':'application/json'}
  }
  try {
    setSaveLoader(true)
      await fetch(`${global._URL}/exercisereports/savestudentperfomances/${reportID.id}/fillquestion/${questionUpdates?.questionNumber}`,option)
         .then((res)=>{
          return res.json()
         })
         .then((result)=>{
          console.log(result)
          setSaveLoader(false)
          if(!result.errorMessage){
              //Other functions
              const notify = () => {
                toast.success(`${result.successMessage}`, {
                  position: "top-center",
                  autoClose:2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
    
            notify()

            moveToNextQuestion()

          }else{
            console.log('Error Message',result.errorMessage)

             const notify = () => {
                  toast.error(`${result.errorMessage}`, {
                    position: "top-center",
                    autoClose:2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
      
          notify()
          }
          
         }).catch((err)=>{
          setSaveLoader(false)
          console.log('Server Error, failed to save student records', err)
          const notify = () => {
                  toast.error("Internal server Error", {
                    position: "top-center",
                    autoClose:2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
      
          notify()
         })
  } catch (error) {
      console.log('Server Error, no connection')
      setSaveLoader(false)
      const notify = () => {
                  toast.warn("IServer Error, no connection", {
                    position: "top-center",
                    autoClose:2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
      
          notify()
  }
}


    const classReportTemplate = classStudents?.map((student)=>{
       return {
        'RegNo':`${student.IDNumber}`,
          'Name':student.studentname,
          'Sign':'',
       } 
    })

   // console.log({classReportTemplate})

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const fileName = 'exerciseReportTemplate'

   /* const exportToExcel = async() =>{
      const ws = XLSX.utils.json_to_sheet(classReportTemplate)
      const wb = {Sheets: {'data':ws},SheetNames:['data']}
      const excelBuffer = XLSX.write(wb, {bookType:'xlsx', type:'array'});
      const data = new Blob([excelBuffer],{type:fileType})
      FileSaver.saveAs(data, fileName + fileExtension);
    }*/

    const exportToExcel = async() =>{
      const ws = XLSX.utils.json_to_sheet(classReportTemplate)
      const wb = XLSX.utils.book_new()
      formData?.questionDetails.map((q)=>{
        XLSX.utils.book_append_sheet(wb, ws, `${q.questionNumber}`)
      })
      
     // XLSX.utils.book_append_sheet(wb, ws, "question 2")
      const excelBuffer = XLSX.write(wb, {bookType:'xlsx', type:'array'});
      const data = new Blob([excelBuffer],{type:fileType})
      FileSaver.saveAs(data, fileName + fileExtension);
    }

    //--------Reading File------------//
    let tempList = []
    const readUploadFile = (e) => {
      e.preventDefault();
      if (e.target.files) {
          const reader = new FileReader();
          reader.onload = (e) => {
              const data = e.target.result;
              const workbook = xlsx.read(data, { type: "array" });
              const sheetName = workbook.SheetNames[0];
              const worksheet = workbook.Sheets[sheetName];
              const json = xlsx.utils.sheet_to_json(worksheet,{raw:false,dateNF:'yyyy-mm-dd'});
              console.log(json,sheetName);

              const studentsData =  workbook.SheetNames.map((q) =>{
                
                const worksheet = workbook.Sheets[q];
                const json = xlsx.utils.sheet_to_json(worksheet,{raw:false,dateNF:'yyyy-mm-dd'});

                const questionIsFilled = json.some(obj => obj.Sign === 'f')

                if(questionIsFilled){
                  for(let i=0; i<json.length; i++){
                  if(json[i].Sign === ''){
                    json[i].Sign = 'p'
                  }
                }
              }
                
                /*console.log({
                  questionNumber:q,
                  data:json
                })*/

                return {
                  questionNumber:q,
                  studentData:json
                }
              })

              console.log({studentsData})
              setStudentsExcelMarks(studentsData)
          };
          reader.readAsArrayBuffer(e.target.files[0]);
      }
  }

  const [saveExcelLoader, setSaveExcelLoader] = useState(false)
  const saveStudentExcelRecords = async() =>{
    console.log({
      id:reportID.id,
      studentsExcelMarks
    })
  
    const option = {
      method:'PUT',
      body:JSON.stringify({studentsExcelMarks}),
      headers:{'Content-type':'application/json'}
    }
    try {
      setSaveExcelLoader(true)
        await fetch(`${global._URL}/exercisereports/savestudentexeclperfomances/${reportID.id}`,option)
           .then((res)=>{
            return res.json()
           })
           .then((result)=>{
            console.log(result)
            setSaveExcelLoader(false)
            if(!result.errorMessage){
                //Other functions
                const notify = () => {
                  toast.success(`${result.successMessage}`, {
                    position: "top-center",
                    autoClose:2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
      
          notify()
            }else{
              console.log('Error Message',result.errorMessage)
  
               const notify = () => {
                    toast.error(`${result.errorMessage}`, {
                      position: "top-center",
                      autoClose:2000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light"
                     })
                   };
        
            notify()
            }
            
           }).catch((err)=>{
            setSaveExcelLoader(false)
            console.log('Server Error, failed to save student records', err)
            const notify = () => {
                    toast.error("Internal server Error", {
                      position: "top-center",
                      autoClose:2000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light"
                     })
                   };
        
            notify()
           })
    } catch (error) {
        console.log('Server Error, no connection')
        setSaveExcelLoader(false)
        const notify = () => {
                    toast.warn("IServer Error, no connection", {
                      position: "top-center",
                      autoClose:2000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light"
                     })
                   };
        
            notify()
    }
  }

const renderStudentsList = (questionIndex) => {
 /* const sortedList = [...classStudents]?.sort(
    (a,b)=> a.studentname.localeCompare(b.studentname))*/
  return (
  <div>
    {classStudents/*sortedList*/?.map((student) => (
        <div 
         className='student_card'
          key={student._id}
        >
          <label>
            <input
              type="checkbox"
              onChange={(e)=> handleStudentPerformanceChange(questionIndex.questionNumber,student._id,e.target.value)}
            />
             {`  `}{student.studentname}
          </label>
        </div>
      ))}
      <div className='allPassed_container'>
      <label>
            <input
              type="checkbox"
              onChange={(e)=> handleAllCorrectPerformanceChange(questionIndex.questionNumber,e.target.value)}
            />
             All Passed
      </label>
      </div>
  </div>
  )
}



//Use formData.questionDetails to get number of questions
//Use the number to move automatically from one quest to another until the end
//At The end Alert Finish line

const sortedQuestions = formData?.questionDetails.slice().sort((a, b) => {
  return parseInt(a.questionNumber) - parseInt(b.questionNumber);
});

//console.log(sortedQuestions);



const renderQuestionButtons = () =>{
  return(
    
        <div className='questionButtons_wrapper'>
        <div className='questionButtons_header'>
          <span>Exercise questions list</span>
        </div>
        {formData !== null ? (
           <div className='questionButtons_questionList'>
           {/*formData?.questionDetails*/sortedQuestions.map((question)=>(
           <button
             key={question.questionNumber}
             onClick={()=> switchToQuestion(question.questionNumber)}
             className={
              question.questionNumber === currentQuestionIndex ? 'activeQuestion':''
            }
           >
             {question.questionNumber}
           </button>
           ))}
           </div>
         ):null}
       
      </div> 
  )
}

const renderQuestionForm = () =>{
  let currentQuestion
  if(formData?.questionDetails){
    currentQuestion = /*formData?.questionDetails*/sortedQuestions[currentQuestionIndex];
  }
  //const currentQuestion = /*formData?.questionDetails*/sortedQuestions[currentQuestionIndex];
  //console.log({reportDetails})
  //console.log({currentQuestion, currentQuestionIndex})
  return(
    <div 
      className='question_wrapper'
      key={currentQuestion?.questionNumber}
    >
      <div className='questionDetails_wrapper'>
        <p>Question: {currentQuestion?.questionNumber}</p>
        <p>Topic: {currentQuestion?.topic}</p>
        <p>Concept: {currentQuestion?.concept}</p>
      </div>
    <div className='studentList_wrapper'>
      {renderStudentsList(/*question.questionNumber*/currentQuestion)}
    </div>
  </div>
  )
  }

    return(
        <div className='mainCompetence_wrapper'>
          <div className='mainCompetence_container'>
           
            {saveExcelLoader ? (
              <div>
                <Digital/>
              </div>
            ):null}
            <div className='mainCompetence_header'>
              <span>Exercise Report Form</span>
            </div>

            <div  className='offlineOptions_container'>
            <div className='downloadTemplate'>
              <button onClick={(e)=>exportToExcel()}>Download Template</button>
            </div>
            <div  className='saveTemplate'>
            <form>
                <input
                    type="file"
                    name="upload"
                    id="upload"
                    onChange={readUploadFile}
                />
            </form>
              <button onClick={saveStudentExcelRecords}>Save Tempate</button>
            </div>
            </div>
            
           <div className='questionButton_container'>
           {questionLoader ? (
            <div className='loaderContainer'>
              <Digital/>
            </div>
              ):(
                <>
                 {renderQuestionButtons()}
                </>
              )}
           </div>
           <div className='questionList_container'>
            {studentsLoader ? (
             <div className='loaderContainer'>
              <Digital/>
             </div>
            ):(
              <>{renderQuestionForm()}</> 
            )}
           </div>
           <div className='mainCompetence_saveButtonContainer'>
            <button
            onClick={() => saveStudentRecords()}
           >
            Save Report
          </button>
            {saveLoader ? (<Digital/>):null}
           </div>
           
          </div>
             <ToastContainer
             position="top-center"
             autoClose={1500}
             hideProgressBar
             newestOnTop
             closeOnClick
             rtl={false}
             pauseOnFocusLoss
             draggable
             pauseOnHover
             theme="light"
            />
         
        </div>
    )
}

export default FillExerciseReport
