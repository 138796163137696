import React,{useEffect, useState} from 'react'
import { Link,useParams,useNavigation,useLocation, Navigate, useNavigate } from "react-router-dom";
import {Digital,Dots} from "react-activity"
import "react-activity/dist/library.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-datepicker/dist/react-datepicker.css'
import "react-toastify/dist/ReactToastify.css";
import './UpdateReport.css'

const UpdateReport = () =>{
    const [selectedRole, setSelectedRole] = useState('');
    const [reportSubjects, setReportSubjects] = useState([])
    const [selectedSubjectItems, setSelectedSubjectItems] = useState([])
    const [focusReport, setFocusReport] = useState({
        reportname:'',
      })

      const reportID= useParams() 

      const subjectList = [
        {id:1, subject:'Mathematics'},
        {id:2, subject:'Arithmetics&Counting'},
        {id:3, subject:'Reading Skills'},
        {id:4, subject:'Writting Skills'},
        {id:5, subject:'Science'},
        {id:6, subject:'English'},
        {id:7, subject:'Kiswahili'},
        {id:8, subject:'C.M.E'},
        {id:9, subject:'S/Studies'},
        {id:10, subject:'French'},
        {id:11, subject:'Arabic'},
        {id:12, subject:'Religion'},
      ]

      const fetchReportDetails = async() =>{
        console.log(reportID.id)
        try {
            await fetch(`${global._URL}/schoolreportform/reportformdetails/${reportID.id}`)
               .then((res)=>{
                return res.json()
               })
               .then((result)=>{
                console.log(result)
                if(!result.errorMessage){
                    //Other functions
                    setFocusReport({
                        reportname:result.result.reportname,
                      })
                    setSelectedRole(result.result.reportType) 
                    setReportSubjects(result.result.subjects) 
                }else{
                  console.log('Error Message',result.errorMessage)
    
                   const notify = () => {
                        toast.error(`${result.errorMessage}`, {
                          position: "top-center",
                          autoClose:2000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light"
                         })
                       };
            
                notify()
                }
                
               }).catch((err)=>{
                console.log('Server Error', err)
                const notify = () => {
                        toast.error("Internal server Error", {
                          position: "top-center",
                          autoClose:2000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light"
                         })
                       };
            
                notify()
               })
        } catch (error) {
            console.log('Server Error, no connection')
    
            const notify = () => {
                        toast.warn("IServer Error, no connection", {
                          position: "top-center",
                          autoClose:2000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light"
                         })
                       };
            
                notify()
        }
    }

    useEffect(()=>{
        fetchReportDetails()
    },[])

    const updateReportDetails = async() =>{
        console.log({
            reportname:focusReport.reportname,
            selectedRole,
            reportSubjects
        })

        const options = {
          method:'PUT',
          body:JSON.stringify({
            reportname:focusReport.reportname,
            reportType:selectedRole,
            subjects:reportSubjects
          }),
          headers:{
            'Content-type':'application/json'
          }
        }

        try {
            await fetch(`${global._URL}/schoolreportform/updateschoolreportform/${reportID.id}`,options)
               .then((res)=>{
                return res.json()
               })
               .then((result)=>{
                console.log(result)
                if(!result.errorMessage){
                    //Other functions
                    const notify = () => {
                      toast.success(`${result.successMessage}`, {
                        position: "top-center",
                        autoClose:2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"
                       })
                     };
          
                   notify()
                }else{
                  console.log('Error Message',result.errorMessage)
    
                   const notify = () => {
                        toast.error(`${result.errorMessage}`, {
                          position: "top-center",
                          autoClose:2000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light"
                         })
                       };
            
                notify()
                }
                
               }).catch((err)=>{
                console.log('Server Error', err)
                const notify = () => {
                        toast.error("Internal server Error", {
                          position: "top-center",
                          autoClose:2000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light"
                         })
                       };
            
                notify()
               })
        } catch (error) {
            console.log('Server Error, no connection')
    
            const notify = () => {
                        toast.warn("IServer Error, no connection", {
                          position: "top-center",
                          autoClose:2000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light"
                         })
                       };
            
                notify()
        }
    }

      const handleInputChange = (e) =>{
        const {name,value} = e.target
        setFocusReport((prevItem)=>({
          ...prevItem,
          [name]:value
        }))
      }

      const handleRoleChange = (e) => {
        setSelectedRole(e.target.value);
      };

      const removeSubject = (subjectId) =>{
        console.log('Clicked')
        const newSubjectList = reportSubjects.filter((subject)=> subject.id !== subjectId )
        console.log(newSubjectList)
        setReportSubjects(newSubjectList)
      }

      const handleSelectedSubject = (item) =>{
        console.log(item)
        //console.log({item,selected:selectedSubjectItems})
        const isSelected = /*selectedSubjectItems*/reportSubjects.find(selected => /*parseInt(selected.id) === item.id*/selected.subject === item.subject)
        console.log(isSelected)
        if(isSelected){
          const updatedItems = /*selectedSubjectItems*/reportSubjects.filter(selected=>selected.subject !== item.subject)
          //setSelectedSubjectItems(updatedItems)
          setReportSubjects(updatedItems)
        }/*else{
          //console.log({saved:item})
         // setSelectedSubjectItems([...selectedSubjectItems, item])
         setReportSubjects([...reportSubjects, item])
        }*/
      }

      const addSubjectOnList = (item) =>{
        console.log(item)
        //console.log({item,selected:selectedSubjectItems})
        const isSelected = /*selectedSubjectItems*/reportSubjects.find(selected => /*parseInt(selected.id) === item.id*/selected.subject === item.subject)
        //console.log(isSelected)
        if(!isSelected){
            setReportSubjects([...reportSubjects, item])
        }
       
      }

    return(
            <div className='reportUpdateDetails_wrapper'>
                <div className='reportUpdate_header'>
                 <span>Update Report Details</span>
              </div> 
             <div className='reportDetails_details'>
             <div className="reportName_container">
               <span>Report Name</span>
               <input 
                  type="text" 
                  placeholder='report name'
                  name="reportname"
                  value={focusReport.reportname}
                  onChange={handleInputChange}
                />
              </div>  
              <div className="Type_container">
                <span>Report Type</span>
               <label> 
               <input
                 type="radio"
                 name="role"
                 value="Special"
                 checked={selectedRole === "Special"}
                 onChange={handleRoleChange}
               />
                Special
              </label>
              <label> 
               <input
                 type="radio"
                 name="role"
                 value="Weekly"
                 checked={selectedRole === "Weekly"}
                 onChange={handleRoleChange}
               />
               Weekly
              </label>
              </div>

              <div className='reportSubjectsList_container'>
                <div className='reportSubjectsList_header'>
                 <span>Associated subjects</span> 
                </div>
               
                {reportSubjects.map((subject)=>(
                    <div 
                      key={subject._id}
                      className="subjectCard"
                    >
                        <span>{subject.subject}</span>
                        <button
                          onClick={()=>{
                            removeSubject(subject.id)
                            //console.log('Clicked')
                          }}
                        >
                            Remove
                        </button>
                    </div>
                ))}
              </div>

              <div className='reportSubjectsChoiceList_container'>
                <div className='reportSubjectsChoiceList_header'>
                 <span>Choose subjects</span> 
                </div>
                {subjectList.map((subject)=>(
                        <div 
                          key={subject.id} 
                          className='subjectCard'>
                          <span>{subject.subject}</span>  
                          <button
                            onClick={()=>/*handleSelectedSubject(subject)*/addSubjectOnList(subject)}
                          >
                            Add
                        </button>
                        {/*<input
                          type="checkbox"
                          checked={selectedSubjectItems.some(selected=>selected.subject === subject.subject)}
                          onChange={()=>handleSelectedSubject(subject)}
                        />
                        <label>{subject.subject}</label>*/}
                      </div>
                 ))}
              </div>
             <div className='updateButton_container'>
             <button
                onClick={updateReportDetails}
              >
                Update
              </button>
             </div>
              
        
             </div>
             <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
           
        </div>
    )
}

export default UpdateReport