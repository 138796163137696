import { Routes, Route, BrowserRouter,createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import { AuthProvider } from "./GlobalStates/GlobalStates";
import "./App.css";
import Sidebar from "./component/SideBar/SideBar";
import FirstPage from "./component/FirstPage/FirstPage";
import Login from "./component/Login/Login";
import Home from "./component/Home/Home";
import StudentProfile from "./component/StudentProfilePage/StudentProfile";
import ParentsPage from "./component/ParentsPage/ParentsPage";
import TeacherPage from "./component/TeacherPage/TeacherPage";
import TeacherDetails from "./component/TeacherDetails/TeacherDetails";
import Report from "./component/Report/Report";
import ClassReportList from "./component/ClassReportList/ClassReportList";
import ClassReporting from "./component/ClassReporting/ClassReporting";
import SpecificAnalytic from "./component/SpecificAnalytic/SpecificAnalytic";
import ClassReportAnalytics from "./component/Analytics/ClassReportAnalytics";
import StudentAnalytics from "./component/StudentAnalytics/StudentAnalytic";
import CBDReport from "./component/CBDReport/CBDReport";
import SubjectAnalytics from "./component/subjectAnalytics/subjectAnalytics";
import ClassAnalytics from "./component/classAnalytics/classAnalytics";
import AddStudent from "./component/AddStudent/AddStudent";
import UpdatedStudentInfo from "./component/UpdateStudentInfo/UpdateStudentInfo";
import AddClass from './component/AddClass/AddClass';
import AddTeacher from './component/AddTeacher/AddTeacher';
import AddReport from './component/AddReport/AddReport';
import AddCAReport from "./component/AddCAReport/AddCAReport";
import ClassDetails from "./component/ClassDetails/ClassDetails";
import ExamResult from "./component/ExamResult/ExamResult";
import UpdateReport from "./component/UpdateReport/UpdateReport";
import UpdateCAReport from "./component/UpdateCAReport/UpdateCAReport";
import AddClassExercise from "./component/AddClassExercise/AddClassExercise";
import UpdateClassExercise from "./component/UpdateClassExercise/UpdateClassExercise";
import FillExerciseReport from "./component/FillExerciseReport/FillExerciseReport";
import StudentCA from "./component/StudentAnalytics/StudentCA/StudentCA";

//Functionalities Pages
import ManageStudents from "./component/subComponents/ManageStudents/ManageStudents";
import ClassList from "./component/subComponents/ClassList/ClassList";
import Analytics from "./component/subComponents/Analytics/Analytics";
import ManageClasses from "./component/subComponents/ManageClasses/ManageClasses";
import ManageTeachers from "./component/subComponents/ManageTeachers/ManageTeachers";
import ManageSchoolReports from "./component/subComponents/ManageSchoolReports/ManageSchoolReports";
import SchoolProfile from "./component/subComponents/SchoolProfile/SchoolProfile";
import TeacherProfile from "./component/subComponents/TeacherProfile/TeacherProfile";
import ManageSubjects from "./component/subComponents/ManageSubjects/ManageSubjects";
import ClassExercises from "./component/subComponents/ClassExercises/ClassExercises";
import MessagePortal from "./component/subComponents/MessagePortal/MessagePortal";
import StudentMessagePortal from "./component/StudentMessagePortal/StudentMessagePortal";
import SendMessage from "./component/subComponents/MessagePortal/SendMessage";
import MessageView from  "./component/subComponents/MessagePortal/MessageView"
import StudentMessageView from './component/StudentMessagePortal/StudentMessageView'
import StudentSendMessage from "./component/StudentMessagePortal/StudentSendMessage";
import ParentMessagingPortal from "./component/ParentMessagingPortal/ParentMessagingPortal";

function App() {
  const local = "http://localhost:5000"
  //const aws = "https://nmjxrmemqf.eu-west-1.awsapprunner.com"
  const aws2 = "https://bhaa2atk3i.us-east-2.awsapprunner.com"
  global._URL = `${aws2}/api`

  const Layout = () => {
      return (
        <div className="main">
          {/*<div className="container">*/}
            <div className="menuContainer">
             <Sidebar />
            </div>
            <div className="contentContainer">
              <Outlet />
            </div>
          {/*</div>*/}
        </div>
      );
    }
  
    const router = createBrowserRouter([
      {
        path: "/",
        element: <FirstPage />,
      },
      {
        path: "/",
        element: <Layout />,
        children: [
          {
            path: "/home",
            element: <Home />,
          },
          {
            path:"/teachersdashboard", 
            element:<TeacherPage/>
          },
          {
            path:"/teacher/:id", 
            element:<TeacherDetails />
          },
          {
            path:"/class",
            element:<ClassList/>
          },  
          

        { path:"/class/:id", element:<ClassReportList />},
        { path:"/report/:id", element:<Report />}, 
        { path:"/studentreport/:id", element:<ClassReporting />}, 
        { path:"/student/:studentId", element:<StudentProfile/>},
        { path:"/analytics/:id", element:<ClassReportAnalytics/>},
        { path:"/classanalytics/:id", element:<SpecificAnalytic/>},
        { path:"/studentanalytics/:id", element:<StudentAnalytics/>},
        { path:"/subjectanalytics/:id", element:<SubjectAnalytics/>},
        { path:"/classoverallanalytics/:id", element:<ClassAnalytics/>},
        { path:"/CBDReport/:id", element:<CBDReport/>},
        { path:"/student/add-student", element:<AddStudent/>},
        { path:"/student/update-student/:id", element:<UpdatedStudentInfo/>},
        { path:"/class/class-details/:id", element:<ClassDetails/>},
        { path:"/class/add-class", element:<AddClass/>},
        { path:"/schoolteachers/add-teacher", element:<AddTeacher/>},
        { path:"/schoolreports/add-report", element:<AddReport/>},
        { path:"/schoolreports/add-CA-report", element:<AddCAReport/>},
        { path:"/report/update/:id", element:<UpdateReport/>},
        { path:"/report/updateCA",element:<UpdateCAReport/>},
        { path:"/exam/results/:id", element:<ExamResult/>},
        { path:"/addclassexercise", element:<AddClassExercise/>},
        { path:"/updateclassexercise/:id", element:<UpdateClassExercise/>},
        { path:"/fillexercisereport/:id", element:<FillExerciseReport/>},
        { path:"/sendmessage/", element:<SendMessage/>},
        { path:"/viewmessage/:id", element:<MessageView/>},
        { path:"/view-studentmessage/:id", element:<StudentMessageView/>},
        { path:"/student-sendmessage/:id", element:<StudentSendMessage/>},
        { path:"/student-message-portal/:id", element:<StudentMessagePortal/>},
        { path:"/CAPage",element:<StudentCA/>},


        { path:"/tools/grade-tracker" ,element:<ClassList/>},
        { path:"/tools/subject-manager" ,element:<ManageSubjects/>},
        { path:"/tools/performance-insights" ,element:<Analytics/>},
        { path:"/tools/class-control" ,element:<ManageClasses/>},
        { path:"/tools/students-manager" ,element:<ManageStudents/>},
        { path:"/tools/schoolteachers" ,element:<ManageTeachers/>},
        { path:"/tools/schoolreports" ,element:<ManageSchoolReports/>},
        { path:"/tools/school-profile" ,element:<SchoolProfile/>},
        { path:"/tools/teacher-profile" ,element:<TeacherProfile/>},
        { path:"/tools/class-quiz" ,element:<ClassExercises/>},
        { path:"/tools/message-portal" ,element:<MessagePortal/>}
        ],
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path:"/parentshome",
        element:<ParentsPage />
      },
      { 
        path:"/parent-message-portal/:id", 
        element:<ParentMessagingPortal/>
      },
    ]);
  
    return <RouterProvider router={router} />;
}

export default App;
