import react,{useState,useEffect} from "react"
import { Link, useNavigate } from "react-router-dom";
import {Dots,Sentry,Levels ,Bounce,Windmill,Digital} from "react-activity"
import "react-activity/dist/library.css"
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './ManageTeachers.css'

import boyKid from '../../assets/boyKid.jpg';
import madamAvatar from '../../assets/madamAvatar.jpg'

const ManageTeachers = () =>{
    const [schoolTeachersList,setSchoolTeachersList] = useState([])
    const [buttonLoader, setButtonLoader] = useState(false)
    const [loader, setLoader] = useState(false)
    const [selectedRole, setSelectedRole] = useState('');
    const [addTeacher, setAddTeacher] = useState(false)
    const [focusTeacher, setFocusTeacher] = useState({
      teachername:'',
    })

    const userCreds = JSON.parse(localStorage.getItem('userCreds'))
    const navigate =  useNavigate()

    const fetchSchoolTeachers = async(schoolId) =>{
      setLoader(true)
        try {
          await fetch(`${global._URL}/teachers/schoolteachers/${schoolId}`)
             .then((res)=>{
              return res.json()
             })
             .then((result)=>{
              setLoader(false)
               console.log(result)
               if(result.result.length === 0){
                const notify = () => {
                  toast.info("No Teacher enrolled yet, add them", {
                    position: "top-center",
                    autoClose:1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
        
                 notify()
               }
               setSchoolTeachersList(result.result)
             })
             .catch((err)=>{
              setLoader(false)
              console.log("Server Error, try again",err)
              const notify = () => {
                toast.warn("Disconnected, check Internet", {
                  position: "top-center",
                  autoClose:1500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
      
               notify()
             })
         } catch (error) {
          setLoader(false)
          const notify = () => {
            toast.warn("Disconnected, check Internet", {
              position: "top-center",
              autoClose:1500,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light"
             })
           };
  
           notify()
          console.log('Failed to get teacher details',error)
         }
      }

      useEffect(()=>{
        const userDetails = JSON.parse(localStorage.getItem('userCreds'))
        console.log(userDetails.user.schoolId)
        fetchSchoolTeachers(userDetails.user.schoolId)
      },[])

      const handleTeacherSave = async() =>{
        setButtonLoader(true)
        console.log({
          schoolId:userCreds.user.schoolId,
          teacher_name:focusTeacher.teachername,
          gender:selectedRole
        })
        try {
          
          const options = {
            method:'POST',
            body:JSON.stringify({
              schoolId:userCreds.user.schoolId,
              teacher_name:focusTeacher.teachername,
              gender:selectedRole
            }),
            headers:{
              'Content-type':'application/json'
            }
          }
          await fetch(`${global._URL}/teachers/newschoolteacher`,options)
             .then((res)=>{
              return res.json()
             })
             .then((result)=>{
              setButtonLoader(false)
               console.log(result)
               if(result.successMessage){
               const notify = () => {
                toast.success(result.successMessage, {
                  position: "top-center",
                  autoClose:1500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
      
               notify()
              }else{
                const notify = () => {
                  toast.error(`Error enrolling new teacher account,${result.errorMessage}`, {
                    position: "top-center",
                    autoClose:1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                   })
                 };
        
                 notify()
              }
               //setSchoolTeachersList(result.result)
             })
             .catch((err)=>{
              setButtonLoader(false)
              console.log("Server Error, try again",err)
              const notify = () => {
                toast.warn("Server Error, try again", {
                  position: "top-center",
                  autoClose:1500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                 })
               };
      
               notify()
             })
        } catch (error) {
          setButtonLoader(false)
          console.log('Error saving teacher', error)
          const notify = () => {
            toast.error('Error enrolling new teacher', {
              position: "top-center",
              autoClose:1500,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light"
             })
           };
  
           notify()
        }
      }

      const handleRoleChange = (e) => {
        setSelectedRole(e.target.value);
      };

      const handleInputChange = (e) =>{
        const {name,value} = e.target
        setFocusTeacher((prevItem)=>({
          ...prevItem,
          [name]:value
        }))
      }

    return(
        <div className='manageTeachers_container'>
           <div className="teachersList-header">
              <p>School Teachers List</p>
            </div> 
           <div className="teachersList-wrapper">
            
            {loader ? (
             <div className="loaderContainer">
               <Dots/>
             </div>
            ):(
            <div className="teachersList-container">
              {schoolTeachersList.map((teacher)=>(
                  <Link 
                    to={`/teacher/${teacher._id}?className=${teacher.className}&teacherData=${encodeURIComponent(JSON.stringify({/*teacher_name:teacher.teacher_name,status:teacher.roleStatus*/teacherDetails:teacher}))}`}
                    style={{textDecoration:'none',color:'#000'}}
                    key={teacher._id}
                  >
                    <div className="teacher-card" key={teacher._id}>
                      <div className="avatarContainer">
                        {teacher.gender === 'Male' ? (
                          <img src={boyKid} />
                        ):(
                          <img src={madamAvatar} />
                        )}
                      </div>
                      <span>{teacher.teacher_name}</span>
                    </div>
                  </Link>
              ))}
            </div>
            )}
           </div>

           <div className="teachers-addButton">
            <button onClick={()=>{
              navigate('/schoolteachers/add-teacher')
            }}>Add New Teacher</button>
           </div>
           
          <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        </div>
    )
}

export default ManageTeachers