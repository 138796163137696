import React,{useState, useEffect} from 'react'
import { Link,useParams,useNavigation,useLocation, Navigate, useNavigate } from "react-router-dom";
import {Digital,Dots} from "react-activity"
import "react-activity/dist/library.css"
import { ToastContainer, toast } from 'react-toastify';
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import "react-toastify/dist/ReactToastify.css";
import './UpdateStudentInfo.css'

const UpdatedStudentInfo = () =>{
    const [updatePage, setUpdatePage] = useState(false)
    const [studentDetails, setStudentDetails] = useState(null)
    const [studentStatus, setSelectedStatus] = useState('')
    const [loader, setLoader] = useState(false)
    const [buttonLoader, setButtonLoader] = useState(false)
    const [DepartYear,setDepartYear]=  useState(null)
    const [classList, setClassList] = useState([])
    const [focusStudent, setFocusStudent] = useState({
        _id:'',
        studentname:'',
        studentId:'',
        classId:'',
        student_id:null,
        student_examNo:''
      })
      const [selectedRole, setSelectedRole] = useState('');
      const [dob, setDob] = useState(null)
     const [gender, setGender] = useState(['Male','Female'])
    const [studentInput, setStudentInput] = useState('')
    //const [classInput, setClassInput] = useState('')
    const [selectedClass, setSelectedClass] = useState(null)  

    const studentID= useParams()  

    const userCreds = JSON.parse(localStorage.getItem('userCreds'))
    const schoolID = userCreds.user.schoolId

    const fetchClasses = async(schoolID) =>{
        setLoader(true)
          try {
              await fetch(`${global._URL}/classes/schoolclasses?schoolId=${schoolID}`)
                .then((res)=>{
                  return res.json()
                })
                .then((result)=>{
                  setLoader(false)
                  //console.log(result)
                  if(!result.message){
                    setClassList(result)
                    
                  }
                  //if(result.length === 1){
                    setSelectedClass(result[0]._id)
                    setFocusStudent((prevItem)=>({
                      ...prevItem,
                      ['classId']:result[0]._id
                    }))
                 // }
                  
                })
                .catch((err)=>{
                  setLoader(false)
                  console.log('Failed to fetch school classes',err)
                  const notify = () => {
                    toast.warn("Disconnected, check Internet", {
                      position: "top-center",
                      autoClose:1500,
                      hideProgressBar: true,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light"
                     })
                   };
          
                   notify()
                })
          } catch (error) {
            setLoader(false)
              console.log('Failed to get classes',error)
          }
      } 

    const fetchStudentInfo = async() =>{
        try {
            await fetch(`${global._URL}/students/studentdetails/${studentID.id}`)
               .then((res)=>{
                return res.json()
               })
               .then((result)=>{
                console.log(result)
                if(!result.errorMessage){
                    setStudentDetails(result.result)
                }
               })
               .catch((err)=>{
                console.log('Server Error', err)
               })
            
        } catch (error) {
            console.log('Disconnected, check Internet',error)
        }
    }

    const [reloadPage, setReloadPage] = useState(false)
    useEffect(()=>{
       const userCreds = JSON.parse(localStorage.getItem('userCreds'))
       const schoolID = userCreds.user.schoolId
       //console.log(schoolID)
       fetchClasses(schoolID)
       fetchStudentInfo()
    },[reloadPage])

    const updateStudent = async () =>{
        setButtonLoader(true)
        console.log("Here")
        //console.log(selectedClass)
        /*console.log({
          studentId:focusStudent.studentId,
          studentname:focusStudent.studentname,
          classId:selectedClass,//focusStudent.classId,
          streamTag:selectedStream,
          schoolId:schoolID,
          gender:selectedRole,
          DOB:dob,
          IDNumber:focusStudent.student_id,
          status:studentStatus,
          departedYear:DepartYear,
          ExamNumber:focusStudent.student_examNo,
        })*/
  
        const options = {
          method:'PUT',
          body:JSON.stringify({
            studentname:focusStudent.studentname,
            classId:selectedClass,//focusStudent.classId,
            streamTag:selectedStream,
            schoolId:schoolID,
            gender:selectedRole,
            DOB:dob,
            IDNumber:focusStudent.student_id,
            ExamNumber:focusStudent.student_examNo,
            status:studentStatus,
            departedYear:DepartYear
          }),
          headers:{
            'Content-type':'application/json'
          }
        }
                
       try {
          await fetch(`${global._URL}/students/updatestudentinfo/${focusStudent.studentId}`,options)
            .then((res)=>{
              return res.json()
            })
            .then((result)=>{
              console.log({result})
              setButtonLoader(false)
              if(!result.errorMessage){
                setReloadPage(true)
                setUpdatePage(false)
                setFocusStudent({
                  _id:'',
                studentname:'',
                studentId:'',
                classId:'',
                student_id:''
                })
                setDob(null)
  
                setStudentInput("")
                
                 //Notification
                const notify = () => {
                toast.success("Student Info Updated !!", {
                     position: "top-left",
                     hideProgressBar: true,
                     closeOnClick: true,
                     draggable: true,
                     progress: undefined,
                     theme: "dark"
                 })
               };
               notify()
              }else{
                const notify = () => {
                  toast.error(`Error, ${result.errorMessage}`, {
                       position: "top-left",
                       hideProgressBar: true,
                       closeOnClick: true,
                       draggable: true,
                       progress: undefined,
                       theme: "dark"
                   })
                 };
      
                 notify()
              }
            })
            .catch((err)=>{
              setStudentInput("")
              setButtonLoader(false)
              console.log('Failed to update student',err)
            })
        } catch (error) {
          setStudentInput("")
          setButtonLoader(false)
          console.log('Failed to update student')
        }
      }

    const formattedDate = (dateString) =>{
        //const date = new Date(dateString)
        //return date.toLocaleString()
        if(!dateString) return;
    
        const dateObject = new Date(dateString); 
        const year = dateObject.getFullYear();
        const month = dateObject.getMonth() + 1; // Month is 0-indexed, so we add 1
        const day = dateObject.getDate();
    
       // Format the components as a string (e.g., "03-08-2015")
       const formattedDate = `${day}-${month}-${year}`;
       return formattedDate
      }

      const formattedYearDate = (dateString) =>{
        //const date = new Date(dateString)
        //return date.toLocaleString()
        if(!dateString) return;
    
        const dateObject = new Date(dateString); 
        const year = dateObject.getFullYear();
        const month = dateObject.getMonth() + 1; // Month is 0-indexed, so we add 1
        const day = dateObject.getDate();
    
       // Format the components as a string (e.g., "03-08-2015")
       const formattedDate = `${year}`;
       return formattedDate
      }

      const handleFocusStudent = (studentdata) =>{
        console.log(studentdata)
        setFocusStudent({
          studentId:studentdata.studentId,
          studentname:studentdata.studentname,
          classId:studentdata.classId,
          schoolId:studentdata.schoolId,
          student_id:studentdata.IDNumber,
        })
  
        setStudentInput(studentdata.studentname)
        setSelectedRole(studentdata.gender)
        setSelectedClass(studentdata.classId)
        setSelectedStatus(studentdata.status)
  
        const dobDate = new Date(studentdata.DOB)
        const dateOutputFormat = new Intl.DateTimeFormat('en-US', {
           month:'2-digit',
           day:'2-digit',
           year:'numeric'
        })
        //console.log(dateOutputFormat.format(dobDate))
        if(studentdata?.DOB){
           const dateInMills = new Date(Date.parse(studentdata?.DOB))
        console.log(dateInMills)
        setDob(dateInMills) 
        }

        if(studentdata?.departedYear){
          const dateInMills = new Date(Date.parse(studentdata?.departedYear))
       console.log(dateInMills)
       setDepartYear(dateInMills) 
       }
        
      }

      const handleInputChange = (e) =>{
        const {name,value} = e.target
        setFocusStudent((prevItem)=>({
          ...prevItem,
          [name]:value
        }))
      }
  
      const handleClassChange = (focus) => {
        const selectedClassItem = focus.target.value
        setSelectedClass(selectedClassItem)
        console.log(selectedClassItem)
      }

      const [selectedStream,setSelectedStream] = useState(null)
      const handleStreamChange = (focus) => {
        const selectedStreamItem = focus.target.value
        setSelectedStream(selectedStreamItem)
        console.log(selectedStreamItem)
      }
  
      const handleRoleChange = (e) => {
        setSelectedRole(e.target.value);
      };

      const handleStatusChange = (e) => {
        setSelectedStatus(e.target.value);
      };
  
      const handleDateChange = (date) =>{
        setDob(date)
      }

      const handleDepartDateChange = (date) =>{
        setDepartYear(date)
      }

      const [activeStreamOptions, setActiveStreamOptions] = useState([])
      useEffect(()=>{
        
       const activeStreamList = classList.filter((classItem)=> {
          return classItem._id === selectedClass
        })

        console.log(activeStreamList[0]?.streams)

       setActiveStreamOptions(activeStreamList[0]?.streams)
      },[selectedClass])

    return(
        <div className='StudentDetails_container'>
            {!updatePage ? (
            <div className='studentDetails_wrapper'>
               <div className='studentUpdate_header'>
                <span>Student Details</span>
               </div> 
            {studentDetails ? (
              <>
              <div className='studentDetails_details'>
              <span>{studentDetails?.studentname}</span>
              <span>{studentDetails?.class}</span>
              {studentDetails.streamTag ? (
                <span>Stream: {studentDetails?.streamTag}</span>
                ):(
                 <span>Stream: Unassigned</span>
              )} 
              
              {studentDetails.IDNumber !== '' ? (
                 <span>
                  Reg.No: <span>{studentDetails?.IDNumber}</span>
                 </span>
                 ):null
              }

              {studentDetails.ExamNumber !== '' ? (
                 <span>
                  Exam.No: <span>{studentDetails?.ExamNumber}</span>
                 </span>
                 ):null
              }
              
              <span>{studentDetails?.gender}</span>
              <span>DOB: {formattedDate(studentDetails?.DOB)}</span>
              <span>Enrolled Year: {formattedYearDate(studentDetails?.enrolledYear)}</span>
              <span>Status: {studentDetails?.status}</span>
              {studentDetails.departedYear ? (
                 <span>
                  Departed Year: {formattedYearDate(studentDetails?.departedYear)}
                 </span>
                 ):null
              }
              
            </div>
            <div className="academicData_link">
              <Link to={`/studentanalytics/${studentID.id}?studentData=${encodeURIComponent(JSON.stringify({classId:studentDetails.classId}))}`}>
                <span>Academic records</span>
              </Link>
            </div>

            <div className="academicData_link">
              <Link to={`/student-message-portal/${studentID.id}`}>
                <span>Messages</span>
              </Link>
            </div>
            </>
            ):(<div className='loaderContainer'>
                 <Dots/>
                </div>
            )}   
            
            <div className='studentDetails_buttons'>
                <button onClick={()=>{
                    setUpdatePage(true)
                    handleFocusStudent(studentDetails)
                }}>
                    Update
                </button>
            </div>
          </div>
            ):(
             <div className='studentUpdateDetails_wrapper'>
                <div className='studentUpdate_header'>
                 <span>Update Student Details</span>
              </div> 
             <div className='studentDetails_details'>
               <input 
                  type="text" 
                  placeholder='student name'
                  name="studentname"
                  value={focusStudent.studentname}
                  onChange={handleInputChange}
                />
               <div className="studentGender_container">
                <span>Gender</span>
               <label> 
               <input
                 type="radio"
                 name="role"
                 value="Male"
                 checked={selectedRole === "Male"}
                 onChange={handleRoleChange}
               />
                Male
              </label>
              <label> 
               <input
                 type="radio"
                 name="role"
                 value="Female"
                 checked={selectedRole === "Female"}
                 onChange={handleRoleChange}
               />
               Female
              </label>
              </div>
              <div className="studentStatus_container">
                <span>Student Status</span>

               <label> 
               <input
                 type="radio"
                 name="studentStatus"
                 value="Active"
                 checked={studentStatus === "Active"}
                 onChange={handleStatusChange}
               />
                Active
              </label>
              <label> 
               <input
                 type="radio"
                 name="studentStatus"
                 value="Left"
                 checked={studentStatus === "Left"}
                 onChange={handleStatusChange}
               />
               Left/Inactive
              </label>
              <label> 
               <input
                 type="radio"
                 name="studentStatus"
                 value="Graduate"
                 checked={studentStatus === "Graduate"}
                 onChange={handleStatusChange}
               />
               Graduate
              </label>
            </div>
              <div className='DOB_container'>
              <span>Date Of birth</span>
              <DatePicker
                selected={dob}
                onChange={handleDateChange}
                dateFormat="MM/dd/yyyy"
                placeholderText={"Date of birth"}
              />
              </div>
              {studentStatus === 'Graduate' || studentStatus === 'Left' ? (
                <div className='Depart_container'>
                <span>Depart year</span>
                 <DatePicker
                 selected={DepartYear}
                 onChange={handleDepartDateChange}
                 showYearPicker
                 dateFormat="yyyy"
                 placeholderText={"Leaving/Graduation year"}
                />
                </div>
              ):null}

              <div className='RegID_container'>
                <span>Registration No.</span>
             <input type="number" 
               placeholder="ID Number"
               name="student_id"
               value={focusStudent.student_id}
               //onChange={(e)=>handleStudentChange(e)}
               onChange={handleInputChange}
             />
              </div>
              <div>
              <input type="text" 
               placeholder="Exam No(Candidate)"
               name="student_examNo"
               value={focusStudent.student_examNo}
               onChange={handleInputChange}
               />
              </div>
            
            <select 
              value={selectedClass} 
              onChange={handleClassChange}
              >
                <option disabled>Classes</option>
              {classList.map((classItem)=>(
                <option key={classItem._id} 
                   value={classItem._id}
                   >
                  {classItem?.classname}
                </option>
              ))}
            </select>

            <select 
              value={selectedStream} 
              onChange={handleStreamChange}
              >
                <option disabled>Streams</option>
              {activeStreamOptions?.map((streamItem,index)=>(
                <option key={index} 
                   value={streamItem.streamTag}
                   >
                  {streamItem?.streamTag}
                </option>
              ))}
            </select>
             </div>
             <div className='studentDetails_buttons'>
                 <button onClick={updateStudent}>
                    Save
                </button>
                {buttonLoader ? (<Digital/>):null}
             </div>
             <div className='closeButton'>
                <button onClick={()=>{setUpdatePage(false)}}>close</button>
             </div>
           </div>
            )}
            <ToastContainer
             position="top-center"
             autoClose={1500}
             hideProgressBar
             newestOnTop
             closeOnClick
             rtl={false}
             pauseOnFocusLoss
             draggable
             pauseOnHover
             theme="light"
            />
        </div>
    )
}

export default UpdatedStudentInfo

