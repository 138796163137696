import React, { useState,useEffect } from 'react';
import {
    FaTh,
    FaBars,
    FaUserAlt,
    FaRegChartBar,
    FaCommentAlt,
    FaShoppingBag,
    FaThList,
    FaBrain,
    FaBuffer,
    FaChartArea,
    FaEnvelope,
    FaChild,
    FaClipboardList,
    FaBookReader,
    FaCheckCircle,
    FaCalendarCheck,
    FaDotCircle,
    FaCircleNotch,
    FaBackward,
    FaDoorClosed
}from "react-icons/fa";
import { NavLink} from 'react-router-dom';
import logo from '../assets/fake_logo.png';


const Sidebar = ({children}) => {
    const[isOpenMenu ,setIsOpenMenu] = useState(false);
    const[isOpen ,setIsOpen] = useState(false);
    const [activeLinks, setActiveLinks] = useState(false)
    const [reload, setReload] = useState(0)
    //const toggle = () => setIsOpen (!isOpen);
    const toggleMenu = () => setIsOpenMenu (!isOpenMenu);
    const shouldShowSideBar = !['/login','/'].includes(window.location.pathname)
    const userLogged = JSON.parse(localStorage.getItem('userCreds'))
    
    const reLoadSideBar = () =>{
      setReload((prev)=> prev + 1)
    }

    useEffect(()=>{
        const isUser = JSON.parse(localStorage.getItem('userCreds'))
        setActiveLinks(!!isUser)
        console.log("Reload",reload)
    },[reload])

    const menuItem=[
        {
            path:-1,
            name:"Back",
            icon:<FaBackward/>
        },
        {
            path:"/home",
            name:"Home",
            icon:<FaTh/>
        },
        {
            path:"/tools/grade-tracker",
            name:"Grade Tracker",
            icon:<FaCalendarCheck/>
        },
        {
            path:"/tools/students-manager",
            name:"Students",
            icon:<FaBookReader/>
        },
        /*{
            path:"/tools/subject-manager",
            name:"Subjects",
            icon:<FaCommentAlt/>
        },*/
        {
            path:"/tools/performance-insights",
            name:"Analytics",
            icon:<FaChartArea/>
        },
        {
            path:"/tools/class-control",
            name:"Classes",
            icon:<FaClipboardList/>
        },
        {
            path:"/tools/schoolteachers",
            name:"Educators",
            icon:<FaUserAlt/>
        },
        {
            path:"/tools/schoolreports",
            name:"Reports",
            icon:<FaBuffer/>
        },
        {
            path:"/tools/class-quiz",
            name:"Competence",
            icon:<FaBrain/>
        },
        {
            path:"/tools/message-portal",
            name:"Messages",
            icon:<FaEnvelope/>
        },
        
    ]

    return (
        <div className="container">
           <div style={{width: isOpen ? "200px" : "50px"}} className="sidebar">
               <div className="top_section">
                   {/*<h1 style={{display: isOpen ? "block" : "none"}} className="sidebar_logo">Logo</h1>*/}
                   <div className='sidebar_logo'>
                    <img style={{display: isOpen ? "block" : "none"}} className="sidebar_logo" src={logo} alt="Company Logo" />
                   </div>
                   {isOpenMenu ? (
                     <div style={{marginLeft: isOpen ? "50px" : "0px"}} className="bars">
                      <FaBars onClick={toggleMenu}/>
                     </div>
                   ):(
                    <div style={{marginLeft: isOpen ? "50px" : "0px"}} className="bars">
                       <FaDoorClosed onClick={toggleMenu}/>
                   </div>
                   )}
                   
               </div>

               {/*<div className="refreshButton">
               <div style={{marginLeft: isOpen ? "50px" : "0px"}} className="bars">
                    <FaCircleNotch onClick={reLoadSideBar}/>
               </div>
              </div> */}

              {isOpenMenu ? (
                   <div className={isOpenMenu ? 'down_section' : ''}>
                     {
                      menuItem.map((item, index)=>(
                       <NavLink 
                          to={item.path} 
                          key={index} 
                          className="link" 
                          activeclassName="active"
                          disabled={true}
                          //disabled={!activeLinks}
                        >
                           <div className="icon">{item.icon}</div>
                           <div style={{display: isOpen ? "block" : "none"}} className="link_text">{item.name}</div>
                       </NavLink>
                      ))
                     }
                   </div>
                 ):null}
               
           </div>
           <main>{children}</main>
        </div>
    
    );
};

export default Sidebar;