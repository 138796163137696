import react,{useState,useEffect} from "react"
import { Link,useParams,useNavigate,useLocation } from "react-router-dom";
import {Dots,Sentry,Levels ,Bounce,Windmill,Digital} from "react-activity"
import "react-activity/dist/library.css"
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './StudentMessageView.css'

const StudentMessageView = () =>{

    const navigate = useNavigate()
    const location = useLocation()
    const urlParams = new URLSearchParams(location.search)
    const messageInfo = JSON.parse(decodeURIComponent(urlParams.get('messageInfo')))
    const messageId = useParams().id
    console.log({messageInfo})

    const formattedDate = (dateString) =>{
      //const date = new Date(dateString)
      //return date.toLocaleString()
  
      const dateObject = new Date(dateString); 
      const year = dateObject.getFullYear();
      const month = dateObject.getMonth() + 1; // Month is 0-indexed, so we add 1
      const day = dateObject.getDate();
  
     // Format the components as a string (e.g., "03-08-2015")
     const formattedDate = `${day} / ${month} / ${year}`;
     return formattedDate
    }

    return(
      <div className="studentmessageView_container">
      <div className="studentmessageView-functionalities_container">
      <div className="studentmessageView_header">
        <span>Message Portal</span>
      </div>
      <div className="studentmessageView-message_wrapper">
       <div className="studentmessageView-message_container">
        <span>{messageInfo.messageDetails.comment}</span>
       </div>
       <div className="studentmessageView-message_date">
         <span>sent {formattedDate(messageInfo.messageDetails.createdAt)}</span>
       </div> 
       
      </div>

      <div className="sendMessage-button_group">
        <button onClick={()=>navigate(-1)}>Back</button>
      </div>
    </div>

            
         
         <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
         />
        </div>
    )
}

export default StudentMessageView