import react,{useState,useEffect} from "react"
import { Link, useNavigate,useParams } from "react-router-dom";
import {Digital} from "react-activity"
import "react-activity/dist/library.css"
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './ParentMessagingPortal.css'
import { truncateString } from "../../utils/truncateString";

const ParentMessagingPortal = () =>{
    const navigate =  useNavigate()
    const [messages, setMessages] = useState(null)
    const [Loader, setLoader] = useState(false)

    const studentId = useParams().id
  
    const fetchStudentMessages = async() =>{
      console.log({studentId})
      setLoader(true)
      try {
        await fetch(`${global._URL}/comments/student-messages/${studentId}`)
            .then((res)=>{
              return res.json()
            })
            .then((result)=>{
              setLoader(false)
              console.log(result)
              
                if(!result.errorMessage){
                  setMessages(result.studentMessageList)
                }else{
                  console.log('Error fetching student messages')
                  const notify = () => {
                    toast.warn(`Error, ${result.errorMessage}`, {
                         position: "top-left",
                         hideProgressBar: true,
                         closeOnClick: true,
                         draggable: true,
                         progress: undefined,
                         theme: "light"
                     })
                   };
        
                   notify()
                }
            }) 
            .catch((err)=>{
              setLoader(false)
              console.log('Failed to fetch messages',err)
              const notify = () => {
                toast.warn("Disconnected, check Internet", {
                     position: "top-left",
                     hideProgressBar: true,
                     closeOnClick: true,
                     draggable: true,
                     progress: undefined,
                     theme: "light"
                 })
               };
    
               notify()
            })
      } catch (error) {
       setLoader(false)
        console.log('Failed to fetch student messages',error)
        const notify = () => {
          toast.warn("Disconnected, check internet", {
               position: "top-left",
               hideProgressBar: true,
               closeOnClick: true,
               draggable: true,
               progress: undefined,
               theme: "light"
           })
         };
  
         notify()
      }
    }
  
    useEffect(()=>{
      fetchStudentMessages()
    },[])
  
    const groupMessagesByYear = (messages) => {
      const groups = {}
      messages.forEach((message) => {
        const year = new Date(message.createdAt).getFullYear();
        if(!groups[year]){
          groups[year] = {messages:[]/*, classes:{}*/}
        }
        groups[year].messages.push(message);
       /* if(!groups[year].classes[message.classId]){
          groups[year].classes[message.classId] = []
        }
        groups[year].classes[message.classId].push(message)*/
      });
      return groups
    }
  
    const formatDate = (date) => {
      return new Date(date).toLocaleString('default',{month:'long',day:'numeric'})
    }

  
    const renderYear = ({year,groupedMessages})=>(
      <div className="parentmessagePortal-messages_container">
      <h3>{year}</h3>
 
           <div className="parentmessagePortal-messages_message-card">
             {groupedMessages[year].messages.map((message) => (
               <Link 
               style={{textDecoration:'none',color:'#333'}}
               to={`/viewmessage/${message?._id}?messageInfo=${encodeURIComponent(JSON.stringify({messageDetails:message}))}`}
               key={message?._id}
               >
               <p key={message?.createdAt}>
                 <span>{formatDate(message?.createdAt)}</span> - {truncateString(message?.comment,50,50)}
               </p>
              </Link> 
             ))}
           </div>
           
   </div>
    )
  
    const renderMessages = () => {
      //let groupedMessages //= groupMessagesByYear(messages);
      if(messages === null){
        return
      }
      const groupedMessages = groupMessagesByYear(messages);
        console.log({groupedMessages})
      
      
      //renderYear({groupedMessages})
      return Object.entries(groupedMessages).map(([year, messages]) => (
        <div key={year}>
          {renderYear({year, messages,groupedMessages})}
        </div>
      ))
    }

    return(
      <div className="parentmessagePortal_container">
         <div className="parentmessagePortal-functionalities_container">
          <div className="parentmessagePortal_header">
            <span>Message Portal</span>
          </div>

          <div className="parentmessagePortal-messages_wrapper">
            <div className="parentmessagePortal-messages_header">
              <span>Recent Messages</span>
            </div>

            {Loader ? (
              <div className="loaderContainer"><Digital/></div>
            ):(
              <>
                {messages?.length !== 0 ? (
                <>
                  {renderMessages()}
                </>
              ):(
               <span>Student Has No Messages</span>
              )
             }
              </>
            )}
            
          </div>  
          <div className="messagePortal_button">
              <button
               onClick={()=>navigate(-1)}
              >
                Back
              </button>
          </div>
         </div>
         <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        </div>
    )
}

export default ParentMessagingPortal