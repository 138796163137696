import React, { useState,useContext } from "react";
//import { AuthContext } from "../../GlobalStates/GlobalStates";
import {useNavigate } from "react-router-dom";
import {Dots} from "react-activity"
import "react-activity/dist/library.css"
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './Login.css'

  /**-------------ADMIN LOGIN PAGE---------- */
  const AdminLoginPage = () => {
    const [errorMessage, setErrorMessage] = useState("")
    const [loader, setLoader] = useState(false)
    const [credentials, setCredentials] = useState({
      admin_name: undefined,
      password: undefined,
    });

    const navigate = useNavigate()

    const adminLogin = async () => {
      setLoader(true);
      const options = {
        method: 'POST',
        body: JSON.stringify({
          admin_name: credentials.admin_name.trim(),
          password: credentials.password.trim(),
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      };
  
      try {
        const response = await fetch(`${global._URL}/schools/adminlogin`, options);
        const result = await response.json();
  
        setLoader(false);
        if (result.successMessage) {
          const user = result.result;
          setErrorMessage('');
  
          // Save token to context
          //setToken(response.headers.get('TOKEN'));
          console.log(response.headers.get('TOKEN'))

          /*setAuthState((prevAuthState) => ({
            ...prevAuthState,
            TOKEN: response.headers.get('TOKEN'),
          }));*/
  
          // Save Data to localStorage
          localStorage.setItem(
            'userCreds',
            JSON.stringify({
              user: {
                username: user.userData.admin_name,
                userId: user.userData._id,
                schoolId: user.userData.schoolId,
                schoolname: user.school,
              },
              role: 'admin',
            })
          );
  
          navigate('/home', { replace: true });
        } else {
          setLoader(false);
          setErrorMessage(result.errorMessage);
        }
      } catch (err) {
        setLoader(false);
        console.log('Server Error, try again', err);
        setErrorMessage('Server Error, try again');
      }
    };

    const handleChange = (focus) => {
      setCredentials((data) => ({
        ...data,
        [focus.target.id]: focus.target.value,
      }));
    };
    return (
        <div className="login-container">
          {loader && <Dots/>}
        {/*<div className="shop__login-container_logo">
            <p>ASAP</p>
        </div>*/}
        <div className="login-container_header">
            <p>School Admin account</p>
        </div>
        {errorMessage && (
        <div className="login_error">
            <p>{errorMessage}</p>
        </div>
        )}
        <div className="login-container_inputs">
            <input
                type="text"
                placeholder="admin name"
                id="admin_name"
                onChange={handleChange}
            />
            <input
                type="password"
                placeholder="password"
                id="password"
                onChange={handleChange}
            />
            <button
                onClick={adminLogin}
                className="login__button"
            >
                Login
            </button>
        </div>
        <ToastContainer/>
    </div>
    );
  };
  
  /**-----------Teacher Login Page---------------- */
  const TeacherLoginPage = ({ selectedSchool }) => {
    const [loader, setLoader] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [credentials, setCredentials] = useState({
      teachername: undefined,
      password: undefined,
    });

    const navigate = useNavigate()

    const handleTeacherChange = (focus) => {
      setCredentials((data) => ({
        ...data,
        [focus.target.id]: focus.target.value,
      }));
    };

    const teacherLogin = async() =>{
    setLoader(true)
      console.log({
        teacher_name:credentials.teachername,
        //password:credentials.password
      })
      const options = {
        method:'POST',
        body:JSON.stringify({
          teacher_name:credentials.teachername.trim(),
          password:credentials.password
        }),
        headers:{
          "Content-type": "application/json; charset=UTF-8",
        }
      }
       try {
        await fetch(`${global._URL}/schoolteachers/teacherlogin`,options)
           .then((res)=>{
            return res.json()
           })
           .then((result)=>{
            
           // console.log({token})
           console.log(result)
            
            setLoader(false)
            if(result.successMessage){
              const user = result.result
              setErrorMessage("")
              //Save token to cookies
              
              //Save Data to localStorage
             localStorage.setItem('userCreds',JSON.stringify({
                user:{
                  username:user.userData.admin_name,
                  userId:user.userData._id,
                  schoolId:user.userData.schoolId,
                  schoolname:user.school,
                  roleStatus:user.userData.roleStatus
                },
                role:'teacher'
               }
               
              ))
              navigate("/teachersdashboard",{replace:true})
            }else{
              setLoader(false)
              setErrorMessage(result.errorMessage)
            }
           })
           .catch((err)=>{
            setLoader(false)
            console.log("Server Error, try again",err)
            setErrorMessage("Server Error, try again")
           })
       } catch (error) {
        console.log('Failed to login admin',error)
        setErrorMessage("Try again")
       }
    }


    // Handle login logic for teacher
    return (
        <div className="login-container">
          {loader && <Dots/>}
        <div className="login-container_header">
            <p>School Teacher account</p>
        </div>
        {errorMessage && (
        <div className="login_error">
            <p>{errorMessage}</p>
        </div>
        )}
        <div className="login-container_inputs">
            <input
                type="text"
                placeholder="teacher name"
                id="teachername"
                onChange={handleTeacherChange}
            />
            <input
                type="password"
                placeholder="password"
                id="password"
                onChange={handleTeacherChange}
            />
            <button
                onClick={teacherLogin}
                className="login__button"
            >
                Login
            </button>
        </div>
    </div>
    );
  };
  
  /**------------Parent Login Page----------------- */
  const ParentLoginPage = () => {
    const [loader, setLoader] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [createAccount, setCreateAccount] = useState(false)
    const [credentials, setCredentials] = useState({
      studentID: undefined,
      password: undefined,
      password_again:undefined,
      childname:undefined,
      phonenumber:undefined,
      schoolname:undefined,
      classname: undefined,
      IDNumber:undefined
    });

    const navigate = useNavigate()
    // Handle login logic for parent
    const handleParentChange = (focus) =>{
      setCredentials((data) => ({
        ...data,
        [focus.target.id]: focus.target.value,
      }));
    }

    const handleParentLogin = async () =>{
      setLoader(true)
      console.log({
        studentID:credentials.studentID
      })
      
      const option = {
        method:'POST',
        body:JSON.stringify({
         studentID:credentials.studentID.trim(),
        /* schoolname:credentials.schoolname.trim(),
         classname:credentials.classname.trim(),
         IDNumber:credentials.IDNumber.trim()
         password:credentials.password,*/
        }),
        headers:{
          "Content-type":"application/json"
        }
      }

     try {
        await fetch(`${global._URL}/parents/parentlogin`,option)
          .then((res)=>{
            return res.json()
          })
          .then((result)=>{
            //console.log(result)
            setLoader(false)
            if(!result.errorMessage){
              setErrorMessage("")
              //Store Parent data to localstorage
              localStorage.setItem('@parentCreds', JSON.stringify(result?.studentData))
              localStorage.setItem('userCreds',JSON.stringify({
                user:{
                  username:result.studentname,
                  userId:result._id,
                  schoolId:result.schoolId,
                  studentId:result._id,
                  classId:result.classId
                },
                role:'parent'
               }
              ))
              navigate("/parentshome",{replace:true})
            }else{
              setErrorMessage(result.errorMessage)
            }
          })
          .catch((err)=>{
            setLoader(false)
            console.log("Failed to create account",err)
            setErrorMessage("Network Error")
          })
      } catch (error) {
        setLoader(false)
        console.log('Failed to create account',error)
        setErrorMessage("Try again")
      }
    }

    const handleParentCreateAccount =async ()=>{
      setLoader(true)
      console.log({
        parentname:credentials.studentname,
        childname:credentials.childname,
        phonenumber:credentials.phonenumber,
        schoolname:credentials.schoolname,
        password:credentials.password
      })

      if(credentials.password !== credentials.password_again) {
        const notify = () => {
          toast.error("Passwords do not match", {
              position: "top-center",
              autoClose:1500,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light"
          })
       };

       notify()
        return 

      }

      const option = {
        method:'POST',
        body:JSON.stringify({
         parentname:credentials.studentname.trim(),
         password:credentials.password,
         childname:credentials.childname,
         phonenumber:credentials.phonenumber,
         schoolname:credentials.schoolname
        }),
        headers:{
          "Content-type":"application/json"
        }
      }

      try {
        await fetch(`${global._URL}/parents/newparent`,option)
          .then((res)=>{
            return res.json()
          })
          .then((result)=>{
            setLoader(false)
            console.log(result)
            if(!result.errorMessage){
              setCreateAccount(false)
              const notify = () => {
                toast.success("Account successfuly created", {
                    position: "top-center",
                    autoClose:1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                })
             };
    
            notify()
            }else{
              setErrorMessage(result.errorMessage)
            }
          })
          .catch((err)=>{
            setLoader(false)
            console.log("Failed to create account",err)
            const notify = () => {
              toast.error("Server Error, try again", {
                  position: "top-center",
                  autoClose:1500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
              })
           };
    
           notify()
          })
      } catch (error) {
        setLoader(false)
        console.log('Failed to create account',error)
        const notify = () => {
          toast.warn("Disconnected, check Internet", {
              position: "top-center",
              autoClose:1500,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light"
          })
       };

       notify()
      }
    }

    return (
        <div className="login-container">
          {loader && <Dots/>}
        {!createAccount ? (
         <> 
        <div className="login-container_header">
          <p>Parent account</p>
        </div>
        {errorMessage && (
        <div className="login_error">
            <p>{errorMessage}</p>
        </div>
        )}
        <div className="login-container_inputs">
            <input
                type="text"
                placeholder="SCHOOL-123"
                id="studentID"
                onChange={handleParentChange}
            />
           {/* <input
                type="password"
                placeholder="secret"
                id="IDNumber"
                onChange={handleParentChange}
            />
            <input
                type="text"
                placeholder="school name"
                id="schoolname"
                onChange={handleParentChange}
            />
            <input
                type="text"
                placeholder="Class / Grade"
                id="classname"
                onChange={handleParentChange}
            />
            <input
                type="password"
                placeholder="ID Number"
                id="password"
                onChange={handleParentChange}
           />*/}
            <button
                onClick={handleParentLogin}
                className="login__button"
            >
                Login
            </button>
            
        </div>
        {/*<div className="login-createlink">
          <p onClick={()=>{setCreateAccount(true)}}>
            create parent account
          </p>
        </div>*/}
        </>
        ):(
        <div className="login-container">
         <div className="login-container_header">
          <p>Create Parent Account</p>
         </div>
         {errorMessage && (
        <div className="login_error">
            <p>{errorMessage}</p>
        </div>
        )}
       <div className="login-container_inputs">
          <input
              type="text"
              placeholder="parent name"
              id="parentname"
              onChange={handleParentChange}
          />
          <input
              type="text"
              placeholder="child name"
              id="childname"
              onChange={handleParentChange}
          />
          <input
              type="text"
              placeholder="phone number"
              id="phonenumber"
              onChange={handleParentChange}
          />
          <input
              type="text"
              placeholder="school name"
              id="schoolname"
              onChange={handleParentChange}
          />
          <input
              type="password"
              placeholder="Password"
              id="password"
              onChange={handleParentChange}
          />
          <input
              type="password"
              placeholder="Repeat password"
              id="password_again"
              onChange={handleParentChange}
          />
          <button
              onClick={handleParentCreateAccount}
              className="login__button"
          >
              Create Account
          </button>
      </div>
      <div className="login-createlink">
          <p onClick={()=>{setCreateAccount(false)}}>
            Back to Login
          </p>
      </div>
         </div>
        )}
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
    </div>
    );
  };

const WelcomePage = () => {
    const [selectedSchool, setSelectedSchool] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    //const [authState, setAuthState] = useContext()
  
  
    const handleSchoolChange = (e) => {
      setSelectedSchool(e.target.value);
    };
  
  
    const handleRoleChange = (e) => {
      setSelectedRole(e.target.value);
    };
  
  
    const handleLoginClick = () => {
      // Navigate to appropriate login page based on selected role
      if (selectedRole === "schoolAdmin") {
        console.log("Navigating to School Admin Login Page");
        // Navigate to AdminLoginPage
      } else if (selectedRole === "teacher") {
        console.log("Navigating to Teacher Login Page");
        // Navigate to TeacherLoginPage
      } else if (selectedRole === "parent") {
        console.log("Navigating to Parent Login Page");
        // Navigate to ParentLoginPage
      }
    };
  
  
    return (
      <div className="body_container">
        <div className="welcome-header">
          <div className="welcome-header_message">
            <p>Welcome,</p>
            <p>Login as:</p>
          </div>
          
          <div className="role-choice">
           <div className="choice-option">
           <label> 
            <input
              type="radio"
              name="role"
              value="schoolAdmin"
              checked={selectedRole === "schoolAdmin"}
              onChange={handleRoleChange}
            />
            School Admin
           </label>
           </div>
           <div className="choice-option">
           <label> 
            <input
              type="radio"
              name="role"
              value="teacher"
              checked={selectedRole === "teacher"}
              onChange={handleRoleChange}
            />
            Teacher
           </label>
           </div> 
          <div className="choice-option">
          <label>
            <input
              type="radio"
              name="role"
              value="parent"
              checked={selectedRole === "parent"}
              onChange={handleRoleChange}
            />
            Parent
          </label>
          </div>
          </div> 
        </div>
  
  
        {selectedRole && (
          <div className="login-pages">
            {selectedRole === "schoolAdmin" && <AdminLoginPage/>}
            {selectedRole === "teacher" && <TeacherLoginPage/>}
            {selectedRole === "parent" && <ParentLoginPage/>}
          </div>
        )}
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    );
  };
  
  
export default WelcomePage;


